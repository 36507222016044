import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { Theme } from '@mui/material';

import { azureLoginRequest } from '../login/azureConfig';
import useFSMAuthentication from './UseFSMAuthentication';
import Login from '../login/Login';
import Loading from '../loading/Loading';
import Unauthorized from '../unauthorized/Unauthorized';
import useGeaAuthorization from './UseGeaAuthorization';
import { FetchingStatus } from '../../../types';
import {
  fetchRefreshToken,
  getAzureAccount,
  isInsideFSM,
  LoginType,
  loginWithAzure,
  logout,
  useAuthData,
  useAuthenticated,
  useLogger,
} from '../../../utils';

interface Props {
  children: (isMocking: boolean) => ReactNode;
  theme: Theme;
}

const WithAuthentication: React.FC<Props> = ({ children, theme }) => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const [authStatus, setAuthStatus] = useState<FetchingStatus>(
    FetchingStatus.IDLE,
  );
  const { logError } = useLogger();
  const { instance: msalInstance } = useMsal();
  const isAuthorized = useGeaAuthorization();
  const isAuthenticated = useAuthenticated();
  const authData = useAuthData();
  const isInsideFSMShell = isInsideFSM();
  useFSMAuthentication({ setAuthStatus });

  useEffect(() => {
    if (!isInsideFSMShell) {
      return;
    }
    window.onbeforeunload = () => {
      window.sessionStorage.clear();
    };
  }, [isInsideFSMShell]);

  const handleRefreshTokenError = useCallback((reason: string) => {
    logError('Unable to refresh token. Reason:', reason);
    logout();
    setAuthStatus(FetchingStatus.IDLE);
    setIsSessionExpired(true);
  }, []);

  // Refresh token logic for Cognito tenants (username-password)
  useEffect(() => {
    if (
      authData === null ||
      authData.loginType !== LoginType.USERNAME_PASSWORD ||
      authData.expiry === null
    ) {
      return;
    }
    const expiresInMs = authData.expiry * 1000 - Date.now();
    const fiveMinutesBeforeExpiryMs = expiresInMs - 1000 * 60 * 5;
    const timeout = window.setTimeout(() => {
      fetchRefreshToken().catch((error) => {
        handleRefreshTokenError(error?.message);
      });
    }, fiveMinutesBeforeExpiryMs);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [authData, handleRefreshTokenError]);

  // Refresh token logic for Azure tenants
  useEffect(() => {
    if (
      authData === null ||
      authData.loginType !== LoginType.AZURE ||
      authData.expiry === null
    ) {
      return;
    }
    const expiresInMs = authData.expiry * 1000 - Date.now();
    const fiveMinutesBeforeExpiryMs = expiresInMs - 1000 * 60 * 5;
    const timeout = window.setTimeout(async () => {
      const account = getAzureAccount();
      if (account === null) {
        handleRefreshTokenError(
          'Azure account info missing, cannot refresh token.',
        );
        return;
      }
      try {
        const authResult = await msalInstance.acquireTokenSilent({
          account,
          forceRefresh: true,
          scopes: azureLoginRequest.scopes,
        });
        await loginWithAzure({
          email: account.username,
          token: authResult.idToken,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleRefreshTokenError(error?.message);
      }
    }, fiveMinutesBeforeExpiryMs);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [authData, msalInstance]);

  const isMocking = useMemo(() => {
    if (authData === null) {
      return false;
    }
    const { isInternalAccount, loginType } = authData;
    const isProduction =
      process.env.REACT_APP_ENVIRONMENT_NAME === 'production';
    // We do not want to activate mocking for these tenants.
    if (loginType === LoginType.USERNAME_PASSWORD && isProduction) {
      return false;
    }
    return isInternalAccount;
  }, [authData]);

  useEffect(() => {
    if (msalInstance === null) {
      logError('Could not set up Azure MSAL instance!');
    }
  }, [msalInstance]);

  const fsmAuthErrorOccurred =
    authStatus === FetchingStatus.ERROR && isInsideFSMShell;

  if (!isAuthorized || fsmAuthErrorOccurred) {
    return <Unauthorized />;
  }

  if (isInsideFSMShell && !isAuthenticated) {
    return <Loading />;
  }

  if (!isInsideFSMShell && !isAuthenticated && msalInstance !== null) {
    return (
      <Login
        authStatus={authStatus}
        setAuthStatus={setAuthStatus}
        isSessionExpired={isSessionExpired}
        theme={theme}
      />
    );
  }

  return <>{children(isMocking)}</>;
};

export default WithAuthentication;
