import api from './api';
import { ApiFetchParams, ApiResponse } from '../types';

export interface SourceImage {
  id: string;
  jobId: string;
  fileName: string;
  mimeType: string;
  url: string;
}

export interface MediaResponseData {
  items: SourceImage[];
}

type MediaResponse = ApiResponse<MediaResponseData>;

interface FetchMediaParams extends ApiFetchParams {
  jobIds: string[];
}

export const fetchMedia = async ({
  jobIds,
  baseUrl,
  signal,
  mock,
}: FetchMediaParams): Promise<SourceImage[]> => {
  const { data } = await api.get<MediaResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/media-files',
    queryParams: {
      jobIds,
    },
    config: { signal },
  });

  return data.items;
};
