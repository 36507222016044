interface CustomerSummaryMockData {
  answer: string;
}

export const ukOnshoreSummaryPartialSeed: CustomerSummaryMockData[] = [
  {
    answer: `<p>UK Onshore WF, based in Middlesbrough, United Kingdom, has a significant service history with us, totaling 365 service jobs. Of these, 343 have been successfully closed, leaving 22 still open. This represents a closure rate of 94%, which is slightly lower than our global average of 98.2%.</p>`,
  },
  {
    answer: `<p>In terms of service job types, UK Onshore WF has a unique distribution compared to our global averages. They have a significantly lower proportion of Failure type jobs (46% vs. 84.5% globally) and a much higher proportion of Maintenance type jobs (53.7% vs. 12.6% globally).</p>`,
  },
  {
    answer: `<b>Service Level Agreement:</b><br><ul><li><a>SLA_UK_Onshore_WF.pdf</a></li><li>All previous issues resolved within agreed timelines. No outstanding SLA violations.</li></ul>`,
  },
  {
    answer: `UK Onshore WF has a positive communication history with regular monthly check-ins and quarterly reviews. Previous concerns related to pitch sensor issues were promptly resolved. The customer prefers email communication, responds promptly during office hours, and has provided positive feedback on equipment efficiency. Currently, a reported issue of a generator fault has a moderate impact on daily operations, deemed urgent due to potential production impact, and has a 'High' priority for immediate attention. Regular maintenance is scheduled for next month.`,
  },
];

export const ukOnshoreSummaryFinalSeed: CustomerSummaryMockData =
  ukOnshoreSummaryPartialSeed.reduce(
    (acc, partial) => {
      acc.answer = acc.answer + partial.answer;

      return acc;
    },
    { answer: '' },
  );

export const esbOffshoreSummaryPartialSeed: CustomerSummaryMockData[] = [
  {
    answer: `<p>ESB, based in Parkeston, is one of our long-standing offshore customers with a service history spanning multiple years. To date, we have completed 150 service jobs for ESB, successfully closing 142 of them. This reflects a closure rate of 94.7%, which aligns closely with our global average. The service job distribution for ESB indicates a balanced approach, `,
  },
  {
    answer: `with a mix of Failure and Maintenance type jobs. Notably, ESB has a slightly higher proportion of Maintenance jobs (58.3%) compared to our global average, suggesting a proactive approach to equipment care.</p>`,
  },
  {
    answer: `<b>Service Level Agreement:</b><br><ul><li><a>SLA_ESB_Offshore.pdf</a></li><li>All previous issues resolved within agreed timelines. No outstanding SLA violations.</li></ul>`,
  },
  {
    answer: `Communication with ESB is established through periodic check-ins and quarterly reviews. Previous concerns, particularly related to turbine performance, were addressed promptly. ESB prefers direct communication channels and exhibits timely responses during office hours. Currently, an identified issue with turbine alignment is marked as 'Medium' priority for resolution. A scheduled maintenance session is planned for the upcoming quarter.`,
  },
];

export const esbOffshoreSummaryFinalSeed: CustomerSummaryMockData =
  esbOffshoreSummaryPartialSeed.reduce(
    (acc, partial) => {
      acc.answer = acc.answer + partial.answer;

      return acc;
    },
    { answer: '' },
  );

export const landsvirkjunSummaryPartialSeed: CustomerSummaryMockData[] = [
  {
    answer: `<p>Landsvirkjun, headquartered in Reykjavik, Iceland, has been a valued customer with whom we have completed 80 service jobs. Out of these, 65 have been successfully closed, resulting in a closure rate of 81.3%. `,
  },
  {
    answer: `Landsvirkjun exhibits a distinctive service job distribution that deviates from the global average. The proportion of Failure jobs is significantly higher than Maintenance jobs, in contrast to our typical global distribution.</p>`,
  },
  {
    answer: `<b>Service Level Agreement:</b><br><ul><li><a>SLA_Landsvirkjun.pdf</a></li><li>Despite our historical success in meeting service level agreements (SLAs) with Landsvirkjun, a recent issue has arisen where we failed to adhere to the agreed timelines.</li><li>The specific SLA breach relates to the resolution time for a reported issue with the power converter.</li></ul>`,
  },
  {
    answer: `Communication with Landsvirkjun is characterized by quarterly phone calls with our customer success manager. Historical concerns regarding gearbox performance have been addressed promptly. Landsvirkjun favors communication through formal channels and demonstrates timely responses (24/7 availability). No maintenance work is planned for the rest of the year.`,
  },
];

export const landsvirkjunSummaryFinalSeed: CustomerSummaryMockData =
  landsvirkjunSummaryPartialSeed.reduce(
    (acc, partial) => {
      acc.answer = acc.answer + partial.answer;

      return acc;
    },
    { answer: '' },
  );

export const borsseleSummaryPartialSeed: CustomerSummaryMockData[] = [
  {
    answer: `<p>Borssele Sites I, located in the Netherlands, has engaged in 60 service jobs with us. Of these, 58 have been successfully closed, resulting in a closure rate of 96.7%, surpassing our global average. `,
  },
  {
    answer: `The service job distribution for Borssele Sites is notably skewed towards Maintenance type jobs, constituting 68.3%, indicating a strong emphasis on proactive equipment care.</p>`,
  },
  {
    answer: `<b>Service Level Agreement:</b><br><ul><li><a>SLA_Borssele_Sites_I.pdf</a></li><li>No outstanding SLA violations have been reported.</li></ul>`,
  },
  {
    answer: `Communication with Borssele Sites is characterized by regular monthly check-ins with our support manager. Historical concerns related to sensor calibration were promptly resolved. Borssele Sites prefers communication through formal channels. Currently, a reported issue with the yaw system has a low impact on daily operations, marked as routine, and holds a 'Medium' priority for attention.`,
  },
];

export const borsseleSummaryFinalSeed: CustomerSummaryMockData =
  borsseleSummaryPartialSeed.reduce(
    (acc, partial) => {
      acc.answer = acc.answer + partial.answer;

      return acc;
    },
    { answer: '' },
  );

export const hollandseSummaryPartialSeed: CustomerSummaryMockData[] = [
  {
    answer: `<p>Hollandse Kust (Noord), based in the Netherlands, is a relatively new customer with whom we have completed 30 service jobs. All 30 jobs have been successfully closed, resulting in a commendable closure rate of 100%. The service job distribution for Hollandse Kust is evenly split between Failure and Maintenance type jobs, aligning closely with our global averages.</p>`,
  },
  {
    answer: `<b>Service Level Agreement:</b><br><ul><li><a>SLA_Hollandse_Kust_Noord.pdf</a></li><li>No outstanding SLA violations have been reported.</li></ul>`,
  },
  {
    answer: `Regular communication with Hollandse Kust involves monthly meetings and reviews. Any past concerns about turbine performance were quickly resolved. Hollandse Kust generally prefers using formal communication channels and is known for responding promptly during office hours. `,
  },
  {
    answer: `There are currently no reported issues affecting daily operations and routine maintenance work is planned for the coming quarter, of which the customer will be informed in advance. It is important to note that there are no plans for maintenance work during peak production times.`,
  },
];

export const hollandseSummaryFinalSeed: CustomerSummaryMockData =
  hollandseSummaryPartialSeed.reduce(
    (acc, partial) => {
      acc.answer = acc.answer + partial.answer;

      return acc;
    },
    { answer: '' },
  );
