import React from 'react';

import { ReactComponent as MenuIcon } from './assets/menu.svg';
import styles from './MobileHeader.module.scss';

interface Props {
  onDrawerToggle: () => void;
  title?: string | null;
}

const MobileHeader: React.FC<Props> = ({ onDrawerToggle, title }) => {
  return (
    <div className={styles.container}>
      <button onClick={onDrawerToggle} className={styles.drawerBtn}>
        <MenuIcon className={styles.drawerIcon} />
      </button>
      {title && <h1 className={styles.title}>{title}</h1>}
    </div>
  );
};

export default MobileHeader;
