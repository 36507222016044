import { isDefined } from './isDefined';

const VERTICAL_OFFSET_PX = 24;
const HEADER_HEIGHT_PX = 116;

export interface ScrollToSessionParams {
  scrollContainerRef: HTMLDivElement;
  sessionId: string;
  behavior?: ScrollBehavior;
}

export const scrollToSession = ({
  sessionId,
  scrollContainerRef,
  behavior = 'smooth',
}: ScrollToSessionParams): void => {
  const session = document.getElementById(`session-${sessionId}`);
  if (!isDefined(session)) {
    return;
  }
  const position = session.offsetTop;
  scrollContainerRef.scrollTo({
    behavior,
    top: position - HEADER_HEIGHT_PX - VERTICAL_OFFSET_PX,
  });
};
