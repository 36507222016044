import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types';
import { AppThunkConfig } from '../store';
import { getAuthDataFromStorage } from '../../utils';
import {
  fetchTrendingSymptoms,
  TrendingSymptomMetric,
} from '../../integration/trendingSymptoms.api';
import { Customer } from '../../integration/customers.api';

export const TRENDING_SYMPTOMS_KEY = 'trendingSymptoms';

export interface TrendingSymptomsState {
  status: FetchingStatus;
  symptoms: TrendingSymptomMetric[] | null;
}

const initialState: TrendingSymptomsState = {
  status: FetchingStatus.IDLE,
  symptoms: null,
};

interface GetTrendingSymptomsParams {
  customer: Customer;
  httpUrl: string;
  isMocking: boolean;
}

export const getTrendingSymptoms = createAsyncThunk<
  TrendingSymptomMetric[],
  GetTrendingSymptomsParams,
  AppThunkConfig
>(
  'getTrendingSymptoms',
  async ({ customer, httpUrl, isMocking }, { signal }) => {
    const authData = getAuthDataFromStorage();
    if (authData === null) {
      throw new Error('Not authenticated!');
    }

    return fetchTrendingSymptoms({
      signal,
      baseUrl: httpUrl,
      mock: isMocking,
      customerId: customer.id,
    });
  },
);

const trendingSymptomsSlice = createSlice({
  name: TRENDING_SYMPTOMS_KEY,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getTrendingSymptoms.pending, (state) => {
        state.status = FetchingStatus.PENDING;
        state.symptoms = null;
      })
      .addCase(getTrendingSymptoms.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.symptoms = action.payload;
      })
      .addCase(getTrendingSymptoms.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.symptoms = null;
      });
  },
});

const { actions, reducer } = trendingSymptomsSlice;

export const { reset: resetTrendingSymptoms } = actions;

export const assistantTrendingSymptomsReducer = reducer;
