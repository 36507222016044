import api from './api';
import {
  ApiResponse,
  FetchMetricsParams,
  KpiSummary,
  MetricWithTrend,
  TimeRange,
  Trend,
} from '../types';
import { getAverage, isValidNumber, truncateNumber } from '../utils';

export interface MeanTimeBetweenVisitsMetric {
  date_interval: string;
  mean_time_between_visits: string; // BE is sending string, but actually a number
}

export interface MeanTimeBetweenVisitsMetrics extends MetricWithTrend {
  mean_time_between_visits: MeanTimeBetweenVisitsMetric[];
}

type MtbvResponse = ApiResponse<MeanTimeBetweenVisitsMetrics>;

export const fetchMeanTimeBetweenVisits = async ({
  baseUrl,
  mock,
  signal,
  customerId,
}: FetchMetricsParams): Promise<KpiSummary> => {
  const { data } = await api.get<MtbvResponse>({
    baseUrl,
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      customerId,
      timeRange: TimeRange.ONE_YEAR,
      customerView: true,
      withTrend: true,
      type: 'MeanTimeBetweenVisits',
    },
    config: { signal },
  });
  const trendValue = isValidNumber(data.trend) ? data.trend * 100 : null;
  const trend = resolveMtbvTrend(trendValue);
  const values = data.mean_time_between_visits
    .map((metric) => Number(metric.mean_time_between_visits))
    .filter((value) => isValidNumber(value));
  if (values.length === 0) {
    return {
      trendValue,
      trend,
      average: null,
    };
  }

  const average = truncateNumber(getAverage(values));

  return {
    average,
    trendValue,
    trend,
  };
};

const resolveMtbvTrend = (trendValue: number | null): Trend => {
  if (trendValue === null || trendValue === 0) {
    return Trend.None;
  }
  if (trendValue > 0) {
    return Trend.Positive;
  } else {
    return Trend.Negative;
  }
};
