import React, { useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { SupportedLanguage } from '../../translations/common';
import { shortenText, useTranslations } from '../../utils';

const PREDICTION_MAX_WORDS = 50;

interface Props {
  prediction: string | null;
  language: SupportedLanguage;
}

const JobPrediction: React.FC<Props> = ({ prediction }) => {
  const {
    assistant: { home: translations },
  } = useTranslations();
  const shortenedPrediction = useMemo(() => {
    if (prediction === null) {
      return null;
    }
    return shortenText(PREDICTION_MAX_WORDS)(prediction);
  }, [prediction]);
  const isPredictionShortened = prediction !== shortenedPrediction;

  if (prediction === null) {
    return <p>{translations.noPrediction}</p>;
  }

  return (
    <Tooltip disableHoverListener={!isPredictionShortened} title={prediction}>
      <p>{shortenedPrediction}</p>
    </Tooltip>
  );
};

export default JobPrediction;
