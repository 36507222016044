import { createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../store';
import { getAuthDataFromStorage } from '../../utils';
import { FetchingStatus } from '../../types';
import {
  ChatbotQueryPayload,
  WebSocketQueryAction,
  wsClient,
} from '../../integration/webSocketClient';
import { initializeStreaming } from '../slices/streaming.slice';
import { SupportedLanguage } from '../../translations/common';
import { Customer } from '../../integration/customers.api';
import { createCustomerSession } from '../slices/customerDetails.slice';
import { getCustomerPastJobs } from '../slices/pastJobs.slice';
import {
  getFirstTimeFixRate,
  getMeanTimeBetweenVisits,
  getMeanTimeToResolution,
} from './customerKpi.actions';

interface GetCustomerDetailsParams {
  customer: Customer;
  language: SupportedLanguage;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
}

interface CustomerSummaryQueryPayload extends ChatbotQueryPayload {
  customerId: string;
}

export const getCustomerDetails = createAsyncThunk<
  void,
  GetCustomerDetailsParams,
  AppThunkConfig
>('getCustomerDetails', async (params, { getState, dispatch }) => {
  const { customer, language, isMocking, wsUrl, httpUrl, logError, logInfo } =
    params;
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('Not authenticated!');
  }
  const {
    streaming: { initStatus },
    customerDetails: { sessions },
  } = getState();

  if (sessions.some((session) => session.id === params.customer.id)) {
    return;
  }

  dispatch(
    createCustomerSession({
      customer,
    }),
  );
  dispatch(
    getCustomerPastJobs({
      customer,
      logError,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );
  dispatch(
    getFirstTimeFixRate({
      customer,
      logError,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );
  dispatch(
    getMeanTimeToResolution({
      customer,
      logError,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );
  dispatch(
    getMeanTimeBetweenVisits({
      customer,
      logError,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );

  const isSent = wsClient.send<CustomerSummaryQueryPayload>(
    WebSocketQueryAction.CustomerSummary,
    {
      language,
      customerId: customer.id,
      tenantId: authData.tenantId,
      token: authData.token,
    },
    logError,
  );
  if (!isSent && initStatus !== FetchingStatus.PENDING) {
    dispatch(
      initializeStreaming({
        logInfo,
        logError,
        wsUrl,
        httpUrl,
        language,
        isMocking,
      }),
    );
  }
});
