import React, { useEffect } from 'react';

import { AssistantProps } from '../../../types';
import { useAppDispatch } from '../../../store/utils/hooks';
import { resetInputs } from '../../../store/slices/inputs.slice';
import SmartLoopContainer from '../../SmartLoopContainer/SmartLoopContainer';
import HomeHeader from './Header/HomeHeader';
import HomeOpenJobs from './OpenJobs/HomeOpenJobs';
import styles from './SmartLoopHome.module.scss';

const SmartLoopHome: React.FC<AssistantProps> = (props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(resetInputs());
  }, [dispatch]);

  return (
    <SmartLoopContainer {...props}>
      <HomeHeader {...props} />
      <div className={styles.openJobsContainer}>
        <HomeOpenJobs {...props} />
      </div>
    </SmartLoopContainer>
  );
};

export default SmartLoopHome;
