import React from 'react';

import { ReactComponent as SearchHistoryIcon } from '../../../../assets/search-history.svg';
import { useTranslations } from '../../../../utils';
import {
  isHistoryEmpty,
  SearchHistoryEntrySegment,
  useSearchHistory,
} from './UseSearchHistory';
import { useAppSelector } from '../../../../store/utils/hooks';
import { FetchingStatus } from '../../../../types';
import SearchHistorySegment from './Segment/SearchHistorySegment';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './SearchHistory.module.scss';

interface Props {
  onClose: () => void;
}

const SearchHistory: React.FC<Props> = ({ onClose }) => {
  const {
    assistant: { drawer: translations },
  } = useTranslations();
  const { status } = useAppSelector((state) => state.history);
  const history = useSearchHistory();

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>
        <SearchHistoryIcon className={styles.searchHistoryIcon} />
        <span>{translations.searchHistory}</span>
      </h3>
      <div className={styles.segments} onClick={onClose}>
        {status === FetchingStatus.PENDING && isHistoryEmpty(history) && (
          <div className={styles.loading}>
            <CircularProgress sx={{ color: 'inherit' }} />
          </div>
        )}
        <SearchHistorySegment
          history={history}
          segment={SearchHistoryEntrySegment.Today}
        />
        <SearchHistorySegment
          history={history}
          segment={SearchHistoryEntrySegment.Yesterday}
        />
        <SearchHistorySegment
          history={history}
          segment={SearchHistoryEntrySegment.LastWeek}
        />
        <SearchHistorySegment
          history={history}
          segment={SearchHistoryEntrySegment.OlderThanLastWeek}
        />
      </div>
    </div>
  );
};

export default SearchHistory;
