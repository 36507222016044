import { v1 as generateId } from 'uuid';

import { Symptom, SymptomType } from '../types';

export const generateSymptom = (name: string): Symptom => {
  const id = generateId();

  return {
    id,
    name,
    type: SymptomType.UserInput,
  };
};
