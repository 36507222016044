import { Symptom, SymptomType } from '../../types';

export const getRelatedSymptomsSeed = (initialSymptom: string): Symptom[] => {
  if (initialSymptom.match(/blade/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Abnormal Noise from the Rotor',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom2',
        name: 'Unexpected Vibrations',
        type: SymptomType.Static,
      },
      {
        id: 'symptom3',
        name: 'Alarms for Aerodynamic Imbalance',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom4',
        name: 'Structural Stress Indicators',
        type: SymptomType.Static,
      },
      {
        id: 'symptom5',
        name: 'Increased Wear on Bearings or Gears',
        type: SymptomType.Dynamic,
      },
    ];
  }
  if (initialSymptom.match(/generator/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Erratic Operation',
        type: SymptomType.Dynamic,
      },
    ];
  }
  if (initialSymptom.match(/pitch bearing/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Increased Pitch Motor Load',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom2',
        name: 'Lubricant Leakage or Degradation',
        type: SymptomType.Static,
      },
      {
        id: 'symptom3',
        name: 'Irregular Blade Pitch Angles',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom4',
        name: 'Erratic Turbine Operation',
        type: SymptomType.Static,
      },
      {
        id: 'symptom5',
        name: 'Overheating of Pitch System Components',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom6',
        name: 'Wear Debris in Oil',
        type: SymptomType.Static,
      },
    ];
  }

  return [
    {
      id: 'symptom1',
      name: 'oil leakage',
      type: SymptomType.Dynamic,
    },
    {
      id: 'symptom2',
      name: 'insufficient oil levels',
      type: SymptomType.Static,
    },
  ];
};
