import { TrendingCustomersData } from '../../integration/trendingCustomers.api';

export const trendingCustomersSeed: TrendingCustomersData = {
  customers_in_focus: [
    {
      customerId: 'customer1',
      name: 'UK Onshore WF',
    },
    {
      customerId: 'customer2',
      name: 'ESB (offshore)',
    },
    {
      customerId: 'customer3',
      name: 'Landsvirkjun',
    },
    {
      customerId: 'customer4',
      name: 'Borssele Sites I',
    },
    {
      customerId: 'customer5',
      name: 'Hollandse Kust (noord)',
    },
  ],
};
