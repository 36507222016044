import React, { useCallback, useEffect, useMemo } from 'react';
import {
  DataGrid,
  gridClasses,
  GridColDef,
  GridPaginationModel,
  GridSortModel,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import clsx from 'clsx';

import {
  AssistantProps,
  DOCUMENTS_PAGE_SIZE_OPTIONS,
  FetchingStatus,
  AppDocument,
  DocumentsPageSize,
} from '../../../../../types';
import { useIsDarkMode, useTranslations } from '../../../../../utils';
import { ReactComponent as DownloadIcon } from '../assets/download.svg';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../store/utils/hooks';
import {
  getDocuments,
  removeDocument,
  updateDocumentPage,
  updateDocumentPageSize,
  updateDocumentSorting,
} from '../../../../../store/slices/documents.slice';
import styles from './DocumentTable.module.scss';

interface Props extends AssistantProps {
  // We need this to force a documents reload when an upload process is finished
  reloadCounter: number;
}

const DocumentTable: React.FC<Props> = ({ reloadCounter, ...props }) => {
  const { httpUrl, isMocking, logError } = props;
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const dispatch = useAppDispatch();
  const { totalElements, pageSize, currentPage, documents, status, sorting } =
    useAppSelector((state) => state.documents);

  // This hack is needed, because setting it to () => 'auto' as the documentation suggests (https://mui.com/x/react-data-grid/row-height/)
  // will often not render the content when the filters are changed.
  const getRowHeight = useCallback(
    () => (documents !== null && documents.length > 0 ? 'auto' : 50),
    [documents],
  );

  const onSortModelChange = useCallback(
    (model: GridSortModel) => {
      dispatch(updateDocumentSorting(model));
    },
    [dispatch],
  );
  const onPaginationChange = useCallback(
    (model: GridPaginationModel) => {
      if (model.pageSize !== pageSize) {
        dispatch(updateDocumentPage(0));
        dispatch(updateDocumentPageSize(model.pageSize as DocumentsPageSize));
      } else {
        dispatch(updateDocumentPage(model.page));
      }
    },
    [dispatch, pageSize],
  );

  useEffect(() => {
    const promise = dispatch(
      getDocuments({
        sorting,
        pageSize,
        currentPage,
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    httpUrl,
    isMocking,
    sorting,
    pageSize,
    currentPage,
    reloadCounter,
  ]);

  const handleDelete = (id: string, name: string) => {
    dispatch(
      removeDocument({ id, name, logError, baseUrl: httpUrl, mock: isMocking }),
    );
  };

  const columns: GridColDef<AppDocument[][number]>[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: translations.fileName,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
      },
      {
        field: 'tags',
        headerName: translations.tags,
        filterable: false,
        disableColumnMenu: true,
        sortable: false,
        flex: 5,
        renderCell: ({ row: document }) => {
          return (
            <div className={styles.tags}>
              {document.tags.map((tag) => (
                <div
                  key={tag}
                  className={clsx(styles.tag, darkMode && styles.darkMode)}
                >
                  {tag}
                </div>
              ))}
            </div>
          );
        },
      },
      {
        field: 'created',
        headerName: translations.date,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        valueFormatter: (created: number) =>
          format(new Date(created), 'dd.MM.yyyy'),
      },
      {
        field: 'size',
        headerName: translations.fileSize,
        filterable: false,
        disableColumnMenu: true,
        flex: 2,
        renderCell: ({ row: document }) => {
          return (
            <div className={styles.interactions}>
              <span className={styles.size}>{document.size}</span>
              <a
                href={document.url}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon className={styles.downloadIcon} />
              </a>
              <DeleteIcon
                className={styles.deleteIcon}
                onClick={() => handleDelete(document.id, document.name)}
              />
            </div>
          );
        },
      },
    ];
  }, [translations]);

  return (
    <DataGrid
      rows={documents || []}
      loading={status === FetchingStatus.PENDING}
      slotProps={{
        loadingOverlay: {
          variant: 'skeleton',
          noRowsVariant: 'skeleton',
        },
      }}
      columns={columns}
      pageSizeOptions={DOCUMENTS_PAGE_SIZE_OPTIONS}
      disableRowSelectionOnClick
      disableColumnSelector
      sortingMode="server"
      sortModel={sorting}
      onSortModelChange={onSortModelChange}
      paginationMode="server"
      paginationModel={{ page: currentPage, pageSize }}
      onPaginationModelChange={onPaginationChange}
      rowCount={totalElements}
      autosizeOnMount
      getRowHeight={getRowHeight}
      sx={{
        [`& .${gridClasses.row}`]: {
          '&:hover': {
            backgroundColor: 'inherit',
          },
        },
        [`& .${gridClasses.columnSeparator}`]: {
          [`&:not(.${gridClasses['columnSeparator--resizable']})`]: {
            display: 'none',
          },
        },
        [`& .${gridClasses.columnHeader}`]: {
          padding: '0 16px',
        },
        [`& .${gridClasses.columnHeaderTitle}`]: {
          fontWeight: 700,
        },
        [`& .${gridClasses.cell}`]: {
          padding: '16px',
        },
        [`& .${gridClasses.footerContainer}, .${gridClasses.main}, .${gridClasses.columnHeader}, .${gridClasses.scrollbarFiller}`]:
          {
            background: darkMode ? '#1A1A1A' : '#fff',
          },
      }}
      disableColumnResize
    />
  );
};

export default DocumentTable;
