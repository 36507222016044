import { ApiFetchParams } from './api';

export interface MetricWithTrend {
  trend: number | null;
}

export enum Trend {
  Positive,
  Negative,
  None,
}

export interface KpiSummary {
  average: number | null;
  trend: Trend;
  trendValue: number | null;
}

export interface FetchMetricsParams extends ApiFetchParams {
  customerId: string;
}
