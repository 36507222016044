import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

import { FetchingStatus, Trend } from '../../../../../../types';
import { truncateNumber, useTranslations } from '../../../../../../utils';
import styles from './KpiTrend.module.scss';

interface Props {
  status: FetchingStatus;
  trend: Trend;
  trendValue: number | null;
}

const KpiTrend: React.FC<Props> = ({ status, trend, trendValue }) => {
  const {
    assistant: {
      customerScreen: { kpi: translations },
    },
  } = useTranslations();

  if (status === FetchingStatus.PENDING) {
    return (
      <Skeleton
        animation="wave"
        variant="rectangular"
        className={styles.trendLoading}
      />
    );
  }

  if (trendValue === null) {
    return <div className={styles.trend}>N/A</div>;
  }

  return (
    <div className={styles.trend}>
      <div
        className={clsx(
          styles.trendValue,
          trend === Trend.Positive && styles.positiveTrend,
          trend === Trend.Negative && styles.negativeTrend,
        )}
      >
        {truncateNumber(trendValue)}%
      </div>
      <div className={styles.comparisonLabel}>
        {trendValue < 0
          ? translations.lessThanAverage
          : translations.moreThanAverage}
      </div>
    </div>
  );
};

export default KpiTrend;
