import React from 'react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CompletedIcon } from './assets/completed.svg';
import { ReactComponent as PendingIcon } from './assets/pending.svg';
import { ReactComponent as DateIcon } from './assets/date.svg';
import {
  AppRoute,
  AssistantProps,
  MixpanelEventType,
  Survey,
  SurveyStatus,
} from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { getSurveyDetails } from '../../../../store/slices/surveyDetails.slice';
import { trackWithMixpanel, useTranslations } from '../../../../utils';
import styles from './Survey.module.scss';

interface Props extends AssistantProps {
  survey: Survey;
}

const CurationHubSurvey: React.FC<Props> = ({
  survey,
  httpUrl,
  isMocking,
  environment,
}) => {
  const {
    assistant: {
      curationHub: { survey: translations },
    },
  } = useTranslations();
  const surveyAvailable = survey.status !== SurveyStatus.Closed;
  const dispatch = useAppDispatch();
  const currentSurveyDetails = useAppSelector(
    (state) => state.surveyDetails.details,
  );
  const navigate = useNavigate();

  const handleOpen = () => {
    if (!surveyAvailable) {
      return;
    }
    if (currentSurveyDetails?.id !== survey.id) {
      dispatch(
        getSurveyDetails({
          httpUrl,
          isMocking,
          survey,
        }),
      );
    }
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.SurveyOpened,
        properties: {
          surveyId: survey.id,
          equipmentType: survey.equipment_type,
        },
      },
    });
    navigate(`${AppRoute.Surveys}/${survey.id}`);
  };

  return (
    <div
      className={clsx(styles.container, surveyAvailable && styles.open)}
      onClick={handleOpen}
    >
      <div>
        <h3 className={styles.equipment}>{survey.equipment_type}</h3>
        <p className={styles.symptom}>{survey.first_question}</p>
        <div className={styles.details}>
          <div className={styles.detail}>
            {surveyAvailable ? (
              <PendingIcon className={clsx(styles.icon, styles.statusIcon)} />
            ) : (
              <CompletedIcon className={clsx(styles.icon, styles.statusIcon)} />
            )}
            <span className={styles.status}>
              {translations.surveyStatuses[survey.status]}
            </span>
          </div>
          <div className={clsx(styles.detail, styles.detailBox)}>
            <span className={styles.detailText}>
              <b>{survey.answered_question_count}</b>/
              {survey.total_question_count}
            </span>
          </div>
          <div className={clsx(styles.detail, styles.detailBox)}>
            <DateIcon className={clsx(styles.icon, styles.dateIcon)} />
            <span className={styles.detailText}>
              <b>{format(survey.created_at, 'dd LLL yyyy')}</b>
            </span>
          </div>
        </div>
      </div>
      {surveyAvailable && (
        <button className={styles.openBtn}>{translations.open}</button>
      )}
    </div>
  );
};

export default CurationHubSurvey;
