import React from 'react';

import { CustomerSessionProps, Trend } from '../../../../../types';
import { useTranslations } from '../../../../../utils';
import { useOnDesktop } from '../../../../../utils/responsive';
import KpiCard from '../KpiCard/KpiCard';

const MeanTimeToResolution: React.FC<CustomerSessionProps> = ({ session }) => {
  const { mttr, mttrStatus } = session;
  const {
    assistant: {
      customerScreen: { kpi: translations },
    },
  } = useTranslations();
  const isDesktop = useOnDesktop();

  if (mttr === null) {
    return (
      <KpiCard
        title={translations.mttr}
        summary={{
          average: null,
          trend: Trend.None,
          trendValue: null,
        }}
        status={mttrStatus}
      />
    );
  }

  return (
    <KpiCard
      title={isDesktop ? translations.mttr : translations.mttrAbbr}
      summary={mttr}
      valueUnit={
        mttr.average !== null ? translations.dayUnit(mttr.average) : undefined
      }
      status={mttrStatus}
    />
  );
};

export default MeanTimeToResolution;
