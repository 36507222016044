import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FetchingStatus,
  FetchOpenJobsResults,
  JobWithPrediction,
  OPEN_JOBS_DEFAULT_FILTER_STATE,
  OpenJobsFilter,
  OpenJobsFilterState,
  OpenJobsRequestParams,
  OpenJobsSorting,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchOpenJobs } from '../../integration/openJobs.api';
import { isDefined } from '../../utils';

export const OPEN_JOBS_KEY = 'openJobs';

export interface OpenJobsState extends OpenJobsFilterState {
  status: FetchingStatus;
  jobs: JobWithPrediction[] | null;
}

const initialState: OpenJobsState = {
  ...OPEN_JOBS_DEFAULT_FILTER_STATE,
  // We want this to be loading by default, because we delay the first request until the user profile loads,
  // and we don't want to show an empty card until that happens.
  status: FetchingStatus.PENDING,
  jobs: null,
};

export const getOpenJobs = createAsyncThunk<
  FetchOpenJobsResults,
  ApiThunkParams & OpenJobsRequestParams,
  AppThunkConfig
>(
  'getOpenJobs',
  async (
    { baseUrl, mock, logError, sorting, filter, currentPage, pageSize },
    { signal },
  ) => {
    return fetchOpenJobs({
      signal,
      logError,
      baseUrl,
      mock,
      sorting,
      filter,
      currentPage,
      pageSize,
    });
  },
);

const openJobsSlice = createSlice({
  name: OPEN_JOBS_KEY,
  initialState,
  reducers: {
    updateOpenJobsFilter: (
      state,
      action: PayloadAction<OpenJobsFilter | null>,
    ) => {
      if (action.payload === null) {
        return {
          ...state,
          filter: null,
        };
      }
      return {
        ...state,
        filter: {
          field: action.payload.field,
          value: isDefined(action.payload.value)
            ? String(action.payload.value)
            : '',
        },
      };
    },
    updateOpenJobsSorting: (state, action: PayloadAction<OpenJobsSorting>) => ({
      ...state,
      sorting: action.payload,
    }),
    updateOpenJobsPageSize: (state, action: PayloadAction<number>) => ({
      ...state,
      pageSize: action.payload,
    }),
    updateOpenJobsPage: (state, action: PayloadAction<number>) => ({
      ...state,
      currentPage: action.payload,
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getOpenJobs.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getOpenJobs.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.jobs = action.payload.jobs;
        state.totalElements = action.payload.totalElements;
      })
      .addCase(getOpenJobs.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.jobs = null;
        state.totalElements = 0;
      });
  },
});

const { actions, reducer } = openJobsSlice;

export const {
  updateOpenJobsPageSize,
  updateOpenJobsPage,
  updateOpenJobsSorting,
  updateOpenJobsFilter,
  reset: resetOpenJobs,
} = actions;

export const openJobsReducer = reducer;
