import React from 'react';
import clsx from 'clsx';

import { JobType } from '../../../types';
import { useTranslations } from '../../../utils';
import styles from './JobTypeField.module.scss';

interface Props {
  type: JobType;
}

const JobTypeField: React.FC<Props> = ({ type }) => {
  const {
    assistant: { jobType: translations },
  } = useTranslations();

  return (
    <div
      className={clsx(
        styles.typeField,
        type === JobType.Failure && styles.failure,
        type === JobType.Maintenance && styles.maintenance,
        type === JobType.Other && styles.other,
      )}
    >
      {translations[type]}
    </div>
  );
};

export default JobTypeField;
