import {
  ApiFetchParams,
  ApiResponse,
  Question,
  Survey,
  SurveyStatus,
} from '../types';
import api from './api';
import { AnswerSurveyParams } from '../store/slices/surveyDetails.slice';

export interface SurveysResponse {
  data: Survey[];
  numberOfElements: number;
}

export const fetchSurveys = async ({
  baseUrl,
  mock,
  signal,
}: ApiFetchParams): Promise<Survey[]> => {
  const { data } = await api.get<SurveysResponse>({
    baseUrl,
    mock,
    path: '/knowledge-curation/api/v1/surveys',
    config: { signal },
    queryParams: {
      page: 1,
      size: 50,
    },
  });

  return data;
};

interface FetchSurveyDetailsParams extends ApiFetchParams {
  surveyId: string;
  questionIds: string[];
}

export const fetchSurveyQuestions = async ({
  baseUrl,
  surveyId,
  mock,
  questionIds,
  signal,
}: FetchSurveyDetailsParams): Promise<Question[]> => {
  return Promise.all(
    questionIds.map((questionId) =>
      fetchQuestion({
        baseUrl,
        mock,
        signal,
        questionId,
        surveyId,
      }),
    ),
  );
};

interface FetchQuestionParams extends ApiFetchParams {
  surveyId: string;
  questionId: string;
}

const fetchQuestion = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
}: FetchQuestionParams): Promise<Question> => {
  const { data } = await api.get<ApiResponse<Question>>({
    baseUrl,
    mock,
    path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}`,
    config: { signal },
  });

  return data;
};

interface ApiAnswerSurveyParams extends AnswerSurveyParams {
  surveyId: string;
}

export const sendSurveyAnswer = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
  additionalInformation,
  rootCauses,
}: ApiAnswerSurveyParams & ApiFetchParams): Promise<void> => {
  if (mock) {
    console.log(`
    Answer to ${surveyId}:${questionId}
    
    Selected root causes: ${rootCauses
      .map((rc) => `${rc.root_cause}:${rc.confirmed}`)
      .join(', ')}
      
    Additional information: ${additionalInformation}
    `);
  } else {
    await api.post<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}/answers`,
      body: {
        additional_information: additionalInformation,
        root_causes: rootCauses,
      },
      config: { signal },
    });
  }
};

export const updateSurveyAnswer = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
  additionalInformation,
  rootCauses,
}: ApiAnswerSurveyParams & ApiFetchParams): Promise<void> => {
  if (mock) {
    console.log(`
    Answer updated for ${surveyId}:${questionId}
    
    Selected root causes: ${rootCauses
      .map((rc) => `${rc.root_cause}:${rc.confirmed}`)
      .join(', ')}
      
    Additional information: ${additionalInformation}
    `);
  } else {
    await api.put<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}`,
      body: {
        id: questionId,
        additional_information: additionalInformation,
        root_causes: rootCauses,
      },
      config: { signal },
    });
  }
};

interface CloseSurveyParams extends ApiFetchParams {
  surveyId: string;
}

export const closeSurvey = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
}: CloseSurveyParams): Promise<void> => {
  if (mock) {
    console.log(`Survey closed for ${surveyId}`);
  } else {
    await api.patch<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}`,
      body: {
        status: SurveyStatus.Closed,
      },
      config: { signal },
    });
  }
};
