import { useEffect, useState } from 'react';
import { SHELL_EVENTS, ShellSdk } from 'fsm-shell';

import {
  delay,
  FSMContext,
  isDefined,
  isInsideFSM,
  LoginType,
  loginWithFSM,
  logout,
  useAuthData,
  useLogger,
} from '../../../utils';
import { FetchingStatus } from '../../../types';

const RETRY_INTERVAL_MS = 3000;
const MAX_RETRY_COUNT = 5;

const shellSdk = ShellSdk.init(window.parent, '*');

const requestFSMContext = () =>
  shellSdk.emit(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, {
    clientIdentifier: 'login',
    auth: {
      response_type: 'token',
    },
  });

interface FSMAuthenticationProps {
  setAuthStatus: (status: FetchingStatus) => void;
}

const useFSMAuthentication = ({
  setAuthStatus,
}: FSMAuthenticationProps): void => {
  const isInsideFSMShell = isInsideFSM();
  const authData = useAuthData();
  const { logError } = useLogger();
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    if (!isInsideFSMShell) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleError = async (error: unknown) => {
      logError('An error occurred when requesting FSM context!');
      if (retryCount === MAX_RETRY_COUNT) {
        logout();
        setAuthStatus(FetchingStatus.ERROR);
      } else {
        await delay(RETRY_INTERVAL_MS);
        requestFSMContext();
        setRetryCount((prevState) => prevState + 1);
      }
    };
    shellSdk.on(SHELL_EVENTS.ERROR, handleError);

    return () => {
      shellSdk.off(SHELL_EVENTS.ERROR, handleError);
    };
  }, [retryCount]);

  useEffect(() => {
    if (!isInsideFSMShell) {
      return;
    }
    const processFSMContext = (event: string) => {
      const context: FSMContext = JSON.parse(event);
      if (!isDefined(context.auth)) {
        return;
      }
      loginWithFSM(context).catch((error) => {
        logError('Unable to authenticate with FSM. Reason:', error?.message);
        logout();
        setAuthStatus(FetchingStatus.ERROR);
      });
    };

    requestFSMContext();
    shellSdk.on(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, processFSMContext);

    return () => {
      shellSdk.off(SHELL_EVENTS.Version1.REQUIRE_CONTEXT, processFSMContext);
    };
  }, []);

  useEffect(() => {
    if (
      authData === null ||
      authData.loginType !== LoginType.FSM ||
      authData.expiry === null
    ) {
      return;
    }
    const expiresInMs = authData.expiry * 1000 - Date.now();
    const fiveMinutesBeforeExpiryMs = expiresInMs - 1000 * 60 * 5;
    const timeout = window.setTimeout(() => {
      requestFSMContext();
    }, fiveMinutesBeforeExpiryMs);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [authData]);
};

export default useFSMAuthentication;
