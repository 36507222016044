import {
  ChatbotResponseType,
  DocumentSearchResponse,
  SourceDocument,
} from '../../integration/webSocketClient';
import { knowledgeBaseDefaultAnswers } from '../../store/utils/defaultAnswers';

interface DocumentSearchMockData {
  answer: string;
  sourceDocuments: SourceDocument[];
}

export const bladeResults: DocumentSearchMockData = {
  answer: `<p><b>Diagnostic Steps</b><ol><li>Conduct a comprehensive inspection of the hydraulic system including blades, checking for leaks, pressure irregularities, and fluid levels.</li><li>Verify the functionality of the pitch control system, assessing the pitch angle response to varying wind conditions.</li><li>Calibrate and validate sensor readings, ensuring accurate data transmission to the control system.</li></ol></p><p><b>Hydraulic System Maintenance</b><ol><li>Perform regular maintenance on the hydraulic system, including fluid replacement and seal inspections, to ensure optimal functionality of the blades.</li><li>Calibrate the pitch control system to synchronize blade adjustments with wind conditions, promoting consistent and reliable movement.</li><li>Verify sensor accuracy and consider replacing faulty sensors to enhance the precision of data input for the control system.</li></ol></p>`,
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'document',
        code: '',
        sources: [
          {
            documentId: 'doc1',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Technical_Manual_Handbook_V2.5.pdf#page=46',
            name: 'Troubleshooting Guide for blades failure modes',
            page: 46,
          },
          {
            documentId: 'doc2',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Preventive_Maintenance_Schedule.pdf#page=24',
            name: 'Preventive Maintenance Plan',
            page: 24,
          },
        ],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'document',
        code: '',
        sources: [
          {
            documentId: 'doc3',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=15',
            name: 'Blades: Wind turbine system schema',
            page: 15,
          },
        ],
      },
    },
  ],
};

export const generatorResults: DocumentSearchMockData = {
  answer: `<p><b>Converter Mechanism</b><ol><li>The converter allows independent control of real and reactive flow in either direction (grid to rotor) or confined to unidirectional (rotor to grid) real power flow.</li><li>With DTC technology field orientation is achieved by using advanced generator theory to calculate the accurate generator torque. The performance of DTC controlled wind turbine converter is most effective, and benefits are e.g., fast torque response, accurate control also at low frequencies, torque repeatability and accuracy of dynamic and static speed operations.</li><li>In the start-up procedure, the converter is operated locally from DriveWindow PC tool. The start-up mainly uses rotor-side converter parameters. When grid-side converter parameters are needed, a reference to the grid-side converter parameter list in ACS800-67(LC) doubly fed induction generator control program firmware manual is given.</li></ol></p>`,
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'document',
        code: '',
        sources: [
          {
            documentId: 'doc1',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=14',
            name: 'Wind Turbine Components and functionality of Converter',
            page: 14,
          },
          {
            documentId: 'doc2',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=22',
            name: 'Control of torque and reactive power via rotor-side converter',
            page: 22,
          },
          {
            documentId: 'doc3',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=56',
            name: 'How to start-up the converter',
            page: 56,
          },
        ],
      },
    },
  ],
};

export const pitchResults: DocumentSearchMockData = {
  answer: `<p><b>Electrical malfunctions</b><ol><li>The replacement of pitch bearings in a wind turbine are often linked to erratic pitch adjustments within specific speed operating ranges.</li><li>Fluctuations in electrical signals, such as short circuits or voltage irregularities, can disrupt the precise control of pitch angles during critical speed areas.</li></ol><b>Inadequate lubrication</b><ol><li>Without proper lubrication, the bearings experience premature deterioration, compromising their structural integrity and overall performance.</li><li>The use of high-performance lubricants capable of withstanding the demanding operational conditions helps mitigate friction-related wear and extends the lifespan of the pitch bearings.</li></ol></p>`,
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'document',
        code: '',
        sources: [
          {
            documentId: 'doc1',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/System_description_Startup_Guide_V4.7.pdf#page=20',
            name: 'System operating speed area',
            page: 20,
          },
          {
            documentId: 'doc2',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Technical_Manual_Handbook_V2.5.pdf#page=87',
            name: 'Inadequate lubrication',
            page: 87,
          },
          {
            documentId: 'doc3',
            link: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/Preventive_Maintenance_Schedule.pdf#page=24',
            name: 'Preventive Maintenance Plan',
            page: 24,
          },
        ],
      },
    },
  ],
};

export const getDocumentSearchResultSeed = (
  message: string,
  conversationId: string,
  sessionId: string,
): DocumentSearchResponse => {
  if (message.match(/blade/i)) {
    return {
      ...bladeResults,
      conversationId,
      sessionId,
      question: message,
      type: ChatbotResponseType.DocumentSearchResults,
    };
  }
  if (message.match(/generator/i)) {
    return {
      ...generatorResults,
      conversationId,
      sessionId,
      question: message,
      type: ChatbotResponseType.DocumentSearchResults,
    };
  }
  if (message.match(/pitch bearing/i)) {
    return {
      ...pitchResults,
      conversationId,
      sessionId,
      question: message,
      type: ChatbotResponseType.DocumentSearchResults,
    };
  }

  return {
    conversationId,
    sessionId,
    answer: knowledgeBaseDefaultAnswers[0],
    sourceDocuments: [],
    question: message,
    type: ChatbotResponseType.DocumentSearchResults,
  };
};
