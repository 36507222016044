import api from './api';
import { ApiFetchParams, ApiResponse, TimeRange } from '../types';

const EQUIPMENT_TO_SHOW = 5;

export interface TrendingEquipmentFailureMetric {
  name: string;
  failures_per_day: string; // BE is sending string, but actually a number
}

type TrendingEquipmentFailureResponse = ApiResponse<
  TrendingEquipmentFailureMetric[]
>;

export const fetchTrendingEquipments = async ({
  baseUrl,
  signal,
  mock,
}: ApiFetchParams): Promise<string[]> => {
  const { data } = await api.get<TrendingEquipmentFailureResponse>({
    baseUrl,
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      timeRange: TimeRange.ONE_YEAR,
      type: 'TopEquipmentFailure',
      withTrend: false,
    },
    config: { signal },
  });

  return data.slice(0, EQUIPMENT_TO_SHOW).map((equipment) => equipment.name);
};
