import { Symptom, SymptomType } from '../../types';

export const getSymptomsSearchSeed = (initialSymptom: string): Symptom[] => {
  if (initialSymptom.match(/blade/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Structural Stress Indicators',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom2',
        name: 'Increased Wear on Bearings or Gears',
        type: SymptomType.Static,
      },
      {
        id: 'symptom3',
        name: 'Reduced Power Output',
        type: SymptomType.Dynamic,
      },
    ];
  }
  if (initialSymptom.match(/generator/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Increased Resistance in Generator Windings',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom2',
        name: 'Alarm Notifications',
        type: SymptomType.Static,
      },
      {
        id: 'symptom3',
        name: 'Electrical Faults',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom4',
        name: 'Generator Not Synchronized',
        type: SymptomType.Dynamic,
      },
    ];
  }
  if (initialSymptom.match(/pitch bearing/i)) {
    return [
      {
        id: 'symptom1',
        name: 'Overheating of Pitch System Components',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom2',
        name: 'Wear Debris in Oil',
        type: SymptomType.Static,
      },
      {
        id: 'symptom3',
        name: 'Blade Imbalance',
        type: SymptomType.Dynamic,
      },
      {
        id: 'symptom4',
        name: 'Unusual Noise or Vibration',
        type: SymptomType.Dynamic,
      },
    ];
  }

  return [
    {
      id: 'symptom1',
      name: 'oil leakage',
      type: SymptomType.Dynamic,
    },
    {
      id: 'symptom2',
      name: 'oil contamination',
      type: SymptomType.Static,
    },
    {
      id: 'symptom3',
      name: 'oil degradation',
      type: SymptomType.Dynamic,
    },
    {
      id: 'symptom4',
      name: 'improper oil viscosity',
      type: SymptomType.Dynamic,
    },
  ];
};
