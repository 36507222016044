import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomerSession, FetchingStatus } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  fetchHistory,
  getEquipmentSessions,
  getCustomerSessions,
  saveEquipmentConversation,
  saveCustomerConversation,
} from '../../integration/conversations.api';
import {
  addEquipmentHistory,
  updateEquipmentSession,
} from './conversations.slice';
import {
  addCustomerHistory,
  updateCustomerSession,
} from './customerDetails.slice';
import { SearchHistoryEntry } from '../../components/SmartLoopContainer/DrawerContent/SearchHistory/UseSearchHistory';
import { isDefined } from '../../utils';

export const HISTORY_KEY = 'history';

export interface HistoryState {
  status: FetchingStatus;
  entryMarkedForDeletion: SearchHistoryEntry | null;
}

const initialState: HistoryState = {
  status: FetchingStatus.PENDING,
  entryMarkedForDeletion: null,
};

export const getHistory = createAsyncThunk<
  void,
  ApiThunkParams,
  AppThunkConfig
>('getHistory', async ({ baseUrl, mock, logError }, { dispatch, signal }) => {
  try {
    const history = await fetchHistory({ baseUrl, signal, mock });
    const equipmentHistorySessions = getEquipmentSessions(history);
    const customerHistorySessions = getCustomerSessions(history);
    dispatch(addEquipmentHistory(equipmentHistorySessions));
    dispatch(addCustomerHistory(customerHistorySessions));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    logError(
      `Could not retrieve conversation history. Reason: ${error?.message}`,
    );
    throw error;
  }
});

interface UpdateEquipmentSessionParams extends ApiThunkParams {
  sessionId: string;
}

export const storeEquipmentSession = createAsyncThunk<
  void,
  UpdateEquipmentSessionParams,
  AppThunkConfig
>(
  'storeEquipmentSession',
  async (
    { sessionId, baseUrl, mock, logError },
    { getState, dispatch, signal },
  ) => {
    const {
      conversations: { sessions },
    } = getState();
    const session = sessions.find((session) => session.id === sessionId);
    if (!isDefined(session)) {
      return;
    }
    try {
      await saveEquipmentConversation({ session, baseUrl, mock, signal });
      dispatch(updateEquipmentSession({ id: sessionId, savedToHistory: true }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not save equipment session ${session.equipmentType} - ${session.question}. Reason: ${error?.message}`,
      );
    }
  },
);

interface UpdateCustomerSessionParams extends ApiThunkParams {
  session: CustomerSession;
}

export const storeCustomerSession = createAsyncThunk<
  void,
  UpdateCustomerSessionParams,
  AppThunkConfig
>(
  'storeCustomerSession',
  async ({ session, baseUrl, mock, logError }, { dispatch, signal }) => {
    try {
      await saveCustomerConversation({ session, baseUrl, mock, signal });
      dispatch(updateCustomerSession({ id: session.id, savedToHistory: true }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not save customer session ${session.customerName}. Reason: ${error?.message}`,
      );
    }
  },
);

const historySlice = createSlice({
  name: HISTORY_KEY,
  initialState,
  reducers: {
    openDeleteConfirmationDialog: (
      state,
      action: PayloadAction<SearchHistoryEntry>,
    ) => {
      return {
        ...state,
        entryMarkedForDeletion: action.payload,
      };
    },
    closeDeleteConfirmationDialog: (state) => {
      return {
        ...state,
        entryMarkedForDeletion: null,
      };
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getHistory.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getHistory.fulfilled, (state) => {
        state.status = FetchingStatus.SUCCESS;
      })
      .addCase(getHistory.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
      });
  },
});

const { actions, reducer } = historySlice;

export const {
  openDeleteConfirmationDialog,
  closeDeleteConfirmationDialog,
  reset: resetHistory,
} = actions;

export const historyReducer = reducer;
