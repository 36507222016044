import React from 'react';

import {
  SearchHistoryEntrySegment,
  SegmentedHistory,
} from '../UseSearchHistory';
import { useTranslations } from '../../../../../utils';
import SearchHistoryConversation from '../Conversation/SearchHistoryConversation';
import styles from '../SearchHistory.module.scss';

interface Props {
  history: SegmentedHistory;
  segment: SearchHistoryEntrySegment;
}

const SearchHistorySegment: React.FC<Props> = ({ history, segment }) => {
  const {
    assistant: { drawer: translations },
  } = useTranslations();

  if (history[segment].length === 0) {
    return null;
  }

  return (
    <div className={styles.segment}>
      <h5 className={styles.segmentTitle}>{translations.segments[segment]}</h5>
      <ul className={styles.entries}>
        {history[segment].map((entry) => (
          <SearchHistoryConversation key={entry.id} entry={entry} />
        ))}
      </ul>
    </div>
  );
};

export default SearchHistorySegment;
