import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ANALYTICS_KEY = 'analytics';

export interface AnalyticsState {
  copiedRootCauseContent: string | null;
}

const initialState: AnalyticsState = {
  copiedRootCauseContent: null,
};

const analyticsSlice = createSlice({
  name: ANALYTICS_KEY,
  initialState,
  reducers: {
    setCopiedRootCauseContent: (state, action: PayloadAction<string>) => ({
      copiedRootCauseContent: action.payload,
    }),
    reset: () => initialState,
  },
});

const { actions, reducer } = analyticsSlice;

export const { setCopiedRootCauseContent, reset: resetAnalytics } = actions;

export const analyticsReducer = reducer;
