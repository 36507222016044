import { RawJob } from '../../types';
import { getDay } from '../../utils';

export const ukOnshoreOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Generator failure',
    code: '8952',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Pitch bearing failure',
    code: '8917',
    equipment_type: 'WT 3.x-103',
    created: getDay(2),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Irregular blade movement',
    code: '8902',
    equipment_type: 'WT 3.x-103',
    created: getDay(3),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Vibration and oscillation',
    code: '8894',
    equipment_type: 'WT 2-B Energy 2B6',
    created: getDay(5),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Aging or deterioration of components over time. Gradual increase in vibration levels and potential failure.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];

export const esbOffshoreOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer2',
    customer_name: 'ESB (offshore)',
    description: 'Turbine misalignment',
    code: '9001',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Long-term exposure to operational stresses caused fatigue in structural components, leading to misalignment.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer2',
    customer_name: 'ESB (offshore)',
    description: 'Irregular blade movement',
    code: '8976',
    equipment_type: 'WT 3.x-103',
    created: getDay(1),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer2',
    customer_name: 'ESB (offshore)',
    description: 'Generator failure',
    code: '8945',
    equipment_type: 'WT 3.x-103',
    created: getDay(3),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer2',
    customer_name: 'ESB (offshore)',
    description: 'Tower structural damage',
    code: '8899',
    equipment_type: 'WT 2-B Energy 2B6',
    created: getDay(9),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Severe weather conditions, such as strong winds or lightning, causing structural stress and damage to the tower.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];

export const landsvirkjunOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer3',
    customer_name: 'Landsvirkjun',
    description: 'Generator failure',
    code: '8954',
    equipment_type: 'WT 2-B Energy 2B6',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer3',
    customer_name: 'Landsvirkjun',
    description: 'Pitch bearing failure',
    code: '8921',
    equipment_type: 'WT 14-236 DD',
    created: getDay(2),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer3',
    customer_name: 'Landsvirkjun',
    description: 'Communication system failure',
    code: '8802',
    equipment_type: 'WT 3.x-103',
    created: getDay(7),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        "Malfunction in the turbine's communication infrastructure, including sensors, data transfer systems, or control interfaces.",
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer3',
    customer_name: 'Landsvirkjun',
    description: 'Yaw system malfunction',
    code: '8743',
    equipment_type: 'WT 14-236 DD',
    created: getDay(21),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Yaw drive or sensor failure, leading to misalignment of the turbine with the wind direction and reduced energy capture.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];

export const borsseleOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer4',
    customer_name: 'Borssele Sites I',
    description: 'Yaw system malfunction',
    code: '8945',
    equipment_type: 'WT 2-B Energy 2B6',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Yaw drive or sensor failure, leading to misalignment of the turbine with the wind direction and reduced energy capture.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer4',
    customer_name: 'Borssele Sites I',
    description: 'Pitch bearing failure',
    code: '8798',
    equipment_type: 'WT 14-236 DD',
    created: getDay(7),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer4',
    customer_name: 'Borssele Sites I',
    description: 'Communication system failure',
    code: '8785',
    equipment_type: 'WT 14-236 DD',
    created: getDay(7),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        "Malfunction in the turbine's communication infrastructure, including sensors, data transfer systems, or control interfaces.",
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer4',
    customer_name: 'Borssele Sites I',
    description: 'Irregular blade movement',
    code: '8677',
    equipment_type: 'WT 3.x-137',
    created: getDay(11),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];

export const hollandseOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer5',
    customer_name: 'Hollandse Kust (noord)',
    description: 'Generator failure',
    code: '8936',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer5',
    customer_name: 'Hollandse Kust (noord)',
    description: 'Pitch bearing failure',
    code: '8933',
    equipment_type: 'WT 3.x-137',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer5',
    customer_name: 'Hollandse Kust (noord)',
    description: 'Irregular blade movement',
    code: '8905',
    equipment_type: 'WT 3.x-103',
    created: getDay(1),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer5',
    customer_name: 'Hollandse Kust (noord)',
    description: 'Vibration and oscillation',
    code: '8893',
    equipment_type: 'WT 2-B Energy 2B6',
    created: getDay(2),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Aging or deterioration of components over time. Gradual increase in vibration levels and potential failure.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];

export const getCustomerOpenJobsSeed = (customerId: string): RawJob[] => {
  switch (customerId) {
    case 'customer1':
      return ukOnshoreOpenJobsSeed;
    case 'customer2':
      return esbOffshoreOpenJobsSeed;
    case 'customer3':
      return landsvirkjunOpenJobsSeed;
    case 'customer4':
      return borsseleOpenJobsSeed;
    case 'customer5':
      return hollandseOpenJobsSeed;
    default:
      return [];
  }
};
