import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  Box,
  TextField,
} from '@mui/material';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  ApiCall,
  AssistantProps,
  FetchingStatus,
  MixpanelEventType,
} from '../../../types';
import {
  isDefined,
  trackWithMixpanel,
  useIsDarkMode,
  useTranslations,
} from '../../../utils';
import {
  getTrendingEquipments,
  searchEquipments,
  selectEquipmentTypes,
  setQuestion,
} from '../../../store/slices/inputs.slice';
import { ApiThunkParams } from '../../../store/store';
import { Equipment } from '../../../integration/equipmentTypes.api';
import styles from './SmartLoopEquipmentSearch.module.scss';

const SmartLoopEquipmentSearch: React.FC<AssistantProps> = (props) => {
  const { isMocking, httpUrl, logError, environment } = props;
  const dispatch = useAppDispatch();
  const darkMode = useIsDarkMode();
  const { assistant: translations } = useTranslations();
  const { selectedTags, equipmentTypes, equipmentSearchStatus } =
    useAppSelector((state) => state.inputs);

  const [fetchPromise, setFetchPromise] =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useState<ApiCall<any, ApiThunkParams>>();

  useEffect(() => {
    const promise = dispatch(
      getTrendingEquipments({
        logError,
        mock: isMocking,
        baseUrl: httpUrl,
      }),
    );
    setFetchPromise(promise);
  }, [dispatch, isMocking, httpUrl]);

  const getOptions = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (isDefined(fetchPromise)) {
        fetchPromise.abort();
      }
      if (value.length >= 2) {
        const promise = dispatch(
          searchEquipments({
            logError,
            baseUrl: httpUrl,
            mock: isMocking,
            searchTerm: value,
          }),
        );
        setFetchPromise(promise);
      }
    }, 300),
    [dispatch, fetchPromise, isMocking, httpUrl],
  );

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // Clicking away triggers reset - we do not want to do anything here
      if (reason === 'reset') {
        return;
      }
      if (!value) {
        setOpen(false);
      }
      setInputValue(value);
      dispatch(selectEquipmentTypes(null));
      getOptions(value);
    },
    [dispatch, getOptions],
  );

  const handleSelect = (
    event: SyntheticEvent,
    _: Equipment[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<Equipment>,
  ) => {
    if (reason === 'clear') {
      dispatch(selectEquipmentTypes(null));
      dispatch(setQuestion(''));
    }

    if (
      (reason === 'selectOption' || reason === 'removeOption') &&
      isDefined(details)
    ) {
      const allEquipments = new Map(
        equipmentTypes.map((eqt) => [eqt.fullName, eqt]),
      );
      const equipments = details.option.input
        .map((name) => allEquipments.get(name))
        .filter(isDefined);
      dispatch(selectEquipmentTypes(equipments));
      dispatch(setQuestion(''));
      setInputValue('');
      trackWithMixpanel({
        environment,
        event: {
          name: MixpanelEventType.EquipmentSelected,
          properties: { equipmentType: equipments[0].fullName },
        },
      });
    }
  };

  const noResultsFound =
    equipmentSearchStatus === FetchingStatus.SUCCESS &&
    equipmentTypes.length === 0;

  return (
    <Autocomplete
      multiple
      open={open}
      onOpen={() => {
        if (inputValue || equipmentTypes.length > 0) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      popupIcon={null}
      onChange={handleSelect}
      value={selectedTags}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loadingText={translations.filterLoading}
      noOptionsText={noResultsFound && translations.filterNoResults}
      isOptionEqualToValue={(option, value) =>
        option.fullName === value.fullName
      }
      getOptionLabel={(option) => option.name}
      options={equipmentTypes}
      loading={equipmentSearchStatus === FetchingStatus.PENDING}
      filterOptions={(x) => x}
      renderTags={(tagValues) => (
        <div className={styles.tags}>
          {tagValues.map((tag) => (
            <div
              key={tag.fullName}
              className={clsx(styles.tag, darkMode && styles.darkMode)}
            >
              {tag.fullName.replace(/###/, ' ')}
            </div>
          ))}
        </div>
      )}
      renderOption={(props, equipmentType) => (
        <Box
          component="li"
          sx={{
            color: (theme) => theme.palette.secondary.light,
            paddingLeft: `${equipmentType.level * 16}px !important`,
          }}
          {...props}
          key={equipmentType.fullName}
        >
          {equipmentType.name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={
            selectedTags.length === 0
              ? translations.equipmentSearchPlaceholder
              : ''
          }
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default SmartLoopEquipmentSearch;
