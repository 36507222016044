import { FileInfo } from '../../types';
import { getDayMs } from '../../utils';

export const documentsSeed: FileInfo[] = [
  {
    document_id: 'document1',
    filename: 'Document 1.pdf',
    url: '',
    size: '280 KB',
    timestamp: getDayMs(1) / 1000,
    tags: ['WT 8.0-167 DD', 'WT 2-B Energy 2B6'],
  },
  {
    document_id: 'document2',
    filename: 'Document 2.pdf',
    url: '',
    size: '520 KB',
    timestamp: getDayMs(1) / 1000,
    tags: ['WT 3.x-103'],
  },
  {
    document_id: 'document3',
    filename: 'Document 3.pdf',
    url: '',
    size: '728 KB',
    timestamp: getDayMs(2) / 1000,
    tags: ['WT 2-B Energy 2B6'],
  },
  {
    document_id: 'document4',
    filename: 'Document 4.pdf',
    url: '',
    size: '345 KB',
    timestamp: getDayMs(2) / 1000,
    tags: ['WT 8.0-167 DD'],
  },
  {
    document_id: 'document5',
    filename: 'Document 5.pdf',
    url: '',
    size: '568 KB',
    timestamp: getDayMs(2) / 1000,
    tags: ['WT 2-B Energy 2B6'],
  },
];
