import React from 'react';

import { SessionProps } from '../../../../../../types';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { updateEquipmentSession } from '../../../../../../store/slices/conversations.slice';
import { useTranslations } from '../../../../../../utils';
import { renderOrDefault } from '../../../../../../utils/renderOrDefault';
import styles from './TroubleshootingFinished.module.scss';

const TroubleshootingFinished: React.FC<SessionProps> = ({ session }) => {
  const dispatch = useAppDispatch();
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();

  const handleClose = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingVisible: false,
      }),
    );
  };

  return (
    <div>
      <h3 className={styles.title}>{translations.completedTitle}</h3>
      <p className={styles.text}>
        <b>{translations.finalSymptoms}</b>{' '}
        {session.troubleshootingSelectedSymptoms
          .map((symptom) => symptom.name)
          .join(', ')}
      </p>
      <p className={styles.text}>
        <b>{translations.rootCause}</b>{' '}
        {renderOrDefault(session.finalRootCause, '-')}
      </p>
      <div className={styles.buttons}>
        <button className={styles.dismissBtn} onClick={handleClose}>
          {translations.closeBtn}
        </button>
      </div>
    </div>
  );
};

export default TroubleshootingFinished;
