import React from 'react';

import { CustomerSessionProps, Trend } from '../../../../../types';
import { useTranslations } from '../../../../../utils';
import { useOnDesktop } from '../../../../../utils/responsive';
import KpiCard from '../KpiCard/KpiCard';

const MeanTimeBetweenVisits: React.FC<CustomerSessionProps> = ({ session }) => {
  const { mtbv, mtbvStatus } = session;
  const {
    assistant: {
      customerScreen: { kpi: translations },
    },
  } = useTranslations();
  const isDesktop = useOnDesktop();

  if (mtbv === null) {
    return (
      <KpiCard
        title={translations.mtbv}
        summary={{
          average: null,
          trend: Trend.None,
          trendValue: null,
        }}
        status={mtbvStatus}
      />
    );
  }

  return (
    <KpiCard
      title={isDesktop ? translations.mtbv : translations.mtbvAbbr}
      summary={mtbv}
      valueUnit={
        mtbv.average !== null ? translations.dayUnit(mtbv.average) : undefined
      }
      status={mtbvStatus}
    />
  );
};

export default MeanTimeBetweenVisits;
