import React from 'react';
import { useIsDarkMode } from '../../../../utils/darkMode';

const LoginSpaceSvg: React.FC = () => {
  const isDarkMode = useIsDarkMode();
  const fillColor = isDarkMode ? '#00BDC7' : '#ffffff';
  const opacity = isDarkMode ? 0.2 : 1;
  return (
    <svg
      width="201"
      height="203"
      viewBox="0 0 201 203"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M126.786 151.124C104.13 153.316 74.1653 153.316 38.3542 147.469C2.543 142.354 -25.9598 133.583 -47.1541 124.813C-50.0775 112.389 -50.8083 99.234 -48.6158 86.0789C-47.885 80.963 -46.4233 75.1163 -44.9616 70.7312C-44.2308 69.2695 -44.2308 67.077 -43.4999 65.6153C-42.7691 64.1536 -42.0382 61.9611 -41.3074 60.4995C-40.5766 59.0378 -39.8457 57.5761 -39.1149 56.1144C-39.1149 55.3836 -38.384 54.6527 -37.6532 53.1911V52.4602C-18.6514 18.1107 18.6215 -2.35282 58.8177 4.22474C99.0139 10.0715 128.978 40.7668 137.748 78.7705C137.748 79.5013 138.479 80.963 138.479 81.6938V82.4247C139.21 87.5405 139.941 93.3872 139.941 99.2339C139.941 104.35 139.941 110.197 139.21 115.312C137.748 128.468 133.363 140.892 126.786 151.124Z"
          stroke={fillColor}
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M111.439 172.318C90.9751 192.051 61.7415 203.014 31.7771 198.629C1.81264 194.244 -23.0359 175.242 -36.9219 150.393C-12.8042 158.432 13.5061 165.01 36.1621 167.933C58.8182 170.857 85.8592 173.049 111.439 172.318Z"
          stroke={fillColor}
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M197.677 134.314C201.331 110.196 173.559 90.4633 137.748 76.5773C137.748 76.5773 137.748 77.3082 137.748 78.039C137.748 78.7699 138.479 80.2315 138.479 80.9623V81.6932C138.479 83.1549 138.479 84.6166 139.209 86.0783C139.209 89.0016 139.94 91.925 139.94 94.1175C139.94 95.5792 139.94 97.0408 139.94 98.5025C166.25 110.927 178.675 123.351 177.944 130.659C177.213 138.699 158.942 147.469 126.785 151.123C104.129 153.316 74.8954 153.316 38.3534 147.469C2.54229 142.353 -25.9605 133.583 -47.1548 124.813C-77.1192 112.388 -91.7361 97.7717 -91.0052 90.4633C-90.2744 83.1549 -74.1959 75.1157 -45.6931 70.7306C-44.9623 69.269 -44.9623 67.0764 -44.2314 65.6147C-43.5006 64.153 -42.7698 61.9605 -42.0389 60.4989C-41.3081 59.0372 -40.5773 57.5755 -39.8464 56.1138C-39.8464 55.383 -39.1156 53.9213 -38.3847 53.1905V52.4596C-38.3847 51.7288 -37.6539 50.9979 -37.6539 50.2671C-75.6576 53.1904 -107.084 64.153 -110.738 87.5399C-114.392 113.85 -79.3118 135.045 -38.3847 149.661C-14.267 157.701 12.0432 164.278 34.6992 167.201C57.3553 170.856 84.3964 172.317 109.976 171.587C155.288 170.856 194.022 160.624 197.677 134.314Z"
          stroke={fillColor}
          strokeWidth="5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default LoginSpaceSvg;
