import { TrendingEquipmentFailureMetric } from '../../integration/trendingEquipment.api';

export const trendingEquipmentFailureSeed: TrendingEquipmentFailureMetric[] = [
  {
    name: 'WT 8.0-167 DD',
    failures_per_day: '250',
  },
  {
    name: 'WT 14-236 DD',
    failures_per_day: '180',
  },
  {
    name: 'WT 3.x-103',
    failures_per_day: '150',
  },
  {
    name: 'WT 3.x-137',
    failures_per_day: '95',
  },
  {
    name: 'WT 2-B Energy 2B6',
    failures_per_day: '25',
  },
];
