import api from './api';
import {
  ApiFetchParams,
  ApiResponse,
  ConversationType,
  CustomerConversationDto,
  CustomerHistorySession,
  CustomerSession,
  EquipmentConversationDto,
  EquipmentHistorySession,
  EquipmentSession,
  HistoryResponseData,
  HistorySession,
} from '../types';
import { getSourceDocumentIds, isDefined } from '../utils';

const isEquipmentSession = (
  session: HistorySession,
): session is EquipmentHistorySession => {
  return session.content.type === ConversationType.Equipment;
};

export const getEquipmentSessions = (
  sessions: HistorySession[],
): EquipmentHistorySession[] => {
  return sessions.filter(isEquipmentSession);
};

const isCustomerSession = (
  session: HistorySession,
): session is CustomerHistorySession => {
  return session.content.type === ConversationType.Customer;
};

export const getCustomerSessions = (
  sessions: HistorySession[],
): CustomerHistorySession[] => {
  return sessions.filter(isCustomerSession);
};

type HistoryResponse = ApiResponse<HistoryResponseData>;

export const fetchHistory = async ({
  baseUrl,
  signal,
  mock,
}: ApiFetchParams): Promise<HistorySession[]> => {
  const {
    data: { sessions },
  } = await api.get<HistoryResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/sessions',
    config: { signal },
  });

  return sessions.map((session) => {
    const content = JSON.parse(session.content);

    return {
      ...session,
      content,
    };
  });
};

interface SaveEquipmentConversationParams extends ApiFetchParams {
  session: EquipmentSession;
}

export const saveEquipmentConversation = async ({
  session,
  baseUrl,
  signal,
  mock,
}: SaveEquipmentConversationParams): Promise<void> => {
  const dto: EquipmentConversationDto = {
    type: ConversationType.Equipment,
    id: session.id,
    conversationId: session.conversationId,
    createdAt: session.createdAt,
    question: session.question,
    equipmentType: session.equipmentType,
    extractedSymptom: session.extractedSymptom,
    rootCause: session.rootCause,
    rootCauseSources: session.rootCauseSources,
    images: session.images,
    documentSearchAnswer: session.documentSearchAnswer,
    documentSearchSources: session.documentSearchSources,
    suggestions: session.suggestions,
    jobs: session.jobs,
    feedback: {
      type: session.feedback.type,
    },
    troubleshootingStatus: session.troubleshootingStatus,
    troubleshootingSelectedSymptoms: session.troubleshootingSelectedSymptoms,
    finalRootCause: session.finalRootCause,
  };
  const documentIds = isDefined(session.documentSearchSources)
    ? getSourceDocumentIds(session.documentSearchSources)
    : [];
  const mediaFileIds = isDefined(session.images)
    ? session.images.map((image) => image.id)
    : [];

  if (mock) {
    // Intentional use of console.log, we should not use the app logger here!
    console.log('Saving session', dto);
  } else {
    await api.put<void>({
      baseUrl,
      mock,
      path: '/knowledge-base/api/v1/sessions',
      body: {
        id: dto.id,
        content: JSON.stringify(dto),
        mediaFileIds,
        documentIds,
      },
      config: { signal },
    });
  }
};

interface SaveCustomerConversationParams extends ApiFetchParams {
  session: CustomerSession;
}

export const saveCustomerConversation = async ({
  session,
  baseUrl,
  signal,
  mock,
}: SaveCustomerConversationParams): Promise<void> => {
  const dto: CustomerConversationDto = {
    type: ConversationType.Customer,
    id: session.id,
    customerName: session.customerName,
    createdAt: session.createdAt,
    summary: session.summary,
    openJobs: session.openJobs,
    pastJobs: session.pastJobs,
    ftfr: session.ftfr,
    mttr: session.mttr,
    mtbv: session.mtbv,
  };

  if (mock) {
    // Intentional use of console.log, we should not use the app logger here!
    console.log('Saving session', dto);
  } else {
    await api.put<void>({
      baseUrl,
      mock,
      path: '/knowledge-base/api/v1/sessions',
      body: {
        id: dto.id,
        content: JSON.stringify(dto),
      },
      config: { signal },
    });
  }
};

interface DeleteSessionsParams extends ApiFetchParams {
  ids: string[];
  logError: (msg: string) => void;
}

export const deleteSessions = async ({
  ids,
  baseUrl,
  signal,
  mock,
  logError,
}: DeleteSessionsParams): Promise<void> => {
  if (mock) {
    return;
  }
  await Promise.all(
    ids.map((id) =>
      api
        .patch<void>({
          baseUrl,
          mock,
          path: `/knowledge-base/api/v1/sessions/${id}`,
          body: {
            deleted: true,
          },
          config: { signal },
        })
        .catch((error) =>
          logError(`Could not delete session ${id}. ${error?.message}`),
        ),
    ),
  );
};
