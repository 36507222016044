import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiThunkParams, AppThunkConfig } from '../store';
import { updateEquipmentSession } from '../slices/conversations.slice';
import { fetchRelatedSymptoms } from '../../integration/symptoms.api';
import { Symptom } from '../../types';

export interface GetRelatedSymptomsParams extends ApiThunkParams {
  sessionId: string;
  confirmedSymptoms: Symptom[];
  equipmentType: string;
  initialSymptom?: string;
}

export const getRelatedSymptoms = createAsyncThunk<
  void,
  GetRelatedSymptomsParams,
  AppThunkConfig
>(
  'getRelatedSymptoms',
  async (
    {
      sessionId,
      confirmedSymptoms,
      equipmentType,
      initialSymptom,
      baseUrl,
      mock,
      logError,
    },
    { dispatch, signal },
  ) => {
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        troubleshootingRelatedSymptoms: null,
      }),
    );
    try {
      const relatedSymptoms = await fetchRelatedSymptoms({
        equipmentType,
        initialSymptom,
        signal,
        baseUrl,
        mock,
        confirmedSymptoms: confirmedSymptoms.map((symptom) => symptom.name),
      });
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          troubleshootingRelatedSymptoms: relatedSymptoms,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch related symptoms for ${sessionId}. Reason: ${error?.message}`,
      );
    }
  },
);
