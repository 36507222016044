import React, {
  forwardRef,
  ForwardRefRenderFunction,
  ReactNode,
  useState,
} from 'react';
import clsx from 'clsx';
import Drawer from '@mui/material/Drawer';

import { AssistantProps } from '../../types';
import { isDefined } from '../../utils';
import Responsive from '../Responsive/Responsive';
import DrawerContent from './DrawerContent/DrawerContent';
import MobileHeader from './MobileHeader/MobileHeader';
import DeleteConfirmationDialog from '../DeleteConfirmationDialog/DeleteConfirmationDialog';
import DesktopDrawer from './DesktopDrawer/DesktopDrawer';
import styles from './SmartLoopContainer.module.scss';

interface Props extends AssistantProps {
  children: ReactNode;
  InputComponent?: ReactNode;
  className?: string;
  mobileTitle?: string | null;
}

const SmartLoopContainer: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, InputComponent, className, mobileTitle, ...props },
  ref,
) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <div className={styles.container}>
      <Responsive
        mobileComponent={
          <Drawer
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              className: styles.mobileDrawer,
            }}
          >
            <DrawerContent {...props} onClose={handleDrawerClose} />
          </Drawer>
        }
        desktopComponent={
          <DesktopDrawer {...props} onClose={handleDrawerClose} />
        }
      />
      <div className={styles.content}>
        <MobileHeader onDrawerToggle={handleDrawerToggle} title={mobileTitle} />
        <div ref={ref} className={clsx(styles.mainContent, className)}>
          {children}
        </div>
        {isDefined(InputComponent) && (
          <div className={styles.inputContainer}>{InputComponent}</div>
        )}
      </div>
      <DeleteConfirmationDialog {...props} />
    </div>
  );
};

export default forwardRef(SmartLoopContainer);
