import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v1 as generateId } from 'uuid';

import {
  EnvironmentName,
  EquipmentSession,
  FeedbackReason,
  FeedbackType,
  FetchingStatus,
  MixpanelEventType,
  QuestionSource,
  TroubleshootingStatus,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  getJwtToken,
  isDefined,
  isUserServiceExpert,
  trackWithMixpanel,
  getAuthDataFromStorage,
  processStreamingPartials,
  isDocumentFault,
  getFaultJobId,
  getSecondsSinceLastQuestion,
} from '../../utils';
import {
  ChatbotQueryPayload,
  ChatbotResponseType,
  CustomerSummaryPartialResponse,
  PredictionsPartialResponse,
  SessionChatbotQueryPayload,
  WebSocketQueryAction,
  wsClient,
} from '../../integration/webSocketClient';
import {
  disableWSMockServer,
  enableWSMockServer,
} from '../../mocks/web-socket-mock-server';
import { sendFeedback } from '../../integration/feedback.api';
import { SupportedLanguage } from '../../translations/common';
import { fetchMedia } from '../../integration/media.api';
import {
  addRootCausePartial,
  createEquipmentSession,
  updateEquipmentSession,
} from './conversations.slice';
import {
  addCustomerSummaryPartial,
  updateCustomerSession,
} from './customerDetails.slice';
import { getSuggestions } from './suggestions.slice';
import { getExtractedSymptom } from '../actions/extractedSymptom.actions';

export const STREAMING_KEY = 'streaming';

export interface StreamingState {
  initStatus: FetchingStatus;
}

const initialState: StreamingState = {
  initStatus: FetchingStatus.IDLE,
};

const processRootCausePartials = (
  parts: PredictionsPartialResponse[],
  currentSessionId: string,
): string => {
  const relevantParts = parts.filter(
    (part) => part.sessionId === currentSessionId,
  );

  return processStreamingPartials(relevantParts);
};

const processCustomerSummaryPartials = (
  parts: CustomerSummaryPartialResponse[],
  currentCustomerId: string,
): string => {
  const relevantParts = parts.filter(
    (part) => part.customerId === currentCustomerId,
  );

  return processStreamingPartials(relevantParts);
};

interface InitializeStreamingParams {
  language: SupportedLanguage;
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

export const initializeStreaming = createAsyncThunk<
  void,
  InitializeStreamingParams,
  AppThunkConfig
>('initializeStreaming', async (params, { dispatch, getState }) => {
  const { isMocking, language, wsUrl, httpUrl, logError, logInfo } = params;
  const userServiceExpert = isUserServiceExpert();
  const {
    streaming: { initStatus },
  } = getState();
  if (initStatus === FetchingStatus.PENDING) {
    return;
  }
  const token = getJwtToken();
  if (token === null) {
    throw new Error('Auth token not found, cannot initialize WS!');
  }
  let predictionsPartials: PredictionsPartialResponse[] = [];
  let customerSummaryPartials: CustomerSummaryPartialResponse[] = [];
  dispatch(startWsInit());
  if (isMocking) {
    enableWSMockServer({ wsUrl, logError, logInfo });
  } else {
    disableWSMockServer({ wsUrl, logError, logInfo });
  }

  wsClient.close();
  await wsClient.init({
    ...params,
    token,
    handleResponse: (response) => {
      // The endpoint sometimes sends an automatic timeout a response, we can safely ignore it
      if (
        !isDefined(response.type) &&
        response.message === 'Endpoint request timed out'
      ) {
        return;
      }

      // Unexpected backend errors that were not emitted by the lambda
      if (
        !isDefined(response.type) &&
        response.message === 'Internal server error'
      ) {
        dispatch(setWsError());
        return;
      }

      switch (response.type) {
        case ChatbotResponseType.DocumentSearchResults:
          // eslint-disable-next-line no-case-declarations
          const faults = response.sourceDocuments.filter((document) =>
            isDocumentFault(document),
          );
          // As per POR #32 we need to produce an error if fault(s) are received instead of proper relevant documents
          if (faults.length > 0) {
            params.logError(
              'Fault(s) received in the document search response!',
            );
            dispatch(
              updateEquipmentSession({
                id: response.sessionId,
                documentSearchStatus: FetchingStatus.ERROR,
              }),
            );
          } else {
            dispatch(
              updateEquipmentSession({
                id: response.sessionId,
                documentSearchAnswer:
                  response.answer === '' ? null : response.answer,
                documentSearchSources: response.sourceDocuments,
                documentSearchStatus: FetchingStatus.SUCCESS,
              }),
            );
          }
          break;
        case ChatbotResponseType.DocumentSearchError:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              documentSearchAnswer: null,
              documentSearchSources: null,
              documentSearchStatus: FetchingStatus.ERROR,
            }),
          );
          break;
        case ChatbotResponseType.PredictionsSourceDocuments:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              rootCauseSources: response.sourceDocuments,
            }),
          );
          if (userServiceExpert) {
            const jobIds = response.sourceDocuments
              .filter(isDocumentFault)
              .map(getFaultJobId);
            dispatch(
              getMedia({
                jobIds,
                logError,
                baseUrl: httpUrl,
                mock: isMocking,
                sessionId: response.sessionId,
              }),
            );
            dispatch(
              getPastJobs({
                jobIds,
                wsUrl,
                httpUrl,
                isMocking,
                language,
                logInfo,
                logError,
                conversationId: response.conversationId,
                sessionId: response.sessionId,
              }),
            );
          }
          break;
        case ChatbotResponseType.PredictionsPartial:
          predictionsPartials.push(response);
          dispatch(
            addRootCausePartial({
              sessionId: response.sessionId,
              partial: processRootCausePartials(
                predictionsPartials,
                response.sessionId,
              ),
            }),
          );
          break;
        case ChatbotResponseType.PredictionsFinished:
          // Clearing the finished response parts from the cache
          predictionsPartials = predictionsPartials.filter(
            (part) => part.sessionId !== response.sessionId,
          );
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              rootCauseStatus: FetchingStatus.SUCCESS,
              rootCause: response.answer === '' ? null : response.answer,
              parts: response.parts || null,
            }),
          );
          break;
        case ChatbotResponseType.PredictionsError:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              rootCause: null,
              rootCauseSources: null,
              rootCauseStatus: FetchingStatus.ERROR,
              parts: null,
              jobsStatus: FetchingStatus.SUCCESS,
              jobs: [],
              imagesStatus: FetchingStatus.SUCCESS,
              images: [],
            }),
          );
          break;
        case ChatbotResponseType.PastJobs:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              jobsStatus: FetchingStatus.SUCCESS,
              jobs: response.results,
            }),
          );
          break;
        case ChatbotResponseType.PastJobsError:
          dispatch(
            updateEquipmentSession({
              id: response.sessionId,
              jobsStatus: FetchingStatus.ERROR,
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryPartial:
          customerSummaryPartials.push(response);
          dispatch(
            addCustomerSummaryPartial({
              customerId: response.customerId,
              partial: processCustomerSummaryPartials(
                customerSummaryPartials,
                response.customerId,
              ),
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryFinished:
          // Clearing the finished response parts from the cache
          customerSummaryPartials = customerSummaryPartials.filter(
            (part) => part.customerId !== response.customerId,
          );
          dispatch(
            updateCustomerSession({
              id: response.customerId,
              summaryStatus: FetchingStatus.SUCCESS,
              summary: response.answer === '' ? null : response.answer,
            }),
          );
          break;
        case ChatbotResponseType.CustomerSummaryError:
          dispatch(
            updateCustomerSession({
              id: response.customerId,
              summary: null,
              summaryStatus: FetchingStatus.ERROR,
            }),
          );
          break;
        default:
          params.logError(`Unknown WS message: ${JSON.stringify(response)}`);
      }
    },
  });
});

interface AskQuestionParams {
  conversationId: string;
  equipmentType: string;
  allEquipmentTags: string[];
  message: string;
  source: QuestionSource;
  language: SupportedLanguage;
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
  environment: EnvironmentName;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

export const askQuestion = createAsyncThunk<
  void,
  AskQuestionParams,
  AppThunkConfig
>('askQuestion', async (params, { getState, dispatch }) => {
  const {
    equipmentType,
    allEquipmentTags,
    language,
    message,
    source,
    isMocking,
    wsUrl,
    httpUrl,
    environment,
    logError,
    logInfo,
    conversationId,
  } = params;
  const authData = getAuthDataFromStorage();
  const userServiceExpert = isUserServiceExpert();
  if (authData === null) {
    throw new Error('Not authenticated!');
  }
  const sessionId = generateId();
  const {
    streaming: { initStatus },
    conversations: { sessions },
  } = getState();
  const conversation = sessions.filter(
    (session) => session.conversationId === conversationId,
  );

  dispatch(
    createEquipmentSession({
      userServiceExpert,
      conversationId,
      sessionId,
      equipmentType,
      allEquipmentTags,
      question: message,
      troubleshootingOpen: shouldShowNextTroubleshooting(conversation),
    }),
  );
  dispatch(
    getSuggestions({
      equipmentType,
      language,
      sessionId,
      logError,
      question: message,
      baseUrl: httpUrl,
      mock: isMocking,
    }),
  );
  const isSent = wsClient.send<SessionChatbotQueryPayload>(
    WebSocketQueryAction.DocumentSearch,
    {
      conversationId,
      sessionId,
      language,
      message,
      equipmentTypes: allEquipmentTags,
      tenantId: authData.tenantId,
      token: authData.token,
    },
    logError,
  );

  if (userServiceExpert) {
    dispatch(
      getExtractedSymptom({
        sessionId,
        logError,
        question: message,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );
    wsClient.send<SessionChatbotQueryPayload>(
      WebSocketQueryAction.RootCause,
      {
        conversationId,
        sessionId,
        language,
        message,
        equipmentTypes: allEquipmentTags,
        tenantId: authData.tenantId,
        token: authData.token,
      },
      logError,
    );
  }

  if (!isSent && initStatus !== FetchingStatus.PENDING) {
    dispatch(
      initializeStreaming({
        logInfo,
        logError,
        wsUrl,
        httpUrl,
        language,
        isMocking,
      }),
    );
  }

  trackWithMixpanel({
    environment,
    event: {
      name: MixpanelEventType.QuestionAsked,
      properties: {
        source,
        question: message,
        secondsSinceLastQuestion: getSecondsSinceLastQuestion(sessions),
      },
    },
  });
});

// If the user closes the last troubleshooting in a conversation thread, we want to hide it in the next one by default.
// However, finishing a troubleshooting should not be counted as the user closing it.
const shouldShowNextTroubleshooting = (
  sessions: EquipmentSession[],
): boolean => {
  if (sessions.length === 0) {
    return true;
  }
  const latestSession = sessions[sessions.length - 1];

  return (
    latestSession.troubleshootingVisible ||
    latestSession.troubleshootingStatus === TroubleshootingStatus.Finished
  );
};

interface GetPastJobsParams {
  jobIds: string[];
  language: SupportedLanguage;
  conversationId: string;
  sessionId: string;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
  isMocking: boolean;
  wsUrl: string;
  httpUrl: string;
}

export interface PastJobsQueryPayload extends ChatbotQueryPayload {
  jobIds: string[];
  conversationId: string;
  sessionId: string;
}

export const getPastJobs = createAsyncThunk<
  void,
  GetPastJobsParams,
  AppThunkConfig
>('getPastJobs', async (params, { getState, dispatch }) => {
  const {
    jobIds,
    language,
    logError,
    logInfo,
    conversationId,
    sessionId,
    isMocking,
    wsUrl,
    httpUrl,
  } = params;
  if (jobIds.length === 0) {
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        jobsStatus: FetchingStatus.SUCCESS,
        jobs: [],
      }),
    );
    return;
  }
  const authData = getAuthDataFromStorage();
  if (authData === null) {
    throw new Error('Not authenticated!');
  }
  const {
    streaming: { initStatus },
  } = getState();

  const isSent = wsClient.send<PastJobsQueryPayload>(
    WebSocketQueryAction.GetPastJobs,
    {
      jobIds,
      language,
      conversationId,
      sessionId,
      tenantId: authData.tenantId,
      token: authData.token,
    },
    logError,
  );
  if (!isSent && initStatus !== FetchingStatus.PENDING) {
    dispatch(
      initializeStreaming({
        isMocking,
        wsUrl,
        httpUrl,
        language,
        logError,
        logInfo,
      }),
    );
  }
});

interface GetMediaParams extends ApiThunkParams {
  sessionId: string;
  jobIds: string[];
}

export const getMedia = createAsyncThunk<void, GetMediaParams, AppThunkConfig>(
  'getMedia',
  async (
    { sessionId, jobIds, baseUrl, mock, logError },
    { dispatch, signal },
  ) => {
    if (jobIds.length === 0) {
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          images: [],
          imagesStatus: FetchingStatus.SUCCESS,
        }),
      );
      return;
    }
    const authData = getAuthDataFromStorage();
    if (authData === null) {
      throw new Error('Not authenticated!');
    }
    try {
      const images = await fetchMedia({
        jobIds,
        signal,
        baseUrl,
        mock,
      });
      dispatch(
        updateEquipmentSession({
          images,
          id: sessionId,
          imagesStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch images for session ${sessionId}, jobIds: ${jobIds.join(
          ', ',
        )}. Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          imagesStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

interface SendFeedbackParams {
  session: EquipmentSession;
  httpUrl: string;
  isMocking: boolean;
  logError: (msg: string) => void;
}

export const sendPositiveFeedback = createAsyncThunk<
  void,
  SendFeedbackParams,
  AppThunkConfig
>(
  'sendPositiveFeedback',
  async ({ session, httpUrl, isMocking, logError }, { dispatch, signal }) => {
    sendFeedback({
      signal,
      baseUrl: httpUrl,
      mock: isMocking,
      sessionId: session.id,
      comment: '',
      reason: FeedbackReason.POSITIVE,
      useful: true,
    }).catch((error) =>
      logError(`Could not process process feedback. Reason: ${error?.message}`),
    );
    dispatch(
      updateEquipmentSession({
        id: session.id,
        feedback: {
          open: false,
          type: FeedbackType.POSITIVE,
        },
      }),
    );
  },
);

interface SendNegativeFeedbackParams extends SendFeedbackParams {
  reason: FeedbackReason;
  details: string;
}

export const sendNegativeFeedback = createAsyncThunk<
  void,
  SendNegativeFeedbackParams,
  AppThunkConfig
>(
  'sendNegativeFeedback',
  async (
    { details, reason, session, httpUrl, isMocking, logError },
    { dispatch, signal },
  ) => {
    sendFeedback({
      signal,
      reason,
      baseUrl: httpUrl,
      mock: isMocking,
      sessionId: session.id,
      comment: details,
      useful: false,
    }).catch((error) =>
      logError(
        `Could not process negative feedback. Reason: ${error?.message}`,
      ),
    );
    dispatch(
      updateEquipmentSession({
        id: session.id,
        feedback: {
          open: false,
          type: FeedbackType.NEGATIVE,
        },
      }),
    );
  },
);

const streamingSlice = createSlice({
  name: STREAMING_KEY,
  initialState,
  reducers: {
    startWsInit: (state) => ({
      ...state,
      initStatus: FetchingStatus.PENDING,
    }),
    setWsError: (state) => {
      return {
        ...state,
        initStatus: FetchingStatus.ERROR,
      };
    },
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers(builder) {
    builder
      .addCase(initializeStreaming.fulfilled, (state) => {
        state.initStatus = FetchingStatus.SUCCESS;
      })
      .addCase(initializeStreaming.rejected, (state) => {
        state.initStatus = FetchingStatus.ERROR;
      });
  },
});

const { actions, reducer } = streamingSlice;

export const { startWsInit, setWsError, reset: resetWsState } = actions;

export const streamingReducer = reducer;
