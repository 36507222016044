import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FetchingStatus, Survey, SurveyStatus } from '../../types';
import { AppThunkConfig } from '../store';
import { fetchSurveys } from '../../integration/surveys.api';

export const SURVEYS_KEY = 'surveys';

export interface SurveysState {
  status: FetchingStatus;
  surveys: Survey[] | null;
}

const initialState: SurveysState = {
  status: FetchingStatus.IDLE,
  surveys: null,
};

interface GetSurveysParams {
  httpUrl: string;
  isMocking: boolean;
}

export const getSurveys = createAsyncThunk<
  Survey[],
  GetSurveysParams,
  AppThunkConfig
>('getSurveys', async ({ httpUrl, isMocking }, { signal }) => {
  const surveys = await fetchSurveys({
    signal,
    baseUrl: httpUrl,
    mock: isMocking,
  });
  const openSurveys = surveys.filter(
    (survey) => survey.status !== SurveyStatus.Closed,
  );
  const completedSurveys = surveys.filter(
    (survey) => survey.status === SurveyStatus.Closed,
  );

  return [...openSurveys, ...completedSurveys];
});

interface UpdateSurveyParams {
  id: string;
  answeredQuestionsCount: number;
  totalQuestionsCount: number;
}

const surveysSlice = createSlice({
  name: SURVEYS_KEY,
  initialState,
  reducers: {
    updateSurvey: (state, action: PayloadAction<UpdateSurveyParams>) => {
      if (state.surveys === null) {
        return state;
      }
      const { id, answeredQuestionsCount } = action.payload;
      const status =
        answeredQuestionsCount === 0
          ? SurveyStatus.Open
          : SurveyStatus.InProgress;

      return {
        ...state,
        surveys: state.surveys.map((survey) => {
          if (survey.id === id) {
            return {
              ...survey,
              status,
              answered_question_count: answeredQuestionsCount,
            };
          } else {
            return survey;
          }
        }),
      };
    },
    setSurveyToCompleted: (state, action: PayloadAction<string>) => {
      if (state.surveys === null) {
        return state;
      }
      return {
        ...state,
        surveys: state.surveys.map((survey) => {
          if (survey.id === action.payload) {
            return {
              ...survey,
              status: SurveyStatus.Closed,
            };
          } else {
            return survey;
          }
        }),
      };
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSurveys.pending, (state) => {
        state.status = FetchingStatus.PENDING;
        state.surveys = null;
      })
      .addCase(getSurveys.fulfilled, (state, action) => {
        state.status = FetchingStatus.SUCCESS;
        state.surveys = action.payload;
      })
      .addCase(getSurveys.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.surveys = null;
      });
  },
});

const { actions, reducer } = surveysSlice;

export const {
  updateSurvey,
  setSurveyToCompleted,
  reset: resetSurveys,
} = actions;

export const surveysReducer = reducer;
