import React, { useState } from 'react';
import clsx from 'clsx';

import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import { ReactComponent as ThumbsDown } from '../assets/thumbs-down.svg';
import { FeedbackReason, SessionProps } from '../../../../../../../types';
import { sendNegativeFeedback } from '../../../../../../../store/slices/streaming.slice';
import { useTranslations, useIsDarkMode } from '../../../../../../../utils';
import { registerNegativeFeedback } from '../../../../../../../store/slices/forceFeedback.slice';
import { useOnDesktop } from '../../../../../../../utils/responsive';
import styles from './FeedbackForm.module.scss';

const FeedbackForm: React.FC<SessionProps> = (props) => {
  const { session, httpUrl, isMocking, logError } = props;
  const {
    assistant: { feedback: translations },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const isDesktop = useOnDesktop();
  const dispatch = useAppDispatch();
  const [reason, setReason] = useState<FeedbackReason | null>(null);
  const [details, setDetails] = useState('');

  const handleNegativeFeedback = () => {
    if (reason === null) {
      return;
    }
    dispatch(
      sendNegativeFeedback({
        details,
        reason,
        session,
        isMocking,
        httpUrl,
        logError,
      }),
    );
    dispatch(registerNegativeFeedback());
  };

  return (
    <div className={styles.container}>
      {isDesktop && <ThumbsDown className={styles.thumbsDown} />}
      <div className={styles.feedback}>
        <div className={styles.feedbackReasons}>
          <button
            onClick={() => setReason(FeedbackReason.WRONG_ROOT_CAUSE)}
            className={clsx(
              styles.button,
              reason === FeedbackReason.WRONG_ROOT_CAUSE && styles.selected,
            )}
          >
            {translations.wrongRootCause}
          </button>
          <button
            onClick={() => setReason(FeedbackReason.IRRELEVANT_DOCUMENTS)}
            className={clsx(
              styles.button,
              reason === FeedbackReason.IRRELEVANT_DOCUMENTS && styles.selected,
            )}
          >
            {translations.wrongDocuments}
          </button>
          <button
            onClick={() => setReason(FeedbackReason.WRONG_PARTS)}
            className={clsx(
              styles.button,
              reason === FeedbackReason.WRONG_PARTS && styles.selected,
            )}
          >
            {translations.wrongParts}
          </button>
          <button
            onClick={() => setReason(FeedbackReason.OTHER)}
            className={clsx(
              styles.button,
              reason === FeedbackReason.OTHER && styles.selected,
            )}
          >
            {translations.other}
          </button>
        </div>
        <textarea
          rows={5}
          placeholder={translations.detailsPlaceholder}
          className={clsx(styles.details, darkMode && styles.darkMode)}
          value={details}
          onChange={(event) => setDetails(event.target.value)}
        />
        <button
          className={clsx(
            styles.button,
            styles.submit,
            styles.selected,
            darkMode && styles.darkMode,
          )}
          disabled={reason === null}
          onClick={handleNegativeFeedback}
        >
          {translations.submit}
        </button>
      </div>
    </div>
  );
};

export default FeedbackForm;
