import React, { useCallback, useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import {
  getOpenJobs,
  updateOpenJobsFilter,
  updateOpenJobsPage,
  updateOpenJobsPageSize,
  updateOpenJobsSorting,
} from '../../../../store/slices/openJobs.slice';
import {
  AssistantProps,
  OpenJobsFilter,
  OpenJobsSorting,
} from '../../../../types';
import OpenJobs from '../../../OpenJobs/OpenJobs';
import { isFetchingResolved } from '../../../../utils';
import { saveUserProfile } from '../../../../store/slices/userProfile.slice';

const HomeOpenJobs: React.FC<AssistantProps> = (props) => {
  const { httpUrl, isMocking, logError } = props;
  const {
    jobs,
    status,
    sorting,
    filter,
    pageSize,
    currentPage,
    totalElements,
  } = useAppSelector((state) => state.openJobs);
  const { status: userProfileStatus } = useAppSelector(
    (state) => state.userProfile,
  );
  const dispatch = useAppDispatch();

  const onFilterChange = useCallback(
    (filter: OpenJobsFilter | null) => {
      dispatch(updateOpenJobsFilter(filter));
      dispatch(updateOpenJobsPage(0));
      dispatch(
        saveUserProfile({
          logError,
          userProfile: { openJobsFilter: filter },
          baseUrl: httpUrl,
          mock: isMocking,
        }),
      );
    },
    [dispatch, httpUrl, isMocking],
  );
  const onSortingChange = useCallback(
    (sorting: OpenJobsSorting) => {
      dispatch(updateOpenJobsSorting(sorting));
    },
    [dispatch],
  );
  const onPageChange = useCallback(
    (page: number) => {
      dispatch(updateOpenJobsPage(page));
    },
    [dispatch],
  );
  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      if (newPageSize !== pageSize) {
        dispatch(updateOpenJobsPageSize(newPageSize));
        dispatch(updateOpenJobsPage(0));
      }
    },
    [dispatch, pageSize],
  );

  useEffect(() => {
    // We want to delay the first request until we get the saved filters from the profile.
    if (!isFetchingResolved(userProfileStatus)) {
      return;
    }
    const promise = dispatch(
      getOpenJobs({
        sorting,
        filter,
        pageSize,
        currentPage,
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    httpUrl,
    isMocking,
    sorting,
    filter,
    pageSize,
    currentPage,
    userProfileStatus,
  ]);

  return (
    <OpenJobs
      {...props}
      jobs={jobs}
      status={status}
      sorting={sorting}
      filter={filter}
      pageSize={pageSize}
      currentPage={currentPage}
      totalElements={totalElements}
      onFilterChange={onFilterChange}
      onSortingChange={onSortingChange}
      onPageChange={onPageChange}
      onPageSizeChange={onPageSizeChange}
    />
  );
};

export default HomeOpenJobs;
