import React, { Fragment } from 'react';
import clsx from 'clsx';

import { DiagnosisQnaStep } from '../../../../../../../../types';
import { useIsDarkMode, useTranslations } from '../../../../../../../../utils';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/close.svg';
import { updateEquipmentSession } from '../../../../../../../../store/slices/conversations.slice';
import { useAppDispatch } from '../../../../../../../../store/utils/hooks';
import { ReactComponent as ArrowIcon } from '../../../../../../../../assets/arrow-down.svg';
import styles from './Answers.module.scss';

interface Props {
  steps: DiagnosisQnaStep[];
  sessionId: string;
}

const Answers: React.FC<Props> = ({ steps, sessionId }) => {
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const darkMode = useIsDarkMode();

  const handleDelete = (index: number) => {
    const previousSteps = steps.slice(0, index);
    const currentStep = steps[index];
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        openedRootCauseId: null,
        qnaSteps: [
          ...previousSteps,
          {
            ...currentStep,
            selectedAnswer: null,
          },
        ],
        resolvedRootCauses: null,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <div>{translations.answerSummary}:</div>
      <div className={styles.steps}>
        {steps.length > 0 &&
          steps.map((step, index) => (
            <Fragment key={index}>
              <div
                key={index}
                className={clsx(styles.step, darkMode && styles.darkMode)}
              >
                <span>
                  {step.question !== null ? `${step.question}: ` : ''}
                  {step.selectedAnswer}
                </span>
                <DeleteIcon
                  className={styles.deleteIcon}
                  onClick={() => handleDelete(index)}
                />
              </div>
              {index !== steps.length - 1 && (
                <ArrowIcon className={styles.arrowIcon} />
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );
};

export default Answers;
