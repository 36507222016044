import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';

import { isDefined } from './isDefined';
import { EnvironmentName, MixpanelEvent } from '../types';
import { useAuthData } from './auth';

interface UseMixpanelParams {
  environment: EnvironmentName;
  token: string;
}

export const useMixpanel = ({ environment, token }: UseMixpanelParams) => {
  const authData = useAuthData();

  useEffect(() => {
    if (environment !== 'production') {
      return;
    }
    if (authData === null) {
      return;
    }
    const { userId, accountId, companyId, tenantId } = authData;
    mixpanel.init(token);
    mixpanel.identify(userId);
    mixpanel.people.set_once({
      accountId,
      companyId,
      tenantId,
    });
  }, [environment, token, authData]);
};

interface TrackWithMixpanelParams {
  environment: EnvironmentName;
  event: MixpanelEvent;
}

export const trackWithMixpanel = ({
  environment,
  event,
}: TrackWithMixpanelParams): void => {
  if (environment !== 'production') {
    // Intentional use of console.log, we should not use the app logger here!
    console.log('Mixpanel triggered with the following event:', event);
    return;
  }
  if (isDefined(event.properties)) {
    mixpanel.track(event.name, event.properties);
  } else {
    mixpanel.track(event.name);
  }
};
