import {
  ConversationType,
  CustomerConversationDto,
  EquipmentConversationDto,
  FeedbackType,
  HistoryResponseData,
  Trend,
} from '../../types';
import {
  bladeFinalSeed,
  generatorFinalSeed,
  pitchFinalSeed,
} from './predictions.seed';
import {
  bladeImagesSeed,
  generatorImagesSeed,
  pitchImagesSeed,
} from './media.seed';
import {
  bladeResults,
  generatorResults,
  pitchResults,
} from './documentSearchResult.seed';
import { getDashboardPastJobsSeed } from './dashboardPastJobs.seed';
import { getSourceDocuments } from '../../utils';
import {
  esbOffshoreSummaryFinalSeed,
  landsvirkjunSummaryFinalSeed,
  ukOnshoreSummaryFinalSeed,
} from './customerSummary.seed';
import {
  esbOffshorePastJobsSeed,
  landsvirkjunPastJobsSeed,
  ukOnshorePastJobsSeed,
} from './customerPastJobs.seed';
import {
  esbOffshoreOpenJobsSeed,
  landsvirkjunOpenJobsSeed,
  ukOnshoreOpenJobsSeed,
} from './customerOpenJobs.seed';

const HOUR_MS = 60 * 60 * 1000;

const session1: EquipmentConversationDto = {
  id: 'session1',
  type: ConversationType.Equipment,
  conversationId: 'conversation1',
  createdAt: Date.now() - HOUR_MS * 3,
  question: 'What are the main causes of Irregular blade movement?',
  equipmentType: 'WT 2-B Energy 2B6',
  extractedSymptom: 'Irregular blade movement',
  rootCause: bladeFinalSeed.answer,
  rootCauseSources: bladeFinalSeed.sourceDocuments,
  images: bladeImagesSeed.items,
  documentSearchAnswer: bladeResults.answer,
  documentSearchSources: bladeResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '15048',
    '12937',
    '10122',
    '98373',
    '10222',
    '10655',
  ]),
  feedback: {
    type: FeedbackType.POSITIVE,
  },
};

const session2: EquipmentConversationDto = {
  id: 'session2',
  type: ConversationType.Equipment,
  conversationId: 'conversation1',
  createdAt: Date.now() - HOUR_MS * 4,
  question: 'What should I do if the generator fails?',
  equipmentType: 'WT 2-B Energy 2B6',
  extractedSymptom: 'Generator failure',
  rootCause: generatorFinalSeed.answer,
  rootCauseSources: generatorFinalSeed.sourceDocuments,
  images: generatorImagesSeed.items,
  documentSearchAnswer: generatorResults.answer,
  documentSearchSources: generatorResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '68122',
    '69836',
    '46143',
    '36029',
    '89922',
    '89836',
  ]),
  feedback: {
    type: FeedbackType.NEGATIVE,
  },
};

const session3: EquipmentConversationDto = {
  id: 'session3',
  type: ConversationType.Equipment,
  conversationId: 'conversation2',
  createdAt: Date.now() - HOUR_MS * 36,
  question: 'The pitch bearing is failing.',
  equipmentType: 'WT 3.x-103',
  extractedSymptom: 'Pitch bearing failure',
  rootCause: pitchFinalSeed.answer,
  rootCauseSources: pitchFinalSeed.sourceDocuments,
  images: pitchImagesSeed.items,
  documentSearchAnswer: pitchResults.answer,
  documentSearchSources: pitchResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '89122',
    '89522',
    '81552',
    '83556',
    '89552',
    '89556',
  ]),
  feedback: {
    type: FeedbackType.NONE,
  },
};

const session4: CustomerConversationDto = {
  id: 'customer1',
  type: ConversationType.Customer,
  createdAt: Date.now() - HOUR_MS * 7,
  customerName: 'UK Onshore WF',
  summary: ukOnshoreSummaryFinalSeed.answer,
  openJobs: ukOnshoreOpenJobsSeed.map((job) => ({
    id: job.id,
    code: job.code,
    customerId: job.customer_id,
    customerName: job.customer_name,
    equipmentType: job.equipment_type,
    created: job.created,
    reportedSymptom: job.description,
    prediction: job.predictions?.prediction || '',
  })),
  pastJobs: ukOnshorePastJobsSeed,
  ftfr: {
    average: 70,
    trend: Trend.Positive,
    trendValue: 4.2,
  },
  mttr: {
    average: 5,
    trend: Trend.Negative,
    trendValue: 10.2,
  },
  mtbv: {
    average: 45,
    trend: Trend.Positive,
    trendValue: 5.3,
  },
};

const session5: CustomerConversationDto = {
  id: 'customer3',
  type: ConversationType.Customer,
  createdAt: Date.now() - HOUR_MS * 25,
  customerName: 'Landsvirkjun',
  summary: landsvirkjunSummaryFinalSeed.answer,
  openJobs: landsvirkjunOpenJobsSeed.map((job) => ({
    id: job.id,
    code: job.code,
    customerId: job.customer_id,
    customerName: job.customer_name,
    equipmentType: job.equipment_type,
    created: job.created,
    reportedSymptom: job.description,
    prediction: job.predictions?.prediction || '',
  })),
  pastJobs: landsvirkjunPastJobsSeed,
  ftfr: {
    average: 63,
    trend: Trend.Negative,
    trendValue: -11.7,
  },
  mttr: {
    average: 3.8,
    trend: Trend.Positive,
    trendValue: -12.8,
  },
  mtbv: {
    average: 29.8,
    trend: Trend.Positive,
    trendValue: 1.7,
  },
};

const session6: EquipmentConversationDto = {
  id: 'session4',
  type: ConversationType.Equipment,
  conversationId: 'conversation3',
  createdAt: Date.now() - HOUR_MS * 49,
  question: 'What are the main causes of Irregular blade movement?',
  extractedSymptom: 'Irregular blade movement',
  equipmentType: 'WT 2-B Energy 2B6',
  rootCause: bladeFinalSeed.answer,
  rootCauseSources: bladeFinalSeed.sourceDocuments,
  images: bladeImagesSeed.items,
  documentSearchAnswer: bladeResults.answer,
  documentSearchSources: bladeResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '15048',
    '12937',
    '10122',
    '98373',
    '10222',
    '10655',
  ]),
  feedback: {
    type: FeedbackType.POSITIVE,
  },
};

const session7: CustomerConversationDto = {
  id: 'customer2',
  type: ConversationType.Customer,
  createdAt: Date.now() - HOUR_MS * 50,
  customerName: 'ESB (offshore)',
  summary: esbOffshoreSummaryFinalSeed.answer,
  openJobs: esbOffshoreOpenJobsSeed.map((job) => ({
    id: job.id,
    code: job.code,
    customerId: job.customer_id,
    customerName: job.customer_name,
    equipmentType: job.equipment_type,
    created: job.created,
    reportedSymptom: job.description,
    prediction: job.predictions?.prediction || '',
  })),
  pastJobs: esbOffshorePastJobsSeed,
  ftfr: {
    average: 59,
    trend: Trend.Negative,
    trendValue: -5.3,
  },
  mttr: {
    average: 6.4,
    trend: Trend.Negative,
    trendValue: 5.7,
  },
  mtbv: {
    average: 17,
    trend: Trend.Negative,
    trendValue: -25.3,
  },
};

const session8: EquipmentConversationDto = {
  id: 'session5',
  type: ConversationType.Equipment,
  conversationId: 'conversation4',
  createdAt: Date.now() - HOUR_MS * 55,
  question: 'What should I do if the generator fails?',
  extractedSymptom: 'Generator failure',
  equipmentType: 'WT 2-B Energy 2B6',
  rootCause: generatorFinalSeed.answer,
  rootCauseSources: generatorFinalSeed.sourceDocuments,
  images: generatorImagesSeed.items,
  documentSearchAnswer: generatorResults.answer,
  documentSearchSources: generatorResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '68122',
    '69836',
    '46143',
    '36029',
    '89922',
    '89836',
  ]),
  feedback: {
    type: FeedbackType.NEGATIVE,
  },
};

const session9: EquipmentConversationDto = {
  id: 'session6',
  type: ConversationType.Equipment,
  conversationId: 'conversation5',
  createdAt: Date.now() - HOUR_MS * 200,
  question: 'The pitch bearing is failing.',
  extractedSymptom: 'Pitch bearing failure',
  equipmentType: 'WT 3.x-103',
  rootCause: pitchFinalSeed.answer,
  rootCauseSources: pitchFinalSeed.sourceDocuments,
  images: pitchImagesSeed.items,
  documentSearchAnswer: pitchResults.answer,
  documentSearchSources: pitchResults.sourceDocuments,
  suggestions: null,
  jobs: getDashboardPastJobsSeed([
    '89122',
    '89522',
    '81552',
    '83556',
    '89552',
    '89556',
  ]),
  feedback: {
    type: FeedbackType.NONE,
  },
};

export const HistorySeed: HistoryResponseData = {
  sessions: [
    {
      id: 'session1',
      content: JSON.stringify(session1),
      mediaFiles: bladeImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(bladeResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
    {
      id: 'session2',
      content: JSON.stringify(session2),
      mediaFiles: generatorImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(generatorResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
    {
      id: 'session3',
      content: JSON.stringify(session3),
      mediaFiles: pitchImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(pitchResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
    {
      id: 'customer1',
      content: JSON.stringify(session4),
    },
    {
      id: 'customer3',
      content: JSON.stringify(session5),
    },
    {
      id: 'session4',
      content: JSON.stringify(session6),
      mediaFiles: bladeImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(bladeResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
    {
      id: 'customer2',
      content: JSON.stringify(session7),
    },
    {
      id: 'session5',
      content: JSON.stringify(session8),
      mediaFiles: generatorImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(generatorResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
    {
      id: 'session6',
      content: JSON.stringify(session9),
      mediaFiles: pitchImagesSeed.items.map((item) => ({
        id: item.id,
        link: item.url,
      })),
      documents: getSourceDocuments(pitchResults.sourceDocuments).map(
        (doc) => ({ id: doc.documentId, link: doc.link }),
      ),
    },
  ],
};
