import api from './api';
import { SupportedLanguage } from '../translations/common';
import { isDefined } from '../utils';
import { ApiFetchParams, ApiResponse } from '../types';

export interface SuggestionsResponseData {
  question?: string;
  answer?: string[];
}

type SuggestionsResponse = ApiResponse<SuggestionsResponseData>;

interface FetchSuggestionsParams extends ApiFetchParams {
  equipmentType: string;
  language: SupportedLanguage;
  question: string;
}

export const fetchSuggestions = async ({
  baseUrl,
  mock,
  signal,
  equipmentType,
  language,
  question,
}: FetchSuggestionsParams): Promise<string[]> => {
  const { data } = await api.get<SuggestionsResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/rewritten-question',
    queryParams: {
      language,
      question,
      tags: equipmentType,
    },
    config: { signal },
  });
  // backend errors return an unexpected empty object
  if (!isDefined(data.answer)) {
    return [];
  }

  return data.answer;
};
