import React, { useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { ReactComponent as ClosedIcon } from '../assets/right-chevron.svg';
import { ReactComponent as OpenIcon } from '../assets/open.svg';
import {
  RootCauseNode,
  SessionProps,
  TroubleshootingStatus,
} from '../../../../../../types';
import {
  isDefined,
  useIsDarkMode,
  useTranslations,
} from '../../../../../../utils';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { updateEquipmentSession } from '../../../../../../store/slices/conversations.slice';
import { isElementOverflowing } from '../../../../../../utils/isElementOverflowing';
import { useOnDesktop } from '../../../../../../utils/responsive';
import styles from './DiagnosisRootCause.module.scss';

interface Props extends SessionProps {
  rootCause: RootCauseNode;
}

const DiagnosisRootCause: React.FC<Props> = ({ rootCause, session }) => {
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const isDesktop = useOnDesktop();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLHeadingElement>(null);
  const hideTooltip = !isElementOverflowing(ref.current);
  const isOpen = session.openedRootCauseId === rootCause.id;
  const isAnotherOpen = session.openedRootCauseId !== null && !isOpen;
  const steps =
    isDefined(rootCause.resolution_steps) &&
    rootCause.resolution_steps.length > 0
      ? rootCause.resolution_steps[0].steps
      : [];

  const toggleSteps = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        openedRootCauseId: isOpen ? null : rootCause.id,
      }),
    );
  };

  const handleFinish = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingStatus: TroubleshootingStatus.Finished,
        finalRootCause: rootCause.name,
      }),
    );
  };

  return (
    <div
      className={clsx(
        styles.container,
        isAnotherOpen && styles.fade,
        darkMode && styles.darkModeBg,
      )}
    >
      <div className={styles.topSection} onClick={toggleSteps}>
        {isDefined(rootCause.image) && isDesktop && (
          <img className={styles.image} src={rootCause.image} alt="" />
        )}
        <div
          className={clsx(
            styles.summary,
            !isDefined(rootCause.parts) && styles.smallCard,
          )}
        >
          <Tooltip title={rootCause.name} disableHoverListener={hideTooltip}>
            <h6 ref={ref} className={styles.symptom}>
              {rootCause.name}
            </h6>
          </Tooltip>
          {isDefined(rootCause.technicians) &&
            rootCause.technicians.length > 0 && (
              <div className={styles.technicians}>
                {translations.technicians(rootCause.technicians)}
              </div>
            )}
          {isDefined(rootCause.parts) && rootCause.parts.length > 0 && (
            <ul className={styles.parts}>
              {rootCause.parts.map((part) => (
                <li key={part}>{part}</li>
              ))}
            </ul>
          )}
        </div>
        {isOpen ? (
          <OpenIcon className={styles.openIcon} />
        ) : (
          <ClosedIcon className={styles.closedIcon} />
        )}
      </div>
      <div className={clsx(styles.steps, isOpen && styles.open)}>
        {steps.map((step, index) => (
          <div key={index} className={styles.step}>
            <div className={styles.stepName}>
              <Checkbox className={styles.checkbox} />
              <span>
                {translations.step} {index + 1}:
              </span>
            </div>
            <div
              className={styles.stepDetails}
              dangerouslySetInnerHTML={{ __html: step }}
            />
          </div>
        ))}
        {rootCause.mockSteps?.map((step, index) => (
          <div key={step.id} className={styles.step}>
            <div className={styles.stepName}>
              <Checkbox className={styles.checkbox} />
              <span>
                {translations.step} {index + 1}: {step.name}
              </span>
            </div>
            <div className={styles.stepDetails}>{step.details}</div>
          </div>
        ))}
        <button className={styles.finishBtn} onClick={handleFinish}>
          {translations.finishBtn}
        </button>
      </div>
    </div>
  );
};

export default DiagnosisRootCause;
