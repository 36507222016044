export enum AppRoute {
  Home = '/',
  Conversations = '/conversations',
  Customer = '/customer',
  Surveys = '/surveys',
  Configuration = '/configuration',
  Documents = 'document-overview',
  Tags = 'tag-management',
  CustomerInformation = 'customer-information',
}
