import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchFirstTimeFixedRate } from '../../integration/ftfr.api';
import { fetchMeanTimeToResolution } from '../../integration/mttr.api';
import { fetchMeanTimeBetweenVisits } from '../../integration/mtbv.api';
import { Customer } from '../../integration/customers.api';
import { updateCustomerSession } from '../slices/customerDetails.slice';
import { FetchingStatus } from '../../types';

export interface GetKpiParams extends ApiThunkParams {
  customer: Customer;
}

export const getFirstTimeFixRate = createAsyncThunk<
  void,
  GetKpiParams,
  AppThunkConfig
>(
  'getFirstTimeFixRate',
  async ({ baseUrl, mock, logError, customer }, { dispatch, signal }) => {
    try {
      const kpiSummary = await fetchFirstTimeFixedRate({
        signal,
        baseUrl,
        mock,
        customerId: customer.id,
      });
      dispatch(
        updateCustomerSession({
          id: customer.id,
          ftfr: kpiSummary,
          ftfrStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch FTFR for customer ${customer.id}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customer.id,
          ftfrStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

export const getMeanTimeToResolution = createAsyncThunk<
  void,
  GetKpiParams,
  AppThunkConfig
>(
  'getMeanTimeToResolution',
  async ({ baseUrl, mock, logError, customer }, { dispatch, signal }) => {
    try {
      const kpiSummary = await fetchMeanTimeToResolution({
        signal,
        baseUrl,
        mock,
        customerId: customer.id,
      });
      dispatch(
        updateCustomerSession({
          id: customer.id,
          mttr: kpiSummary,
          mttrStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch MTTR for customer ${customer.id}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customer.id,
          mttrStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

export const getMeanTimeBetweenVisits = createAsyncThunk<
  void,
  GetKpiParams,
  AppThunkConfig
>(
  'getMeanTimeBetweenVisits',
  async ({ baseUrl, mock, logError, customer }, { dispatch, signal }) => {
    try {
      const kpiSummary = await fetchMeanTimeBetweenVisits({
        signal,
        baseUrl,
        mock,
        customerId: customer.id,
      });
      dispatch(
        updateCustomerSession({
          id: customer.id,
          mtbv: kpiSummary,
          mtbvStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch MTBV for customer ${customer.id}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customer.id,
          mtbvStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);
