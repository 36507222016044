export const getSuggestionsSeed = (question: string): string[] => {
  if (question.match(/blade/i)) {
    return [
      'What should I do if the generator fails?',
      'The pitch bearing is failing.',
      'How can a lack of proper lubrication affect blade movement?',
    ];
  }
  if (question.match(/generator/i)) {
    return [
      'The pitch bearing is not working properly, what should I do?',
      'What are the primary factors that lead to irregular blade movement?',
      'Problems with soiling and vibration',
    ];
  }
  if (question.match(/pitch bearing/i)) {
    return [
      'What steps should I take when troubleshooting a generator failure?',
      'What are the main causes of Irregular blade movement?',
      'Which tools should I take to diagnose a generator malfunction?',
    ];
  }

  return [
    'What should I do if the generator fails?',
    'The pitch bearing is failing.',
    'What are the main causes of Irregular blade movement?',
  ];
};
