import { MeanTimeBetweenVisitsMetrics } from '../../integration/mtbv.api';

const ukOnshoreMtbvSeed: MeanTimeBetweenVisitsMetrics = {
  mean_time_between_visits: [
    { date_interval: '', mean_time_between_visits: '45' },
  ],
  trend: 0.053,
};

const esbOffshoreMtbvSeed: MeanTimeBetweenVisitsMetrics = {
  mean_time_between_visits: [
    { date_interval: '', mean_time_between_visits: '17' },
  ],
  trend: -0.253,
};

const landsvirkjunMtbvSeed: MeanTimeBetweenVisitsMetrics = {
  mean_time_between_visits: [
    { date_interval: '', mean_time_between_visits: '29.8' },
  ],
  trend: 0.017,
};

const borsseleMtbvSeed: MeanTimeBetweenVisitsMetrics = {
  mean_time_between_visits: [
    { date_interval: '', mean_time_between_visits: '93.3' },
  ],
  trend: -0.039,
};

const hollandseMtbvSeed: MeanTimeBetweenVisitsMetrics = {
  mean_time_between_visits: [],
  trend: null,
};

export const getMtbvSeed = (
  customerId: string,
): MeanTimeBetweenVisitsMetrics => {
  switch (customerId) {
    case 'customer1':
      return ukOnshoreMtbvSeed;
    case 'customer2':
      return esbOffshoreMtbvSeed;
    case 'customer3':
      return landsvirkjunMtbvSeed;
    case 'customer4':
      return borsseleMtbvSeed;
    case 'customer5':
    default:
      return hollandseMtbvSeed;
  }
};
