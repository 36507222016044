import { SourceDocument } from '../integration/webSocketClient';
import { SourceImage } from '../integration/media.api';
import { JobWithPrediction, PastJob } from './jobs';
import { FeedbackType } from './common';
import { KpiSummary } from './kpi';
import { TroubleshootingStatus } from './troubleshooting';
import { Symptom } from './symptoms';

export enum ConversationType {
  Equipment,
  Customer,
}

export interface ConversationDto {
  type: ConversationType;
}

export interface EquipmentConversationDto extends ConversationDto {
  type: ConversationType.Equipment;
  id: string;
  conversationId: string;
  createdAt: number;
  question: string;
  equipmentType: string;
  rootCause: string | null;
  rootCauseSources: SourceDocument[] | null;
  images: SourceImage[] | null;
  documentSearchAnswer: string | null;
  documentSearchSources: SourceDocument[] | null;
  suggestions: string[] | null;
  jobs: PastJob[] | null;
  feedback: {
    type: FeedbackType;
  };
  // We need to be prepared for missing fields to avoid breaking changes
  extractedSymptom?: string | null;
  troubleshootingStatus?: TroubleshootingStatus;
  troubleshootingSelectedSymptoms?: Symptom[] | string[];
  finalRootCause?: string | null;
  allEquipmentTags?: string[];
}

export interface CustomerConversationDto extends ConversationDto {
  type: ConversationType.Customer;
  id: string;
  customerName: string;
  createdAt: number;
  summary: string | null;
  openJobs: JobWithPrediction[] | null;
  pastJobs: PastJob[] | null;
  ftfr: KpiSummary | null;
  mttr: KpiSummary | null;
  mtbv: KpiSummary | null;
}

export interface HistorySession {
  id: string;
  content: ConversationDto;
}

export interface EquipmentHistorySession {
  id: string;
  content: EquipmentConversationDto;
  mediaFiles: HistorySessionLink[];
  documents: HistorySessionLink[];
}

export interface CustomerHistorySession {
  id: string;
  content: CustomerConversationDto;
}

export interface HistorySessionDto {
  id: string;
  content: string; // session as JSON
  mediaFiles?: HistorySessionLink[];
  documents?: HistorySessionLink[];
}

export interface HistorySessionLink {
  id: string;
  link: string;
}

export interface HistoryResponseData {
  sessions: HistorySessionDto[];
}
