import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

import { AppRoute, AssistantProps } from '../../types';
import SmartLoopHome from './Home/SmartLoopHome';
import DocumentSearchHome from './DocumentSearchHome/DocumentSearchHome';
import SmartLoopDashboard from './Dashboard/SmartLoopDashboard';
import CustomerScreen from './CustomerScreen/CustomerScreen';
import CurationHub from './CurationHub/CurationHub';
import SurveyQuestions from './SurveyQuestions/SurveyQuestions';
import Configuration from './Configuration/Configuration';
import DocumentOverview from './Configuration/DocumentOverview/DocumentOverview';
import TagManagement from './Configuration/TagManagement/TagManagement';
import CustomerInformation from './Configuration/CustomerInformation/CustomerInformation';

interface Params {
  assistantProps: AssistantProps;
  userServiceExpert: boolean;
}

// This is needed to get rid of unnecessary warnings.
const routerOptions = {
  future: {
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_relativeSplatPath: true,
    v7_skipActionErrorRevalidation: true,
  },
};

export const createAppRouter = ({
  assistantProps,
  userServiceExpert,
}: Params) => {
  if (userServiceExpert) {
    return createBrowserRouter(
      [
        {
          path: AppRoute.Home,
          element: <SmartLoopHome {...assistantProps} />,
        },
        {
          path: `${AppRoute.Conversations}/:conversationId`,
          element: <SmartLoopDashboard {...assistantProps} />,
        },
        {
          path: `${AppRoute.Customer}/:customerId`,
          element: <CustomerScreen {...assistantProps} />,
        },
        {
          path: AppRoute.Surveys,
          element: <CurationHub {...assistantProps} />,
        },
        {
          path: `${AppRoute.Surveys}/:surveyId`,
          element: <SurveyQuestions {...assistantProps} />,
        },
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ],
      routerOptions,
    );
  } else {
    return createBrowserRouter(
      [
        {
          path: AppRoute.Home,
          element: <DocumentSearchHome {...assistantProps} />,
        },
        {
          path: AppRoute.Configuration,
          element: <Configuration {...assistantProps} />,
          children: [
            {
              path: AppRoute.Documents,
              element: <DocumentOverview {...assistantProps} />,
            },
            {
              path: AppRoute.Tags,
              element: <TagManagement {...assistantProps} />,
            },
            {
              path: AppRoute.CustomerInformation,
              element: <CustomerInformation {...assistantProps} />,
            },
            {
              path: '',
              element: <Navigate to={AppRoute.Documents} replace />,
            },
          ],
        },
        {
          path: `${AppRoute.Conversations}/:conversationId`,
          element: <SmartLoopDashboard {...assistantProps} />,
        },
        {
          path: '*',
          element: <Navigate to="/" replace />,
        },
      ],
      routerOptions,
    );
  }
};
