import React from 'react';

import { closeDeleteConfirmationDialog } from '../../store/slices/history.slice';
import { SearchHistoryEntryType } from '../SmartLoopContainer/DrawerContent/SearchHistory/UseSearchHistory';
import { deleteEquipmentSession } from '../../store/slices/conversations.slice';
import { deleteCustomerSession } from '../../store/slices/customerDetails.slice';
import { AssistantProps } from '../../types';
import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { useTranslations } from '../../utils';
import AppDialog from '../Dialog';
import styles from './DeleteConfirmationDialog.module.scss';

const DeleteConfirmationDialog: React.FC<AssistantProps> = ({
  httpUrl,
  isMocking,
  logError,
}) => {
  const { deleteConfirmationDialog: translations } = useTranslations();
  const { entryMarkedForDeletion } = useAppSelector((state) => state.history);
  const dispatch = useAppDispatch();
  const closeDialog = () => dispatch(closeDeleteConfirmationDialog());

  if (entryMarkedForDeletion === null) {
    return null;
  }

  const handleDelete = () => {
    if (entryMarkedForDeletion.type === SearchHistoryEntryType.Conversation) {
      dispatch(
        deleteEquipmentSession({
          logError,
          ids: entryMarkedForDeletion.sessions.map((session) => session.id),
          mock: isMocking,
          baseUrl: httpUrl,
        }),
      );
    } else if (
      entryMarkedForDeletion.type === SearchHistoryEntryType.Customer
    ) {
      dispatch(
        deleteCustomerSession({
          logError,
          id: entryMarkedForDeletion.id,
          mock: isMocking,
          baseUrl: httpUrl,
        }),
      );
    }
    closeDialog();
  };
  const threadLabel =
    entryMarkedForDeletion.type === SearchHistoryEntryType.Conversation
      ? `${entryMarkedForDeletion.label} - ${
          entryMarkedForDeletion.sessions[
            entryMarkedForDeletion.sessions.length - 1
          ].question
        }`
      : entryMarkedForDeletion.label;

  return (
    <AppDialog
      open
      showCloseIcon={false}
      handleClose={closeDialog}
      className={styles.dialog}
    >
      <h3 className={styles.title}>{translations.title}</h3>
      <p className={styles.description}>
        {translations.description(threadLabel)}
      </p>
      <div className={styles.buttons}>
        <button onClick={closeDialog} className={styles.cancelBtn}>
          {translations.cancel}
        </button>
        <button onClick={handleDelete} className={styles.deleteBtn}>
          {translations.confirm}
        </button>
      </div>
    </AppDialog>
  );
};

export default DeleteConfirmationDialog;
