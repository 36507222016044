import React from 'react';

import OpenJobsFilters from '../../Filters/OpenJobsFilters';
import { ReactComponent as OpenJobsIcon } from '../assets/open-jobs.svg';
import { useTranslations } from '../../../../utils';
import { OpenJobsFilter } from '../../../../types';
import styles from './OpenJobsHeadline.module.scss';

interface Props {
  filter: OpenJobsFilter | null;
  onFilterChange: (filter: OpenJobsFilter | null) => void;
}

const OpenJobsHeadline: React.FC<Props> = ({ filter, onFilterChange }) => {
  const { assistant: translations } = useTranslations();

  return (
    <div className={styles.headline}>
      <div className={styles.titleAndIcon}>
        <OpenJobsIcon className={styles.icon} />
        <h3 className={styles.title}>{translations.home.openJobsTitle}</h3>
      </div>
      <OpenJobsFilters filter={filter} onFilterChange={onFilterChange} />
    </div>
  );
};

export default OpenJobsHeadline;
