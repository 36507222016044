import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchSymptoms } from '../../integration/symptoms.api';
import { updateEquipmentSession } from '../slices/conversations.slice';
import { FetchingStatus } from '../../types';

export interface SearchSymptomsParams extends ApiThunkParams {
  sessionId: string;
  equipmentType: string;
  searchTerm: string;
}

export const searchSymptoms = createAsyncThunk<
  void,
  SearchSymptomsParams,
  AppThunkConfig
>(
  'searchSymptoms',
  async (
    { sessionId, equipmentType, searchTerm, mock, baseUrl, logError },
    { dispatch, signal },
  ) => {
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        troubleshootingSymptoms: [],
        troubleshootingSymptomsStatus: FetchingStatus.PENDING,
      }),
    );
    try {
      const symptoms = await fetchSymptoms({
        equipmentType,
        searchTerm,
        baseUrl,
        mock,
        signal,
      });
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          troubleshootingSymptoms: symptoms,
          troubleshootingSymptomsStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch troubleshooting symptoms for session ${sessionId}. Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          troubleshootingSymptoms: [],
          troubleshootingSymptomsStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);
