import React from 'react';

import { Translations } from './common';
import { JobType, SurveyStatus } from '../types';
import { SearchHistoryEntrySegment } from '../components/SmartLoopContainer/DrawerContent/SearchHistory/UseSearchHistory';

const germanTranslations: Translations = {
  loading: 'Suche nach einer Antwort',
  error: (
    <div>
      Ups.
      <br />
      Es sieht so aus, als ob dieser Bereich nicht funktioniert. Versuchen Sie
      es später noch einmal oder senden Sie uns eine{' '}
      <a
        href="mailto:contact-project+coresystemsag1-service-ai-analytics-ui-support@incoming.gitlab.com"
        target=""
      >
        Nachricht
      </a>
      .
    </div>
  ),
  auth: {
    login: 'Login',
    email: 'Email',
    password: 'Passwort',
    or: 'oder',
    loading: 'Authentifizierung...',
    marketingMessage: 'Bereit zum Abheben?',
    invalidCredentials: 'Ungültige E-Mail oder Passwort',
    sessionExpired:
      'Ihre Session ist abgelaufen. Bitte melden Sie sich erneut an, um InsightLoop weiter zu nutzen.',
    pilotDescription: (
      <>
        Partnerschaft für den Erfolg: Unser kollaborativer Ansatz, um Ihre Reise
        zu unterstützen. Lassen Sie uns Ihnen helfen, das Potenzial Ihrer Daten
        zu nutzen - fordern Sie jetzt Ihren{' '}
        <a href="mailto:sales@coresystems.ch" target="">
          Zugang
        </a>{' '}
        an.
      </>
    ),
    unauthorized: 'Der Assistent ist derzeit nur für Pilot-Kunden verfügbar.',
    azure: {
      loginButtonText: 'Login mit Microsoft',
      tooltipLabel: 'Benötigen Sie Hilfe?',
      tooltipContent:
        'Dies ist das gleiche Login, welches Sie für den Zugang zu Azure verwenden. Wenn Sie nicht sicher sind, wenden Sie sich bitte an den IT-Support.',
    },
  },
  noData: {
    defaultMessage:
      'Wir konnten keine Daten laden. Versuchen Sie, einen Filter zu ändern.',
    openJobs: 'Derzeit sind keine offenen Jobs verfügbar.',
    pastJobs: 'Derzeit ist keine Servicehistorie verfügbar.',
    images: 'Derzeit sind keine Bilder verfügbar.',
    customerSummary: 'Derzeit ist keine Kundenzusammenfassung verfügbar.',
    diagnoses: 'Derzeit sind keine Diagnosen verfügbar.',
    tags: 'Derzeit sind keine Tags verfügbar.',
  },
  deleteConfirmationDialog: {
    title: 'Thema löschen?',
    description: (label) => (
      <span>
        Damit wird Ihr Suchthema <b>“{label}”</b> gelöscht.
      </span>
    ),
    cancel: 'Abbrechen',
    confirm: 'Löschen',
  },
  assistant: {
    noAnswer: 'Leider kann ich keine Antwort darauf finden.',
    noCustomer: 'Kein Kunde vorhanden',
    noDate: 'No date available',
    noEquipment: 'No equipment available',
    noDivision: 'No division available',
    noCode: 'No job nr. available',
    equipmentSearchPlaceholder: 'Equipment auswählen...',
    customerSearchPlaceholder: 'Customer auswählen...',
    filterLoading: 'Suchen...',
    filterNoResults: 'Keine Ergebnisse',
    questionPlaceholder: 'Geben Sie Ihre Frage hier ein...',
    followUpQuestionPlaceholder: 'Folgefrage stellen...',
    activeFilterToggle: {
      customer: 'Customer',
      equipment: 'Equipment',
    },
    jobType: {
      [JobType.Failure]: 'Reparatur',
      [JobType.Maintenance]: 'Wartung',
      [JobType.Other]: 'Sonstige',
    },
    drawer: {
      newSearch: 'Neue Suche',
      tipsTitle: 'Tipps zur Navigation',
      tipsContent: `Stellen Sie Folgefragen zu demselben Equipment, oder klicken Sie auf „Neue Suche“, um ein neues Thema zu beginnen.`,
      searchHistory: 'Suchverlauf',
      segments: {
        [SearchHistoryEntrySegment.Today]: 'Heute',
        [SearchHistoryEntrySegment.Yesterday]: 'Gestern',
        [SearchHistoryEntrySegment.LastWeek]: 'Letzte 7 Tage',
        [SearchHistoryEntrySegment.OlderThanLastWeek]: 'Älter',
      },
    },
    troubleshooting: {
      toggleLabel: 'Fehlerbehebung',
      title: 'Geführte Fehlerbehebung',
      description:
        'Anhand von bestätigten Symptomen, Problembeschreibungen oder Fehlercodes werden Sie durch den Fehlerbehebungsprozess geführt.',
      symptoms: 'Bekannte Symptome:',
      symptomSearchPlaceholder: 'Symptome suchen...',
      skipBtn: 'Vorerst überspringen',
      startBtn: 'Start',
      finishBtn: 'Fehlerbehebung beenden',
      closeBtn: 'Schliessen',
      symptomSelectorLabel:
        'Geben Sie weitere Symptome ein oder wählen Sie aus verwandten Symptomen aus:',
      relatedSymptoms: 'Verwandte Symptome:',
      answerSummary: 'Details',
      resumeTroubleshooting: 'Fehlerbehebungsbaum wieder öffnen',
      completedTitle: 'Fehlerbehebung abgeschlossen',
      finalSymptoms: 'Definitive Symptome:',
      successMessage: 'Erfolgreich diagnostiziert.',
      rootCause: 'Ursache:',
      technicians: (technicians) => (
        <>
          <em>{technicians[0]}</em> und{' '}
          <em>{technicians.length - 1} weitere</em> Techniker hatten diese
          Ursache.
        </>
      ),
      step: 'Schritt',
      diagnosisSummary: ({ diagnoses, symptoms }) => {
        if (symptoms.length === 0) {
          return 'Please select a symptom to proceed.';
        }
        const numberOfDiagnoses =
          diagnoses === null || diagnoses.length === 0
            ? 'Keine Ursache gefunden'
            : `${diagnoses.length} gefundene Ursache${
                diagnoses.length > 1 ? 'n' : ''
              }`;
        const quotedSymptoms = symptoms.map((symptom) => `“${symptom}”`);
        if (symptoms.length < 3) {
          return `${numberOfDiagnoses} basierend auf ${quotedSymptoms.join(
            ' und ',
          )}.`;
        }
        const allButTheLastSymptom = symptoms.slice(0, -1);
        const lastSymptom = symptoms[symptoms.length - 1];

        return `${numberOfDiagnoses} basierend auf ${allButTheLastSymptom.join(
          ', ',
        )} und ${lastSymptom}.`;
      },
    },
    home: {
      mainTitle: (
        <span>
          Your <em>Service Knowledge</em> Base
        </span>
      ),
      openJobsTitle: 'Offene Jobs',
      openJobsHeaders: {
        created: 'Erstellt',
        equipment: 'Equipment',
        customer: 'Kunde',
        symptom: 'Berichtetes Problem',
        rootCause: 'Hinweise',
        division: 'Division',
        jobCode: 'Job Nr.',
      },
      openJobsFilterValuePlaceholder: 'Suchen...',
      noPrediction:
        'Keine Vorhersage oder Vorhersagezuverlässigkeit ist zu gering (<10%)',
    },
    dashboard: {
      answer: 'Antwort',
      similarServiceJobs: 'Ähnliche Service Jobs',
      serviceHistory: 'Servicegeschichte',
      mostFrequentIssuesTitle: (equipmentType) =>
        `Häufigste Probleme bei ${equipmentType}`,
      imagesTitle: 'Bilder aus der Praxis',
      sources: 'Quellen',
      page: 'Seite',
      noData: (question) =>
        `Ich kann keine Informationen zu ${question} finden. Es gibt keine Antworten oder Vorschläge für die Informationen, die Sie angegeben haben. Bitte vergewissern Sie sich, dass Sie den Text richtig eingegeben haben, oder versuchen Sie eine andere Abfrage.`,
      searchSuggestionsTitle: 'Verwandte Themen',
    },
    customerScreen: {
      summary: 'Customer Summary',
      kpi: {
        title: 'KPIs',
        lessThanAverage: 'weniger als weltweiter Durchschnitt',
        moreThanAverage: 'mehr als weltweiter Durchschnitt',
        ftfr: 'First Time Fix Rate',
        ftfrAbbr: 'FTFR',
        mttr: 'Mean Time To Resolution',
        mttrAbbr: 'MTTR',
        mtbv: 'Mean Time Between Visits',
        mtbvAbbr: 'MTBV',
        dayUnit: (value) => (value < 2 ? ' Tag' : ' Tage'),
      },
    },
    feedback: {
      prompt: 'War die Antwort hilfreich?',
      positive: 'Ja',
      negative: 'Nein',
      thanks: 'Vielen Dank für Ihr Feedback.',
      wrongRootCause: 'Falsche Ursache',
      wrongDocuments: 'Irrelevante Informationen',
      wrongParts: 'Falsche Ersatzteile',
      other: 'Sonstiges',
      detailsPlaceholder: 'Geben Sie zusätzliches Feedback',
      submit: 'Feedback einreichen',
      forceFeedbackPrompt:
        'Nehmen Sie sich einen Moment Zeit, um Feedback zu geben',
    },
    curationHub: {
      recentSurveys: 'Aktuelle Umfragen',
      survey: {
        surveyStatuses: {
          [SurveyStatus.Open]: 'Ausstehend',
          [SurveyStatus.InProgress]: 'In Bearbeitung',
          [SurveyStatus.Closed]: 'Abgeschlossen',
        },
        open: 'Öffnen',
      },
      surveyDetails: {
        title: (equipmentType) => `Fragen zu ${equipmentType}`,
        prompt: 'Wählen Sie eine oder mehrere Antworten aus.',
        moreInformation: 'Mehr Informationen (optional)',
        questionJobIds: (jobId) => (
          <span>
            Frage generiert durch Job <em>{jobId}</em>
          </span>
        ),
        answerJobIds: (jobIds) => (
          <span>
            Antworten durch Job{jobIds.length > 1 ? 's' : ''}{' '}
            <em>{jobIds.join(', ')}</em> generiert
          </span>
        ),
        next: 'Nächste',
        back: 'Zurück',
      },
      surveyCompleted: {
        thanks: 'Danke!',
        completionText: (equipmentType) => (
          <span>
            Sie haben die Umfrage zum <b>{equipmentType}</b> erfolgreich
            abgeschlossen.
          </span>
        ),
        close: 'Schliessen',
      },
    },
    documentManagement: {
      homeTitle: (
        <span>
          Dein <em>Service</em> Assistent
        </span>
      ),
      configurationLabel: 'Konfiguration',
      documentOverview: 'Dokumentenübersicht',
      tagManagement: 'Verwaltung von Tags',
      customerInformation: 'Kundeninformationen',
      tagsPlaceholder: 'Suche nach Tags...',
      equipmentTypesTitle: 'Equipment Typen',
      documentsPlaceholder: 'Suche nach Dokumenten...',
      uploadDocumentsButtonLabel: 'Dokumente hochladen',
      uploadDialogTitle: 'Dokumente hochladen',
      cancel: 'Abbrechen',
      close: 'Schliessen',
      fileDropZoneLabel: (
        <span>
          <em>Zum Hochladen klicken</em> oder per Drag & Drop
        </span>
      ),
      fileName: 'Dokument',
      tags: 'Tags',
      date: 'Datum',
      fileSize: 'Grösse',
    },
  },
};

export default germanTranslations;
