import React, { useEffect } from 'react';
import clsx from 'clsx';

import { AssistantProps, FetchingStatus } from '../../../types';
import CurationHubSurvey from './Survey/Survey';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { getSurveys } from '../../../store/slices/surveys.slice';
import { useTranslations } from '../../../utils';
import LoadingOverlay from '../../LoadingOverlay';
import SurveysAppContainer from '../../SurveysAppContainer/SurveysAppContainer';
import styles from './CurationHub.module.scss';

const CurationHub: React.FC<AssistantProps> = (props) => {
  const { httpUrl, isMocking } = props;
  const {
    assistant: { curationHub: translations },
  } = useTranslations();
  const { surveys, status } = useAppSelector((state) => state.surveys);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (surveys !== null && surveys.length > 0) {
      return;
    }
    const promise = dispatch(getSurveys({ httpUrl, isMocking }));

    return () => {
      promise.abort();
    };
  }, [dispatch, httpUrl, isMocking]);

  return (
    <SurveysAppContainer>
      <h2 className={clsx(styles.title, styles.recentSurveysTitle)}>
        {translations.recentSurveys}
      </h2>
      <div className={styles.surveys}>
        {status === FetchingStatus.PENDING && <LoadingOverlay />}
        {surveys?.map((survey) => (
          <CurationHubSurvey {...props} key={survey.id} survey={survey} />
        ))}
      </div>
    </SurveysAppContainer>
  );
};

export default CurationHub;
