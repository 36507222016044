import React from 'react';

import { useTranslations } from '../../../utils';
import styles from './NoData.module.scss';

interface Props {
  message?: string;
}

const NoData: React.FC<Props> = ({ message }) => {
  const translations = useTranslations();

  return (
    <div className={styles.container}>
      <p className={styles.text}>
        {message || translations.noData.defaultMessage}
      </p>
    </div>
  );
};

export default NoData;
