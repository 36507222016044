import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FetchingStatus,
  Survey,
  SurveyDetails,
  SurveyRootCause,
} from '../../types';
import { AppThunkConfig } from '../store';
import {
  closeSurvey,
  fetchSurveyQuestions,
  sendSurveyAnswer,
  updateSurveyAnswer,
} from '../../integration/surveys.api';
import { setSurveyToCompleted, updateSurvey } from './surveys.slice';

export const SURVEY_DETAILS_KEY = 'surveyDetails';

export interface SurveyDetailsState {
  status: FetchingStatus;
  details: SurveyDetails | null;
  selectedEquipment: string | null;
  openedAt: number | null;
  timeSpentOnCurrentQuestion: number | null;
}

const initialState: SurveyDetailsState = {
  status: FetchingStatus.IDLE,
  details: null,
  selectedEquipment: null,
  openedAt: null,
  timeSpentOnCurrentQuestion: null,
};

interface GetSurveyDetailsParams {
  httpUrl: string;
  isMocking: boolean;
  survey: Survey;
}

interface GetSurveyDetailsResults {
  details: SurveyDetails;
  openedAt: number;
}

export const getSurveyDetails = createAsyncThunk<
  GetSurveyDetailsResults,
  GetSurveyDetailsParams,
  AppThunkConfig
>(
  'getSurveyDetails',
  async ({ httpUrl, isMocking, survey }, { signal, dispatch }) => {
    const { id, equipment_type, status, question_ids } = survey;
    dispatch(setSelectedEquipment(equipment_type));
    // finish passing fields from state

    const questions = await fetchSurveyQuestions({
      signal,
      surveyId: id,
      baseUrl: httpUrl,
      mock: isMocking,
      questionIds: question_ids,
    });

    return {
      details: {
        id,
        questions,
        status,
        equipmentType: equipment_type,
      },
      openedAt: Date.now(),
    };
  },
);

export interface AnswerSurveyParams {
  mock: boolean;
  baseUrl: string;
  questionId: string;
  additionalInformation: string;
  rootCauses: SurveyRootCause[];
  answered: boolean;
}

export const answerSurvey = createAsyncThunk<
  void,
  AnswerSurveyParams,
  AppThunkConfig
>('answerSurvey', async (params, { dispatch, getState, signal }) => {
  const {
    surveyDetails: { details },
  } = getState();
  if (details === null) {
    return;
  }
  const { id, questions } = details;
  if (params.answered) {
    await updateSurveyAnswer({ ...params, signal, surveyId: id });
  } else {
    const answeredQuestionsCount =
      questions.filter((question) => question.answered).length + 1; // State is 1 answer behind at this point, the next action dispatched will update it
    dispatch(setQuestionAnswered(params.questionId));
    dispatch(
      updateSurvey({
        id,
        answeredQuestionsCount,
        totalQuestionsCount: questions.length,
      }),
    );
    await sendSurveyAnswer({ ...params, signal, surveyId: id });
  }
  // TODO: introduce error handling
});

export interface CompleteSurveyParams {
  mock: boolean;
  baseUrl: string;
  surveyId: string;
}

export const completeSurvey = createAsyncThunk<
  void,
  CompleteSurveyParams,
  AppThunkConfig
>('completeSurvey', async (params, { dispatch, signal }) => {
  dispatch(setSurveyToCompleted(params.surveyId));
  await closeSurvey({ ...params, signal });
  // TODO: log error
});

const surveyDetailsSlice = createSlice({
  name: SURVEY_DETAILS_KEY,
  initialState,
  reducers: {
    setSelectedEquipment: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        selectedEquipment: action.payload,
      };
    },
    setQuestionAnswered: (state, action: PayloadAction<string>) => {
      if (state.details === null) {
        return state;
      }

      return {
        ...state,
        details: {
          ...state.details,
          questions: state.details.questions.map((question) => {
            if (question.id !== action.payload) {
              return question;
            } else {
              return {
                ...question,
                answered: true,
              };
            }
          }),
        },
      };
    },
    setTimeSpentOnCurrentQuestion: (state) => ({
      ...state,
      timeSpentOnCurrentQuestion: Date.now(),
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSurveyDetails.pending, (state) => {
        state.status = FetchingStatus.PENDING;
        state.details = null;
      })
      .addCase(getSurveyDetails.fulfilled, (state, action) => {
        const { openedAt, details } = action.payload;
        state.status = FetchingStatus.SUCCESS;
        state.details = details;
        state.openedAt = openedAt;
      })
      .addCase(getSurveyDetails.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.details = null;
      });
  },
});

const { actions, reducer } = surveyDetailsSlice;

export const {
  setSelectedEquipment,
  setQuestionAnswered,
  setTimeSpentOnCurrentQuestion,
  reset: resetSurveyDetails,
} = actions;

export const surveyDetailsReducer = reducer;
