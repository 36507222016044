import React from 'react';
import Backdrop from '@mui/material/Backdrop';

import { SourceImage } from '../../../../../../integration/media.api';
import styles from './ImageViewer.module.scss';

interface Props {
  image: SourceImage | null;
  handleClose: () => void;
}

const ImageViewer: React.FC<Props> = ({ image, handleClose }) => {
  return (
    <Backdrop
      sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={image !== null}
      onClick={handleClose}
    >
      {image && (
        <div className={styles.container}>
          <img src={image.url} alt={image.fileName} className={styles.image} />
        </div>
      )}
    </Backdrop>
  );
};

export default ImageViewer;
