import { SupportedLanguage, Translations } from '../translations/common';
import english from '../translations/english';
import german from '../translations/german';

const supportedLanguages: Record<SupportedLanguage, Translations> = {
  [SupportedLanguage.ENGLISH]: english,
  [SupportedLanguage.GERMAN]: german,
};

interface LanguageSettings {
  language: SupportedLanguage;
  locale: string;
}

export const useLanguage = (): LanguageSettings => {
  const isTestEnv = process.env.NODE_ENV === 'test';
  if (isTestEnv) {
    return {
      language: SupportedLanguage.ENGLISH,
      locale: 'en-GB',
    };
  }
  const locale = navigator.language;
  const language = locale.slice(0, 2);

  return {
    locale,
    language:
      Object.values(SupportedLanguage).find(
        (supportedLanguage) => supportedLanguage === language,
      ) || SupportedLanguage.ENGLISH,
  };
};

export const useTranslations = (): Translations => {
  const { language } = useLanguage();

  return supportedLanguages[language];
};
