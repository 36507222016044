import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import { ApiCall, AssistantProps } from '../../../../types';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { isDefined, useIsDarkMode, useTranslations } from '../../../../utils';
import { ApiThunkParams } from '../../../../store/store';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/close.svg';
import { ReactComponent as CreateIcon } from '../../../../assets/plus.svg';
import {
  createEquipmentTag,
  deleteEquipmentTag,
  searchEquipmentTags,
} from '../../../../store/slices/equipmentTags.slice';
import SmartLoopCardContent from '../../../Card/SmartLoopCardContent';
import styles from './TagManagement.module.scss';

const TagManagement: React.FC<AssistantProps> = (props) => {
  const { httpUrl, isMocking, logError } = props;
  const {
    assistant: { documentManagement: translations },
    noData,
  } = useTranslations();
  const dispatch = useAppDispatch();
  const darkMode = useIsDarkMode();
  const { equipmentTypes, equipmentSearchStatus } = useAppSelector(
    (state) => state.equipmentTags,
  );

  const [creatingTag, setCreatingTag] = useState(false);
  const newTagRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (creatingTag) {
      newTagRef.current?.focus();
    }
  }, [creatingTag]);

  const [newTagContent, setNewTagContent] = useState('');
  const handleNewTagContentChange = () => {
    setNewTagContent(newTagRef.current?.textContent || '');
  };
  const handleNewTagKeyDown = (event: React.KeyboardEvent<HTMLSpanElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      dispatch(
        createEquipmentTag({
          logError,
          baseUrl: httpUrl,
          mock: isMocking,
          equipmentType: newTagContent,
        }),
      );
      cancelNewTag();
    }
  };
  const cancelNewTag = () => {
    setCreatingTag(false);
    setNewTagContent('');
  };

  useEffect(() => {
    setCreatingTag(false);
    const promise = dispatch(
      searchEquipmentTags({
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [dispatch, isMocking, httpUrl]);

  const [fetchPromise, setFetchPromise] =
    useState<ApiCall<string[], ApiThunkParams>>();
  const getEquipmentTypes = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (isDefined(fetchPromise)) {
        fetchPromise.abort();
      }
      if (value.length === 0) {
        const promise = dispatch(
          searchEquipmentTags({
            logError,
            baseUrl: httpUrl,
            mock: isMocking,
          }),
        );
        setFetchPromise(promise);
      } else if (value.length >= 2) {
        const promise = dispatch(
          searchEquipmentTags({
            logError,
            baseUrl: httpUrl,
            mock: isMocking,
            searchTerm: value,
          }),
        );
        setFetchPromise(promise);
      }
    }, 300),
    [dispatch, fetchPromise, isMocking, httpUrl],
  );

  const [value, setValue] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setValue(value);
    getEquipmentTypes(value);
  };

  const handleDelete = (equipmentType: string) => {
    dispatch(
      deleteEquipmentTag({
        equipmentType,
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );
  };

  return (
    <>
      <div className={styles.inputContainer}>
        <FilledInput
          fullWidth
          disableUnderline
          placeholder={translations.tagsPlaceholder}
          value={value}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={styles.searchIcon} />
            </InputAdornment>
          }
        />
      </div>
      <h3 className={styles.title}>{translations.equipmentTypesTitle}</h3>
      <div className={styles.tagContainer}>
        <SmartLoopCardContent
          data={equipmentTypes}
          status={equipmentSearchStatus}
          emptyStateMessage={noData.tags}
        >
          {(tags) => (
            <div className={styles.tags}>
              {tags.map((tag) => (
                <div
                  key={tag}
                  className={clsx(styles.tag, darkMode && styles.darkMode)}
                >
                  <span>{tag}</span>
                  <DeleteIcon
                    className={styles.deleteIcon}
                    onClick={() => handleDelete(tag)}
                  />
                </div>
              ))}
              {creatingTag ? (
                <div className={clsx(styles.tag, darkMode && styles.darkMode)}>
                  <span
                    ref={newTagRef}
                    className={styles.newTag}
                    contentEditable
                    onInput={handleNewTagContentChange}
                    onKeyDown={handleNewTagKeyDown}
                  ></span>
                  <DeleteIcon
                    className={styles.deleteIcon}
                    onClick={cancelNewTag}
                  />
                </div>
              ) : (
                <button
                  className={clsx(
                    styles.createButton,
                    darkMode && styles.darkMode,
                  )}
                  onClick={() => setCreatingTag(true)}
                >
                  <CreateIcon className={styles.createIcon} />
                </button>
              )}
            </div>
          )}
        </SmartLoopCardContent>
      </div>
    </>
  );
};

export default TagManagement;
