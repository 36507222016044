import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMsal } from '@azure/msal-react';
import { AuthenticationResult } from '@azure/msal-common';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import {
  Backdrop,
  CircularProgress,
  InputAdornment,
  OutlinedInput,
  Theme,
} from '@mui/material';
import clsx from 'clsx';

import {
  isDefined,
  isInsideSalesForce,
  loginWithAzure,
  loginWithUsernameAndPassword,
  saveAzureAccount,
  useIsDarkMode,
  useLogger,
  useTranslations,
} from '../../../utils';
import { FetchingStatus } from '../../../types';
import { ReactComponent as Logo } from './assets/logo.svg';
import { ReactComponent as LogoBlack } from './assets/logo-black.svg';
import { ReactComponent as RocketImage } from './assets/login-rocket.svg';
import { ReactComponent as UserImage } from './assets/login-user.svg';
import { ReactComponent as KeyImage } from './assets/login-key.svg';
import { azureLoginRequest } from './azureConfig';
import LoginSpaceSvg from './assets/LoginSpace';
import MicrosoftIcon from './assets/MicrosoftIcon';
import PlanetSvg from './assets/PlanetSvg';
import { validateEmailAndPassword } from './utils/validateEmailAndPassword';
import styles from './Login.module.scss';

interface Props {
  authStatus: FetchingStatus;
  setAuthStatus: (status: FetchingStatus) => void;
  isSessionExpired: boolean;
  theme: Theme;
}

const Login: React.FC<Props> = ({
  authStatus,
  setAuthStatus,
  isSessionExpired,
  theme,
}) => {
  const isDarkMode = useIsDarkMode();
  const { logError } = useLogger();
  const { instance } = useMsal();
  const { auth: translations } = useTranslations();
  const insideSalesForce = isInsideSalesForce();

  const handleLogin = useCallback(
    async (authResult: AuthenticationResult | null): Promise<void> => {
      // We receive null when handleRedirectPromise() gets called before the redirect happened, so it's not an error
      if (authResult === null) {
        return;
      }
      try {
        if (!isDefined(authResult.account)) {
          throw new Error('Azure account info missing!');
        }
        saveAzureAccount(authResult.account);
        const email = authResult.account.username;
        setAuthStatus(FetchingStatus.PENDING);
        await loginWithAzure({ email, token: authResult.idToken });
        setAuthStatus(FetchingStatus.SUCCESS);
      } catch (error) {
        logError(error);
        setAuthStatus(FetchingStatus.ERROR);
      }
    },
    [],
  );

  useEffect(() => {
    // Azure Popups don't work on mobile, so we need to use redirect in general.
    // Redirect on the other hand does not work in Sales Force, so we have to use popups there.
    if (insideSalesForce) {
      return;
    }
    instance.handleRedirectPromise().then(handleLogin);
  }, [instance, handleLogin]);

  const handleAzureLoginWithRedirect = async () => {
    setAuthStatus(FetchingStatus.PENDING);
    instance.loginRedirect(azureLoginRequest).catch((error) => {
      logError(error);
      setAuthStatus(FetchingStatus.ERROR);
    });
  };
  const handleAzureLoginWithPopup = () => {
    instance.loginPopup(azureLoginRequest).then(handleLogin);
  };

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    try {
      setAuthStatus(FetchingStatus.PENDING);
      await loginWithUsernameAndPassword({
        username: username.trim().toLowerCase(),
        password,
      });
      setAuthStatus(FetchingStatus.SUCCESS);
    } catch (error) {
      logError(error);
      setAuthStatus(FetchingStatus.ERROR);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.planet}>
        <PlanetSvg />
      </div>
      <div className={styles.space}>
        <LoginSpaceSvg />
      </div>
      <div className={styles.box}>
        <div className={styles.logo}>
          {isDarkMode ? <Logo /> : <LogoBlack />}
        </div>
        <div className={styles.rocketImg}>
          <RocketImage />
          <div className={styles.sessionExpired}>
            {isSessionExpired && translations.sessionExpired}
          </div>
        </div>
        <form className={styles.form} onSubmit={handleSubmit}>
          <OutlinedInput
            className={clsx(
              styles.textField,
              isDarkMode && styles.textFieldDark,
            )}
            placeholder={translations.email}
            type="text"
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <UserImage />
              </InputAdornment>
            }
            value={username}
            onChange={handleUsernameChange}
          />
          <OutlinedInput
            className={clsx(
              styles.textField,
              isDarkMode && styles.textFieldDark,
            )}
            placeholder={translations.password}
            type="password"
            fullWidth
            startAdornment={
              <InputAdornment position="start">
                <KeyImage />
              </InputAdornment>
            }
            value={password}
            onChange={handlePasswordChange}
          />
          <div className={styles.validationText}>
            {authStatus === FetchingStatus.ERROR &&
              translations.invalidCredentials}
          </div>
          <Button
            disabled={!validateEmailAndPassword(username, password)}
            className={clsx(
              styles.loginButton,
              isDarkMode && styles.loginButtonDarkMode,
            )}
            variant="contained"
            type="submit"
          >
            {translations.login}
          </Button>
        </form>
        <div className={styles.separator}>
          <div className={styles.separatorLine} />
          <p className={styles.separatorText}>{translations.or} </p>
          <div className={styles.separatorLine} />
        </div>
        <Button
          variant="contained"
          className={styles.button}
          onClick={
            insideSalesForce
              ? handleAzureLoginWithPopup
              : handleAzureLoginWithRedirect
          }
          sx={{
            backgroundColor: isDarkMode
              ? theme.palette.info.main
              : theme.palette.secondary.dark,
          }}
        >
          <MicrosoftIcon />
          {translations.azure.loginButtonText}
        </Button>
        <Tooltip title={translations.azure.tooltipContent}>
          <p className={styles.help}>{translations.azure.tooltipLabel}</p>
        </Tooltip>
      </div>
      <Backdrop
        className={styles.loading}
        open={authStatus === FetchingStatus.PENDING}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default Login;
