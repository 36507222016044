import React, { useCallback } from 'react';
import { format } from 'date-fns';
import clsx from 'clsx';

import { ReactComponent as PastJobsIcon } from './assets/past-jobs.svg';
import {
  AppRoute,
  AssistantProps,
  FetchingStatus,
  MixpanelEventType,
  PastJob,
} from '../../types';
import {
  trackWithMixpanel,
  useAuthData,
  useTranslations,
  renderOrDefault,
  useCurrentRoute,
} from '../../utils';
import SmartLoopCard from '../Card/SmartLoopCard';
import JobTypeField from './JobTypeField/JobTypeField';
import styles from './PastJobs.module.scss';

interface Props extends AssistantProps {
  status: FetchingStatus;
  jobs: PastJob[] | null;
  className?: string;
}

const PastJobs: React.FC<Props> = ({
  jobs,
  status,
  className,
  environment,
}) => {
  const { assistant: translations, noData } = useTranslations();
  const authData = useAuthData();
  const cluster = authData === null ? null : authData.cluster;
  const currentRoute = useCurrentRoute();

  const handleSymptomCopied = useCallback(() => {
    const selection = document.getSelection();
    if (selection === null) {
      return;
    }
    trackWithMixpanel({
      environment,
      event: {
        name:
          currentRoute === AppRoute.Customer
            ? MixpanelEventType.ServiceHistorySymptomCopied
            : MixpanelEventType.SimilarServiceJobsSymptomCopied,
        properties: { text: selection.toString() },
      },
    });
  }, [environment, currentRoute]);

  return (
    <SmartLoopCard
      collapsible
      status={status}
      className={clsx(styles.container, className)}
      data={jobs}
      showBackground={false}
      emptyStateMessage={noData.pastJobs}
      title={{
        text:
          currentRoute === AppRoute.Conversations
            ? translations.dashboard.similarServiceJobs
            : translations.dashboard.serviceHistory,
        icon: <PastJobsIcon className={styles.pastJobsIcon} />,
      }}
    >
      {(loadedJobs) => (
        <div>
          {loadedJobs.map((job) => (
            <div key={job.id} className={styles.job}>
              <div className={styles.row}>
                <div>
                  <b>
                    {currentRoute === AppRoute.Customer
                      ? renderOrDefault(job.equipment)
                      : renderOrDefault(
                          job.customerName,
                          translations.noCustomer,
                        )}
                  </b>
                </div>
                <div>
                  {job.created !== null
                    ? format(job.created, 'dd.MM.yyyy')
                    : ''}
                </div>
              </div>
              <div className={styles.row}>
                <JobTypeField type={job.type} />
                <a
                  target="_blank"
                  href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                  rel="noreferrer"
                  onClick={(event) => event.stopPropagation()}
                >
                  {job.code}
                </a>
              </div>
              <div onCopy={handleSymptomCopied}>{job.reportedSymptom}</div>
            </div>
          ))}
        </div>
      )}
    </SmartLoopCard>
  );
};

export default PastJobs;
