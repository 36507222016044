import React from 'react';

import { SessionProps, TroubleshootingStatus } from '../../../../../../types';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { updateEquipmentSession } from '../../../../../../store/slices/conversations.slice';
import { useTranslations } from '../../../../../../utils';
import styles from './TroubleshootingLanding.module.scss';

const TroubleshootingLanding: React.FC<SessionProps> = ({ session }) => {
  const dispatch = useAppDispatch();
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();

  const handleClose = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingVisible: false,
      }),
    );
  };

  const handleOpen = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingStatus: TroubleshootingStatus.InProgressOpen,
      }),
    );
  };

  return (
    <div>
      <h3 className={styles.title}>{translations.title}</h3>
      <p className={styles.description}>{translations.description}</p>
      <p className={styles.symptoms}>
        <b>{translations.symptoms}</b>{' '}
        {session.extractedSymptom || session.question}
      </p>
      <div className={styles.buttons}>
        <button className={styles.closeBtn} onClick={handleClose}>
          {translations.skipBtn}
        </button>
        <button className={styles.openBtn} onClick={handleOpen}>
          {translations.startBtn}
        </button>
      </div>
    </div>
  );
};

export default TroubleshootingLanding;
