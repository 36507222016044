import { FirstTimeFixedRateMetrics } from '../../integration/ftfr.api';

const ukOnshoreFtfrSeed: FirstTimeFixedRateMetrics = {
  first_time_fix_rate: [{ date_interval: '', rate: '0.7' }],
  trend: 0.042,
};

const esbOffshoreFtfrSeed: FirstTimeFixedRateMetrics = {
  first_time_fix_rate: [{ date_interval: '', rate: '0.59' }],
  trend: -0.053,
};

const landsvirkjunFtfrSeed: FirstTimeFixedRateMetrics = {
  first_time_fix_rate: [{ date_interval: '', rate: '0.63' }],
  trend: -0.117,
};

const borsseleFtfrSeed: FirstTimeFixedRateMetrics = {
  first_time_fix_rate: [{ date_interval: '', rate: '0.91' }],
  trend: 0.143,
};

const hollandseFtfrSeed: FirstTimeFixedRateMetrics = {
  first_time_fix_rate: [],
  trend: null,
};

export const getFtfrSeed = (customerId: string): FirstTimeFixedRateMetrics => {
  switch (customerId) {
    case 'customer1':
      return ukOnshoreFtfrSeed;
    case 'customer2':
      return esbOffshoreFtfrSeed;
    case 'customer3':
      return landsvirkjunFtfrSeed;
    case 'customer4':
      return borsseleFtfrSeed;
    case 'customer5':
    default:
      return hollandseFtfrSeed;
  }
};
