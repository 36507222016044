import { JobType, PastJob } from '../../types';
import {
  bladeFinalSeed,
  generatorFinalSeed,
  pitchFinalSeed,
} from './predictions.seed';
import { getDay } from '../../utils';

const generatorSeed: PastJob[] = [
  {
    id: 'open_job_1',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Unusual Noise Emission',
    code: '8912',
    equipment: 'WT 8.0-167 DD',
    equipmentSerial: 'WT 8.0-167 DD',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(20),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_2',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Reduced Power Output',
    code: '8800',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(30),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_3',
    customerId: 'customer2',
    customerName: 'Borssele',
    reportedSymptom: 'Vibration and Oscillation',
    code: '8795',
    equipment: 'WT 3.x-137',
    equipmentSerial: 'WT 3.x-137',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(30),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_4',
    customerId: 'customer3',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Irregular Blade Movement',
    code: '8687',
    equipment: 'WT 2-B Energy',
    equipmentSerial: 'WT 2-B Energy',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(34),
    ended: null,
    started: null,
    closed: null,
  },
];

const pitchSeed: PastJob[] = [
  {
    id: 'open_job_1',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Unusual Noise Emission',
    code: '7839',
    equipment: 'WT 8.0-167 DD',
    equipmentSerial: 'WT 8.0-167 DD',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(17),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_2',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Reduced Power Output',
    code: '7689',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(21),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_3',
    customerId: 'customer2',
    customerName: 'Borssele',
    reportedSymptom: 'Vibration and Oscillation',
    code: '7504',
    equipment: 'WT 3.x-137',
    equipmentSerial: 'WT 3.x-137',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(29),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_4',
    customerId: 'customer3',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Irregular Blade Movement',
    code: '7487',
    equipment: 'WT 2-B Energy',
    equipmentSerial: 'WT 2-B Energy',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(30),
    ended: null,
    started: null,
    closed: null,
  },
];

const bladeSeed: PastJob[] = [
  {
    id: 'open_job_1',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Unusual Noise Emission',
    code: '15048',
    equipment: 'WT 8.0-167 DD',
    equipmentSerial: 'WT 8.0-167 DD',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(44),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_2',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Reduced Power Output',
    code: '14967',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(47),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_3',
    customerId: 'customer2',
    customerName: 'Borssele',
    reportedSymptom: 'Vibration and Oscillation',
    code: '14855',
    equipment: 'WT 3.x-137',
    equipmentSerial: 'WT 3.x-137',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(49),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'open_job_4',
    customerId: 'customer3',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Irregular Blade Movement',
    code: '14698',
    equipment: 'WT 2-B Energy',
    equipmentSerial: 'WT 2-B Energy',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(60),
    ended: null,
    started: null,
    closed: null,
  },
];

export const getDashboardPastJobsSeed = (jobIds: string[]): PastJob[] => {
  if (
    jobIds.some(
      (jobId) => jobId === bladeFinalSeed.sourceDocuments[0].metadata.code,
    )
  ) {
    return bladeSeed;
  }
  if (
    jobIds.some(
      (jobId) => jobId === generatorFinalSeed.sourceDocuments[0].metadata.code,
    )
  ) {
    return generatorSeed;
  }
  if (
    jobIds.some(
      (jobId) => jobId === pitchFinalSeed.sourceDocuments[0].metadata.code,
    )
  ) {
    return pitchSeed;
  } else {
    return [];
  }
};
