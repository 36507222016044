import api from './api';
import {
  ApiFetchParams,
  ApiResponse,
  UserProfile,
  UserProfileResponseData,
} from '../types';

type UserProfileResponse = ApiResponse<UserProfileResponseData>;

export const fetchUserProfile = async ({
  baseUrl,
  mock,
  signal,
}: ApiFetchParams): Promise<UserProfile> => {
  const { data } = await api.get<UserProfileResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/profile-settings',
    config: { signal },
  });

  return data.settings;
};

interface UpdateUserProfileParams extends ApiFetchParams {
  userProfile: UserProfile;
}

export const updateUserProfile = async ({
  userProfile,
  baseUrl,
  mock,
  signal,
}: UpdateUserProfileParams): Promise<void> => {
  if (mock) {
    // Intentional use of console.log, we should not use the app logger here!
    console.log('User Profile saved.', userProfile);
  } else {
    await api.patch<UserProfileResponse>({
      baseUrl,
      mock,
      path: '/knowledge-base/api/v1/profile-settings',
      body: userProfile,
      config: { signal },
    });
  }
};
