import React, { useCallback, useMemo, useRef, useState } from 'react';

import SmartLoopCard from '../../../../Card/SmartLoopCard';
import { SessionProps } from '../../../../../types';
import { useTranslations } from '../../../../../utils';
import { ReactComponent as ImagesIcon } from './assets/images.svg';
import { ReactComponent as ScrollLeftIcon } from './assets/scroll-left.svg';
import { ReactComponent as ScrollRightIcon } from './assets/scroll-right.svg';
import ImageViewer from './ImageViewer/ImageViewer';
import { SourceImage } from '../../../../../integration/media.api';
import { isElementOverflowing } from '../../../../../utils/isElementOverflowing';
import styles from './Carousel.module.scss';

const Carousel: React.FC<SessionProps> = (props) => {
  const { session } = props;
  const {
    assistant: { dashboard: translations },
    noData,
  } = useTranslations();
  const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  const imagesRef = useRef<HTMLDivElement>(null);
  const areImagesScrollable = useMemo(() => {
    return isElementOverflowing(imagesRef.current);
  }, [loadedImagesCount]);

  const scrollLeft = useCallback(() => {
    if (imagesRef.current === null) {
      return;
    }
    imagesRef.current.scrollBy({ left: -100, behavior: 'smooth' });
  }, []);
  const scrollRight = useCallback(() => {
    if (imagesRef.current === null) {
      return;
    }
    imagesRef.current.scrollBy({ left: 100, behavior: 'smooth' });
  }, []);

  const [selectedImage, setSelectedImage] = useState<SourceImage | null>(null);

  return (
    <SmartLoopCard
      collapsible
      status={session.rootCauseStatus}
      collapsedCardClassname={styles.collapsedCard}
      data={session.images}
      emptyStateMessage={noData.images}
      title={{
        text: translations.imagesTitle,
        icon: <ImagesIcon className={styles.imagesIcon} />,
      }}
    >
      {(images) => (
        <div className={styles.container}>
          {areImagesScrollable && (
            <ScrollLeftIcon
              onClick={scrollLeft}
              className={styles.scrollIcon}
            />
          )}
          <div ref={imagesRef} className={styles.images}>
            {images.map((image) => (
              <img
                key={image.id}
                src={image.url}
                alt={image.fileName}
                className={styles.image}
                onClick={() => setSelectedImage(image)}
                onLoad={() =>
                  setLoadedImagesCount((prevState) => prevState + 1)
                }
              />
            ))}
          </div>
          {areImagesScrollable && (
            <ScrollRightIcon
              onClick={scrollRight}
              className={styles.scrollIcon}
            />
          )}
          <ImageViewer
            image={selectedImage}
            handleClose={() => setSelectedImage(null)}
          />
        </div>
      )}
    </SmartLoopCard>
  );
};

export default Carousel;
