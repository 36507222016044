import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiThunkParams, AppThunkConfig } from '../store';
import { FetchingStatus } from '../../types';
import { updateEquipmentSession } from '../slices/conversations.slice';
import { fetchExtractedSymptom } from '../../integration/extractedSymptom.api';
import { generateSymptom } from '../../utils';

interface GetExtractedSymptomParams extends ApiThunkParams {
  sessionId: string;
  question: string;
}

export const getExtractedSymptom = createAsyncThunk<
  void,
  GetExtractedSymptomParams,
  AppThunkConfig
>(
  'getExtractedSymptom',
  async (
    { sessionId, question, mock, logError, baseUrl },
    { signal, dispatch },
  ) => {
    try {
      const extractedSymptom = await fetchExtractedSymptom({
        question,
        baseUrl,
        mock,
        signal,
      });
      dispatch(
        updateEquipmentSession({
          extractedSymptom,
          troubleshootingSelectedSymptoms: [
            generateSymptom(extractedSymptom || question),
          ],
          extractedSymptomStatus: FetchingStatus.SUCCESS,
          id: sessionId,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch extracted symptom for ${question} Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          troubleshootingSelectedSymptoms: [generateSymptom(question)],
          extractedSymptomStatus: FetchingStatus.ERROR,
          id: sessionId,
        }),
      );
    }
  },
);
