import React from 'react';
import clsx from 'clsx';

import {
  FetchingStatus,
  SessionProps,
  TroubleshootingStatus,
} from '../../../../../../types';
import { ReactComponent as CloseIcon } from '../assets/close.svg';
import { ReactComponent as OpenIcon } from '../assets/open.svg';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { updateEquipmentSession } from '../../../../../../store/slices/conversations.slice';
import { useTranslations } from '../../../../../../utils';
import SymptomSearch from './SymptomSearch/SymptomSearch';
import SmartLoopCardContent from '../../../../../Card/SmartLoopCardContent';
import Questionnaire from './Questionnaire/Questionnaire';
import DiagnosisRootCause from '../RootCause/DiagnosisRootCause';
import LoadingOverlay from '../../../../../LoadingOverlay';
import styles from './TroubleshootingStarted.module.scss';

const TroubleshootingStarted: React.FC<SessionProps> = (props) => {
  const { session } = props;
  const {
    troubleshootingStatus,
    troubleshootingSelectedSymptoms,
    resolvedRootCauses,
  } = session;
  const dispatch = useAppDispatch();
  const {
    assistant: { troubleshooting: translations },
    noData,
  } = useTranslations();

  const toggleContent = (open: boolean) => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingStatus: open
          ? TroubleshootingStatus.InProgressOpen
          : TroubleshootingStatus.InProgressClosed,
      }),
    );
  };

  const handleClose = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingVisible: false,
      }),
    );
  };

  return (
    <>
      <div className={styles.headline}>
        <h3 className={styles.title}>{translations.title}</h3>
        {troubleshootingStatus === TroubleshootingStatus.InProgressOpen ? (
          <CloseIcon
            className={styles.toggleOpenIcon}
            onClick={() => toggleContent(false)}
          />
        ) : (
          <OpenIcon
            className={styles.toggleOpenIcon}
            onClick={() => toggleContent(true)}
          />
        )}
      </div>
      <div
        className={clsx(
          styles.content,
          troubleshootingStatus === TroubleshootingStatus.InProgressOpen &&
            styles.open,
        )}
      >
        <SymptomSearch {...props} />
        <Questionnaire {...props} />
        {session.diagnosisStatus === FetchingStatus.PENDING && (
          <div className={styles.loading}>
            <LoadingOverlay />
          </div>
        )}
        {session.resolvedRootCauses !== null && (
          <div className={styles.diagnoses}>
            <SmartLoopCardContent
              status={session.diagnosisStatus}
              data={session.resolvedRootCauses}
              emptyStateMessage={noData.diagnoses}
            >
              {(rootCauses) =>
                rootCauses.map((rootCause) => (
                  <DiagnosisRootCause
                    {...props}
                    key={rootCause.id}
                    rootCause={rootCause}
                  />
                ))
              }
            </SmartLoopCardContent>
          </div>
        )}
      </div>
      <div
        className={clsx(
          styles.content,
          styles.diagnosisSummary,
          troubleshootingStatus === TroubleshootingStatus.InProgressClosed &&
            styles.open,
        )}
      >
        {translations.diagnosisSummary({
          diagnoses: resolvedRootCauses,
          symptoms: troubleshootingSelectedSymptoms.map(
            (symptom) => symptom.name,
          ),
        })}
      </div>
      <button className={styles.closeBtn} onClick={handleClose}>
        {translations.skipBtn}
      </button>
    </>
  );
};

export default TroubleshootingStarted;
