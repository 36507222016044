import { OpenJobsSorting, OpenJobsSortingField, SortDirection } from '../types';

export const getSortingFromColumn = (
  field: OpenJobsSortingField,
  previousSorting: OpenJobsSorting,
): OpenJobsSorting => {
  if (previousSorting.field !== field) {
    return {
      field,
      direction: SortDirection.Ascending,
    };
  } else {
    return {
      field,
      direction:
        previousSorting.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending,
    };
  }
};
