import React from 'react';

import {
  trackWithMixpanel,
  useLanguage,
  useTranslations,
} from '../../../../../utils';
import {
  FetchingStatus,
  MixpanelEventType,
  QuestionSource,
  SessionProps,
} from '../../../../../types';
import {
  ActiveFilter,
  setActiveFilter,
} from '../../../../../store/slices/inputs.slice';
import { askQuestion } from '../../../../../store/slices/streaming.slice';
import { useAppDispatch } from '../../../../../store/utils/hooks';
import { ReactComponent as ArrowIcon } from '../../../../../assets/arrow-right.svg';
import styles from './SearchSuggestions.module.scss';

const SearchSuggestions: React.FC<SessionProps> = (props) => {
  const { session, wsUrl, httpUrl, isMocking, environment, logError, logInfo } =
    props;
  const {
    conversationId,
    equipmentType,
    allEquipmentTags,
    question,
    suggestions,
    suggestionsStatus,
  } = session;
  const {
    assistant: { dashboard: translations },
  } = useTranslations();
  const { language } = useLanguage();
  const dispatch = useAppDispatch();

  const handleSuggestionClicked = (suggestion: string) => {
    dispatch(setActiveFilter(ActiveFilter.EquipmentType));
    dispatch(
      askQuestion({
        conversationId,
        wsUrl,
        httpUrl,
        language,
        isMocking,
        environment,
        logError,
        logInfo,
        equipmentType,
        allEquipmentTags,
        message: suggestion,
        source: QuestionSource.Suggestion,
      }),
    );
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.SearchSuggestionClicked,
        properties: {
          equipmentType,
          question,
          suggestion,
          conversationId,
          sessionId: session.id,
        },
      },
    });
  };

  if (
    suggestionsStatus !== FetchingStatus.SUCCESS ||
    suggestions === null ||
    suggestions.length === 0
  ) {
    return null;
  }

  return (
    <div className={styles.container}>
      <h4 className={styles.title}>{translations.searchSuggestionsTitle}</h4>
      <ul className={styles.suggestions}>
        {suggestions.map((suggestion) => (
          <li
            key={suggestion}
            className={styles.suggestion}
            onClick={() => handleSuggestionClicked(suggestion)}
          >
            <span>{suggestion}</span>
            <ArrowIcon className={styles.arrowIcon} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SearchSuggestions;
