import { MeanTimeToResolutionMetrics } from '../../integration/mttr.api';

const ukOnshoreMttrSeed: MeanTimeToResolutionMetrics = {
  mean_time_to_resolution: [{ date_interval: '', mean_time_to_res: '7200' }],
  trend: 0.102,
};

const esbOffshoreMttrSeed: MeanTimeToResolutionMetrics = {
  mean_time_to_resolution: [{ date_interval: '', mean_time_to_res: '9216' }],
  trend: 0.057,
};

const landsvirkjunMttrSeed: MeanTimeToResolutionMetrics = {
  mean_time_to_resolution: [{ date_interval: '', mean_time_to_res: '5472' }],
  trend: -0.128,
};

const borsseleMttrSeed: MeanTimeToResolutionMetrics = {
  mean_time_to_resolution: [{ date_interval: '', mean_time_to_res: '1730' }],
  trend: 0.014,
};

const hollandseMttrSeed: MeanTimeToResolutionMetrics = {
  mean_time_to_resolution: [],
  trend: null,
};

export const getMttrSeed = (
  customerId: string,
): MeanTimeToResolutionMetrics => {
  switch (customerId) {
    case 'customer1':
      return ukOnshoreMttrSeed;
    case 'customer2':
      return esbOffshoreMttrSeed;
    case 'customer3':
      return landsvirkjunMttrSeed;
    case 'customer4':
      return borsseleMttrSeed;
    case 'customer5':
    default:
      return hollandseMttrSeed;
  }
};
