import { JobType, PastJob } from '../../types';
import { getDay } from '../../utils';

export const ukOnshorePastJobsSeed: PastJob[] = [
  {
    id: 'job_1',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Gearbox lubrication failure',
    code: '7637',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(21),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_2',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Scheduled oil & filter maintenance',
    code: '7622',
    equipment: 'WT 8.0-167',
    equipmentSerial: 'WT 8.0-167',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(22),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_3',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Pitch bearing failure',
    code: '7578',
    equipment: 'WT 2.5-120',
    equipmentSerial: 'WT 2.5-120',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(24),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_4',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Installation new Wind Turbine Onshore',
    code: '7456',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Other,
    created: getDay(33),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_5',
    customerId: 'customer1',
    customerName: 'UK Onshore WF',
    reportedSymptom: 'Brake system inspection',
    code: '7304',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(45),
    ended: null,
    started: null,
    closed: null,
  },
];

export const esbOffshorePastJobsSeed: PastJob[] = [
  {
    id: 'job_1',
    customerId: 'customer2',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Tower structural damaged',
    code: '8731',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(14),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_2',
    customerId: 'customer2',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Pitch bearing failure',
    code: '8725',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(14),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_3',
    customerId: 'customer2',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Anemometer calibration',
    code: '8619',
    equipment: 'WT 2.5-120',
    equipmentSerial: 'WT 2.5-120',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(17),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_4',
    customerId: 'customer2',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Installation & Replace gearbox',
    code: '8557',
    equipment: 'WT 6.0-154 DD',
    equipmentSerial: 'WT 6.0-154 DD',
    equipmentType: null,
    address: null,
    type: JobType.Other,
    created: getDay(19),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_5',
    customerId: 'customer2',
    customerName: 'ESB Offshore',
    reportedSymptom: 'Vibration monitoring',
    code: '8405',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(24),
    ended: null,
    started: null,
    closed: null,
  },
];

export const landsvirkjunPastJobsSeed: PastJob[] = [
  {
    id: 'job_1',
    customerId: 'customer3',
    customerName: 'Landsvirkjun',
    reportedSymptom: 'Communication system failure',
    code: '7931',
    equipment: 'WT 4.x-130',
    equipmentSerial: 'WT 4.x-130',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(56),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_2',
    customerId: 'customer3',
    customerName: 'Landsvirkjun',
    reportedSymptom: 'Hydraulic system leak',
    code: '7550',
    equipment: 'WT 2-B Energy 2B6',
    equipmentSerial: 'WT 2-B Energy 2B6',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(67),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_3',
    customerId: 'customer3',
    customerName: 'Landsvirkjun',
    reportedSymptom: 'Pitch bearing failure',
    code: '7370',
    equipment: 'WT 2.5-120',
    equipmentSerial: 'WT 2.5-120',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(74),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_4',
    customerId: 'customer3',
    customerName: 'Landsvirkjun',
    reportedSymptom: 'Electrical system inspection',
    code: '7140',
    equipment: 'WT 4.x-130',
    equipmentSerial: 'WT 4.x-130',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(77),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_5',
    customerId: 'customer3',
    customerName: 'Landsvirkjun',
    reportedSymptom: 'Blade inspection and cleaning',
    code: '6029',
    equipment: 'WT 2-B Energy 2B6',
    equipmentSerial: 'WT 2-B Energy 2B6',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(102),
    ended: null,
    started: null,
    closed: null,
  },
];

export const borsselePastJobsSeed: PastJob[] = [
  {
    id: 'job_1',
    customerId: 'customer4',
    customerName: 'Borssele Sites I',
    reportedSymptom: 'Visual inspections',
    code: '7954',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(23),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_2',
    customerId: 'customer4',
    customerName: 'Borssele Sites I',
    reportedSymptom: 'Irregular blade movement',
    code: '7798',
    equipment: 'WT 8.0-167',
    equipmentSerial: 'WT 8.0-167',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(27),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_3',
    customerId: 'customer4',
    customerName: 'Borssele Sites I',
    reportedSymptom: 'Generator failure',
    code: '7580',
    equipment: 'WT 2.5-120',
    equipmentSerial: 'WT 2.5-120',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(31),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_4',
    customerId: 'customer4',
    customerName: 'Borssele Sites I',
    reportedSymptom: 'Pitch system calibration',
    code: '7540',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(32),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_5',
    customerId: 'customer4',
    customerName: 'Borssele Sites I',
    reportedSymptom: 'Installation yaw system',
    code: '7284',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Other,
    created: getDay(42),
    ended: null,
    started: null,
    closed: null,
  },
];

export const hollandsePastJobsSeed: PastJob[] = [
  {
    id: 'job_1',
    customerId: 'customer5',
    customerName: 'Hollandse Kust (noord)',
    reportedSymptom: 'Generator installation',
    code: '8043',
    equipment: 'WT 3.x-103',
    equipmentSerial: 'WT 3.x-103',
    equipmentType: null,
    address: null,
    type: JobType.Other,
    created: getDay(19),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_2',
    customerId: 'customer5',
    customerName: 'Hollandse Kust (noord)',
    reportedSymptom: 'Blade erosion',
    code: '7764',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(22),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_3',
    customerId: 'customer5',
    customerName: 'Hollandse Kust (noord)',
    reportedSymptom: 'Gearbox overheating',
    code: '7758',
    equipment: 'WT 2.5-120',
    equipmentSerial: 'WT 2.5-120',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(22),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_4',
    customerId: 'customer5',
    customerName: 'Hollandse Kust (noord)',
    reportedSymptom: 'Gearbox lubrication failure',
    code: '7757',
    equipment: 'WT 6.0-154 DD',
    equipmentSerial: 'WT 6.0-154 DD',
    equipmentType: null,
    address: null,
    type: JobType.Failure,
    created: getDay(22),
    ended: null,
    started: null,
    closed: null,
  },
  {
    id: 'job_5',
    customerId: 'customer5',
    customerName: 'Hollandse Kust (noord)',
    reportedSymptom: 'Software updates',
    code: '6867',
    equipment: 'WT 5.6-170',
    equipmentSerial: 'WT 5.6-170',
    equipmentType: null,
    address: null,
    type: JobType.Maintenance,
    created: getDay(39),
    ended: null,
    started: null,
    closed: null,
  },
];

export const getCustomerPastJobsSeed = (customerId: string): PastJob[] => {
  switch (customerId) {
    case 'customer1':
      return ukOnshorePastJobsSeed;
    case 'customer2':
      return esbOffshorePastJobsSeed;
    case 'customer3':
      return landsvirkjunPastJobsSeed;
    case 'customer4':
      return borsselePastJobsSeed;
    case 'customer5':
      return hollandsePastJobsSeed;
    default:
      return [];
  }
};
