import React, { useState } from 'react';
import clsx from 'clsx';

import { AssistantProps, MixpanelEventType, Question } from '../../../../types';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import {
  isDefined,
  trackWithMixpanel,
  useIsDarkMode,
  useTranslations,
} from '../../../../utils';
import { useAppDispatch } from '../../../../store/utils/hooks';
import { answerSurvey } from '../../../../store/slices/surveyDetails.slice';
import styles from './SurveyForm.module.scss';

interface Props extends AssistantProps {
  question: Question;
  visible: boolean;
}

const SurveyForm: React.FC<Props> = ({
  question,
  visible,
  httpUrl,
  isMocking,
  environment,
}) => {
  const {
    assistant: {
      curationHub: { surveyDetails: translations },
    },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const [selectedRootCauses, setSelectedRootCauses] = useState<boolean[]>(() =>
    question.root_causes.map((rc) => rc.confirmed),
  );
  const dispatch = useAppDispatch();
  const [details, setDetails] = useState(question.additional_information || '');

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checkBoxIndex: number,
    rootCause: string,
  ) => {
    const updatedSelection = selectedRootCauses.map((prevValue, index) => {
      if (index === checkBoxIndex) {
        return event.target.checked;
      } else {
        return prevValue;
      }
    });
    dispatch(
      answerSurvey({
        baseUrl: httpUrl,
        mock: isMocking,
        questionId: question.id,
        additionalInformation: details,
        answered: question.answered,
        rootCauses: question.root_causes.map((rc, index) => ({
          ...rc,
          confirmed: updatedSelection[index],
        })),
      }),
    );
    setSelectedRootCauses(updatedSelection);
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.SurveyRootCauseSelected,
        properties: { rootCause, question: question.text },
      },
    });
  };

  const handleBlur = () => {
    dispatch(
      answerSurvey({
        baseUrl: httpUrl,
        mock: isMocking,
        questionId: question.id,
        additionalInformation: details,
        answered: question.answered,
        rootCauses: question.root_causes.map((rc, index) => ({
          ...rc,
          confirmed: selectedRootCauses[index],
        })),
      }),
    );
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.SurveyDetailsProvided,
        properties: { details, question: question.text },
      },
    });
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.text}>{question.text}</h2>
        <p className={styles.prompt}>{translations.prompt}</p>
        <div className={styles.rootCauses}>
          <FormGroup>
            {question.root_causes.map((rc, index) => (
              <FormControlLabel
                key={rc.root_cause}
                label={rc.root_cause}
                control={
                  <Checkbox
                    color="info"
                    checked={selectedRootCauses[index]}
                    onChange={(event) =>
                      handleChange(event, index, rc.root_cause)
                    }
                  />
                }
              />
            ))}
          </FormGroup>
        </div>
        <label htmlFor="details" className={styles.detailsLabel}>
          {translations.moreInformation}
        </label>
        <textarea
          id="details"
          className={clsx(styles.details, darkMode && styles.darkMode)}
          rows={5}
          value={details}
          onChange={(event) => setDetails(event.target.value)}
          onBlur={handleBlur}
        />
      </div>
      <div className={styles.jobIdContainer}>
        {isDefined(question.question_job_code) &&
          question.question_job_code.length > 0 && (
            <p className={styles.jobIds}>
              {translations.questionJobIds(question.question_job_code)}
            </p>
          )}
        {isDefined(question.source_job_codes) &&
          question.source_job_codes.length > 0 && (
            <p className={styles.jobIds}>
              {translations.answerJobIds(question.source_job_codes)}
            </p>
          )}
      </div>
    </div>
  );
};

export default SurveyForm;
