import React, { useCallback, useMemo } from 'react';

import {
  getSourceDocuments,
  trackWithMixpanel,
  useTranslations,
  areDocumentResultsEmptyOrError,
} from '../../../../../../utils';
import { isDocumentFault } from '../../../../../../utils/documents';
import {
  FetchingStatus,
  MixpanelEventType,
  SessionProps,
} from '../../../../../../types';
import TextLoader from '../../../../../TextLoader/TextLoader';
import styles from './DocumentSearchResults.module.scss';

const DocumentSearchResults: React.FC<SessionProps> = (props) => {
  const { session, environment } = props;
  const { documentSearchAnswer, documentSearchSources, documentSearchStatus } =
    session;
  const {
    assistant: { dashboard: translations },
  } = useTranslations();

  const documents = useMemo(() => {
    if (documentSearchSources === null) {
      return [];
    }
    return getSourceDocuments(
      documentSearchSources.filter((doc) => !isDocumentFault(doc)),
    );
  }, [documentSearchSources]);

  const handleSummaryCopied = useCallback(() => {
    const selection = document.getSelection();
    if (selection === null) {
      return;
    }
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.DocumentSummaryCopied,
        properties: { text: selection.toString() },
      },
    });
  }, [environment]);

  if (documentSearchStatus === FetchingStatus.PENDING) {
    return <TextLoader />;
  }

  // The parent component handles empty/error state
  if (areDocumentResultsEmptyOrError(session)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p
        className={styles.summary}
        dangerouslySetInnerHTML={{
          __html: documentSearchAnswer || '',
        }}
        onCopy={handleSummaryCopied}
      />
      {documents.length > 0 && (
        <h4 className={styles.documentsTitle}>{translations.sources}:</h4>
      )}
      <ul className={styles.documents}>
        {documents.map((document, index) => (
          <li key={index}>
            <a
              onClick={() =>
                trackWithMixpanel({
                  environment,
                  event: {
                    name: MixpanelEventType.DocumentClicked,
                    properties: {
                      name: document.name,
                      link: document.link,
                    },
                  },
                })
              }
              href={document.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {document.name} ({translations.page} {document.page})
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentSearchResults;
