import React from 'react';
import clsx from 'clsx';

import { CardProps } from './SmartLoopCard';
import { FetchingStatus } from '../../types';
import { isDefined } from '../../utils';
import LoadingOverlay from '../LoadingOverlay';
import Error from './Error/Error';
import NoData from './NoData/NoData';
import styles from './SmartLoopCardContent.module.scss';

// eslint-disable-next-line
const SmartLoopCardContent = <T extends any>({
  status,
  contentClassname,
  children,
  data,
  FallbackComponent,
  LoadingComponent,
  emptyStateMessage,
}: CardProps<T>) => {
  const noData = !isDefined(data) || (Array.isArray(data) && data.length === 0);
  const NoDataComponent = isDefined(FallbackComponent) ? (
    FallbackComponent
  ) : (
    <NoData message={emptyStateMessage} />
  );

  switch (status) {
    case FetchingStatus.PENDING:
      return isDefined(LoadingComponent) ? (
        LoadingComponent
      ) : (
        <LoadingOverlay />
      );
    case FetchingStatus.ERROR:
      return <Error />;
    case FetchingStatus.SUCCESS:
      if (noData) {
        return NoDataComponent;
      } else {
        return (
          <div className={clsx(styles.content, contentClassname)}>
            {children(data)}
          </div>
        );
      }
    default:
      return null;
  }
};

export default SmartLoopCardContent;
