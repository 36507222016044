import React, { useRef } from 'react';
import clsx from 'clsx';

import { isUserServiceExpert, useTranslations } from '../../../../../../utils';
import { FeedbackType, SessionProps } from '../../../../../../types';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { sendPositiveFeedback } from '../../../../../../store/slices/streaming.slice';
import { registerPositiveFeedback } from '../../../../../../store/slices/forceFeedback.slice';
import { updateEquipmentSession } from '../../../../../../store/slices/conversations.slice';
import { ReactComponent as ThumbsUp } from './assets/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from './assets/thumbs-down.svg';
import TroubleshootingToggle from './TroubleshootingToggle/TroubleshootingToggle';
import styles from './Feedback.module.scss';

const Feedback: React.FC<SessionProps> = (props) => {
  const { session, httpUrl, isMocking, logError } = props;
  const userServiceExpert = isUserServiceExpert();
  const {
    assistant: { feedback: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const handlePositiveFeedback = () => {
    dispatch(sendPositiveFeedback({ session, httpUrl, isMocking, logError }));
    dispatch(registerPositiveFeedback());
  };
  const openFeedbackForm = () => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        feedback: {
          type: session.feedback.type,
          open: true,
        },
      }),
    );
  };

  return (
    <div ref={ref} className={styles.container}>
      {userServiceExpert && <TroubleshootingToggle {...props} />}
      <div className={styles.feedbackContainer}>
        <p>
          {session.feedback.type === FeedbackType.NONE
            ? translations.prompt
            : translations.thanks}
        </p>
        <div className={styles.feedback} onClick={handlePositiveFeedback}>
          <ThumbsUp
            className={clsx(
              styles.thumbsIcon,
              session.feedback.type === FeedbackType.POSITIVE && styles.active,
            )}
          />
        </div>
        <div className={styles.feedback} onClick={openFeedbackForm}>
          <ThumbsDown
            className={clsx(
              styles.thumbsIcon,
              session.feedback.type === FeedbackType.NEGATIVE && styles.active,
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default Feedback;
