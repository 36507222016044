import React, { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { AssistantProps } from '../../../../../../types';
import { ReactComponent as PdfIcon } from './assets/pdf.svg';
import { isElementOverflowing } from '../../../../../../utils/isElementOverflowing';
import { useAppSelector } from '../../../../../../store/utils/hooks';
import DocumentStatusIndicator from './Indicator/DocumentStatusIndicator';
import { useIsDarkMode, useTranslations } from '../../../../../../utils';
import styles from './DocumentUploadStatus.module.scss';

interface Props extends AssistantProps {
  files: File[];
}

const DocumentUploadStatus: React.FC<Props> = ({ files, ...props }) => {
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();
  const { newDocuments } = useAppSelector((state) => state.documents);
  const ref = useRef<HTMLDivElement>(null);
  const hideTooltip = !isElementOverflowing(ref.current);
  const darkMode = useIsDarkMode();

  return (
    <div className={styles.container}>
      <div className={clsx(styles.row, styles.headers)}>
        <div />
        <div>{translations.fileName}</div>
        <div>{translations.tags}</div>
        <div>{translations.fileSize}</div>
      </div>
      <div className={styles.documents}>
        {newDocuments.map((doc) => (
          <div key={doc.id} className={clsx(styles.row, styles.document)}>
            <div>
              <PdfIcon className={styles.pdfIcon} />
            </div>
            <Tooltip title={doc.name} disableHoverListener={hideTooltip}>
              <div ref={ref}>
                <b>{doc.name}</b>
              </div>
            </Tooltip>
            <div>
              {doc.tags.map((tag) => (
                <div
                  key={tag}
                  className={clsx(styles.tag, darkMode && styles.darkMode)}
                >
                  {tag}
                </div>
              ))}
            </div>
            <DocumentStatusIndicator
              {...props}
              document={doc}
              file={files.find((file) => file.name === doc.name)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default DocumentUploadStatus;
