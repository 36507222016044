import api from './api';
import {
  ApiFetchParams,
  ApiResponse,
  DocumentField,
  DocumentSorting,
  DocumentsRequestParams,
  DocumentsResponse,
  DocumentStatus,
  FetchDocumentsResults,
  UploadedDocumentResults,
} from '../types';
import { delay, isDefined } from '../utils';

type FetchDocumentsParams = ApiFetchParams & DocumentsRequestParams;

export const fetchDocuments = async ({
  currentPage,
  pageSize,
  sorting,
  baseUrl,
  mock,
  signal,
}: FetchDocumentsParams): Promise<FetchDocumentsResults> => {
  const sort = processDocumentSorting(sorting);
  const { data } = await api.get<DocumentsResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/documents',
    queryParams: {
      sort,
      page: currentPage + 1,
      size: pageSize,
    },
    config: { signal },
  });

  return {
    documents: data.items.map((item) => ({
      id: item.document_id,
      name: item.filename,
      url: item.url,
      size: item.size,
      created: item.timestamp * 1000,
      tags: item.tags || [],
    })),
    totalElements: data.totalElements,
  };
};

const processDocumentSorting = (sortings: DocumentSorting[]): string | null => {
  if (sortings.length === 0) {
    return null;
  }
  const sorting = sortings[0];
  if (!isDefined(sorting.sort)) {
    return null;
  }
  if (sorting.field === DocumentField.Name) {
    return `filename,${sorting.sort}`;
  }
  if (sorting.field === DocumentField.Size) {
    return `size,${sorting.sort}`;
  }
  if (sorting.field === DocumentField.Date) {
    return `created_at,${sorting.sort}`;
  }
  return null;
};

interface UploadDocumentsParams extends ApiFetchParams {
  equipmentTypes: string[];
  files: File[];
  logError: (msg: string) => void;
}

export const uploadAndRegisterDocuments = async ({
  equipmentTypes,
  files,
  baseUrl,
  logError,
  mock,
  signal,
}: UploadDocumentsParams): Promise<UploadedDocumentResults[]> => {
  if (mock) {
    await delay(3000);
    return files.map((file, index) => ({
      name: file.name,
      url: file.name,
      status: index % 2 === 1 ? DocumentStatus.Error : DocumentStatus.Uploaded,
    }));
  }
  const preSignedUrls = await fetchPreSignedUrls({
    equipmentTypes,
    files,
    baseUrl,
    logError,
    mock,
    signal,
  }).catch((error) => {
    logError(`Could not fetch pre-signed URLs. Reason: ${error}`);

    return {} as Record<string, string>;
  });

  return Promise.all(
    files.map((file) => {
      const url = preSignedUrls[file.name];
      if (!isDefined(url)) {
        return {
          name: file.name,
          url: null,
          status: DocumentStatus.Error,
        };
      }

      return fetch(url, { signal, method: 'PUT', body: file })
        .then(() =>
          registerUploadedFile({ fileName: file.name, baseUrl, mock, signal }),
        )
        .then(() => ({ url, name: file.name, status: DocumentStatus.Uploaded }))
        .catch(() => ({ url, name: file.name, status: DocumentStatus.Error }));
    }),
  );
};

export const fetchPreSignedUrls = async ({
  equipmentTypes,
  files,
  baseUrl,
  mock,
  signal,
}: UploadDocumentsParams): Promise<Record<string, string>> => {
  const response = await api.post<ApiResponse<Record<string, string>>>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/presigned_urls',
    body: files.map((file) => ({
      filename: file.name,
      file_size: file.size,
      tags: equipmentTypes,
    })),
    config: { signal },
  });

  return response.data;
};

interface RegisterUploadedFileParams extends ApiFetchParams {
  fileName: string;
}

const registerUploadedFile = async ({
  fileName,
  baseUrl,
  mock,
  signal,
}: RegisterUploadedFileParams): Promise<void> => {
  return api.patch<void>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/documents',
    queryParams: {
      filename: fileName,
    },
    config: { signal },
  });
};

interface DeleteDocumentParams extends ApiFetchParams {
  id: string;
  name: string;
}

export const deleteDocument = async ({
  id,
  name,
  baseUrl,
  mock,
  signal,
}: DeleteDocumentParams): Promise<void> => {
  return api.delete<void>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/documents',
    queryParams: {
      id,
      name,
    },
    config: { signal },
  });
};
