import {
  DiagnosisNode,
  DiagnosisNodeType,
  ProcessedDiagnosisResponse,
  RootCauseNode,
} from '../../types';

const bladeDiagnosesSeed1: RootCauseNode[] = [
  {
    id: 'diagnosis1',
    name: 'Physical Damage',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: ['New blade sections', 'Hub plates', 'Pitch actuators'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Physical+Damage.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Assessment and Safety',
        details:
          'Shut down the turbine, ensure safety protocols, and perform a visual inspection to document and assess the extent of physical damage.',
      },
      {
        id: 'step 2',
        name: 'Damage Evaluation',
        details:
          'Engage experts to evaluate the structural impact and determine if components require repair or replacement, considering any secondary damage.',
      },
      {
        id: 'step 3',
        name: 'Repair Strategy and Procurement',
        details:
          'Decide on a repair or replacement strategy, consult manufacturer guidelines, and procure necessary parts and materials.',
      },
      {
        id: 'step 4',
        name: 'Perform Repairs',
        details:
          'Execute the repair or replacement of damaged components, ensuring all work adheres to quality standards and manufacturer specifications.',
      },
      {
        id: 'step 5',
        name: 'Testing and Documentation',
        details:
          "Conduct operational tests post-repair, document the process, and schedule follow-up inspections to monitor the turbine's condition.",
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis2',
    name: 'Balancing',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: ['Blade Balancing Weights', 'Hub Fasteners'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Balancing.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Diagnosis',
        details:
          'Shut down the turbine, review the control system for balance-related alarms, and perform a visual inspection to identify any obvious issues with the balancing components.',
      },
      {
        id: 'step 2',
        name: 'Detailed Inspection',
        details:
          'Examine the blade balancing weights, pitch control system, and rotor hub for signs of wear, misalignment, or damage that could affect balance.',
      },
      {
        id: 'step 3',
        name: 'Component Testing',
        details:
          'Test the pitch control motors, sensors, and bearings to ensure they are functioning correctly and not contributing to the imbalance.',
      },
      {
        id: 'step 4',
        name: 'Repair or Replace',
        details:
          'Adjust, repair, or replace any faulty balancing components such as weights, bearings, or pitch control motors as needed, following manufacturer guidelines.',
      },
      {
        id: 'step 5',
        name: 'Post-Repair Testing and Documentation',
        details:
          'Conduct balance tests to confirm the issue is resolved, document the repairs, and monitor the turbine for any further signs of imbalance.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis3',
    name: 'Pitch System',
    technicians: ['Sam Brown', 'x', 'x', 'x', 'x', 'x', 'x'],
    parts: [
      'Pitch actuators',
      'Pitch control unit',
      'Sensors and feedback devices',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Pitch+System.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Diagnosis and Safety',
        details:
          'Shut down the turbine, check the control system for pitch-related alarms, and visually inspect the pitch components for any obvious damage or misalignment.',
      },
      {
        id: 'step 2',
        name: 'Component Inspection',
        details:
          'Inspect the pitch motors, bearings, and sensors to identify wear, damage, or malfunction that could be affecting the blade angle adjustments.',
      },
      {
        id: 'step 3',
        name: 'System Testing',
        details:
          'Perform functional tests on the pitch control system, including manual operation of the pitch motors and checking sensor accuracy to ensure they are responding correctly.',
      },
      {
        id: 'step 4',
        name: 'Repair or Replace',
        details:
          'Repair or replace any faulty pitch components such as motors, sensors, or bearings according to manufacturer specifications.',
      },
      {
        id: 'step 5',
        name: 'Post-Repair Testing and Documentation',
        details:
          'Conduct operational tests to ensure the pitch system is functioning properly, document the repairs made, and monitor the turbine for any recurring issues.',
      },
    ],
    images: [],
  },
];
const bladeDiagnosesSeed2: RootCauseNode[] = [
  {
    id: 'diagnosis1',
    name: 'Blade Imbalance',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: [
      'Blade balancing weights',
      'Blade pitch bearings',
      'New or repaired rotor blades (if damage is significant)',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Blade+Imbalance.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Assessment and Safety',
        details:
          'Shut down the turbine, ensure all safety protocols are in place, and perform a visual inspection of the blades for visible damage or irregularities.',
      },
      {
        id: 'step 2',
        name: 'Balancing Check',
        details:
          'Measure the weight distribution across all blades using specialized equipment to identify any imbalances or discrepancies.',
      },
      {
        id: 'step 3',
        name: 'Adjust or Replace Weights',
        details:
          'Adjust the blade balancing weights or add new ones as needed to correct any identified imbalances, following manufacturer guidelines.',
      },
      {
        id: 'step 4',
        name: 'Inspect and Repair Components',
        details:
          'Check the blade pitch bearings and hub for wear or damage, and repair or replace these components if they are contributing to the imbalance.',
      },
      {
        id: 'step 5',
        name: 'Post-Repair Testing and Documentation',
        details:
          'Conduct balance and vibration tests to confirm the issue is resolved, document the steps taken, and monitor the turbine for any further signs of imbalance.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis2',
    name: 'Pitch System Malfunction',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: ['Pitch control motors', 'Pitch control sensors', 'Pitch bearings'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Pitch+System+Malfunction.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Safety and Diagnostics',
        details:
          'Power down the turbine, ensure all safety measures are active, and review system diagnostics to identify any errors related to the pitch system.',
      },
      {
        id: 'step 2',
        name: 'Thorough Component Inspection',
        details:
          'Examine the pitch control motors, sensors, and actuators for signs of wear, damage, or failure that could be affecting blade positioning.',
      },
      {
        id: 'step 3',
        name: 'Functional Testing',
        details:
          'Test the pitch control system, including manual operation and sensor feedback, to verify that each component is functioning correctly and responding as expected.',
      },
      {
        id: 'step 4',
        name: 'Repair or Replace Faulty Parts',
        details:
          'Replace or repair malfunctioning components, such as motors, sensors, or bearings, ensuring they meet the manufacturer’s specifications for proper operation.',
      },
      {
        id: 'step 5',
        name: 'Final Testing and Monitoring',
        details:
          'Perform comprehensive operational tests to ensure the pitch system operates smoothly, document all repairs, and schedule follow-up monitoring to prevent future issues.',
      },
    ],
    images: [],
  },
];

const generatorDiagnosesSeed: RootCauseNode[] = [
  {
    id: 'diagnosis1',
    name: 'Electrical Fault due to Overheating',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: ['Cooling Fans', 'Ventilation', 'Enclosures'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Electrical+Fault+due+to+Overheating.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Immediate Shutdown',
        details:
          'Safely shut down the turbine to prevent further damage, and allow the system to cool down naturally before beginning any inspection.',
      },
      {
        id: 'step 2',
        name: 'Inspect Cooling System',
        details:
          'Check the cooling system components, such as fans, heat exchangers, and coolant levels, to ensure they are functioning correctly and repair or replace any faulty parts.',
      },
      {
        id: 'step 3',
        name: 'Examine Electrical Connections',
        details:
          'Inspect all electrical connections, wiring, and insulation for signs of heat damage, such as discoloration, melting, or burning, and repair or replace as necessary.',
      },
      {
        id: 'step 4',
        name: 'Assess and Replace Affected Components',
        details:
          'Identify and replace any components that have been damaged by the overheating, such as fuses, circuit boards, or power modules.',
      },
      {
        id: 'step 5',
        name: 'Restart and Monitor',
        details:
          'Once repairs are completed, restart the turbine and monitor the system closely to ensure that the overheating issue has been resolved and no further electrical faults occur.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis2',
    name: 'Converter Failure caused by Cooling System Issues',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: ['Cooling Fan', 'Heat Exchanger', 'Temperature Sensors'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Converter+Failure.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shut Down the Turbine',
        details:
          'Immediately shut down the turbine to prevent further damage to the converter and allow the system to cool before proceeding with the inspection.',
      },
      {
        id: 'step 2',
        name: 'Inspect Cooling System Components',
        details:
          'Check the cooling fans, pumps, heat exchangers, and coolant levels for any malfunction or blockage, and repair or replace faulty components as needed.',
      },
      {
        id: 'step 3',
        name: 'Check for Coolant Leaks',
        details:
          'Examine the cooling system for any leaks in hoses, seals, or connectors and address any issues by replacing damaged parts or sealing leaks.',
      },
      {
        id: 'step 4',
        name: 'Assess Converter for Damage',
        details:
          'Inspect the converter for signs of overheating damage, such as burnt wiring or damaged power modules, and replace any affected components.',
      },
      {
        id: 'step 5',
        name: 'Restart and Test',
        details:
          'Once repairs are complete, restart the turbine and closely monitor the cooling system and converter performance to ensure the issue is fully resolved and normal operation is restored.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis3',
    name: 'Control System Malfunction',
    technicians: ['Sam Brown', 'x', 'x', 'x', 'x', 'x', 'x'],
    parts: ['Generator coils', 'Control Board'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Control+System+Malfunction.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Perform a System Reset',
        details:
          'Start by resetting the control system to clear any temporary errors or glitches and observe if the issue persists.',
      },
      {
        id: 'step 2',
        name: 'Check Software and Firmware',
        details:
          'Verify that all software and firmware are up to date; if needed, perform updates, or reinstall the control software to eliminate any bugs or corrupted files.',
      },
      {
        id: 'step 3',
        name: 'Inspect Sensors and Input Devices',
        details:
          'Examine sensors, input devices, and communication lines for faults or disconnections that could be causing incorrect data or commands, and repair or replace any defective components.',
      },
      {
        id: 'step 4',
        name: 'Evaluate Electrical Connections',
        details:
          "Inspect the control system's wiring, connectors, and power supply for signs of wear, damage, or loose connections, and address any issues found.",
      },
      {
        id: 'step 5',
        name: 'Test and Calibrate the System',
        details:
          'After repairs, conduct a thorough test of the control system, including calibrating sensors and verifying correct operation of all control functions, before returning the turbine to normal operation.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis4',
    name: 'Worn or Damaged Bearings',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: [
      'Replacement bearings',
      'Lubrication oil or grease (if applicable)',
      'Bearing housing (if damaged)',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Worn+or+damaged+bearings.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shut Down the Turbine',
        details:
          'Safely shut down the turbine to prevent further damage to the generator and allow the system to cool down before proceeding with maintenance.',
      },
      {
        id: 'step 2',
        name: 'Disassemble the Affected Components',
        details:
          'Remove the necessary parts to access the generator bearings, including the housing, to inspect the condition of the bearings.',
      },
      {
        id: 'step 3',
        name: 'Inspect and Remove the Bearings',
        details:
          'Examine the bearings for signs of wear, damage, or overheating, and carefully remove them using appropriate tools.',
      },
      {
        id: 'step 4',
        name: 'Install New Bearings',
        details:
          'Clean the bearing housing and surrounding components, then install the new bearings, ensuring they are properly lubricated and securely seated.',
      },
      {
        id: 'step 5',
        name: 'Reassemble and Test',
        details:
          'Reassemble the components, restart the turbine, and monitor the system closely to ensure the new bearings are functioning correctly and the issue is resolved.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis5',
    name: 'Electrical Faults in Generator Windings',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: [
      'Generator winding repair kit or new winding coils',
      'Insulation materials',
      'Electrical connectors and wiring',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Electrical+Faults+Generator+Windings.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shut Down the Turbine',
        details:
          'Immediately shut down the turbine to prevent further damage to the generator and ensure safety during inspection and repair.',
      },
      {
        id: 'step 2',
        name: 'Inspect the Windings',
        details:
          'Open the generator housing and carefully inspect the windings for signs of insulation failure, short circuits, or open circuits, such as discoloration, burning, or physical damage.',
      },
      {
        id: 'step 3',
        name: 'Test Electrical Continuity',
        details:
          'Use a multimeter or insulation resistance tester to check the continuity and insulation resistance of the windings, identifying the specific areas of failure.',
      },
      {
        id: 'step 4',
        name: 'Repair or Replace the Windings',
        details:
          'Depending on the severity of the damage, either repair the affected windings using insulation materials and winding repair kits or replace the entire winding set if necessary.',
      },
      {
        id: 'step 5',
        name: 'Reassemble and Test',
        details:
          'Reassemble the generator, ensuring all connections are secure, then restart the turbine and monitor its operation to confirm that the electrical fault has been resolved and normal function is restored.',
      },
    ],
    images: [],
  },
];

const pitchDiagnosesSeed1: RootCauseNode[] = [
  {
    id: 'diagnosis1',
    name: 'PCS malfunction',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: [
      'IGBT Modules (Insulated Gate Bipolar Transistors)',
      'Control Circuit Boards',
      'Fuses and Circuit Breakers',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/PCS+Malfunction.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Initial Diagnosis',
        details:
          'Conduct a system check using diagnostic tools to identify error codes or fault indicators in the PCS, focusing on components like IGBTs, capacitors, and control boards.',
      },
      {
        id: 'step 2',
        name: 'Visual and Physical Inspection',
        details:
          'Inspect the PCS for obvious signs of damage, such as burnt components, loose connections, or signs of overheating, and check for any tripped fuses or circuit breakers.',
      },
      {
        id: 'step 3',
        name: 'Component Testing and Replacement',
        details:
          'Test key components (e.g., IGBTs, capacitors, sensors) for proper function, and replace any faulty parts with spares, ensuring that all connections are secure.',
      },
      {
        id: 'step 4',
        name: 'Software and Firmware Update',
        details:
          'If applicable, update the PCS firmware and software to the latest versions to fix any known bugs or issues that could be causing the malfunction.',
      },
      {
        id: 'step 5',
        name: 'System Reboot and Monitoring',
        details:
          'Reboot the PCS and closely monitor its operation for any recurrence of issues, verifying that the system is stable and functioning within normal parameters.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis2',
    name: 'Rolling Element Fatigue',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: ['Lubricants', 'Monitoring Sensors', 'Bearing Isolators'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Rolling+Element+Fatigue.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shutdown and Inspection',
        details:
          'Immediately shut down the affected equipment to prevent further damage and perform a detailed inspection of the rolling elements (bearings) to assess the extent of wear or fatigue.',
      },
      {
        id: 'step 2',
        name: 'Component Removal and Cleaning',
        details:
          'Carefully remove the fatigued bearings and associated components, clean the housing and surrounding areas to remove any debris or contaminants that could interfere with the new bearings.',
      },
      {
        id: 'step 3',
        name: 'Bearing Replacement',
        details:
          'Install new bearings, ensuring they are correctly sized and compatible with the equipment, and apply the appropriate lubrication to reduce friction and prevent premature wear.',
      },
      {
        id: 'step 4',
        name: 'Alignment and Balancing',
        details:
          'After installation, ensure the rolling elements are properly aligned and the system is balanced to prevent uneven load distribution, which could lead to further fatigue.',
      },
      {
        id: 'step 5',
        name: 'Monitoring and Maintenance',
        details:
          'Reinitiate operation and monitor the new bearings for any abnormal noise, vibration, or temperature; establish a regular maintenance schedule to check lubrication levels and bearing condition to prevent future fatigue issues.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis3',
    name: 'Bearing Seal Degradation',
    technicians: ['Sam Brown', 'x', 'x', 'x', 'x', 'x', 'x'],
    parts: ['Bearings', 'Seals & Lubrication'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Bearing+Seal+Degradation.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shutdown and Inspection',
        details:
          'Shut down the equipment to prevent further damage and inspect the bearing seals for signs of wear, cracking, or leakage, noting any contaminants that may have entered the bearing.',
      },
      {
        id: 'step 2',
        name: 'Seal and Bearing Removal',
        details:
          'Carefully remove the degraded seals and bearings, ensuring all contaminants are cleaned from the housing and surrounding areas to prepare for new components.',
      },
      {
        id: 'step 3',
        name: 'Seal Replacement',
        details:
          'Install new, compatible seals that match the bearing specifications, ensuring they are properly seated to prevent future leaks and protect the bearings from contaminants.',
      },
      {
        id: 'step 4',
        name: 'Lubrication and Bearing Installation',
        details:
          'Re-lubricate the bearings according to manufacturer specifications and install them securely, ensuring the lubrication is sufficient to protect the new seals and bearings during operation.',
      },
      {
        id: 'step 5',
        name: 'Monitoring and Maintenance',
        details:
          'Restart the equipment and monitor for any signs of leakage or abnormal performance and implement a regular inspection and lubrication schedule to maintain seal integrity and bearing performance.',
      },
    ],
    images: [],
  },
];

const pitchDiagnosesSeed2: RootCauseNode[] = [
  {
    id: 'diagnosis1',
    name: 'Inadequate Lubrication',
    technicians: [
      'John Smith',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
      'x',
    ],
    parts: ['Lubricant', 'Pump', 'Nozzles'],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Inadequate+Lubrication.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shutdown and Inspection',
        details:
          'Immediately shut down the equipment to prevent further damage and inspect the lubrication system for signs of low lubricant levels, leaks, or degraded oil.',
      },
      {
        id: 'step 2',
        name: 'Lubricant Drainage and Analysis',
        details:
          'Drain the existing lubricant and perform an analysis to check for contamination or degradation, which could indicate the root cause of the lubrication issue.',
      },
      {
        id: 'step 3',
        name: 'System Cleaning',
        details:
          'Thoroughly clean the lubrication system, including reservoirs, lines, and filters, to remove any contaminants or degraded lubricant that might interfere with the new lubricant.',
      },
      {
        id: 'step 4',
        name: 'Lubricant Replacement',
        details:
          'Refill the system with the correct type and grade of lubricant specified for the equipment, ensuring that the quantity is sufficient to cover all moving parts adequately.',
      },
      {
        id: 'step 5',
        name: 'System Monitoring and Maintenance',
        details:
          'Restart the equipment and monitor lubricant levels, pressure, and temperature closely, and establish a regular maintenance schedule to check and replenish lubricant as needed to prevent recurrence of inadequate lubrication.',
      },
    ],
    images: [],
  },
  {
    id: 'diagnosis2',
    name: 'Bearing Misalignment',
    technicians: ['Tom Wilson', 'x', 'x', 'x', 'x'],
    parts: [
      'New Bearings',
      'Alignment Tools/Components',
      'Shafts and housings',
    ],
    image:
      'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/troubleshooting/Bearing+Misalignment.png',
    mockSteps: [
      {
        id: 'step 1',
        name: 'Shutdown and Inspection',
        details:
          'Shut down the equipment to prevent further damage and inspect the bearings for signs of misalignment, such as uneven wear patterns or abnormal noises.',
      },
      {
        id: 'step 2',
        name: 'Alignment Measurement',
        details:
          'Use precision alignment tools to measure the current alignment of the bearings relative to the shaft and housing, identifying any deviations from the correct position.',
      },
      {
        id: 'step 3',
        name: 'Component Adjustment or Replacement',
        details:
          'Adjust the position of the bearings or associated components (e.g., shafts, housings) to achieve proper alignment, or replace any worn or damaged parts that are contributing to the misalignment.',
      },
      {
        id: 'step 4',
        name: 'Reinstallation and Alignment Verification',
        details:
          'Reinstall the bearings and verify the alignment using the same tools to ensure that the corrections have been successful and that the bearings are correctly positioned.',
      },
      {
        id: 'step 5',
        name: 'Monitoring and Maintenance',
        details:
          'Restart the equipment and monitor it for any signs of misalignment, such as vibration or noise, and schedule regular inspections to maintain proper alignment and prevent future issues.',
      },
    ],
    images: [],
  },
];

export const diagnosisTreeSeed1: DiagnosisNode[] = [
  {
    id: 1,
    type: DiagnosisNodeType.Question,
    label: 'Is the generator output stable during normal wind conditions?',
    children: [2, 3],
  },
  {
    id: 2,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [4],
  },
  {
    id: 3,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [4],
  },
  {
    id: 4,
    type: DiagnosisNodeType.Question,
    label: 'Temperature readings from the generator',
    children: [5, 6],
  },
  {
    id: 5,
    type: DiagnosisNodeType.Answer,
    label: 'Normal',
    children: [7],
  },
  {
    id: 6,
    type: DiagnosisNodeType.Answer,
    label: 'Higher than normal',
    children: [7],
  },
  {
    id: 7,
    type: DiagnosisNodeType.Question,
    label:
      'Is the cooling system (e.g., fans, ventilation) for the generator and converter operational?',
    children: [8, 9],
  },
  {
    id: 8,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [10],
  },
  {
    id: 9,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [13],
  },
  {
    id: 10,
    type: DiagnosisNodeType.Question,
    label: 'Are control system alarms triggered frequently?',
    children: [11, 12],
  },
  {
    id: 11,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [14],
  },
  {
    id: 12,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [15],
  },
  {
    id: 13,
    type: DiagnosisNodeType.RootCause,
    label: 'Converter Failure caused by Cooling System Issues',
    children: [],
  },
  {
    id: 14,
    type: DiagnosisNodeType.RootCause,
    label: 'Control System Malfunction',
    children: [],
  },
  {
    id: 15,
    type: DiagnosisNodeType.RootCause,
    label: 'Electrical Fault due to Overheating',
    children: [],
  },
];

export const diagnosisTreeSeed2: DiagnosisNode[] = [
  {
    id: 1,
    type: DiagnosisNodeType.Question,
    label: 'Is the erratic operation occurring during normal wind conditions?',
    children: [2, 3],
  },
  {
    id: 2,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [4],
  },
  {
    id: 3,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [4],
  },
  {
    id: 4,
    type: DiagnosisNodeType.Question,
    label: `Are there temperature fluctuations in the generator's internal readings?`,
    children: [5, 6],
  },
  {
    id: 5,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [7],
  },
  {
    id: 6,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [7],
  },
  {
    id: 7,
    type: DiagnosisNodeType.Question,
    label:
      'Is the cooling system functioning properly in both the generator and converter?',
    children: [8, 9],
  },
  {
    id: 8,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [10],
  },
  {
    id: 9,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [13],
  },
  {
    id: 10,
    type: DiagnosisNodeType.Question,
    label:
      'Is there visible damage to the generator windings (e.g., electrical faults)?',
    children: [11, 12],
  },
  {
    id: 11,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [14],
  },
  {
    id: 12,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [15],
  },
  {
    id: 13,
    type: DiagnosisNodeType.RootCause,
    label: 'Converter Failure caused by Cooling System Issues',
    children: [],
  },
  {
    id: 14,
    type: DiagnosisNodeType.RootCause,
    label: 'Electrical Faults in Generator Windings',
    children: [],
  },
  {
    id: 15,
    type: DiagnosisNodeType.RootCause,
    label: 'Control System Malfunction',
    children: [],
  },
];

export const diagnosisTreeSeed3: DiagnosisNode[] = [
  {
    id: 1,
    type: DiagnosisNodeType.Question,
    label: 'Is there unusual noise or vibration coming from the generator?',
    children: [2, 3],
  },
  {
    id: 2,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [10],
  },
  {
    id: 3,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [4],
  },
  {
    id: 4,
    type: DiagnosisNodeType.Question,
    label: `What is the status of the generator's power output?`,
    children: [5, 6],
  },
  {
    id: 5,
    type: DiagnosisNodeType.Answer,
    label: 'Irregular',
    children: [11],
  },
  {
    id: 6,
    type: DiagnosisNodeType.Answer,
    label: 'Stable',
    children: [7],
  },
  {
    id: 7,
    type: DiagnosisNodeType.Question,
    label:
      'Are there temperature fluctuations or electrical malfunctions reported in the windings?',
    children: [8, 9],
  },
  {
    id: 8,
    type: DiagnosisNodeType.Answer,
    label: 'Yes',
    children: [11],
  },
  {
    id: 9,
    type: DiagnosisNodeType.Answer,
    label: 'No',
    children: [10],
  },
  {
    id: 10,
    type: DiagnosisNodeType.RootCause,
    label: 'Worn or Damaged Bearings',
    children: [],
  },
  {
    id: 11,
    type: DiagnosisNodeType.RootCause,
    label: 'Electrical Faults in Generator Windings',
    children: [],
  },
];

export const matchSymptoms = (symptoms: string[], regex: RegExp): boolean => {
  return symptoms.some((symptom) => symptom.match(regex));
};

export const getDiagnosesSeed = (
  symptoms: string[],
): ProcessedDiagnosisResponse => {
  if (
    matchSymptoms(symptoms, /erratic operation/i) &&
    matchSymptoms(symptoms, /generator/i)
  ) {
    return {
      allNodes: diagnosisTreeSeed2,
      rootCauses: generatorDiagnosesSeed,
    };
  }
  if (matchSymptoms(symptoms, /erratic operation/i)) {
    return {
      allNodes: diagnosisTreeSeed3,
      rootCauses: generatorDiagnosesSeed,
    };
  }
  if (matchSymptoms(symptoms, /generator/i)) {
    return {
      allNodes: diagnosisTreeSeed1,
      rootCauses: generatorDiagnosesSeed,
    };
  }
  if (matchSymptoms(symptoms, /unexpected vibrations/i)) {
    return {
      allNodes: [],
      rootCauses: bladeDiagnosesSeed2,
    };
  }
  if (matchSymptoms(symptoms, /blade/i)) {
    return {
      allNodes: [],
      rootCauses: bladeDiagnosesSeed1,
    };
  }
  if (matchSymptoms(symptoms, /lubricant leakage or degradation/i)) {
    return {
      allNodes: [],
      rootCauses: pitchDiagnosesSeed2,
    };
  }
  if (matchSymptoms(symptoms, /pitch bearing/i)) {
    return {
      allNodes: [],
      rootCauses: pitchDiagnosesSeed1,
    };
  }

  return {
    allNodes: [],
    rootCauses: [],
  };
};
