import { BaseSessionProps, PastJob } from '../types';

export enum ChatbotResponseType {
  PredictionsSourceDocuments = 'PredictionsSourceDocuments',
  PredictionsPartial = 'PredictionsPartial',
  PredictionsFinished = 'PredictionsFinished',
  PredictionsError = 'PredictionsError',
  CustomerSummaryPartial = 'CustomerSummaryPartial',
  CustomerSummaryFinished = 'CustomerSummaryFinished',
  CustomerSummaryError = 'CustomerSummaryError',
  DocumentSearchResults = 'DocumentSearchResults',
  DocumentSearchError = 'DocumentSearchError',
  PastJobs = 'PastJobs',
  PastJobsError = 'PastJobsError',
}

interface BaseWebsocketResponse extends BaseSessionProps {
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface KnowledgeBaseResponse extends BaseWebsocketResponse {
  question: string;
}

export interface SourceDocument {
  page_content: string;
  metadata: {
    doc_type: string;
    sources: DocumentSource[];
    code?: string;
    source?: string;
    job_id?: string;
  };
}

export interface DocumentSource {
  documentId: string;
  name: string;
  link: string;
  page?: number;
}

export interface PredictionsSourceDocumentsResponse
  extends KnowledgeBaseResponse {
  type: ChatbotResponseType.PredictionsSourceDocuments;
  sourceDocuments: SourceDocument[];
}

export interface PredictionsPartialResponse extends KnowledgeBaseResponse {
  type: ChatbotResponseType.PredictionsPartial;
  index: number;
  answer: string;
}

export interface PredictionsClosingResponse extends KnowledgeBaseResponse {
  type: ChatbotResponseType.PredictionsFinished;
  answer: string;
  parts?: string[]; // Only mock data exists, not implemented on BE side
}

export interface DocumentSearchResponse extends KnowledgeBaseResponse {
  type: ChatbotResponseType.DocumentSearchResults;
  answer: string;
  sourceDocuments: SourceDocument[];
}

export interface PastJobsResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.PastJobs;
  results: PastJob[];
}

export interface CustomerSummaryPartialResponse {
  type: ChatbotResponseType.CustomerSummaryPartial;
  answer: string;
  customerId: string;
  index: number;
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface CustomerSummaryFinalResponse {
  type: ChatbotResponseType.CustomerSummaryFinished;
  answer: string;
  customerId: string;
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface CustomerSummaryErrorResponse {
  type: ChatbotResponseType.CustomerSummaryError;
  customerId: string;
  message?: string; // Field is needed because Gateway API errors contain it
}

export interface PredictionsErrorResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.PredictionsError;
}

export interface DocumentSearchErrorResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.DocumentSearchError;
}

export interface PastJobsErrorResponse extends BaseWebsocketResponse {
  type: ChatbotResponseType.PastJobsError;
}

export type ChatbotResponse =
  | PredictionsSourceDocumentsResponse
  | PredictionsPartialResponse
  | PredictionsClosingResponse
  | DocumentSearchResponse
  | PastJobsResponse
  | PredictionsErrorResponse
  | DocumentSearchErrorResponse
  | PastJobsErrorResponse
  | CustomerSummaryPartialResponse
  | CustomerSummaryFinalResponse
  | CustomerSummaryErrorResponse;

export interface ChatbotQueryPayload {
  tenantId: number;
  language: string;
  token: string;
}

export interface SessionChatbotQueryPayload extends ChatbotQueryPayload {
  conversationId: string;
  sessionId: string;
  equipmentTypes: string[] | null;
  message?: string;
}

export enum WebSocketQueryAction {
  DocumentSearch = 'documentSearch',
  RootCause = 'rootCause',
  GetPastJobs = 'getPastJobs',
  CustomerSummary = 'customerSummary',
  Heartbeat = 'pong',
}

export interface WebSocketQuery<P extends ChatbotQueryPayload> {
  action: WebSocketQueryAction;
  payload: P;
}

export interface WebSocketInitParams {
  token: string;
  handleResponse: (response: ChatbotResponse) => void;
  wsUrl: string;
  logError: (msg: string) => void;
}

class WebSocketClient {
  private client: WebSocket | null = null;
  private interval: number | null = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private messageQueue: WebSocketQuery<any>[] = [];

  public init = async ({
    handleResponse,
    wsUrl,
    logError,
  }: WebSocketInitParams): Promise<void> => {
    const client = new WebSocket(wsUrl);

    return new Promise((resolve, reject) => {
      client.addEventListener('open', () => {
        this.interval = window.setInterval(() => {
          client.send(
            JSON.stringify({ action: WebSocketQueryAction.Heartbeat }),
          );
        }, 60 * 1000);
        client.addEventListener('message', (event: MessageEvent<string>) => {
          const response: ChatbotResponse = JSON.parse(event.data);
          handleResponse(response);
        });
        client.addEventListener('close', () => {
          if (this.interval !== null) {
            window.clearInterval(this.interval);
            this.interval = null;
          }
          this.client = null;
        });
        client.addEventListener('error', (event) => {
          logError(`WebSocket error: ${event.type}`);
        });
        this.client = client;
        try {
          this.messageQueue.forEach((message) =>
            this.send(message.action, message.payload, logError),
          );
          this.messageQueue = [];
        } catch (error) {
          reject();
        }
        resolve();
      });
    });
  };

  public close = (): void => {
    if (this.client !== null) {
      this.client.close();
      this.client = null;
    }
    if (this.interval !== null) {
      window.clearInterval(this.interval);
      this.interval = null;
    }
  };

  public send = <P extends ChatbotQueryPayload>(
    action: WebSocketQueryAction,
    payload: P,
    logError: (msg: string) => void,
  ): boolean => {
    const message: WebSocketQuery<P> = {
      action,
      payload,
    };
    if (this.client === null) {
      this.messageQueue.push(message);

      return false;
    }
    try {
      this.client.send(JSON.stringify(message));

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      this.messageQueue.push(message);
      logError(`Could not send message. Reason: ${error?.message}`);

      return false;
    }
  };
}

export const wsClient = new WebSocketClient();
