import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssistantProps, MixpanelEventType } from '../../../../types';
import { ReactComponent as CompletedIcon } from './assets/completed.svg';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { trackWithMixpanel, useTranslations } from '../../../../utils';
import {
  completeSurvey,
  resetSurveyDetails,
} from '../../../../store/slices/surveyDetails.slice';
import styles from './SurveyCompleted.module.scss';

const SurveyCompleted: React.FC<AssistantProps> = ({
  environment,
  httpUrl,
  isMocking,
}) => {
  const dispatch = useAppDispatch();
  const {
    assistant: {
      curationHub: { surveyCompleted: translations },
    },
  } = useTranslations();
  const { details, openedAt } = useAppSelector((state) => state.surveyDetails);
  const navigate = useNavigate();

  useEffect(() => {
    if (details !== null && openedAt !== null) {
      const totalMsElapsed = Date.now() - openedAt;
      trackWithMixpanel({
        environment,
        event: {
          name: MixpanelEventType.SurveyCompleted,
          properties: {
            surveyId: details.id,
            equipmentType: details.equipmentType,
            secondsElapsed: Math.floor(totalMsElapsed / 1000),
          },
        },
      });
      dispatch(
        completeSurvey({
          baseUrl: httpUrl,
          mock: isMocking,
          surveyId: details.id,
        }),
      );
    }
  }, [details, openedAt, httpUrl, isMocking]);

  const handleCompleted = () => {
    dispatch(resetSurveyDetails());
    navigate(-1);
  };

  if (details === null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <CompletedIcon className={styles.completedIcon} />
      <h1 className={styles.title}>{translations.thanks}</h1>
      <p className={styles.description}>
        {translations.completionText(details.equipmentType)}
      </p>
      <button onClick={handleCompleted} className={styles.button}>
        {translations.close}
      </button>
    </div>
  );
};

export default SurveyCompleted;
