import React from 'react';
import clsx from 'clsx';

import {
  DiagnosisNodeType,
  DiagnosisQnaAnswer,
  SessionProps,
} from '../../../../../../../types';
import { ReactComponent as ArrowIcon } from '../../../../../../../assets/arrow-right.svg';
import { isDefined, useTranslations } from '../../../../../../../utils';
import { getQnaStep } from '../../../../../../../store/actions/diagnoses.actions';
import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import { updateEquipmentSession } from '../../../../../../../store/slices/conversations.slice';
import Answers from './Answers/Answers';
import styles from './Questionnaire.module.scss';

const Questionnaire: React.FC<SessionProps> = ({ session, logError }) => {
  const {
    qnaSteps,
    resolvedRootCauses,
    diagnosisNodes,
    rootCauses,
    openedRootCauseId,
    id,
  } = session;
  const dispatch = useAppDispatch();
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();

  const handleAnswer = (step: DiagnosisQnaAnswer) => {
    if (diagnosisNodes === null || qnaSteps === null || rootCauses === null) {
      return;
    }
    const link = step.links[0];
    const linkedNode = diagnosisNodes.find((node) => node.id === link);
    if (!isDefined(linkedNode)) {
      return;
    }
    const resolvedQuestions = qnaSteps.slice(0, -1);
    const currentQuestion = qnaSteps[qnaSteps.length - 1];
    const answeredQuestion = {
      ...currentQuestion,
      selectedAnswer: step.answer,
    };

    switch (linkedNode.type) {
      case DiagnosisNodeType.Question:
        // eslint-disable-next-line no-case-declarations
        const nextStep = getQnaStep(linkedNode, diagnosisNodes);
        dispatch(
          updateEquipmentSession({
            id,
            qnaSteps: [...resolvedQuestions, answeredQuestion, nextStep],
          }),
        );
        break;
      case DiagnosisNodeType.RootCause:
        // eslint-disable-next-line no-case-declarations
        const rcNodeIds = new Set(step.links);
        // eslint-disable-next-line no-case-declarations
        const rcNodes = diagnosisNodes.filter((node) => rcNodeIds.has(node.id));
        // eslint-disable-next-line no-case-declarations
        const rcNodeLabels = new Set(rcNodes.map((node) => node.label));
        dispatch(
          updateEquipmentSession({
            id,
            qnaSteps: [...resolvedQuestions, answeredQuestion],
            resolvedRootCauses: rootCauses.filter((rc) =>
              rcNodeLabels.has(rc.name),
            ),
          }),
        );
        break;
      default:
        logError(
          `Wrong node type linked to answer! Answer ${step.answer} linked to node type ${linkedNode.type} with label ${linkedNode.label}.`,
        );
    }
  };

  const handleResumeTroubleshooting = () => {
    dispatch(
      updateEquipmentSession({
        id,
        openedRootCauseId: null,
      }),
    );
  };

  if (qnaSteps === null || qnaSteps.length === 0) {
    return null;
  }

  if (openedRootCauseId !== null) {
    return (
      <button
        className={styles.resumeBtn}
        onClick={handleResumeTroubleshooting}
      >
        {translations.resumeTroubleshooting}
      </button>
    );
  }

  if (resolvedRootCauses !== null) {
    return <Answers steps={qnaSteps} sessionId={id} />;
  }

  const previousAnswers = qnaSteps.slice(0, -1);
  const currentStep = qnaSteps[qnaSteps.length - 1];

  return (
    <>
      <Answers steps={previousAnswers} sessionId={id} />
      <div
        className={clsx(
          styles.qnaSection,
          currentStep.question === null && styles.onlyAnswers,
        )}
      >
        {currentStep.question !== null && <span>{currentStep.question}</span>}
        {currentStep.answers.map((step) => (
          <div
            key={step.answer}
            className={styles.answer}
            onClick={() => handleAnswer(step)}
          >
            <span>{step.answer}</span>
            <ArrowIcon className={styles.arrowIcon} />
          </div>
        ))}
      </div>
    </>
  );
};

export default Questionnaire;
