import React, { useState } from 'react';
import { format } from 'date-fns';
import TablePagination from '@mui/material/TablePagination';

import {
  AppRoute,
  FetchingStatus,
  JobWithPrediction,
  OPEN_JOBS_PAGE_SIZE_OPTIONS,
  OpenJobsParams,
} from '../../../types';
import {
  isDefined,
  useAuthData,
  useTranslations,
  renderOrDefault,
  useCurrentRoute,
} from '../../../utils';
import { ReactComponent as OpenJobsIcon } from './assets/open-jobs.svg';
import { ReactComponent as MenuIcon } from './assets/mobile-menu.svg';
import JobList from './JobList/JobList';
import OpenJobsFilters from '../Filters/OpenJobsFilters';
import styles from './OpenJobsMobile.module.scss';

interface Props extends OpenJobsParams {
  jobs: JobWithPrediction[] | null;
  status: FetchingStatus;
  onJobClicked: (job: JobWithPrediction) => void;
}

const OpenJobsMobile: React.FC<Props> = ({
  onJobClicked,
  jobs,
  status,
  totalElements,
  filter,
  pageSize,
  currentPage,
  onFilterChange,
  onPageChange,
  onPageSizeChange,
}) => {
  const { assistant: translations } = useTranslations();
  const authData = useAuthData();
  const cluster = authData === null ? null : authData.cluster;
  const currentRoute = useCurrentRoute();
  const onHomeScreen = currentRoute === AppRoute.Home;
  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };

  return (
    <div>
      <div className={styles.headline}>
        <OpenJobsIcon className={styles.icon} />
        <h3 className={styles.title}>{translations.home.openJobsTitle}</h3>
        <MenuIcon
          className={styles.menuIcon}
          onClick={() => setFiltersOpen((prevState) => !prevState)}
        />
      </div>
      {filtersOpen && (
        <OpenJobsFilters filter={filter} onFilterChange={onFilterChange} />
      )}
      {onHomeScreen ? (
        <JobList jobs={jobs} status={status}>
          {(jobs) =>
            jobs.map((job) => (
              <div
                key={job.id}
                className={styles.job}
                onClick={() => onJobClicked(job)}
              >
                <div className={styles.row}>
                  <div>
                    <b>
                      {renderOrDefault(
                        job.equipmentType,
                        translations.noEquipment,
                      )}
                    </b>
                  </div>
                  <div>
                    {job.created !== null
                      ? format(job.created, 'dd.MM.yyyy')
                      : translations.noDate}
                  </div>
                </div>
                <div className={styles.row}>
                  <div>
                    {renderOrDefault(job.customerName, translations.noCustomer)}
                  </div>
                  <div>
                    {renderOrDefault(job.divisionName, translations.noDivision)}
                  </div>
                </div>
                <div className={styles.row}>
                  <div>{job.reportedSymptom}</div>
                  <div>
                    {isDefined(job.code) ? (
                      <a
                        target="_blank"
                        href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                        rel="noreferrer"
                        onClick={(event) => event.stopPropagation()}
                      >
                        {job.code}
                      </a>
                    ) : (
                      translations.noCode
                    )}
                  </div>
                </div>
                <div>
                  {renderOrDefault(
                    job.prediction,
                    translations.home.noPrediction,
                  )}
                </div>
              </div>
            ))
          }
        </JobList>
      ) : (
        <JobList jobs={jobs} status={status}>
          {(jobs) =>
            jobs.map((job) => (
              <div
                key={job.id}
                className={styles.job}
                onClick={() => onJobClicked(job)}
              >
                <div className={styles.row}>
                  <div>
                    <b>
                      {renderOrDefault(
                        job.equipmentType,
                        translations.noEquipment,
                      )}
                    </b>
                  </div>
                  <div>
                    {job.created !== null
                      ? format(job.created, 'dd.MM.yyyy')
                      : translations.noDate}
                  </div>
                </div>
                <div className={styles.row}>
                  <div>{job.reportedSymptom}</div>
                  <div>
                    {isDefined(job.code) ? (
                      <a
                        target="_blank"
                        href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                        rel="noreferrer"
                        onClick={(event) => event.stopPropagation()}
                      >
                        {job.code}
                      </a>
                    ) : (
                      translations.noCode
                    )}
                  </div>
                </div>
                <div>
                  {renderOrDefault(
                    job.prediction,
                    translations.home.noPrediction,
                  )}
                </div>
              </div>
            ))
          }
        </JobList>
      )}
      <TablePagination
        component="div"
        rowsPerPageOptions={OPEN_JOBS_PAGE_SIZE_OPTIONS}
        count={totalElements}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OpenJobsMobile;
