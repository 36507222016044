import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus, UserProfile } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  fetchUserProfile,
  updateUserProfile,
} from '../../integration/userProfile.api';
import { updateOpenJobsFilter } from './openJobs.slice';

export const USER_PROFILE_KEY = 'userProfile';

export interface UserProfileState {
  status: FetchingStatus;
}

const initialState: UserProfileState = {
  status: FetchingStatus.IDLE,
};

export const getUserProfile = createAsyncThunk<
  void,
  ApiThunkParams,
  AppThunkConfig
>('getUserProfile', async ({ mock, baseUrl }, { signal, dispatch }) => {
  const userProfile = await fetchUserProfile({
    signal,
    baseUrl,
    mock,
  });
  dispatch(updateOpenJobsFilter(userProfile.openJobsFilter));
});

interface SaveUserProfileParams extends ApiThunkParams {
  userProfile: UserProfile;
}

export const saveUserProfile = createAsyncThunk<
  void,
  SaveUserProfileParams,
  AppThunkConfig
>('saveUserProfile', async ({ userProfile, mock, baseUrl }, { signal }) => {
  await updateUserProfile({
    userProfile,
    signal,
    baseUrl,
    mock,
  });
});

const userProfileSlice = createSlice({
  name: USER_PROFILE_KEY,
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.status = FetchingStatus.PENDING;
      })
      .addCase(getUserProfile.fulfilled, (state) => {
        state.status = FetchingStatus.SUCCESS;
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
      });
  },
});

const { reducer } = userProfileSlice;

export const userProfileReducer = reducer;
