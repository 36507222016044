import React from 'react';
import TableCell from '@mui/material/TableCell';
import clsx from 'clsx';

import {
  OpenJobsSorting,
  OpenJobsSortingField,
  SortDirection,
} from '../../../../types';
import { ReactComponent as ArrowIcon } from '../../../../assets/arrow-down.svg';
import styles from './OpenJobsDesktopHeader.module.scss';

interface Props {
  field: OpenJobsSortingField;
  handleColumnClicked: (field: OpenJobsSortingField) => void;
  sorting: OpenJobsSorting;
  title: string;
}

const OpenJobsDesktopHeader: React.FC<Props> = ({
  field,
  handleColumnClicked,
  sorting,
  title,
}) => {
  if (sorting === null || sorting.field !== field) {
    return (
      <TableCell onClick={() => handleColumnClicked(field)}>{title}</TableCell>
    );
  }

  return (
    <TableCell
      onClick={() => handleColumnClicked(field)}
      className={clsx(
        styles.sortedHeader,
        sorting.direction === SortDirection.Ascending && styles.ascending,
      )}
    >
      <span>{title}</span>
      <ArrowIcon className={styles.sortIcon} />
    </TableCell>
  );
};

export default OpenJobsDesktopHeader;
