import React from 'react';
import { Outlet } from 'react-router-dom';

import { AssistantProps } from '../../../types';
import ConfigurationHeader from './Header/ConfigurationHeader';
import styles from './Configuration.module.scss';

const Configuration: React.FC<AssistantProps> = () => {
  return (
    <div className={styles.container}>
      <ConfigurationHeader />
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default Configuration;
