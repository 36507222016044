import React, { ReactElement, ReactNode } from 'react';

import { FetchingStatus } from '../../types';
import { isDefined } from '../../utils';
import SmartLoopCardContent from './SmartLoopCardContent';
import CollapsibleCard from './Collapsible/CollapsibleCard';

export interface CardProps<T> {
  status: FetchingStatus;
  className?: string;
  contentClassname?: string;
  collapsedCardClassname?: string;
  children: (props: T) => ReactNode;
  title?: {
    text: string;
    icon: ReactNode;
  };
  data?: T | null;
  FallbackComponent?: ReactElement;
  LoadingComponent?: ReactElement;
  collapsible?: boolean;
  openByDefault?: boolean;
  showBackground?: boolean;
  emptyStateMessage?: string;
}

interface Props<T> extends CardProps<T> {
  title: {
    text: string;
    icon: ReactNode;
  };
}

// eslint-disable-next-line
const SmartLoopCard = <T extends any>(props: Props<T>) => {
  const {
    collapsible,
    openByDefault,
    className,
    contentClassname,
    collapsedCardClassname,
    title,
    showBackground = true,
  } = props;

  const noData =
    !isDefined(props.data) ||
    (Array.isArray(props.data) && props.data.length === 0);
  const showBg =
    showBackground || props.status !== FetchingStatus.SUCCESS || noData;

  return (
    <CollapsibleCard
      collapsible={collapsible}
      collapsedCardClassname={collapsedCardClassname}
      title={title}
      openByDefault={openByDefault}
      showBackground={showBg}
      className={className}
      contentClassname={contentClassname}
    >
      {/* eslint-disable-next-line react/no-children-prop */}
      <SmartLoopCardContent {...props} children={props.children} />
    </CollapsibleCard>
  );
};

export default SmartLoopCard;
