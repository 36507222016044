import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { store } from '../../store/store';
import { AssistantProps, FetchingStatus, MixpanelEventType } from '../../types';
import { initializeStreaming } from '../../store/slices/streaming.slice';
import { createAppRouter } from './routes';
import {
  trackWithMixpanel,
  isUserServiceExpert,
  useLanguage,
  useMixpanel,
} from '../../utils';
import { getHistory } from '../../store/slices/history.slice';
import { getUserProfile } from '../../store/slices/userProfile.slice';

const SmartLoopPage: React.FC<AssistantProps> = (props) => {
  const {
    isMocking,
    wsUrl,
    httpUrl,
    mixpanelToken,
    environment,
    logInfo,
    logError,
  } = props;
  const userServiceExpert = isUserServiceExpert();
  const dispatch = useAppDispatch();
  const { initStatus } = useAppSelector((state) => state.streaming);
  const { language } = useLanguage();

  useMixpanel({ environment, token: mixpanelToken });
  useEffect(() => {
    dispatch(
      initializeStreaming({
        isMocking,
        language,
        wsUrl,
        httpUrl,
        logInfo,
        logError,
      }),
    );
  }, [dispatch, isMocking, wsUrl, httpUrl]);

  useEffect(() => {
    dispatch(getHistory({ logError, mock: isMocking, baseUrl: httpUrl }));
    dispatch(getUserProfile({ logError, mock: isMocking, baseUrl: httpUrl }));
  }, [dispatch, isMocking, httpUrl]);

  useEffect(() => {
    const handler = () => {
      trackWithMixpanel({
        environment,
        event: { name: MixpanelEventType.BrowserNavigation },
      });
    };
    window.addEventListener('popstate', handler);

    return () => {
      window.removeEventListener('popstate', handler);
    };
  }, []);

  if (initStatus === FetchingStatus.IDLE) {
    return null;
  }

  return (
    <RouterProvider
      router={createAppRouter({ userServiceExpert, assistantProps: props })}
      future={{ v7_startTransition: true }}
    />
  );
};

const SmartLoop: React.FC<AssistantProps> = (props) => {
  return (
    <Provider store={store}>
      <SmartLoopPage {...props} />
    </Provider>
  );
};

export default SmartLoop;
