import React, { useCallback } from 'react';

import {
  FetchingStatus,
  MixpanelEventType,
  CustomerSessionProps,
} from '../../../../types';
import SmartLoopCard from '../../../Card/SmartLoopCard';
import { trackWithMixpanel, useTranslations } from '../../../../utils';
import { ReactComponent as CustomerIcon } from './assets/customer.svg';
import TextLoader from '../../../TextLoader/TextLoader';
import styles from './CustomerSummary.module.scss';

const CustomerSummary: React.FC<CustomerSessionProps> = (props) => {
  const { environment, session } = props;
  const { summary, summaryStatus } = session;
  const {
    assistant: { customerScreen: translations },
    noData,
  } = useTranslations();

  const handleCustomerSummaryCopied = useCallback(() => {
    const selection = document.getSelection();
    if (selection === null) {
      return;
    }
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.CustomerSummaryCopied,
        properties: { text: selection.toString() },
      },
    });
  }, [environment]);

  return (
    <SmartLoopCard
      openByDefault
      status={summary !== null ? FetchingStatus.SUCCESS : summaryStatus}
      className={styles.container}
      data={summary}
      emptyStateMessage={noData.customerSummary}
      title={{
        text: translations.summary,
        icon: <CustomerIcon className={styles.customerIcon} />,
      }}
      LoadingComponent={<TextLoader />}
    >
      {(summary) => (
        <div
          onCopy={handleCustomerSummaryCopied}
          dangerouslySetInnerHTML={{ __html: summary }}
          className={styles.text}
        />
      )}
    </SmartLoopCard>
  );
};

export default CustomerSummary;
