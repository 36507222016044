import React from 'react';
import clsx from 'clsx';

import { AssistantProps, MixpanelEventType, Question } from '../../../../types';
import { ReactComponent as NextIcon } from './assets/next.svg';
import { ReactComponent as PrevIcon } from './assets/prev.svg';
import { trackWithMixpanel, useTranslations } from '../../../../utils';
import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { setTimeSpentOnCurrentQuestion } from '../../../../store/slices/surveyDetails.slice';
import styles from './QuestionEnumerator.module.scss';

const INDICES_TO_SHOW = 10;
// We want to measure time spent on each answered question.
// If the user spends less than or equal milliseconds on a given question,
// we treat it as an already answered question that was just skipped through,
// to avoid generating redundant Mixpanel events.
const ANSWER_MS_THRESHOLD = 1000;

const getIndices = (numberOfQuestions: number, currentIndex: number) => {
  const decimal = currentIndex - (currentIndex % INDICES_TO_SHOW);

  if (numberOfQuestions - decimal > INDICES_TO_SHOW) {
    return [...Array(INDICES_TO_SHOW).keys()].map(
      (_, index) => index + decimal,
    );
  } else {
    return [...Array(numberOfQuestions - decimal).keys()].map(
      (_, index) => index + decimal,
    );
  }
};

interface Props extends AssistantProps {
  currentIndex: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  questions: Question[];
  onCompleted: () => void;
}

const QuestionEnumerator: React.FC<Props> = ({
  currentIndex,
  questions,
  setIndex,
  onCompleted,
  environment,
}) => {
  const {
    assistant: {
      curationHub: { surveyDetails: translations },
    },
  } = useTranslations();
  const numberOfQuestions = questions.length;
  const currentQuestion = questions[currentIndex];
  const dispatch = useAppDispatch();
  const { openedAt, timeSpentOnCurrentQuestion } = useAppSelector(
    (state) => state.surveyDetails,
  );
  if (openedAt === null) {
    return null;
  }

  const handleNext = () => {
    const onLastQuestion = currentIndex === numberOfQuestions - 1;
    const surveyCompleted = questions.every((question) => question.answered);

    if (onLastQuestion && surveyCompleted) {
      onCompleted();
      return;
    }
    if (onLastQuestion) {
      return;
    }

    const now = Date.now();
    const msElapsedSinceLastQuestion =
      timeSpentOnCurrentQuestion === null
        ? now - openedAt
        : now - timeSpentOnCurrentQuestion;
    if (
      currentQuestion.answered &&
      msElapsedSinceLastQuestion > ANSWER_MS_THRESHOLD
    ) {
      trackWithMixpanel({
        environment,
        event: {
          name: MixpanelEventType.SurveyQuestionAnswered,
          properties: {
            question: currentQuestion.text,
            secondsElapsed: Math.floor(msElapsedSinceLastQuestion / 1000),
          },
        },
      });
    }

    setIndex((prevState) => prevState + 1);
    dispatch(setTimeSpentOnCurrentQuestion());
  };
  const handlePrevious = () => {
    if (currentIndex === 0) {
      return;
    }
    setIndex((prevState) => prevState - 1);
    dispatch(setTimeSpentOnCurrentQuestion());
  };

  const handleIndexClicked = (index: number) => {
    setIndex(index);
    dispatch(setTimeSpentOnCurrentQuestion());
  };

  if (numberOfQuestions === 1) {
    return (
      <div className={styles.container}>
        <div className={styles.indices}>
          <div className={clsx(styles.index, styles.active)}>1</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.indices}>
        {getIndices(numberOfQuestions, currentIndex).map((index) => (
          <div
            key={index}
            className={clsx(
              styles.index,
              questions[index].answered && styles.answered,
              index === currentIndex && styles.active,
            )}
            onClick={() => handleIndexClicked(index)}
          >
            {index + 1}
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <button className={styles.prevBtn} onClick={handlePrevious}>
          <PrevIcon className={styles.prevIcon} />
          <span>{translations.back}</span>
        </button>
        <button className={styles.nextBtn} onClick={handleNext}>
          <span>{translations.next}</span>
          <NextIcon className={styles.nextIcon} />
        </button>
      </div>
    </div>
  );
};

export default QuestionEnumerator;
