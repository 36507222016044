import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  createEquipmentType,
  deleteEquipmentType,
  fetchEquipmentTypes,
} from '../../integration/equipmentTypes.api';

export const EQUIPMENT_TAGS_KEY = 'equipmentTags';

const TAGS_TO_SHOW = 50;

export interface EquipmentTagsState {
  equipmentSearchStatus: FetchingStatus;
  equipmentTypes: string[];
}

const initialState: EquipmentTagsState = {
  equipmentSearchStatus: FetchingStatus.IDLE,
  equipmentTypes: [],
};

interface SearchEquipmentTagsParams extends ApiThunkParams {
  searchTerm?: string;
}

export const searchEquipmentTags = createAsyncThunk<
  string[],
  SearchEquipmentTagsParams,
  AppThunkConfig
>('searchEquipmentTags', async ({ searchTerm, mock, baseUrl }, { signal }) => {
  const equipmentTypes = await fetchEquipmentTypes({
    limit: TAGS_TO_SHOW,
    searchTerm,
    baseUrl,
    signal,
    mock,
  });

  return equipmentTypes.map((eq) => eq.fullName);
});

interface MutateEquipmentTagsParams extends ApiThunkParams {
  equipmentType: string;
}

export const createEquipmentTag = createAsyncThunk<
  void,
  MutateEquipmentTagsParams,
  AppThunkConfig
>(
  'createEquipmentTag',
  async ({ equipmentType, mock, baseUrl }, { signal }) => {
    await createEquipmentType({
      equipmentType,
      baseUrl,
      signal,
      mock,
    });
  },
);

export const deleteEquipmentTag = createAsyncThunk<
  void,
  MutateEquipmentTagsParams,
  AppThunkConfig
>(
  'deleteEquipmentTag',
  async ({ equipmentType, mock, baseUrl }, { signal }) => {
    await deleteEquipmentType({
      equipmentType,
      baseUrl,
      signal,
      mock,
    });
  },
);

const equipmentTagsSlice = createSlice({
  name: EQUIPMENT_TAGS_KEY,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(searchEquipmentTags.pending, (state) => {
        state.equipmentSearchStatus = FetchingStatus.PENDING;
        state.equipmentTypes = [];
      })
      .addCase(searchEquipmentTags.fulfilled, (state, action) => {
        state.equipmentSearchStatus = FetchingStatus.SUCCESS;
        state.equipmentTypes = action.payload;
      })
      .addCase(searchEquipmentTags.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.equipmentSearchStatus = FetchingStatus.ERROR;
        state.equipmentTypes = [];
      })
      .addCase(createEquipmentTag.pending, (state, action) => {
        state.equipmentTypes = [
          ...state.equipmentTypes,
          action.meta.arg.equipmentType,
        ];
      })
      .addCase(deleteEquipmentTag.pending, (state, action) => {
        state.equipmentTypes = state.equipmentTypes.filter(
          (eqt) => eqt !== action.meta.arg.equipmentType,
        );
      });
  },
});

const { actions, reducer } = equipmentTagsSlice;

export const { reset: resetEquipmentTags } = actions;

export const equipmentTagsReducer = reducer;
