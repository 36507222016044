import api from './api';
import { ApiFetchParams, ApiResponse } from '../types';

type ExtractedSymptomResponse = ApiResponse<string | null>;

interface FetchExtractedSymptomParams extends ApiFetchParams {
  question: string;
}

export const fetchExtractedSymptom = async ({
  question,
  baseUrl,
  signal,
  mock,
}: FetchExtractedSymptomParams): Promise<string | null> => {
  // TODO: remove this when the backend is ready
  if (!mock) {
    return question;
  }
  const { data } = await api.get<ExtractedSymptomResponse>({
    baseUrl,
    mock,
    path: '/knowledge-base/api/v1/extracted-symptom',
    queryParams: {
      question,
    },
    config: { signal },
  });

  return data;
};
