import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AppRoute,
  AssistantProps,
  CustomerSession,
  FetchingStatus,
  OPEN_JOBS_DEFAULT_FILTER_STATE,
} from '../../../types';
import { useOnDesktop } from '../../../utils/responsive';
import { useCustomerDetails } from '../../../store/slices/customerDetails.slice';
import SmartLoopContainer from '../../SmartLoopContainer/SmartLoopContainer';
import CustomerPastJobs from './PastJobs/CustomerPastJobs';
import CustomerSummary from './Summary/CustomerSummary';
import CustomerKpi from './CustomerKpi/CustomerKpi';
import CustomerOpenJobs from './OpenJobs/CustomerOpenJobs';
import { isFetchingResolved } from '../../../utils';
import { storeCustomerSession } from '../../../store/slices/history.slice';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import styles from './CustomerDetails.module.scss';

const CustomerScreen: React.FC<AssistantProps> = (props) => {
  const { isMocking, httpUrl, logError } = props;
  const dispatch = useAppDispatch();
  const { status: historyStatus } = useAppSelector((state) => state.history);
  const { customerId } = useParams();
  const navigate = useNavigate();
  const onDesktop = useOnDesktop();
  const session = useCustomerDetails(customerId);
  const [savedCustomers, setSavedCustomers] = useState<string[]>([]);

  useEffect(() => {
    if (isFetchingResolved(historyStatus) && session === null) {
      navigate(AppRoute.Home, { replace: true });
    }
  }, [session, historyStatus]);

  useEffect(() => {
    if (
      session !== null &&
      !savedCustomers.some((id) => id === session.id) &&
      isSessionFullyLoaded(session) &&
      !session.savedToHistory
    ) {
      setSavedCustomers((prevState) => [...prevState, session.id]);
      dispatch(
        storeCustomerSession({
          session,
          logError,
          mock: isMocking,
          baseUrl: httpUrl,
        }),
      );
    }
  }, [dispatch, session, httpUrl, isMocking]);

  if (historyStatus === FetchingStatus.PENDING && session === null) {
    return (
      <SmartLoopContainer
        {...props}
        className={styles.container}
        mobileTitle={null}
      >
        <div className={styles.content}>
          <div className={styles.mainInfo}>
            <CustomerSummary {...props} session={emptySession} />
            <CustomerOpenJobs {...props} session={emptySession} />
          </div>
          <div className={styles.additionalInfo}>
            <CustomerKpi {...props} session={emptySession} />
            <CustomerPastJobs {...props} session={emptySession} />
          </div>
        </div>
      </SmartLoopContainer>
    );
  }

  if (session === null) {
    return null;
  }

  return (
    <SmartLoopContainer
      {...props}
      className={styles.container}
      mobileTitle={session.customerName}
    >
      {onDesktop && <h2 className={styles.title}>{session.customerName}</h2>}
      <div className={styles.content}>
        <div className={styles.mainInfo}>
          <CustomerSummary {...props} session={session} />
          <CustomerOpenJobs {...props} session={session} />
        </div>
        <div className={styles.additionalInfo}>
          <CustomerKpi {...props} session={session} />
          <CustomerPastJobs {...props} session={session} />
        </div>
      </div>
    </SmartLoopContainer>
  );
};

const emptySession: CustomerSession = {
  ...OPEN_JOBS_DEFAULT_FILTER_STATE,
  id: 'customer-loading',
  savedToHistory: false,
  customerName: '',
  createdAt: Date.now(),
  summary: null,
  summaryStatus: FetchingStatus.PENDING,
  openJobs: null,
  openJobsStatus: FetchingStatus.PENDING,
  pastJobs: null,
  pastJobsStatus: FetchingStatus.PENDING,
  ftfr: null,
  ftfrStatus: FetchingStatus.PENDING,
  mttr: null,
  mttrStatus: FetchingStatus.PENDING,
  mtbv: null,
  mtbvStatus: FetchingStatus.PENDING,
};

const isSessionFullyLoaded = (session: CustomerSession): boolean => {
  return isFetchingResolved(
    session.summaryStatus,
    session.openJobsStatus,
    session.pastJobsStatus,
    session.ftfrStatus,
    session.mttrStatus,
    session.mtbvStatus,
  );
};

export default CustomerScreen;
