export enum JobStatus {
  Closed = 'Closed',
  Open = 'Open',
}

export interface Address {
  city: string | null;
  country: string | null;
  street: string | null;
  streetNumber: string | null;
  zip: string | null;
}

export enum JobType {
  Maintenance = 'Maintenance',
  Failure = 'Failure',
  Other = 'Other',
}

export interface RawJob {
  id: string;
  code: string | null;
  customer_id: string | null;
  customer_name: string | null;
  equipment_type: string | null;
  created: string | null; // ISO 8601 time the job was created
  ended: string | null; // ISO 8601 time when the technician started working on it
  started: string | null; // ISO 8601 time when the technician finished working on it
  closed: string | null; // ISO 8601 time when the job was closed
  description: string | null;
  symptoms: JobSymptoms[] | null;
  predictions: {
    prediction: string;
  } | null;
  division_name?: string | null;
  division_id?: number | null;
}

interface Job {
  id: string;
  code: string | null;
  customerId: string | null;
  customerName: string | null;
  equipmentType: string | null;
  created: string | null; // ISO 8601 time the job was created
  reportedSymptom: string | null;
  divisionName?: string | null;
}

export interface JobWithPrediction extends Job {
  prediction: string | null;
}

export interface PastJob extends Job {
  type: JobType;
  equipment: string;
  equipmentSerial: string;
  address: Address | null;
  ended: string | null; // ISO 8601 time when the technician started working on it
  started: string | null; // ISO 8601 time when the technician finished working on it
  closed: string | null; // ISO 8601 time when the job was closed
}

export interface JobSymptoms {
  id_: string;
  value: string;
  cluster: string;
  explanation: string;
  language: string;
}

export interface OpenJobsResponse {
  data: {
    faults: RawJob[];
    meta: {
      first: boolean;
      last: boolean;
      number: number;
      numberOfElements: number;
      size: number;
      totalElements: number;
      totalPages: number;
    };
  };
}

export enum OpenJobsFilterField {
  EquipmentType = 'equipmentType',
  CustomerName = 'customerName',
  DivisionName = 'divisionName',
  Code = 'code',
}

export interface OpenJobsFilter {
  field: OpenJobsFilterField;
  value: string;
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum OpenJobsSortingField {
  Created = 'created',
  EquipmentType = 'equipmentType',
  CustomerName = 'customerName',
  DivisionName = 'divisionName',
  JobCode = 'jobCode',
}

export interface OpenJobsSorting {
  field: OpenJobsSortingField;
  direction: SortDirection;
}

export interface OpenJobsRequestParams {
  filter: OpenJobsFilter | null;
  sorting: OpenJobsSorting;
  pageSize: number;
  currentPage: number;
}

export interface OpenJobsFilterState extends OpenJobsRequestParams {
  totalElements: number;
}

export interface OpenJobsParams extends OpenJobsFilterState {
  onFilterChange: (filter: OpenJobsFilter | null) => void;
  onSortingChange: (sorting: OpenJobsSorting) => void;
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
}

export const OPEN_JOBS_PAGE_SIZE_OPTIONS = [10, 25, 50];

export const OPEN_JOBS_DEFAULT_PAGE_SIZE = 10;
export const OPEN_JOBS_DEFAULT_SORTING: OpenJobsSorting = {
  field: OpenJobsSortingField.Created,
  direction: SortDirection.Descending,
};

export const OPEN_JOBS_DEFAULT_FILTER_STATE: OpenJobsFilterState = {
  filter: null,
  sorting: OPEN_JOBS_DEFAULT_SORTING,
  pageSize: OPEN_JOBS_DEFAULT_PAGE_SIZE,
  currentPage: 0,
  totalElements: -1,
};

export interface FetchOpenJobsResults {
  jobs: JobWithPrediction[];
  totalElements: number;
}
