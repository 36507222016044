import React from 'react';
import { Link } from 'react-router-dom';

import { AppRoute, AssistantProps } from '../../../types';
import SmartLoopContainer from '../../SmartLoopContainer/SmartLoopContainer';
import SmartLoopEquipmentSearch from '../../Inputs/EquipmentSearch/SmartLoopEquipmentSearch';
import SmartLoopQuestionSearch from '../../Inputs/QuestionSearch/SmartLoopQuestionSearch';
import { useAppSelector } from '../../../store/utils/hooks';
import { useTranslations } from '../../../utils';
import { ReactComponent as LinkIcon } from '../../../assets/link.svg';
import styles from './DocumentSearchHome.module.scss';

const DocumentSearchHome: React.FC<AssistantProps> = (props) => {
  const { selectedEquipmentType, selectedTags } = useAppSelector(
    (state) => state.inputs,
  );
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();

  return (
    <SmartLoopContainer {...props}>
      <h1 className={styles.title}>{translations.homeTitle}</h1>
      <div className={styles.inputs}>
        <SmartLoopEquipmentSearch {...props} />
        <SmartLoopQuestionSearch
          {...props}
          equipmentType={selectedEquipmentType}
          allEquipmentTags={selectedTags.map((tag) => tag.fullName)}
        />
      </div>
      <Link
        to={`${AppRoute.Configuration}/${AppRoute.Documents}`}
        className={styles.link}
      >
        <span>{translations.configurationLabel}</span>
        <LinkIcon className={styles.linkIcon} />
      </Link>
    </SmartLoopContainer>
  );
};

export default DocumentSearchHome;
