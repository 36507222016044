import React from 'react';
import clsx from 'clsx';

import { updateEquipmentSession } from '../../../../../../../store/slices/conversations.slice';
import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import {
  SessionProps,
  TroubleshootingStatus,
} from '../../../../../../../types';
import { useTranslations } from '../../../../../../../utils';
import styles from './TroubleshootingToggle.module.scss';

const TroubleshootingToggle: React.FC<SessionProps> = ({ session }) => {
  const finished =
    session.troubleshootingStatus === TroubleshootingStatus.Finished;
  const troubleshootingVisible = session.troubleshootingVisible;
  const {
    assistant: { troubleshooting: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();

  const toggleTroubleshooting = () => {
    if (finished) {
      return;
    }
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingVisible: !troubleshootingVisible,
      }),
    );
  };

  return (
    <div className={styles.container}>
      <span className={styles.label}>
        {finished ? translations.successMessage : translations.toggleLabel}
      </span>
      <div
        className={clsx(
          styles.iconContainer,
          !troubleshootingVisible && !finished && styles.troubleshootingHidden,
        )}
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={clsx(styles.icon, finished && styles.finished)}
          onClick={toggleTroubleshooting}
        >
          <g clipPath="url(#clip0_25539_2780)">
            <path
              d="M15.9273 10.9959C17.7385 11.1729 19.5756 10.3218 20.575 8.65798C21.71 6.76853 21.4123 4.41785 19.9974 2.87018L17.5955 6.86889L16.0458 6.7489L15.0049 5.31279L17.3662 1.38172C15.4053 0.986278 13.3221 1.83428 12.2331 3.64715C11.2337 5.311 11.3451 7.33251 12.3522 8.84838L5.80985 18.9343C5.50193 19.409 5.64885 20.0444 6.13388 20.3358L7.99348 21.4528C8.45541 21.7303 9.05412 21.5918 9.34737 21.1398L15.9273 10.9959Z"
              stroke={finished ? '#00BDC7' : '#999999'}
            />
            <path
              d="M1 7.46392C3.20397 6.90802 4.01828 6.20174 4.46392 4C4.94036 6.20052 5.70152 6.9712 8 7.46392C5.58732 8.07061 4.85052 8.87264 4.46392 11C3.89531 8.60282 3.0853 7.89427 1 7.46392Z"
              stroke={finished ? '#00BDC7' : '#999999'}
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_25539_2780">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  );
};

export default TroubleshootingToggle;
