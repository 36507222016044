import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { SelectChangeEvent, selectClasses } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import debounce from 'lodash.debounce';

import { AppRoute, OpenJobsFilterField, OpenJobsFilter } from '../../../types';
import { useTranslations } from '../../../utils';
import { ReactComponent as DropdownIcon } from './assets/dropdown.svg';
import { ReactComponent as ClearIcon } from './assets/clear.svg';
import { useCurrentRoute } from '../../../utils/useCurrentRoute';
import styles from './OpenJobsFilters.module.scss';

interface Props {
  filter: OpenJobsFilter | null;
  onFilterChange: (filter: OpenJobsFilter | null) => void;
}

const OpenJobsFilters: React.FC<Props> = ({ filter, onFilterChange }) => {
  const { assistant: translations } = useTranslations();
  const currentRoute = useCurrentRoute();
  const [filterType, setFilterType] = useState(() => {
    if (filter !== null) {
      return filter.field;
    }
    if (currentRoute === AppRoute.Customer) {
      return OpenJobsFilterField.EquipmentType;
    } else {
      return OpenJobsFilterField.CustomerName;
    }
  });
  const [filterValue, setFilterValue] = useState(filter?.value || '');

  const handleFilterTypeChange = (event: SelectChangeEvent) => {
    const newField = event.target.value as OpenJobsFilterField;
    setFilterType(newField);
    setFilterValue('');
    onFilterChange(null);
  };
  const handleFilterValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilterValue(newValue);
    updateFilterValue(filterType, newValue);
  };
  const handleFilterReset = () => {
    setFilterValue('');
    onFilterChange(null);
  };

  const updateFilterValue = useCallback(
    debounce((field: OpenJobsFilterField, value: string) => {
      if (value === '') {
        onFilterChange(null);
      } else {
        onFilterChange({
          field,
          value,
        });
      }
    }, 300),
    [onFilterChange],
  );

  useEffect(() => {
    if (filter === null) {
      setFilterValue('');
    } else {
      setFilterType(filter.field);
      setFilterValue(filter.value);
    }
  }, [filter]);

  return (
    <div className={styles.filters}>
      <ClearIcon className={styles.clearIcon} onClick={handleFilterReset} />
      <Select
        variant="standard"
        value={filterType}
        onChange={handleFilterTypeChange}
        disableUnderline
        IconComponent={() => <DropdownIcon className={styles.dropdownIcon} />}
        sx={{
          [`& .${selectClasses.select}`]: {
            backgroundColor: 'transparent !important',
          },
        }}
      >
        {currentRoute !== AppRoute.Customer && (
          <MenuItem value={OpenJobsFilterField.CustomerName}>
            {translations.home.openJobsHeaders.customer}
          </MenuItem>
        )}
        <MenuItem value={OpenJobsFilterField.EquipmentType}>
          {translations.home.openJobsHeaders.equipment}
        </MenuItem>
        <MenuItem value={OpenJobsFilterField.Code}>
          {translations.home.openJobsHeaders.jobCode}
        </MenuItem>
        <MenuItem value={OpenJobsFilterField.DivisionName}>
          {translations.home.openJobsHeaders.division}
        </MenuItem>
      </Select>
      <TextField
        variant="standard"
        placeholder={translations.home.openJobsFilterValuePlaceholder}
        value={filterValue}
        onChange={handleFilterValueChange}
        InputProps={{ disableUnderline: true }}
        className={styles.value}
      />
    </div>
  );
};

export default OpenJobsFilters;
