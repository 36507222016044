import { ReactNode } from 'react';
import { JobType, RootCauseNode, SurveyStatus } from '../types';
import { SearchHistoryEntrySegment } from '../components/SmartLoopContainer/DrawerContent/SearchHistory/UseSearchHistory';

type LabelCreator<T> = (params: T) => string;

export enum SupportedLanguage {
  ENGLISH = 'en',
  GERMAN = 'de',
}

export interface Translations {
  loading: string;
  error: ReactNode;
  auth: {
    login: string;
    email: string;
    password: string;
    or: string;
    loading: string;
    marketingMessage: string;
    pilotDescription: ReactNode;
    unauthorized: string;
    invalidCredentials: string;
    sessionExpired: string;
    azure: {
      loginButtonText: string;
      tooltipLabel: string;
      tooltipContent: string;
    };
  };
  noData: {
    defaultMessage: string;
    openJobs: string;
    pastJobs: string;
    images: string;
    customerSummary: string;
    diagnoses: string;
    tags: string;
  };
  deleteConfirmationDialog: {
    title: string;
    description: (label: string) => ReactNode;
    cancel: string;
    confirm: string;
  };
  assistant: {
    noAnswer: string;
    noCustomer: string;
    noDate: string;
    noEquipment: string;
    noDivision: string;
    noCode: string;
    filterLoading: string;
    filterNoResults: string;
    equipmentSearchPlaceholder: string;
    customerSearchPlaceholder: string;
    questionPlaceholder: string;
    followUpQuestionPlaceholder: string;
    activeFilterToggle: {
      customer: string;
      equipment: string;
    };
    jobType: Record<JobType, string>;
    drawer: {
      newSearch: string;
      tipsTitle: string;
      tipsContent: string;
      searchHistory: string;
      segments: Record<SearchHistoryEntrySegment, string>;
    };
    troubleshooting: {
      toggleLabel: string;
      title: string;
      description: string;
      symptomSearchPlaceholder: string;
      symptoms: string;
      skipBtn: string;
      startBtn: string;
      finishBtn: string;
      closeBtn: string;
      symptomSelectorLabel: string;
      relatedSymptoms: string;
      answerSummary: string;
      resumeTroubleshooting: string;
      completedTitle: string;
      finalSymptoms: string;
      successMessage: string;
      rootCause: string;
      technicians: (technicians: string[]) => ReactNode;
      step: string;
      diagnosisSummary: LabelCreator<{
        diagnoses: RootCauseNode[] | null;
        symptoms: string[];
      }>;
    };
    home: {
      mainTitle: ReactNode;
      openJobsTitle: string;
      openJobsHeaders: {
        created: string;
        equipment: string;
        customer: string;
        symptom: string;
        rootCause: string;
        division: string;
        jobCode: string;
      };
      openJobsFilterValuePlaceholder: string;
      noPrediction: string;
    };
    dashboard: {
      answer: string;
      similarServiceJobs: string;
      serviceHistory: string;
      mostFrequentIssuesTitle: LabelCreator<string>;
      imagesTitle: string;
      sources: string;
      page: string;
      noData: (question: string) => string;
      searchSuggestionsTitle: string;
    };
    customerScreen: {
      summary: string;
      kpi: {
        title: string;
        moreThanAverage: string;
        lessThanAverage: string;
        ftfr: string;
        ftfrAbbr: string;
        mttr: string;
        mttrAbbr: string;
        mtbv: string;
        mtbvAbbr: string;
        dayUnit: LabelCreator<number>;
      };
    };
    feedback: {
      prompt: string;
      thanks: string;
      positive: string;
      negative: string;
      wrongRootCause: string;
      wrongParts: string;
      wrongDocuments: string;
      other: string;
      detailsPlaceholder: string;
      submit: string;
      forceFeedbackPrompt: string;
    };
    curationHub: {
      recentSurveys: string;
      survey: {
        surveyStatuses: Record<SurveyStatus, string>;
        open: string;
      };
      surveyDetails: {
        title: LabelCreator<string>;
        prompt: string;
        moreInformation: string;
        questionJobIds: (jobId: string) => ReactNode;
        answerJobIds: (jobIds: string[]) => ReactNode;
        next: string;
        back: string;
      };
      surveyCompleted: {
        thanks: string;
        completionText: (equipmentType: string) => ReactNode;
        close: string;
      };
    };
    documentManagement: {
      homeTitle: ReactNode;
      configurationLabel: string;
      documentOverview: string;
      tagManagement: string;
      customerInformation: string;
      tagsPlaceholder: string;
      equipmentTypesTitle: string;
      documentsPlaceholder: string;
      uploadDocumentsButtonLabel: string;
      uploadDialogTitle: string;
      cancel: string;
      close: string;
      fileDropZoneLabel: ReactNode;
      fileName: string;
      tags: string;
      date: string;
      fileSize: string;
    };
  };
}
