import React, { useRef } from 'react';
import Tooltip from '@mui/material/Tooltip';

import { SessionSummary } from '../../../../../../types';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import { isElementOverflowing } from '../../../../../../utils/isElementOverflowing';
import { setFocusedSession } from '../../../../../../store/slices/conversations.slice';
import styles from './SearchHistorySession.module.scss';

interface Props {
  session: SessionSummary;
}

const SearchHistorySession: React.FC<Props> = ({ session }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLLIElement>(null);
  const hideTooltip = !isElementOverflowing(ref.current);

  const handleClick = () => {
    dispatch(setFocusedSession(session.id));
  };

  return (
    <Tooltip title={session.question} disableHoverListener={hideTooltip}>
      <li ref={ref} className={styles.session} onClick={handleClick}>
        {session.question}
      </li>
    </Tooltip>
  );
};

export default SearchHistorySession;
