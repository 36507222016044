import React from 'react';
import { format } from 'date-fns';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TablePagination from '@mui/material/TablePagination';

import {
  AppRoute,
  FetchingStatus,
  JobWithPrediction,
  OPEN_JOBS_PAGE_SIZE_OPTIONS,
  OpenJobsParams,
  OpenJobsSortingField,
} from '../../../types';
import {
  getSortingFromColumn,
  isDefined,
  useAuthData,
  useLanguage,
  useTranslations,
  useCurrentRoute,
  renderOrDefault,
} from '../../../utils';
import JobPrediction from '../JobPrediction';
import OpenJobsHeadline from './Headline/OpenJobsHeadline';
import SmartLoopCardContent from '../../Card/SmartLoopCardContent';
import OpenJobsDesktopHeader from './Header/OpenJobsDesktopHeader';
import styles from './OpenJobsDesktop.module.scss';

interface Props extends OpenJobsParams {
  jobs: JobWithPrediction[] | null;
  status: FetchingStatus;
  onJobClicked: (job: JobWithPrediction) => void;
}

const OpenJobsDesktop: React.FC<Props> = ({
  onJobClicked,
  jobs,
  status,
  totalElements,
  sorting,
  filter,
  pageSize,
  currentPage,
  onFilterChange,
  onPageChange,
  onPageSizeChange,
  onSortingChange,
}) => {
  const { assistant: translations, noData } = useTranslations();
  const currentRoute = useCurrentRoute();
  const { language } = useLanguage();
  const authData = useAuthData();
  const cluster = authData === null ? null : authData.cluster;
  const onHomeScreen = currentRoute === AppRoute.Home;

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    onPageChange(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    onPageSizeChange(newPageSize);
  };
  const handleColumnClicked = (column: OpenJobsSortingField) => {
    const newSorting = getSortingFromColumn(column, sorting);
    onSortingChange(newSorting);
  };

  return (
    <div className={styles.container}>
      <OpenJobsHeadline filter={filter} onFilterChange={onFilterChange} />
      <div className={styles.tableContainer}>
        <SmartLoopCardContent
          data={jobs}
          status={status}
          emptyStateMessage={noData.openJobs}
        >
          {(jobs) => (
            <Table aria-label="open jobs table">
              <TableHead>
                <TableRow>
                  <OpenJobsDesktopHeader
                    field={OpenJobsSortingField.Created}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.home.openJobsHeaders.created}
                  />
                  <OpenJobsDesktopHeader
                    field={OpenJobsSortingField.EquipmentType}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.home.openJobsHeaders.equipment}
                  />
                  {onHomeScreen && (
                    <OpenJobsDesktopHeader
                      field={OpenJobsSortingField.CustomerName}
                      handleColumnClicked={handleColumnClicked}
                      sorting={sorting}
                      title={translations.home.openJobsHeaders.customer}
                    />
                  )}
                  <TableCell>
                    {translations.home.openJobsHeaders.symptom}
                  </TableCell>
                  <TableCell>
                    {translations.home.openJobsHeaders.rootCause}
                  </TableCell>
                  <OpenJobsDesktopHeader
                    field={OpenJobsSortingField.DivisionName}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.home.openJobsHeaders.division}
                  />
                  <OpenJobsDesktopHeader
                    field={OpenJobsSortingField.JobCode}
                    handleColumnClicked={handleColumnClicked}
                    sorting={sorting}
                    title={translations.home.openJobsHeaders.jobCode}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {jobs.map((job) => (
                  <TableRow key={job.id} onClick={() => onJobClicked(job)}>
                    <TableCell>
                      {isDefined(job.created)
                        ? format(job.created, 'dd.MM.yyyy')
                        : translations.noDate}
                    </TableCell>
                    <TableCell>
                      {renderOrDefault(
                        job.equipmentType,
                        translations.noEquipment,
                      )}
                    </TableCell>
                    {onHomeScreen && (
                      <TableCell>
                        {renderOrDefault(
                          job.customerName,
                          translations.noCustomer,
                        )}
                      </TableCell>
                    )}
                    <TableCell>{job.reportedSymptom}</TableCell>
                    <TableCell>
                      <JobPrediction
                        prediction={job.prediction}
                        language={language}
                      />
                    </TableCell>
                    <TableCell>
                      {renderOrDefault(
                        job.divisionName,
                        translations.noDivision,
                      )}
                    </TableCell>
                    <TableCell>
                      {isDefined(job.code) ? (
                        <a
                          target="_blank"
                          href={`https://${cluster}.coresystems.net/shell/#/planning-dispatching/service-calls/detail?~id=${job.id}&~code=${job.code}`}
                          rel="noreferrer"
                          onClick={(event) => event.stopPropagation()}
                        >
                          {job.code}
                        </a>
                      ) : (
                        translations.noCode
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </SmartLoopCardContent>
      </div>
      <TablePagination
        component="div"
        rowsPerPageOptions={OPEN_JOBS_PAGE_SIZE_OPTIONS}
        count={totalElements}
        page={currentPage}
        onPageChange={handleChangePage}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default OpenJobsDesktop;
