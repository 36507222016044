import { DocumentSource, SourceDocument } from '../integration/webSocketClient';
import { isDefined } from './isDefined';

export const getSourceDocuments = (
  documents: SourceDocument[],
): DocumentSource[] => {
  return documents
    .map((doc) => doc.metadata.sources)
    .filter((sources) => isDefined(sources) && Array.isArray(sources))
    .reduce((acc, sources) => {
      return [...acc, ...sources];
    }, []);
};

export const getSourceDocumentIds = (documents: SourceDocument[]): string[] => {
  return getSourceDocuments(documents).map((document) => document.documentId);
};
