import React, { useCallback, useEffect } from 'react';

import {
  AssistantProps,
  CustomerSession,
  OpenJobsFilter,
  OpenJobsSorting,
} from '../../../../types';
import OpenJobs from '../../../OpenJobs/OpenJobs';
import { useAppDispatch } from '../../../../store/utils/hooks';
import {
  getCustomerOpenJobs,
  updateCustomerSession,
} from '../../../../store/slices/customerDetails.slice';
import styles from './CustomerOpenJobs.module.scss';

interface Props extends AssistantProps {
  session: CustomerSession;
}

const CustomerOpenJobs: React.FC<Props> = ({ session, ...props }) => {
  const { logError, httpUrl, isMocking } = props;
  const { id, sorting, filter, pageSize, currentPage, totalElements } = session;
  const dispatch = useAppDispatch();

  const onPageChange = useCallback(
    (page: number) => {
      dispatch(
        updateCustomerSession({
          id,
          currentPage: page,
        }),
      );
    },
    [dispatch],
  );
  const onPageSizeChange = useCallback(
    (newPageSize: number) => {
      if (newPageSize !== pageSize) {
        dispatch(
          updateCustomerSession({
            id,
            currentPage: 0,
            pageSize: newPageSize,
          }),
        );
      }
    },
    [dispatch, pageSize],
  );

  const onFilterChange = useCallback(
    (filter: OpenJobsFilter | null) => {
      dispatch(
        updateCustomerSession({
          id,
          filter,
          currentPage: 0,
        }),
      );
    },
    [dispatch],
  );
  const onSortingChange = useCallback(
    (sorting: OpenJobsSorting) => {
      dispatch(
        updateCustomerSession({
          id,
          sorting,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const promise = dispatch(
      getCustomerOpenJobs({
        sorting,
        filter,
        pageSize,
        currentPage,
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
        customerId: id,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    httpUrl,
    isMocking,
    sorting,
    filter,
    pageSize,
    currentPage,
    id,
  ]);

  return (
    <div className={styles.container}>
      <OpenJobs
        {...props}
        jobs={session.openJobs}
        status={session.openJobsStatus}
        height={400}
        filter={filter}
        sorting={sorting}
        pageSize={pageSize}
        currentPage={currentPage}
        totalElements={totalElements}
        onFilterChange={onFilterChange}
        onSortingChange={onSortingChange}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};

export default CustomerOpenJobs;
