import { Client } from 'mock-socket';
import {
  ChatbotResponse,
  ChatbotResponseType,
  WebSocketQueryAction,
} from '../integration/webSocketClient';
import { isDefined } from '../utils';
import {
  bladeFinalSeed,
  bladePartialSeed,
  generatorFinalSeed,
  generatorPartialSeed,
  pitchFinalSeed,
  pitchPartialSeed,
} from './seed/predictions.seed';
import { getDocumentSearchResultSeed } from './seed/documentSearchResult.seed';
import { getDashboardPastJobsSeed } from './seed/dashboardPastJobs.seed';
import {
  borsseleSummaryFinalSeed,
  borsseleSummaryPartialSeed,
  esbOffshoreSummaryFinalSeed,
  esbOffshoreSummaryPartialSeed,
  hollandseSummaryFinalSeed,
  hollandseSummaryPartialSeed,
  landsvirkjunSummaryFinalSeed,
  landsvirkjunSummaryPartialSeed,
  ukOnshoreSummaryFinalSeed,
  ukOnshoreSummaryPartialSeed,
} from './seed/customerSummary.seed';
import { knowledgeBaseDefaultAnswers } from '../store/utils/defaultAnswers';

export interface WebSocketHandler {
  action: WebSocketQueryAction;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handle: (payload: any, socket: Client) => void;
}

interface SendWithDelayParams {
  socket: Client;
  delay: number;
  message: ChatbotResponse;
}

const sendWithDelay = ({ socket, delay, message }: SendWithDelayParams) => {
  window.setTimeout(() => {
    socket.send(JSON.stringify(message));
  }, delay);
};

export const webSocketHandlers: WebSocketHandler[] = [
  {
    action: WebSocketQueryAction.RootCause,
    handle: ({ message, conversationId, sessionId }, socket) => {
      if (!isDefined(message)) {
        return;
      }
      if (message.match(/blade/i)) {
        sendWithDelay({
          socket,
          delay: 250,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsSourceDocuments,
            question: message,
            sourceDocuments: bladeFinalSeed.sourceDocuments,
          },
        });
        bladePartialSeed.forEach((partial, index) => {
          sendWithDelay({
            socket,
            delay: index * 500 + 500,
            message: {
              conversationId,
              sessionId,
              index,
              type: ChatbotResponseType.PredictionsPartial,
              question: message,
              answer: partial.answer,
            },
          });
        });
        sendWithDelay({
          socket,
          delay: bladePartialSeed.length * 500 + 500,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsFinished,
            question: message,
            answer: bladeFinalSeed.answer,
            parts: bladeFinalSeed.parts,
          },
        });
      } else if (message.match(/generator/i)) {
        sendWithDelay({
          socket,
          delay: 250,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsSourceDocuments,
            question: message,
            sourceDocuments: generatorFinalSeed.sourceDocuments,
          },
        });
        generatorPartialSeed.forEach((partial, index) => {
          sendWithDelay({
            socket,
            delay: index * 500 + 500,
            message: {
              conversationId,
              sessionId,
              index,
              type: ChatbotResponseType.PredictionsPartial,
              question: message,
              answer: partial.answer,
            },
          });
        });
        sendWithDelay({
          socket,
          delay: generatorPartialSeed.length * 500 + 500,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsFinished,
            question: message,
            answer: generatorFinalSeed.answer,
            parts: generatorFinalSeed.parts,
          },
        });
      } else if (message.match(/pitch bearing/i)) {
        sendWithDelay({
          socket,
          delay: 250,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsSourceDocuments,
            question: message,
            sourceDocuments: pitchFinalSeed.sourceDocuments,
          },
        });
        pitchPartialSeed.forEach((partial, index) => {
          sendWithDelay({
            socket,
            delay: index * 500 + 500,
            message: {
              conversationId,
              sessionId,
              index,
              type: ChatbotResponseType.PredictionsPartial,
              question: message,
              answer: partial.answer,
            },
          });
        });
        sendWithDelay({
          socket,
          delay: pitchPartialSeed.length * 500 + 500,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsFinished,
            question: message,
            answer: pitchFinalSeed.answer,
            parts: pitchFinalSeed.parts,
          },
        });
      } else {
        sendWithDelay({
          socket,
          delay: 250,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsSourceDocuments,
            question: message,
            sourceDocuments: [],
          },
        });
        sendWithDelay({
          socket,
          delay: 300,
          message: {
            conversationId,
            sessionId,
            type: ChatbotResponseType.PredictionsFinished,
            question: message,
            answer: knowledgeBaseDefaultAnswers[0],
          },
        });
      }
    },
  },
  {
    action: WebSocketQueryAction.DocumentSearch,
    handle: ({ message, conversationId, sessionId }, socket) => {
      if (!isDefined(message)) {
        return;
      }
      sendWithDelay({
        socket,
        delay: 1500,
        message: getDocumentSearchResultSeed(
          message,
          conversationId,
          sessionId,
        ),
      });
    },
  },
  {
    action: WebSocketQueryAction.GetPastJobs,
    handle: ({ conversationId, jobIds, sessionId }, socket) => {
      sendWithDelay({
        socket,
        delay: 1500,
        message: {
          conversationId,
          sessionId,
          type: ChatbotResponseType.PastJobs,
          results: getDashboardPastJobsSeed(jobIds),
        },
      });
    },
  },
  {
    action: WebSocketQueryAction.CustomerSummary,
    handle: ({ customerId }, socket) => {
      switch (customerId) {
        case 'customer1':
          ukOnshoreSummaryPartialSeed.forEach((partial, index) => {
            sendWithDelay({
              socket,
              delay: index * 500 + 500,
              message: {
                customerId,
                index,
                type: ChatbotResponseType.CustomerSummaryPartial,
                answer: partial.answer,
              },
            });
          });
          sendWithDelay({
            socket,
            delay: ukOnshoreSummaryPartialSeed.length * 500 + 500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: ukOnshoreSummaryFinalSeed.answer,
            },
          });
          break;
        case 'customer2':
          esbOffshoreSummaryPartialSeed.forEach((partial, index) => {
            sendWithDelay({
              socket,
              delay: index * 500 + 500,
              message: {
                customerId,
                index,
                type: ChatbotResponseType.CustomerSummaryPartial,
                answer: partial.answer,
              },
            });
          });
          sendWithDelay({
            socket,
            delay: esbOffshoreSummaryPartialSeed.length * 500 + 500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: esbOffshoreSummaryFinalSeed.answer,
            },
          });
          break;
        case 'customer3':
          landsvirkjunSummaryPartialSeed.forEach((partial, index) => {
            sendWithDelay({
              socket,
              delay: index * 500 + 500,
              message: {
                customerId,
                index,
                type: ChatbotResponseType.CustomerSummaryPartial,
                answer: partial.answer,
              },
            });
          });
          sendWithDelay({
            socket,
            delay: landsvirkjunSummaryPartialSeed.length * 500 + 500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: landsvirkjunSummaryFinalSeed.answer,
            },
          });
          break;
        case 'customer4':
          borsseleSummaryPartialSeed.forEach((partial, index) => {
            sendWithDelay({
              socket,
              delay: index * 500 + 500,
              message: {
                customerId,
                index,
                type: ChatbotResponseType.CustomerSummaryPartial,
                answer: partial.answer,
              },
            });
          });
          sendWithDelay({
            socket,
            delay: borsseleSummaryPartialSeed.length * 500 + 500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: borsseleSummaryFinalSeed.answer,
            },
          });
          break;
        case 'customer5':
          hollandseSummaryPartialSeed.forEach((partial, index) => {
            sendWithDelay({
              socket,
              delay: index * 500 + 500,
              message: {
                customerId,
                index,
                type: ChatbotResponseType.CustomerSummaryPartial,
                answer: partial.answer,
              },
            });
          });
          sendWithDelay({
            socket,
            delay: hollandseSummaryPartialSeed.length * 500 + 500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: hollandseSummaryFinalSeed.answer,
            },
          });
          break;
        default:
          sendWithDelay({
            socket,
            delay: 1500,
            message: {
              customerId,
              type: ChatbotResponseType.CustomerSummaryFinished,
              answer: 'There is no information in the provided content.',
            },
          });
      }
    },
  },
  {
    action: WebSocketQueryAction.Heartbeat,
    handle: () => {
      // no need to reply
    },
  },
];
