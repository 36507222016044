import { SourceDocument } from '../../integration/webSocketClient';

interface PredictionsMockData {
  answer: string;
  sourceDocuments: SourceDocument[];
  parts: string[];
}

interface PredictionsPartialMockData {
  answer: string;
}

export const bladePartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>irregular blade movement</i> is often associated with:</p>`,
  },
  {
    answer: `<p><b>Physical Damage (91%)</b><br>Inspect the blades for visible damage, such as cracks, chips, or misalignment. If you find any, depending on the extent of the damage, you may need to replace or repair the blade.<br>Critical Parts: New blade sections, Hub plates, Pitch actuators<br>Similar Jobs: <a>15048</a>, <a>12937</a></p>`,
  },
  {
    answer: `<p><b>Balancing (52%)</b><br>Ensure that the blades are correctly balanced. Imbalances can lead to irregular movement. You will need a dynamic balancing machine to balance the blades accurately.<br>Similar Jobs: <a>10122</a>, <a>9837</a></p>`,
  },
  {
    answer: `<p><b>Pitch System (35%)</b><br>The pitch system adjusts the blades' angle to control the turbine's speed and rotation. Malfunctions in the pitch system can lead to irregular movement.<br>Critical Parts: Pitch actuators, Pitch control unit. Sensors and feedback devices<br>Similar Jobs: <a>10222</a>, <a>10655</a></p>`,
  },
];

export const bladeFinalSeed: PredictionsMockData = {
  answer: bladePartialSeed.map((part) => part.answer).join(''),
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '15048',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '12937',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '10122',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '98373',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '10222',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '10655',
        sources: [],
      },
    },
  ],
  parts: [],
};

export const generatorPartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>generator and power converter failures</i> are often associated with:</p>`,
  },
  {
    answer: `<p><b>Electrical Fault due to Overheating (72%)</b><br>High wind speeds can cause the turbine to produce more power than the generator and power converter can handle.<br>Critical Parts: Cooling Fans, Ventilation and Enclosures<br>Similar Jobs: <a>89122</a>, <a>69836</a></p>`,
  },
  {
    answer: `<p><b>Converter Failure caused by Cooling System Issues (64%)</b><br>Addressing the cooling system problems is crucial to prevent further damage and ensure the proper functioning of the converter. Check cooling fluid levels and verify pump operation.<br>Similar Jobs: <a>46143</a>, <a>36029</a></p>`,
  },
  {
    answer: `<p><b>Control System Malfunction (38%)</b><br>Conduct thorough electrical system analysis to identify and address overloads, voltage fluctuations, and power quality problems. Ensure that the turbine's control system and software are up-to-date.<br>Critical Parts: Generator coils, Control Board<br>Similar Jobs: <a>89922</a>, <a>89836</a></p>`,
  },
];

export const generatorFinalSeed: PredictionsMockData = {
  answer: generatorPartialSeed.map((part) => part.answer).join(''),
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '68122',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '69836',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '46143',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '36029',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89922',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89836',
        sources: [],
      },
    },
  ],
  parts: [],
};

export const pitchPartialSeed: PredictionsPartialMockData[] = [
  {
    answer: `<p>Based on past service jobs, <i>pitch-bearing failures</i> are often associated with:</p>`,
  },
  {
    answer: `<p><b>PCS malfunction (81%)</b><br>Electrical analysis of the pitch control system, addressing and repairing any faults or malfunctions. Install additional safeguards, such as surge protectors and voltage regulators.<br>Similar Jobs: <a>68122</a>, <a>89522</a></p>`,
  },
  {
    answer: `<p><b>Rolling Element Fatigue (66%)</b><br>Prolonged operation under heavy loads, high rotational speeds, or insufficient lubrication can fatigue these rolling elements. Regular inspection and monitoring of rolling elements are essential to identify early signs of fatigue and prevent catastrophic failure.<br>Critical Parts: Lubricants, Monitoring Sensors, Bearing Isolators<br>Similar Jobs: <a>81552</a>, <a>83556</a></p>`,
  },
  {
    answer: `<p><b>Bearing Seal Degradation (62%)</b><br>The seals in pitch bearings prevent dust, moisture, and other environmental contamination. If these seals degrade over time due to wear, improper installation, or exposure to harsh conditions, contaminants can be ingested.<br>Critical Parts: Bearings, Seals & Lubrication<br>Similar Jobs: <a>89552</a>, <a>89556</a></p>`,
  },
];

export const pitchFinalSeed: PredictionsMockData = {
  answer: pitchPartialSeed.map((part) => part.answer).join(''),
  sourceDocuments: [
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89122',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89522',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '81552',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '83556',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89552',
        sources: [],
      },
    },
    {
      page_content: '',
      metadata: {
        doc_type: 'fault',
        code: '89556',
        sources: [],
      },
    },
  ],
  parts: [],
};
