import { MediaResponseData } from '../../integration/media.api';

export const bladeImagesSeed: MediaResponseData = {
  items: [
    {
      id: 'image01',
      jobId: '15048',
      fileName: 'Irregular blade movement 1',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade1.jpeg',
    },
    {
      id: 'image02',
      jobId: '15048',
      fileName: 'Irregular blade movement 2',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade2.png',
    },
    {
      id: 'image03',
      jobId: '12937',
      fileName: 'Irregular blade movement 3',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade3.jpeg',
    },
    {
      id: 'image04',
      jobId: '12937',
      fileName: 'Irregular blade movement 4',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade4.jpeg',
    },
    {
      id: 'image05',
      jobId: '10122',
      fileName: 'Irregular blade movement 5',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade5.png',
    },
    {
      id: 'image06',
      jobId: '10122',
      fileName: 'Irregular blade movement 6',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade6.png',
    },
    {
      id: 'image07',
      jobId: '10122',
      fileName: 'Irregular blade movement 7',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/blade7.jpeg',
    },
  ],
};

export const generatorImagesSeed: MediaResponseData = {
  items: [
    {
      id: 'image01',
      jobId: '89122',
      fileName: 'Generator and power converter failures 1',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/generator1.png',
    },
    {
      id: 'image02',
      jobId: '89122',
      fileName: 'Generator and power converter failures 2',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/generator2.png',
    },
    {
      id: 'image03',
      jobId: '89122',
      fileName: 'Generator and power converter failures 3',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/generator3.png',
    },
    {
      id: 'image04',
      jobId: '46143',
      fileName: 'Generator and power converter failures 4',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/generator4.jpeg',
    },
    {
      id: 'image05',
      jobId: '46143',
      fileName: 'Generator and power converter failures 5',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/generator5.jpeg',
    },
  ],
};

export const pitchImagesSeed: MediaResponseData = {
  items: [
    {
      id: 'image01',
      jobId: '83556',
      fileName: 'Pitch-bearing failures 1',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/pitch1.png',
    },
    {
      id: 'image02',
      jobId: '83556',
      fileName: 'Pitch-bearing failures 2',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/pitch2.jpeg',
    },
    {
      id: 'image03',
      jobId: '83556',
      fileName: 'Pitch-bearing failures 3',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/pitch3.jpeg',
    },
    {
      id: 'image04',
      jobId: '89552',
      fileName: 'Pitch-bearing failures 4',
      mimeType: 'image/png',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/pitch4.png',
    },
    {
      id: 'image05',
      jobId: '89552',
      fileName: 'Pitch-bearing failures 5',
      mimeType: 'image/jpeg',
      url: 'https://service-ai-demo-public.s3.eu-west-1.amazonaws.com/pitch5.jpeg',
    },
  ],
};
