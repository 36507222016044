import React, { useCallback, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import FilledInput from '@mui/material/FilledInput';
import InputAdornment from '@mui/material/InputAdornment';

import { AssistantProps } from '../../../../types';
import DocumentTable from './DocumentTable/DocumentTable';
import DocumentUpload from './DocumentUpload/DocumentUpload';
import {
  clearNewDocuments,
  useDocumentUploadFinished,
} from '../../../../store/slices/documents.slice';
import { useAppDispatch } from '../../../../store/utils/hooks';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import { ReactComponent as UploadIcon } from './assets/download.svg';
import { useTranslations } from '../../../../utils';
import styles from './DocumentOverview.module.scss';

const DocumentOverview: React.FC<AssistantProps> = (props) => {
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const isDocumentUploadFinished = useDocumentUploadFinished();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [reloadCounter, setReloadCounter] = useState(0);
  const handleClose = useCallback(
    (uploadHappened: boolean) => {
      if (!isDocumentUploadFinished) {
        return;
      }
      setDialogOpen(false);
      dispatch(clearNewDocuments());
      // Only trigger reloads when the user uploaded files, not when they just closed the dialog.
      if (uploadHappened) {
        setReloadCounter((prev) => prev + 1);
      }
    },
    [dispatch, isDocumentUploadFinished],
  );

  return (
    <div className={styles.container}>
      <div className={styles.searchAndUpload}>
        <FilledInput
          fullWidth
          placeholder={translations.documentsPlaceholder}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={styles.searchIcon} />
            </InputAdornment>
          }
          disableUnderline
          className={styles.input}
        />
        <button
          className={styles.uploadBtn}
          onClick={() => setDialogOpen(true)}
        >
          <UploadIcon className={styles.uploadIcon} />
          <span>{translations.uploadDocumentsButtonLabel}</span>
        </button>
      </div>
      <div className={styles.table}>
        <DocumentTable {...props} reloadCounter={reloadCounter} />
      </div>
      <Dialog
        open={dialogOpen}
        maxWidth={false}
        PaperProps={{ style: { backgroundImage: 'none' } }}
        transitionDuration={{ exit: 0 }}
      >
        <DocumentUpload {...props} onClose={handleClose} />
      </Dialog>
    </div>
  );
};

export default DocumentOverview;
