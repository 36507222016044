import React from 'react';
import Skeleton from '@mui/material/Skeleton';

import { isDefined } from '../../../../../utils';
import { FetchingStatus, KpiSummary } from '../../../../../types';
import KpiTrend from './Trend/KpiTrend';
import styles from './KpiCard.module.scss';

interface Props {
  title: string;
  summary: KpiSummary;
  status: FetchingStatus;
  valueUnit?: string;
}

// The trend value is coming from customer's internal comparison,
// while the trend is that value compared to multiple customers' average.
// The component is colour-coded based on the trend,
// but the arrow points up or down based on the trend value.
const KpiCard: React.FC<Props> = ({ title, summary, valueUnit, status }) => {
  const { average, trend, trendValue } = summary;

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{title}</h3>
      {status === FetchingStatus.PENDING ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.valueLoading}
        />
      ) : (
        <div className={styles.value}>
          {average !== null
            ? `${average}${isDefined(valueUnit) ? valueUnit : ''}`
            : 'N/A'}
        </div>
      )}
      <KpiTrend status={status} trend={trend} trendValue={trendValue} />
    </div>
  );
};

export default KpiCard;
