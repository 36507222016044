import api from './api';
import { ApiFetchParams, ApiResponse } from '../types';

export interface Customer {
  id: string;
  name: string;
  city?: string; // Customers coming from the trending customer chart have no city
}

export interface CustomersResponse {
  customers: Customer[];
}

type CustomerSearchResponse = ApiResponse<CustomersResponse>;

interface FetchCountriesParams extends ApiFetchParams {
  searchTerm: string;
}

export const fetchCustomers = async ({
  searchTerm,
  baseUrl,
  signal,
  mock,
}: FetchCountriesParams): Promise<Customer[]> => {
  const { data } = await api.get<CustomerSearchResponse>({
    baseUrl,
    mock,
    path: '/domain-model/api/v1/customers/actions/search',
    queryParams: {
      searchTerm,
    },
    config: { signal },
  });

  return data.customers;
};
