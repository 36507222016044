import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as MobileLogo } from '../../../../assets/mobile-logo.svg';
import { ReactComponent as MobileLogoLight } from '../../../../assets/mobile-logo-light.svg';
import { ReactComponent as LinkIcon } from '../../../../assets/link.svg';
import { AppRoute, AssistantProps } from '../../../../types';
import { useIsDarkMode, useTranslations } from '../../../../utils';
import { useOnDesktop } from '../../../../utils/responsive';
import HomeHeaderInputs from './Inputs/HomeHeaderInputs';
import Responsive from '../../../Responsive/Responsive';
import styles from './HomeHeader.module.scss';

const HomeHeader: React.FC<AssistantProps> = (props) => {
  const {
    assistant: { home: translations },
  } = useTranslations();
  const onDesktop = useOnDesktop();
  const darkMode = useIsDarkMode();

  return (
    <div className={styles.container}>
      <Responsive
        mobileComponent={
          darkMode ? (
            <MobileLogo className={styles.logo} />
          ) : (
            <MobileLogoLight className={styles.logo} />
          )
        }
        desktopComponent={
          <h1 className={styles.title}>{translations.mainTitle}</h1>
        }
      />
      <HomeHeaderInputs {...props} />
      {onDesktop && (
        <Link to={AppRoute.Surveys} className={styles.curationHubBtn}>
          <span>CurationHub</span>
          <LinkIcon className={styles.linkIcon} />
        </Link>
      )}
    </div>
  );
};

export default HomeHeader;
