import React from 'react';
import { CircularProgress, Typography } from '@mui/material';

import { useTranslations } from '../../../utils';
import styles from './Loading.module.scss';

const Loading: React.FC = () => {
  const {
    auth: { loading },
  } = useTranslations();

  return (
    <div className={styles.container}>
      <CircularProgress sx={{ color: (theme) => theme.palette.info.main }} />
      <Typography variant="body1" className={styles.label}>
        {loading}
      </Typography>
    </div>
  );
};
export default Loading;
