import React from 'react';
import { useIsDarkMode } from '../../../../utils/darkMode';

const LoginSpaceSvg: React.FC = () => {
  const isDarkMode = useIsDarkMode();
  const fillColor = isDarkMode ? '#00BDC7' : '#ffffff';
  const opacity = isDarkMode ? 0.2 : 1;
  return (
    <svg
      width="230"
      height="420"
      viewBox="0 0 230 420"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity={opacity}>
        <path
          d="M316.255 315C374.476 257.01 374.476 162.99 316.255 105C258.034 47.0101 163.639 47.0101 105.418 105C47.1974 162.99 47.1974 257.01 105.418 315C163.639 372.99 258.034 372.99 316.255 315Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M144.488 76.8982L132.441 60.0239C132.441 60.0239 98.5576 72.7734 72.957 109.897L88.3927 124.896"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.0944 276.02L60.5293 290.269C60.5293 290.269 80.4827 332.642 127.919 357.016L141.849 342.017"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M162.168 273.758C149.367 285.007 130.167 283.882 118.873 271.133C107.578 258.383 108.708 239.259 121.508 228.01C134.308 216.76 153.509 217.885 164.803 230.635C176.097 243.009 174.968 262.508 162.168 273.758Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M216.386 282.382C208.856 289.132 197.186 288.382 190.409 280.882C183.632 273.382 184.385 261.758 191.915 255.008C199.444 248.258 211.115 249.008 217.892 256.508C224.668 264.008 224.292 275.632 216.386 282.382Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M170.455 133.902C161.796 141.401 148.619 140.651 141.09 132.402C133.56 123.777 134.313 110.653 142.596 103.153C151.255 95.6534 164.431 96.4033 171.961 104.653C179.867 112.903 179.114 126.027 170.455 133.902Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M214.887 94.515C211.122 97.8899 205.475 97.5149 202.086 93.765C198.698 90.0152 199.074 84.3904 202.839 81.0156C206.604 77.6407 212.251 78.0157 215.64 81.7655C218.651 85.5154 218.275 91.1401 214.887 94.515Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M194.939 343.132C191.55 346.132 186.656 345.757 184.021 342.382C181.009 339.007 181.385 334.132 184.774 331.507C188.162 328.508 193.056 328.882 195.692 332.257C198.327 335.632 198.327 340.507 194.939 343.132Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M108.719 164.646C104.578 168.396 98.5542 168.021 94.7894 163.896C91.4011 159.771 91.7776 153.397 95.5424 150.022C99.3072 146.647 105.707 146.647 109.472 150.772C113.237 154.897 112.86 160.896 108.719 164.646Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M217.516 186.389C210.739 192.389 200.574 191.639 194.551 185.264C188.527 178.515 189.28 168.39 195.68 162.39C202.457 156.391 212.622 157.141 218.645 163.515C225.045 169.89 224.292 180.39 217.516 186.389Z"
          stroke={fillColor}
          strokeWidth="6"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default LoginSpaceSvg;
