import React, { useEffect, useState } from 'react';

import { useTranslations } from '../../utils';
import styles from './TextLoader.module.scss';

const CYCLE_DOTS_INTERVAL_MS = 300;

const TextLoader: React.FC = () => {
  const translations = useTranslations();
  const [numberOfDots, setNumberOfDots] = useState(0);
  useEffect(() => {
    const cycleDots = () => {
      setNumberOfDots((prevState) => (prevState === 3 ? 0 : prevState + 1));
    };
    const interval = window.setInterval(cycleDots, CYCLE_DOTS_INTERVAL_MS);

    return () => {
      window.clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles.loadingText}>
      {translations.loading}
      {'.'.repeat(numberOfDots)}
    </div>
  );
};

export default TextLoader;
