import React, { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';

import { Resolution, useMaxResolution } from '../../utils/responsive';
import styles from './Dialog.module.scss';

interface Props {
  open: boolean;
  children: ReactNode;
  handleClose?: () => void;
  keepMounted?: boolean;
  showCloseIcon?: boolean;
  closeOnBackdropClick?: boolean;
  className?: string;
  closeBtnClassName?: string;
  testId?: string;
  dialogClassName?: string;
  fullScreen?: boolean;
}

const AppDialog: React.FC<Props> = ({
  open,
  children,
  className,
  closeBtnClassName,
  testId,
  handleClose,
  closeOnBackdropClick = true,
  keepMounted = false,
  showCloseIcon = true,
  fullScreen,
  dialogClassName,
}) => {
  const onPhone = useMaxResolution(Resolution.Mobile);
  const isFullScreen = fullScreen || onPhone;

  return (
    <Dialog
      className={dialogClassName}
      open={open}
      onClose={closeOnBackdropClick ? handleClose : undefined}
      maxWidth={false}
      data-testid={testId}
      keepMounted={keepMounted}
      fullScreen={isFullScreen}
      PaperProps={{ style: { backgroundImage: 'none' } }}
    >
      <div className={clsx(styles.container, className)}>
        {showCloseIcon && (
          <IconButton
            className={clsx(styles.closeButton, closeBtnClassName)}
            onClick={handleClose}
          >
            ✕
          </IconButton>
        )}
        {children}
      </div>
    </Dialog>
  );
};

export default AppDialog;
