import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppThunkConfig } from '../store';

export const UI_KEY = 'ui';

const LOCAL_STORAGE_DRAWER_MINIMIZED_KEY = 'insightloopIsDrawerMinimized';

export interface UIState {
  drawerMinimized: boolean;
}

const initialState: UIState = {
  drawerMinimized:
    localStorage.getItem(LOCAL_STORAGE_DRAWER_MINIMIZED_KEY) === 'true',
};

export const setDrawerMinimized = createAsyncThunk<
  boolean,
  boolean,
  AppThunkConfig
>('setDrawerMinimized', async (isMinimized) => {
  localStorage.setItem(
    LOCAL_STORAGE_DRAWER_MINIMIZED_KEY,
    isMinimized.toString(),
  );

  return isMinimized;
});

const uiSlice = createSlice({
  name: UI_KEY,
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(setDrawerMinimized.fulfilled, (state, action) => {
      state.drawerMinimized = action.payload;
    });
  },
});

const { actions, reducer } = uiSlice;

export const { reset: resetUI } = actions;

export const uiReducer = reducer;
