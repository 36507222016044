interface Partial {
  index: number;
  answer: string;
}

/**
 * Partials answers come in non-deterministic order from the backend, so we need to make sure that:
 * 1. The first part is always displayed first
 * 2. We display parts without gaps, e.g. part 4 cannot follow part 2
 * @param partials
 */
export const processStreamingPartials = (partials: Partial[]): string => {
  if (partials.length === 0) {
    return '';
  }

  const partialsInOrder = partials
    .slice()
    .sort((partA, partB) => partA.index - partB.index);

  if (partialsInOrder[0].index !== 0) {
    return '';
  }

  const answers: string[] = [partialsInOrder[0].answer];
  for (let i = 1; i < partialsInOrder.length; i++) {
    const currentPart = partialsInOrder[i];
    const prevPart = partialsInOrder[i - 1];
    if (currentPart.index !== prevPart.index + 1) {
      return answers.join('');
    }
    answers.push(currentPart.answer);
  }

  return answers.join('');
};
