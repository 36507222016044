import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AtLeast,
  BaseSessionProps,
  EquipmentConversationDto,
  EquipmentHistorySession,
  EquipmentSession,
  FeedbackType,
  FetchingStatus,
  Symptom,
  TroubleshootingStatus,
} from '../../types';
import { useAppSelector } from '../utils/hooks';
import { generateSymptom, isDefined } from '../../utils';
import { deleteSessions } from '../../integration/conversations.api';
import { ApiThunkParams, AppThunkConfig } from '../store';

export const CONVERSATIONS_KEY = 'conversations';

export interface ConversationsState {
  sessions: EquipmentSession[];
  focusedSessionId: string | null;
  status: FetchingStatus;
}

const initialState: ConversationsState = {
  sessions: [],
  focusedSessionId: null,
  status: FetchingStatus.IDLE,
};

export interface CreateSessionParams extends BaseSessionProps {
  question: string;
  equipmentType: string;
  allEquipmentTags: string[];
  userServiceExpert: boolean;
  troubleshootingOpen: boolean;
}

const generateEquipmentSession = ({
  userServiceExpert,
  conversationId,
  sessionId,
  question,
  equipmentType,
  allEquipmentTags,
  troubleshootingOpen,
}: CreateSessionParams): EquipmentSession => ({
  question,
  equipmentType,
  allEquipmentTags,
  conversationId,
  id: sessionId,
  extractedSymptom: null,
  extractedSymptomStatus: userServiceExpert
    ? FetchingStatus.PENDING
    : FetchingStatus.SUCCESS,
  savedToHistory: false,
  createdAt: Date.now(),
  rootCause: null,
  rootCauseSources: null,
  rootCauseStatus: userServiceExpert
    ? FetchingStatus.PENDING
    : FetchingStatus.SUCCESS,
  parts: null,
  images: null,
  imagesStatus: userServiceExpert
    ? FetchingStatus.PENDING
    : FetchingStatus.SUCCESS,
  documentSearchAnswer: null,
  documentSearchSources: null,
  documentSearchStatus: FetchingStatus.PENDING,
  suggestions: null,
  suggestionsStatus: FetchingStatus.PENDING,
  jobs: null,
  jobsStatus: userServiceExpert
    ? FetchingStatus.PENDING
    : FetchingStatus.SUCCESS,
  feedback: {
    type: FeedbackType.NONE,
    open: false,
  },
  troubleshootingVisible: troubleshootingOpen,
  troubleshootingStatus: TroubleshootingStatus.Idle,
  troubleshootingSymptoms: [],
  troubleshootingSymptomsStatus: FetchingStatus.IDLE,
  troubleshootingSelectedSymptoms: [],
  troubleshootingRelatedSymptoms: null,
  diagnosisNodes: null,
  diagnosisStatus: FetchingStatus.IDLE,
  qnaSteps: null,
  resolvedRootCauses: null,
  rootCauses: null,
  openedRootCauseId: null,
  finalRootCause: null,
});

interface DeleteEquipmentSessionParams extends ApiThunkParams {
  ids: string[];
}

export const deleteEquipmentSession = createAsyncThunk<
  void,
  DeleteEquipmentSessionParams,
  AppThunkConfig
>(
  'deleteEquipmentSession',
  async ({ ids, baseUrl, mock, logError }, { signal }) => {
    await deleteSessions({
      ids,
      baseUrl,
      mock,
      signal,
      logError,
    });
  },
);

export interface AddRootCausePartialParams {
  sessionId: string;
  partial: string;
}

// We need this for backward compatibility
const processTroubleshootingSelectedSymptoms = ({
  troubleshootingSelectedSymptoms,
  extractedSymptom,
  question,
}: EquipmentConversationDto): Symptom[] => {
  if (
    !isDefined(troubleshootingSelectedSymptoms) ||
    troubleshootingSelectedSymptoms.some(
      (symptom) => typeof symptom === 'string',
    )
  ) {
    return [generateSymptom(extractedSymptom || question)];
  } else {
    return troubleshootingSelectedSymptoms as Symptom[];
  }
};

const conversationsSlice = createSlice({
  name: CONVERSATIONS_KEY,
  initialState,
  reducers: {
    createEquipmentSession: (
      state,
      action: PayloadAction<CreateSessionParams>,
    ) => {
      return {
        ...state,
        focusedSessionId: action.payload.sessionId,
        sessions: [...state.sessions, generateEquipmentSession(action.payload)],
      };
    },
    updateEquipmentSession: (
      state,
      action: PayloadAction<AtLeast<EquipmentSession, 'id'>>,
    ) => {
      const updatedSession = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id === updatedSession.id) {
            return {
              ...session,
              ...updatedSession,
            };
          } else {
            return session;
          }
        }),
      };
    },
    setFocusedSession: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        focusedSessionId: action.payload,
      };
    },
    focusOnFirstSession: (state, action: PayloadAction<string>) => {
      const conversationId = action.payload;
      // This works only because new sessions are always added to the end of the list of sessions, their order is never changed
      const firstSession = state.sessions.find(
        (session) => session.conversationId === conversationId,
      );
      if (!isDefined(firstSession)) {
        return state;
      }

      return {
        ...state,
        focusedSessionId: firstSession.id,
      };
    },
    addRootCausePartial: (
      state,
      action: PayloadAction<AddRootCausePartialParams>,
    ) => {
      const { sessionId, partial } = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          // Ignore stray parts arriving after the final answer
          if (
            session.id === sessionId &&
            session.rootCauseStatus === FetchingStatus.PENDING
          ) {
            return {
              ...session,
              rootCause: partial,
            };
          } else {
            return session;
          }
        }),
      };
    },
    addEquipmentHistory: (
      state,
      action: PayloadAction<EquipmentHistorySession[]>,
    ) => {
      return {
        ...state,
        sessions: [
          ...state.sessions,
          ...action.payload.map(({ id, documents, mediaFiles, content }) => {
            const freshImageUrls = new Map(
              mediaFiles.map((mf) => [mf.id, mf.link]),
            );
            const images =
              content.images !== null
                ? content.images.map((image) => ({
                    ...image,
                    url: freshImageUrls.get(image.id) || '',
                  }))
                : null;
            const freshDocumentUrls = new Map(
              documents.map((doc) => [doc.id, doc.link]),
            );
            const documentSearchSources =
              content.documentSearchSources !== null
                ? content.documentSearchSources.map((sourceDocument) => ({
                    ...sourceDocument,
                    metadata: {
                      ...sourceDocument.metadata,
                      sources: sourceDocument.metadata.sources.map(
                        (source) => ({
                          ...source,
                          link: freshDocumentUrls.get(source.documentId) || '',
                        }),
                      ),
                    },
                  }))
                : null;

            return {
              id,
              images,
              documentSearchSources,
              savedToHistory: true,
              conversationId: content.conversationId,
              createdAt: content.createdAt,
              question: content.question,
              equipmentType: content.equipmentType,
              allEquipmentTags: content.allEquipmentTags || [
                content.equipmentType,
              ],
              extractedSymptom: content.extractedSymptom || content.question,
              extractedSymptomStatus: FetchingStatus.SUCCESS,
              rootCause: content.rootCause,
              rootCauseSources: content.rootCauseSources,
              rootCauseStatus: FetchingStatus.SUCCESS,
              parts: null,
              documentSearchAnswer: content.documentSearchAnswer,
              documentSearchStatus: FetchingStatus.SUCCESS,
              suggestions: content.suggestions,
              suggestionsStatus: FetchingStatus.SUCCESS,
              jobs: content.jobs,
              jobsStatus: FetchingStatus.SUCCESS,
              imagesStatus: FetchingStatus.SUCCESS,
              feedback: {
                type: content.feedback.type,
                open: false,
              },
              troubleshootingVisible: false,
              troubleshootingStatus:
                content.troubleshootingStatus || TroubleshootingStatus.Idle,
              troubleshootingSymptoms: [],
              troubleshootingSymptomsStatus: FetchingStatus.IDLE,
              troubleshootingSelectedSymptoms:
                processTroubleshootingSelectedSymptoms(content),

              // TODO: add to history - except what we don't need because it gets automatically reloaded
              diagnosisNodes: null,
              diagnosisStatus: FetchingStatus.IDLE,
              qnaSteps: null,
              resolvedRootCauses: null,
              rootCauses: null,
              openedRootCauseId: null,
              troubleshootingRelatedSymptoms: null,

              finalRootCause: content.finalRootCause || null,
            };
          }),
        ],
      };
    },
    reset: (state) => ({
      ...state,
      sessions: [],
      focusedSessionId: null,
    }),
  },
  extraReducers(builder) {
    builder.addCase(deleteEquipmentSession.pending, (state, action) => {
      const ids = new Set(action.meta.arg.ids);
      return {
        ...state,
        sessions: state.sessions.filter((session) => !ids.has(session.id)),
      };
    });
  },
});

const { actions, reducer } = conversationsSlice;

export const {
  createEquipmentSession,
  updateEquipmentSession,
  setFocusedSession,
  focusOnFirstSession,
  addRootCausePartial,
  addEquipmentHistory,
  reset: resetConversations,
} = actions;

export const useConversation = (id?: string): EquipmentSession[] => {
  const { sessions } = useAppSelector((state) => state.conversations);

  return sessions.filter((session) => session.conversationId === id);
};

export const conversationsReducer = reducer;
