import { configureStore } from '@reduxjs/toolkit';

import {
  TRENDING_SYMPTOMS_KEY,
  assistantTrendingSymptomsReducer,
} from './slices/trendingSymptoms.slice';
import { STREAMING_KEY, streamingReducer } from './slices/streaming.slice';
import {
  CONVERSATIONS_KEY,
  conversationsReducer,
} from './slices/conversations.slice';
import { INPUTS_KEY, inputsReducer } from './slices/inputs.slice';
import { OPEN_JOBS_KEY, openJobsReducer } from './slices/openJobs.slice';
import {
  FORCE_FEEDBACK_KEY,
  forceFeedbackReducer,
} from './slices/forceFeedback.slice';
import { ANALYTICS_KEY, analyticsReducer } from './slices/analytics.slice';
import {
  CUSTOMER_DETAILS_KEY,
  customerDetailsReducer,
} from './slices/customerDetails.slice';
import { SURVEYS_KEY, surveysReducer } from './slices/surveys.slice';
import {
  SURVEY_DETAILS_KEY,
  surveyDetailsReducer,
} from './slices/surveyDetails.slice';
import { HISTORY_KEY, historyReducer } from './slices/history.slice';
import { DOCUMENTS_KEY, documentsReducer } from './slices/documents.slice';
import {
  EQUIPMENT_TAGS_KEY,
  equipmentTagsReducer,
} from './slices/equipmentTags.slice';
import {
  USER_PROFILE_KEY,
  userProfileReducer,
} from './slices/userProfile.slice';
import { UI_KEY, uiReducer } from './slices/ui.slice';

const reducers = {
  [TRENDING_SYMPTOMS_KEY]: assistantTrendingSymptomsReducer,
  [OPEN_JOBS_KEY]: openJobsReducer,
  [STREAMING_KEY]: streamingReducer,
  [CONVERSATIONS_KEY]: conversationsReducer,
  [INPUTS_KEY]: inputsReducer,
  [FORCE_FEEDBACK_KEY]: forceFeedbackReducer,
  [ANALYTICS_KEY]: analyticsReducer,
  [CUSTOMER_DETAILS_KEY]: customerDetailsReducer,
  [SURVEYS_KEY]: surveysReducer,
  [SURVEY_DETAILS_KEY]: surveyDetailsReducer,
  [HISTORY_KEY]: historyReducer,
  [DOCUMENTS_KEY]: documentsReducer,
  [EQUIPMENT_TAGS_KEY]: equipmentTagsReducer,
  [USER_PROFILE_KEY]: userProfileReducer,
  [UI_KEY]: uiReducer,
};

export const store = configureStore({ reducer: reducers });

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export interface AppThunkConfig {
  dispatch: AppDispatch;
  state: RootState;
}

export interface ApiThunkParams {
  mock: boolean;
  baseUrl: string;
  logError: (msg: string) => void;
}

// This is going to be used to provide a store for integration tests.
export const createAppStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: reducers,
  });
};
