import api from './api';
import { ApiFetchParams, ApiResponse, TimeRange } from '../types';

export interface TrendingSymptomResponseMetric {
  jobscount: number;
  symptom: string;
}

export interface TrendingSymptomMetric {
  jobCount: number;
  symptom: string;
}

type TrendingSymptomsResponse = ApiResponse<TrendingSymptomResponseMetric[]>;

interface FetchTrendingSymptomsParams extends ApiFetchParams {
  customerId: string;
}

export const fetchTrendingSymptoms = async ({
  baseUrl,
  customerId,
  mock,
  signal,
}: FetchTrendingSymptomsParams): Promise<TrendingSymptomMetric[]> => {
  const { data } = await api.get<TrendingSymptomsResponse>({
    baseUrl,
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      customerId,
      timeRange: TimeRange.ONE_YEAR,
      type: 'TopSymptoms',
      withTrend: false,
    },
    config: { signal },
  });

  return data.map((metric) => ({
    jobCount: metric.jobscount,
    symptom: metric.symptom,
  }));
};
