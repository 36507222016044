import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchSuggestions } from '../../integration/suggestions.api';
import { SupportedLanguage } from '../../translations/common';
import { FetchingStatus } from '../../types';
import { updateEquipmentSession } from './conversations.slice';

interface GetSuggestionsParams extends ApiThunkParams {
  sessionId: string;
  equipmentType: string;
  language: SupportedLanguage;
  question: string;
}

export const getSuggestions = createAsyncThunk<
  void,
  GetSuggestionsParams,
  AppThunkConfig
>(
  'getSuggestions',
  async (
    { baseUrl, sessionId, equipmentType, language, mock, question, logError },
    { signal, dispatch },
  ) => {
    try {
      const suggestions = await fetchSuggestions({
        baseUrl,
        equipmentType,
        language,
        mock,
        question,
        signal,
      });
      dispatch(
        updateEquipmentSession({
          suggestions: suggestions.slice(0, 3),
          suggestionsStatus: FetchingStatus.SUCCESS,
          id: sessionId,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch suggestions for ${question} Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          suggestions: null,
          suggestionsStatus: FetchingStatus.ERROR,
          id: sessionId,
        }),
      );
    }
  },
);
