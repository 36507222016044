import api from './api';
import {
  ApiFetchParams,
  ApiResponse,
  Symptom,
  SymptomSearchResults,
} from '../types';
import { delay } from '../utils';
import { getRelatedSymptomsSeed } from '../mocks/seed/relatedSymptoms.seed';

type SymptomsSearchResponse = ApiResponse<SymptomSearchResults>;

interface FetchSymptomsParams extends ApiFetchParams {
  equipmentType: string;
  searchTerm: string;
}

export const fetchSymptoms = async ({
  equipmentType,
  searchTerm,
  baseUrl,
  signal,
  mock,
}: FetchSymptomsParams): Promise<Symptom[]> => {
  const { data } = await api.get<SymptomsSearchResponse>({
    baseUrl,
    mock,
    path: '/troubleshooting/api/v2/symptoms/actions/search',
    queryParams: {
      equipmentType,
      name: searchTerm,
    },
    config: { signal },
  });

  return data.symptoms;
};

interface FetchRelatedSymptomsParams extends ApiFetchParams {
  confirmedSymptoms: string[];
  equipmentType: string;
  initialSymptom?: string;
}

export const fetchRelatedSymptoms = async ({
  confirmedSymptoms,
  equipmentType,
  initialSymptom,
  baseUrl,
  signal,
  mock,
}: FetchRelatedSymptomsParams): Promise<Symptom[]> => {
  if (mock) {
    await delay(1500);
    return getRelatedSymptomsSeed(initialSymptom || '');
  }

  const { data } = await api.get<SymptomsSearchResponse>({
    baseUrl,
    mock,
    path: '/troubleshooting/api/v2/symptoms',
    queryParams: {
      equipmentType,
      confirmedSymptoms,
    },
    config: { signal },
  });

  return data.symptoms;
};
