import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import clsx from 'clsx';

import styles from './LoadingOverlay.module.scss';

interface Props {
  className?: string;
}

const LoadingOverlay: React.FC<Props> = ({ className }) => {
  return (
    <Skeleton
      animation="wave"
      variant="rectangular"
      className={clsx(styles.loading, className)}
    />
  );
};

export default LoadingOverlay;
