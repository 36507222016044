import { RawJob } from '../../types';
import { getDay } from '../../utils';

export const dashboardOpenJobsSeed: RawJob[] = [
  {
    id: 'open_job_1',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Pitch bearing failure',
    code: '8790',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_2',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Irregular Blade Movement',
    code: '8782',
    equipment_type: 'WT 3.x-103',
    created: getDay(2),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_3',
    customer_id: 'customer2',
    customer_name: 'Borssele Sites I',
    description: 'Generator failure',
    code: '8770',
    equipment_type: 'WT 3.x-137',
    created: getDay(4),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to Overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_4',
    customer_id: 'customer3',
    customer_name: 'ESB Offshore',
    description: 'Irregular Blade Movement',
    code: '8712',
    equipment_type: 'WT 2-B Energy',
    created: getDay(6),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_5',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Pitch bearing failure',
    code: '8549',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(50),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_6',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Irregular Blade Movement',
    code: '8548',
    equipment_type: 'WT 3.x-103',
    created: getDay(51),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_7',
    customer_id: 'customer2',
    customer_name: 'Borssele Sites I',
    description: 'Generator failure',
    code: '8477',
    equipment_type: 'WT 3.x-137',
    created: getDay(63),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Electrical fault due to Overheating or a converter failure caused by cooling system.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
  {
    id: 'open_job_8',
    customer_id: 'customer3',
    customer_name: 'ESB Offshore',
    description: 'Irregular Blade Movement',
    code: '8453',
    equipment_type: 'WT 2-B Energy',
    created: getDay(65),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Switzerland',
  },
  {
    id: 'open_job_9',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Pitch bearing failure',
    code: '8450',
    equipment_type: 'WT 8.0-167 DD',
    created: getDay(65),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Potential PCS malfunction, rolling element fatigue or bearing seal issue.',
    },
    symptoms: null,
    division_name: 'Germany',
  },
  {
    id: 'open_job_10',
    customer_id: 'customer1',
    customer_name: 'UK Onshore WF',
    description: 'Irregular Blade Movement',
    code: '8425',
    equipment_type: 'WT 3.x-103',
    created: getDay(66),
    ended: null,
    started: null,
    closed: null,
    predictions: {
      prediction:
        'Control system failure, such as issues with the yaw mechanism leading to misalignment of the blades.',
    },
    symptoms: null,
    division_name: 'Spain',
  },
];
