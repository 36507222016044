import React from 'react';

import { SessionProps } from '../../../../../types';
import {
  isFetchingResolved,
  areDocumentResultsEmptyOrError,
  isRootCauseEmptyOrError,
  useTranslations,
  isSessionAnswered,
} from '../../../../../utils';
import { ReactComponent as AnswerIcon } from './assets/answer.svg';
import CollapsibleCard from '../../../../Card/Collapsible/CollapsibleCard';
import RootCauses from './RootCauses/RootCauses';
import DocumentSearchResults from './DocumentSearchResults/DocumentSearchResults';
import Feedback from './Feedback/Feedback';
import FeedbackForm from './Feedback/Form/FeedbackForm';
import Responsive from '../../../../Responsive/Responsive';
import AppDialog from '../../../../Dialog';
import DashboardNoData from './NoData/DashboardNoData';
import styles from './EquipmentSessionAnswer.module.scss';

const EquipmentSessionAnswer: React.FC<SessionProps> = (props) => {
  const { session } = props;
  const { rootCauseStatus } = session;

  const {
    assistant: { dashboard: translations },
  } = useTranslations();
  const title = {
    text: translations.answer,
    icon: <AnswerIcon className={styles.titleIcon} />,
  };
  const noData =
    isRootCauseEmptyOrError(session) && areDocumentResultsEmptyOrError(session);

  return (
    <div>
      <CollapsibleCard title={title} openByDefault>
        <RootCauses {...props} />
        {isFetchingResolved(rootCauseStatus) && (
          <DocumentSearchResults {...props} />
        )}
        {noData && <DashboardNoData {...props} />}
        {isSessionAnswered(session) && <Feedback {...props} />}
      </CollapsibleCard>
      {session.feedback.open && (
        <Responsive
          mobileComponent={
            <AppDialog
              open
              showCloseIcon={false}
              className={styles.feedbackDialog}
            >
              <FeedbackForm {...props} />
            </AppDialog>
          }
          desktopComponent={<FeedbackForm {...props} />}
        />
      )}
    </div>
  );
};

export default EquipmentSessionAnswer;
