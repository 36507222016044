import React from 'react';

import { SessionProps } from '../../../../../types';
import PastJobs from '../../../../PastJobs/PastJobs';

const DashboardSessionPastJobs: React.FC<SessionProps> = (props) => {
  return (
    <PastJobs
      {...props}
      jobs={props.session.jobs}
      status={props.session.jobsStatus}
    />
  );
};

export default DashboardSessionPastJobs;
