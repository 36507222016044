import React, { useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import InputAdornment from '@mui/material/InputAdornment';
import { inputClasses } from '@mui/material';
import debounce from 'lodash.debounce';

import {
  ApiCall,
  AssistantProps,
  FetchingStatus,
} from '../../../../../../types';
import DocumentDragAndDrop from './DragAndDrop/DocumentDragAndDrop';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../store/utils/hooks';
import { ReactComponent as SearchIcon } from '../../../../../../assets/search.svg';
import { isDefined, useTranslations } from '../../../../../../utils';
import { ApiThunkParams } from '../../../../../../store/store';
import {
  resetEquipmentTags,
  searchEquipmentTags,
} from '../../../../../../store/slices/equipmentTags.slice';
import styles from './DocumentUploadInputs.module.scss';

interface Props extends AssistantProps {
  setFiles: (files: File[]) => void;
}

const DocumentUploadInputs: React.FC<Props> = ({ setFiles, ...props }) => {
  const { httpUrl, isMocking, logError } = props;
  const { assistant: translations } = useTranslations();
  const dispatch = useAppDispatch();
  const { equipmentTypes, equipmentSearchStatus } = useAppSelector(
    (state) => state.equipmentTags,
  );
  const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    dispatch(resetEquipmentTags());
  }, [dispatch]);

  const [fetchPromise, setFetchPromise] =
    useState<ApiCall<string[], ApiThunkParams>>();
  const getEquipmentTypes = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (isDefined(fetchPromise)) {
        fetchPromise.abort();
      }
      if (value.length >= 2) {
        const promise = dispatch(
          searchEquipmentTags({
            logError,
            baseUrl: httpUrl,
            mock: isMocking,
            searchTerm: value,
          }),
        );
        setFetchPromise(promise);
      }
    }, 300),
    [dispatch, fetchPromise, isMocking, httpUrl],
  );
  const handleInputChange = (_: React.SyntheticEvent, value: string) => {
    getEquipmentTypes(value);
  };

  const noResultsFound =
    equipmentSearchStatus === FetchingStatus.SUCCESS &&
    equipmentTypes.length === 0;

  return (
    <div>
      <Autocomplete
        multiple
        freeSolo
        className={styles.input}
        options={equipmentTypes}
        value={tags}
        onChange={(_, values) => setTags(values)}
        onInputChange={handleInputChange}
        loading={equipmentSearchStatus === FetchingStatus.PENDING}
        loadingText={translations.filterLoading}
        noOptionsText={noResultsFound && translations.filterNoResults}
        filterOptions={(x) => x}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={translations.documentManagement.tagsPlaceholder}
            variant="standard"
            sx={{
              [`& .${inputClasses.root}`]: {
                paddingBottom: '12px',
              },
              [`& .${inputClasses.underline}:after`]: {
                borderBottomColor: '#808080',
              },
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  <InputAdornment position="start">
                    <SearchIcon className={styles.searchIcon} />
                  </InputAdornment>
                </>
              ),
            }}
          />
        )}
      />
      <DocumentDragAndDrop {...props} tags={tags} setFiles={setFiles} />
    </div>
  );
};

export default DocumentUploadInputs;
