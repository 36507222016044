import React, { useState } from 'react';
import clsx from 'clsx';

import { AssistantProps } from '../../../../../types';
import { ReactComponent as UploadIcon } from '../assets/upload.svg';
import DocumentUploadInputs from './Inputs/DocumentUploadInputs';
import DocumentUploadStatus from './Status/DocumentUploadStatus';
import { useAppSelector } from '../../../../../store/utils/hooks';
import { useDocumentUploadFinished } from '../../../../../store/slices/documents.slice';
import { useIsDarkMode, useTranslations } from '../../../../../utils';
import styles from './DocumentUpload.module.scss';

interface Props extends AssistantProps {
  onClose: (uploadHappened: boolean) => void;
}

const DocumentUpload: React.FC<Props> = ({ onClose, ...props }) => {
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();
  const { newDocuments } = useAppSelector((state) => state.documents);
  const isDocumentUploadFinished = useDocumentUploadFinished();
  const isUploadInProgress = newDocuments.length > 0;
  const darkMode = useIsDarkMode();
  // We store the files here, as they cannot be in the Redux store (it cannot hold non-serializable values).
  // We may need them later in case a file needs to be re-uploaded.
  const [files, setFiles] = useState<File[]>([]);

  return (
    <div className={styles.container}>
      <div className={clsx(styles.headline, darkMode && styles.darkMode)}>
        <UploadIcon className={styles.icon} />
        <h3 className={styles.title}>{translations.uploadDialogTitle}</h3>
      </div>
      <div className={styles.content}>
        {isUploadInProgress ? (
          <DocumentUploadStatus {...props} files={files} />
        ) : (
          <DocumentUploadInputs {...props} setFiles={setFiles} />
        )}
        <div className={styles.btnContainer}>
          <button
            className={styles.cancelBtn}
            onClick={() => onClose(isUploadInProgress)}
            disabled={!isDocumentUploadFinished}
          >
            {isUploadInProgress ? translations.close : translations.cancel}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocumentUpload;
