import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { scrollToSession } from '../../utils/scrollToSession';
import { AppThunkConfig } from '../store';

export const FORCE_FEEDBACK_KEY = 'forceFeedback';

const DEFAULT_QUESTIONS_TO_FORCE_FEEDBACK = 5;
const QUESTIONS_TO_FORCE_FEEDBACK_AFTER_POSITIVE_FEEDBACK = 10;
const QUESTIONS_TO_FORCE_FEEDBACK_AFTER_NEGATIVE_FEEDBACK = 5;
const QUESTIONS_TO_FORCE_FEEDBACK_AFTER_DISMISS = 15;

export interface ForceFeedbackState {
  show: boolean;
  questionsToForceFeedback: number;
}

const initialState: ForceFeedbackState = {
  show: false,
  questionsToForceFeedback: DEFAULT_QUESTIONS_TO_FORCE_FEEDBACK,
};

interface RegisterNewSessionParams {
  scrollContainerRef: HTMLDivElement | null;
  sessionId: string;
}

export const registerNewSession = createAsyncThunk<
  void,
  RegisterNewSessionParams,
  AppThunkConfig
>(
  'registerNewSession',
  async ({ sessionId, scrollContainerRef }, { getState }) => {
    const {
      forceFeedback: { questionsToForceFeedback },
    } = getState();
    if (scrollContainerRef !== null && questionsToForceFeedback - 1 === 0) {
      scrollToSession({ sessionId, scrollContainerRef, behavior: 'auto' });
    }
  },
);

const forceFeedbackSlice = createSlice({
  name: FORCE_FEEDBACK_KEY,
  initialState,
  reducers: {
    dismissForceFeedback: (state) => ({
      ...state,
      show: false,
      questionsToForceFeedback: QUESTIONS_TO_FORCE_FEEDBACK_AFTER_DISMISS,
    }),
    registerPositiveFeedback: (state) => ({
      ...state,
      questionsToForceFeedback:
        QUESTIONS_TO_FORCE_FEEDBACK_AFTER_POSITIVE_FEEDBACK,
    }),
    registerNegativeFeedback: (state) => ({
      ...state,
      questionsToForceFeedback:
        QUESTIONS_TO_FORCE_FEEDBACK_AFTER_NEGATIVE_FEEDBACK,
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(registerNewSession.fulfilled, (state) => {
      if (state.questionsToForceFeedback - 1 === 0) {
        return {
          ...state,
          show: true,
          questionsToForceFeedback: 0,
        };
      } else {
        return {
          ...state,
          questionsToForceFeedback: state.questionsToForceFeedback - 1,
        };
      }
    });
  },
});

const { actions, reducer } = forceFeedbackSlice;

export const {
  dismissForceFeedback,
  registerPositiveFeedback,
  registerNegativeFeedback,
  reset: resetForceFeedback,
} = actions;

export const forceFeedbackReducer = reducer;
