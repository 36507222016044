import { EquipmentSession, FetchingStatus } from '../types';
import { isFetchingResolved } from './isFetchingResolved';
import { knowledgeBaseDefaultAnswers } from '../store/utils/defaultAnswers';

export const isRootCauseEmptyOrError = ({
  rootCause,
  rootCauseStatus,
}: EquipmentSession): boolean => {
  if (!isFetchingResolved(rootCauseStatus)) {
    return false;
  }
  const isDefaultAnswer = knowledgeBaseDefaultAnswers.some(
    (answer) => answer === rootCause,
  );

  return (
    rootCause === null ||
    isDefaultAnswer ||
    rootCauseStatus === FetchingStatus.ERROR
  );
};

export const areDocumentResultsEmptyOrError = ({
  documentSearchAnswer,
  documentSearchSources,
  documentSearchStatus,
}: EquipmentSession): boolean => {
  if (!isFetchingResolved(documentSearchStatus)) {
    return false;
  }
  const isDefaultAnswer = knowledgeBaseDefaultAnswers.some(
    (answer) => answer === documentSearchAnswer,
  );
  const isSummaryEmpty = documentSearchAnswer === null || isDefaultAnswer;
  const noDocuments =
    documentSearchSources === null || documentSearchSources.length === 0;
  const noResults = isSummaryEmpty && noDocuments;

  return noResults || documentSearchStatus === FetchingStatus.ERROR;
};

export const isSessionFullyLoaded = (session: EquipmentSession): boolean => {
  return isFetchingResolved(
    session.rootCauseStatus,
    session.documentSearchStatus,
    session.jobsStatus,
    session.imagesStatus,
    session.suggestionsStatus,
    session.extractedSymptomStatus,
  );
};

export const isSessionAnswered = (session: EquipmentSession): boolean => {
  return isFetchingResolved(
    session.rootCauseStatus,
    session.documentSearchStatus,
    session.extractedSymptomStatus,
  );
};
