import React from 'react';

import { CustomerSessionProps } from '../../../../types';
import PastJobs from '../../../PastJobs/PastJobs';

const CustomerPastJobs: React.FC<CustomerSessionProps> = (props) => {
  const { session } = props;
  const { pastJobs, pastJobsStatus } = session;

  return <PastJobs {...props} jobs={pastJobs} status={pastJobsStatus} />;
};

export default CustomerPastJobs;
