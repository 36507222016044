import api from './api';
import { ApiFetchParams, ApiResponse, JobStatus, PastJob } from '../types';

const MAX_JOBS_TO_DISPLAY = 5;

interface FetchPastJobsParams extends ApiFetchParams {
  customerId: string;
}

export const fetchPastJobs = async ({
  baseUrl,
  customerId,
  mock,
  signal,
}: FetchPastJobsParams): Promise<PastJob[]> => {
  const { data } = await api.get<ApiResponse<PastJob[]>>({
    baseUrl,
    mock,
    path: '/domain-model/api/v1/jobs',
    queryParams: {
      customerId,
      jobStatus: JobStatus.Closed,
      jobType: 'All', // temporary solution, we need this to get all types of jobs back
      limit: MAX_JOBS_TO_DISPLAY,
      sort: 'created,desc',
    },
    config: { signal },
  });

  return data;
};
