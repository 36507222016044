import { Server } from 'mock-socket';

import { webSocketHandlers } from './web-socket-handlers';
import { WebSocketQuery } from '../integration/webSocketClient';

interface WsMockServerParams {
  wsUrl: string;
  logError: (msg: string) => void;
  logInfo: (msg: string) => void;
}

const createWebSocketMockServer = ({
  wsUrl,
  logError,
  logInfo,
}: WsMockServerParams) => {
  const mockServer = new Server(wsUrl);

  mockServer.on('connection', (socket) => {
    logInfo('WebSocket mock server connected.');

    socket.on('message', (message) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const parsedMessage: WebSocketQuery<any> = JSON.parse(message as string);
      webSocketHandlers.forEach((handler) => {
        if (handler.action === parsedMessage.action) {
          handler.handle(parsedMessage.payload, socket);
        }
      });
    });
    socket.on('close', () => {
      logInfo('WebSocket mock server disconnected.');
    });
    socket.on('error', (err) => {
      logError(`WebSocket mock server error: ${err.message}`);
    });
  });

  return mockServer;
};

let webSocketMockServer: Server | null = null;

export const enableWSMockServer = (params: WsMockServerParams): void => {
  disableWSMockServer(params);
  try {
    webSocketMockServer = createWebSocketMockServer(params);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    params.logError(`WS mock server could not be started: ${error?.message}`);
  }
};

export const disableWSMockServer = (params: WsMockServerParams): void => {
  if (webSocketMockServer !== null) {
    try {
      webSocketMockServer.close();
      webSocketMockServer.stop();
      webSocketMockServer = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      params.logError(`WS mock server could not be stopped: ${error?.message}`);
    }
  }
};
