import { EquipmentSession } from '../types';

export const getSecondsSinceLastQuestion = (
  sessions: EquipmentSession[],
): number => {
  if (sessions.length === 0) {
    return 0;
  }
  const lastSessionCreatedAt = sessions[sessions.length - 1].createdAt;
  const now = Date.now();

  return Math.floor((now - lastSessionCreatedAt) / 1000);
};
