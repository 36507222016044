import { useLocation } from 'react-router-dom';

import { AppRoute } from '../types';

export const useCurrentRoute = (): AppRoute | null => {
  const { pathname } = useLocation();

  if (pathname === AppRoute.Home) {
    return AppRoute.Home;
  }
  if (pathname.match(AppRoute.Conversations)) {
    return AppRoute.Conversations;
  }
  if (pathname.match(AppRoute.Customer)) {
    return AppRoute.Customer;
  }
  if (pathname.match(AppRoute.Surveys)) {
    return AppRoute.Surveys;
  }
  return null;
};
