import React, { ReactNode } from 'react';
import Skeleton from '@mui/material/Skeleton';

import { FetchingStatus, JobWithPrediction } from '../../../../types';
import { useTranslations } from '../../../../utils';
import NoData from '../../../Card/NoData/NoData';
import Error from '../../../Card/Error/Error';
import styles from './JobList.module.scss';

interface Props {
  jobs: JobWithPrediction[] | null;
  status: FetchingStatus;
  children: (props: JobWithPrediction[]) => ReactNode;
}

const JobList: React.FC<Props> = ({ jobs, status, children }) => {
  const noDataFound = jobs === null || jobs.length === 0;
  const { noData } = useTranslations();

  switch (status) {
    case FetchingStatus.PENDING:
      return (
        <Skeleton
          animation="wave"
          variant="rectangular"
          className={styles.loading}
        />
      );
    case FetchingStatus.ERROR:
      return <Error />;
    case FetchingStatus.SUCCESS:
      if (noDataFound) {
        return <NoData message={noData.openJobs} />;
      } else {
        return <>{children(jobs)}</>;
      }
    default:
      return null;
  }
};

export default JobList;
