import { SourceDocument } from '../integration/webSocketClient';
import { DocumentType } from '../types';
import { isDefined } from './isDefined';

export const isDocumentFault = (document: SourceDocument): boolean => {
  const documentIsTypeFault =
    document.metadata.doc_type === 'fault' ||
    document.metadata.doc_type === 'ticket';
  const documentContainsJobCode =
    isDefined(document.metadata.code) || isDefined(document.metadata.source);

  return documentIsTypeFault && documentContainsJobCode;
};

export const getFaultJobCode = (document: SourceDocument): string => {
  if (isDefined(document.metadata.code)) {
    return document.metadata.code;
  } else if (isDefined(document.metadata.source)) {
    return document.metadata.source;
  } else {
    throw new Error('Fault did not contain job ID!');
  }
};

export const getFaultJobId = (document: SourceDocument): string => {
  if (isDefined(document.metadata.job_id)) {
    return document.metadata.job_id;
  } else {
    return getFaultJobCode(document);
  }
};

export const getDocumentType = (source: string): DocumentType => {
  if (/\.pdf/i.test(source)) {
    return DocumentType.Pdf;
  }
  if (/\.xml/i.test(source)) {
    return DocumentType.Xml;
  }
  if (/\.html/i.test(source)) {
    return DocumentType.Html;
  }
  return DocumentType.None;
};
