import api from './api';
import {
  ApiFetchParams,
  FetchOpenJobsResults,
  JobStatus,
  OpenJobsFilterField,
  OpenJobsFilter,
  OpenJobsRequestParams,
  OpenJobsResponse,
  RawJob,
} from '../types';

type FetchOpenJobsParams = ApiFetchParams &
  OpenJobsRequestParams & {
    logError: (msg: string) => void;
    customerId?: string;
  };

export const fetchOpenJobs = async ({
  baseUrl,
  customerId,
  mock,
  signal,
  filter,
  sorting,
  currentPage,
  pageSize,
}: FetchOpenJobsParams): Promise<FetchOpenJobsResults> => {
  const {
    data: { faults, meta },
  } = await api.get<OpenJobsResponse>({
    baseUrl,
    mock,
    path: '/fault/api/v1/faults',
    queryParams: {
      ...getFilter(filter),
      customerId,
      job_status: JobStatus.Open,
      page: currentPage + 1, // server pagination starts from 1
      size: pageSize,
      sort: sorting !== null ? `${sorting.field},${sorting.direction}` : null,
    },
    config: { signal },
  });
  if (faults.length === 0) {
    return {
      jobs: [],
      totalElements: 0,
    };
  }

  return {
    jobs: faults.map((job) => ({
      id: job.id,
      code: job.code,
      customerId: job.customer_id,
      customerName: job.customer_name,
      equipmentType: job.equipment_type,
      created: job.created,
      reportedSymptom: getReportedSymptom(job),
      prediction: job.predictions === null ? null : job.predictions.prediction,
      divisionName: job.division_name,
    })),
    totalElements: meta.totalElements,
  };
};

const getReportedSymptom = (job: RawJob): string | null => {
  if (job.symptoms === null) {
    return job.description;
  }
  if (job.symptoms.length === 0) {
    return job.description;
  }
  return job.symptoms.map((symptom) => symptom.value).join(', ');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFilter = (filter: OpenJobsFilter | null): any => {
  if (filter === null) {
    return {};
  }
  // As per https://gitlab.com/CoresystemsAG1/service-ai/backend/fault-service/-/issues/103, backend expect 'jobCode' instead of 'code'.
  if (filter.field === OpenJobsFilterField.Code) {
    return {
      jobCode: filter.value,
    };
  }
  return {
    [filter.field]: filter.value,
  };
};
