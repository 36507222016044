import {
  TrendingSymptomMetric,
  TrendingSymptomResponseMetric,
} from '../../integration/trendingSymptoms.api';

export const sessionTrendingSymptomsSeed: TrendingSymptomMetric[] = [
  {
    symptom: 'Irregular blade movement',
    jobCount: 27,
  },
  {
    symptom: 'Generator failure',
    jobCount: 25,
  },
  {
    symptom: 'Pitch bearing failure',
    jobCount: 17,
  },
  {
    symptom: 'Vibration and Oscillation',
    jobCount: 15,
  },
  {
    symptom: 'Electrical component failure',
    jobCount: 12,
  },
];

const ukOnshoreTrendingSymptomsSeed: TrendingSymptomResponseMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 78,
  },
  {
    symptom: 'Blade failure',
    jobscount: 61,
  },
  {
    symptom: 'Generator failure',
    jobscount: 55,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 30,
  },
  {
    symptom: 'Control system failure',
    jobscount: 26,
  },
];

const esbOffshoreTrendingSymptomsSeed: TrendingSymptomResponseMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 47,
  },
  {
    symptom: 'Generator failure',
    jobscount: 34,
  },
  {
    symptom: 'Blade failure',
    jobscount: 29,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 23,
  },
  {
    symptom: 'Control system failure',
    jobscount: 11,
  },
];

const landsvirkjunTrendingSymptomsSeed: TrendingSymptomResponseMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 14,
  },
  {
    symptom: 'Hydraulic system leak',
    jobscount: 11,
  },
  {
    symptom: 'Blade failure',
    jobscount: 9,
  },
  {
    symptom: 'Electrical component failure',
    jobscount: 7,
  },
  {
    symptom: 'Communication system failure',
    jobscount: 3,
  },
];

const borsseleTrendingSymptomsSeed: TrendingSymptomResponseMetric[] = [
  {
    symptom: 'Gearbox failure',
    jobscount: 10,
  },
  {
    symptom: 'Hydraulic system leak',
    jobscount: 8,
  },
  {
    symptom: 'Generator failure',
    jobscount: 5,
  },
  {
    symptom: 'Pitch bearing issues',
    jobscount: 3,
  },
  {
    symptom: 'Communication system failure',
    jobscount: 1,
  },
];

const hollandseTrendingSymptomsSeed: TrendingSymptomResponseMetric[] = [
  {
    symptom: 'Control system failure',
    jobscount: 7,
  },
  {
    symptom: 'Hydraulic system leak',
    jobscount: 6,
  },
  {
    symptom: 'Generator failure',
    jobscount: 6,
  },
  {
    symptom: 'Pitch bearing issues',
    jobscount: 3,
  },
  {
    symptom: 'Blade failure',
    jobscount: 2,
  },
];

export const getCustomerTrendingSymptomsSeed = (
  customerId: string,
): TrendingSymptomResponseMetric[] => {
  switch (customerId) {
    case 'customer1':
      return ukOnshoreTrendingSymptomsSeed;
    case 'customer2':
      return esbOffshoreTrendingSymptomsSeed;
    case 'customer3':
      return landsvirkjunTrendingSymptomsSeed;
    case 'customer4':
      return borsseleTrendingSymptomsSeed;
    case 'customer5':
      return hollandseTrendingSymptomsSeed;
    default:
      return [];
  }
};
