import React from 'react';

import { useTranslations } from '../../../../../../utils';
import { SessionProps } from '../../../../../../types';
import styles from './DashboardNoData.module.scss';

const DashboardNoData: React.FC<SessionProps> = ({ session }) => {
  const { question } = session;
  const { assistant: translations } = useTranslations();

  return (
    <p className={styles.container}>
      {translations.dashboard.noData(question)}
    </p>
  );
};

export default DashboardNoData;
