import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import SmartLoopContainer from '../../SmartLoopContainer/SmartLoopContainer';
import { AppRoute, AssistantProps, FetchingStatus } from '../../../types';
import DashboardSession from './Session/DashboardSession';
import DashboardInputBox from './InputBox/DashboardInputBox';
import {
  focusOnFirstSession,
  useConversation,
} from '../../../store/slices/conversations.slice';
import { isDefined, isFetchingResolved, scrollToSession } from '../../../utils';
import DashboardLoading from './Loading/DashboardLoading';

const SmartLoopDashboard: React.FC<AssistantProps> = (props) => {
  const navigate = useNavigate();
  const { conversationId } = useParams();
  const { focusedSessionId } = useAppSelector((state) => state.conversations);
  const { status: historyStatus } = useAppSelector((state) => state.history);
  const sessions = useConversation(conversationId);
  const dispatch = useAppDispatch();
  const equipmentType = sessions.length > 0 ? sessions[0].equipmentType : null;
  const allEquipmentTags =
    sessions.length > 0 ? sessions[0].allEquipmentTags : null;
  const isSessionLoading =
    historyStatus === FetchingStatus.PENDING && sessions.length === 0;

  const sessionsContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (focusedSessionId === null || sessionsContainerRef.current === null) {
      return;
    }
    scrollToSession({
      scrollContainerRef: sessionsContainerRef.current,
      sessionId: focusedSessionId,
    });
  }, [focusedSessionId]);

  useEffect(() => {
    if (isFetchingResolved(historyStatus) && sessions.length === 0) {
      navigate(AppRoute.Home, { replace: true });
    }
  }, [sessions, historyStatus]);

  useEffect(() => {
    if (!isDefined(conversationId)) {
      return;
    }
    dispatch(focusOnFirstSession(conversationId));
  }, [conversationId]);

  return (
    <SmartLoopContainer
      ref={sessionsContainerRef}
      InputComponent={
        equipmentType !== null && allEquipmentTags !== null ? (
          <DashboardInputBox
            {...props}
            equipmentType={equipmentType}
            allEquipmentTags={allEquipmentTags}
          />
        ) : null
      }
      mobileTitle={equipmentType}
      {...props}
    >
      {isSessionLoading ? (
        <DashboardLoading {...props} />
      ) : (
        sessions.map((session) => (
          <DashboardSession
            key={session.id}
            session={session}
            allSessions={sessions}
            containerRef={sessionsContainerRef.current}
            {...props}
          />
        ))
      )}
    </SmartLoopContainer>
  );
};

export default SmartLoopDashboard;
