import React from 'react';

import { CustomerSessionProps, Trend } from '../../../../../types';
import { useTranslations } from '../../../../../utils';
import { useOnDesktop } from '../../../../../utils/responsive';
import KpiCard from '../KpiCard/KpiCard';

const FirstTimeFixRate: React.FC<CustomerSessionProps> = ({ session }) => {
  const { ftfr, ftfrStatus } = session;
  const {
    assistant: {
      customerScreen: { kpi: translations },
    },
  } = useTranslations();
  const isDesktop = useOnDesktop();

  if (ftfr === null) {
    return (
      <KpiCard
        title={translations.ftfr}
        summary={{
          average: null,
          trend: Trend.None,
          trendValue: null,
        }}
        valueUnit="%"
        status={ftfrStatus}
      />
    );
  }

  return (
    <KpiCard
      title={isDesktop ? translations.ftfr : translations.ftfrAbbr}
      summary={ftfr}
      valueUnit="%"
      status={ftfrStatus}
    />
  );
};

export default FirstTimeFixRate;
