import React from 'react';
import { Typography } from '@mui/material';

import RocketSvg from './icons/RocketSvg';
import { useTranslations } from '../../../utils';
import styles from './Unauthorized.module.scss';

const Unauthorized: React.FC = () => {
  const { auth: translations } = useTranslations();

  return (
    <div className={styles.container}>
      <RocketSvg />
      <Typography variant="h1" className={styles.title}>
        {translations.marketingMessage}
      </Typography>
      <Typography variant="body1">{translations.pilotDescription}</Typography>
    </div>
  );
};

export default Unauthorized;
