export enum TroubleshootingStatus {
  Idle = 'Idle',
  InProgressOpen = 'InProgressOpen',
  InProgressClosed = 'InProgressClosed',
  Finished = 'Finished',
}

export interface RawRootCauseStep {
  id: string;
  steps: string[];
}

export interface MockRootCauseStep {
  id: string;
  name: string;
  details: string;
}

export interface DiagnosisResponseData {
  root_causes: RootCauseNode[];
  tree: {
    id: string;
    tree: DiagnosisNode[];
  } | null;
}

export interface DiagnosisNode {
  id: number;
  type: DiagnosisNodeType;
  label: string | null;
  children: number[];
}

// Mock data structure vastly differs from what's available on the BE right now.
export interface RootCauseNode {
  id: string;
  name: string;
  resolution_steps?: RawRootCauseStep[];
  mockSteps?: MockRootCauseStep[];
  parts?: string[];
  image?: string;
  images?: string[];
  technicians?: string[];
}

export interface ProcessedDiagnosisResponse {
  allNodes: DiagnosisNode[];
  rootCauses: RootCauseNode[];
}

export enum DiagnosisNodeType {
  Question = 'QUESTION',
  Answer = 'ANSWER',
  RootCause = 'ROOT_CAUSE',
}

export interface DiagnosisQnaStep {
  question: string | null;
  selectedAnswer: string | null;
  answers: DiagnosisQnaAnswer[];
}

export interface DiagnosisQnaAnswer {
  answer: string | null;
  links: number[];
}
