import React, { useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { isElementOverflowing } from '../../../../../utils/isElementOverflowing';
import { AppRoute } from '../../../../../types';
import { ReactComponent as DeleteIcon } from './assets/delete.svg';
import SearchHistorySession from './Session/SearchHistorySession';
import {
  SearchHistoryEntry,
  SearchHistoryEntryType,
} from '../UseSearchHistory';
import { useCurrentRoute } from '../../../../../utils/useCurrentRoute';
import { useAppDispatch } from '../../../../../store/utils/hooks';
import { openDeleteConfirmationDialog } from '../../../../../store/slices/history.slice';
import styles from './SearchHistoryConversation.module.scss';

interface Props {
  entry: SearchHistoryEntry;
}

const SearchHistoryConversation: React.FC<Props> = ({ entry }) => {
  const dispatch = useAppDispatch();
  const ref = useRef<HTMLAnchorElement>(null);
  const hideTooltip = !isElementOverflowing(ref.current);
  const currentRoute = useCurrentRoute();
  const { conversationId, customerId } = useParams();
  const conversationMatches =
    currentRoute === AppRoute.Conversations && conversationId === entry.id;
  const customerMatches =
    currentRoute === AppRoute.Customer && customerId === entry.id;
  const targetRoute =
    entry.type === SearchHistoryEntryType.Conversation
      ? AppRoute.Conversations
      : AppRoute.Customer;

  const handleDelete = () => {
    dispatch(openDeleteConfirmationDialog(entry));
  };

  return (
    <li className={styles.conversation}>
      <div
        className={clsx(
          styles.conversationTitle,
          (conversationMatches || customerMatches) && styles.active,
        )}
      >
        <Tooltip
          title={entry.label}
          disableHoverListener={hideTooltip}
          className={styles.link}
        >
          <Link ref={ref} to={`${targetRoute}/${entry.id}`}>
            {entry.label}
          </Link>
        </Tooltip>
        <DeleteIcon className={styles.deleteIcon} onClick={handleDelete} />
      </div>
      {entry.type === SearchHistoryEntryType.Conversation &&
        conversationMatches && (
          <ul className={styles.sessions}>
            {entry.sessions.map((session) => (
              <SearchHistorySession key={session.id} session={session} />
            ))}
          </ul>
        )}
    </li>
  );
};

export default SearchHistoryConversation;
