import React, { ReactNode, useState } from 'react';
import clsx from 'clsx';

import { useOnMobile } from '../../../utils/responsive';
import { isDefined } from '../../../utils';
import { ReactComponent as OpenIcon } from '../../../assets/plus.svg';
import { ReactComponent as CloseIcon } from '../../../assets/minus.svg';
import styles from './CollapsibleCard.module.scss';

interface Props {
  children: ReactNode;
  title: {
    text: string;
    icon: ReactNode;
  };
  className?: string;
  contentClassname?: string;
  collapsedCardClassname?: string;
  collapsible?: boolean;
  openByDefault?: boolean;
  showBackground?: boolean;
}

const CollapsibleCard: React.FC<Props> = (props) => {
  const isMobile = useOnMobile();
  const {
    className,
    title,
    children,
    openByDefault = false,
    showBackground = true,
  } = props;
  const collapsible = isDefined(props.collapsible)
    ? props.collapsible
    : isMobile;
  const [open, setOpen] = useState(openByDefault);

  if (collapsible && !open) {
    return (
      <div
        className={clsx(styles.collapsedCard, props.collapsedCardClassname)}
        onClick={() => setOpen(true)}
      >
        <h3 className={styles.collapsedCardTitle}>{title.text}</h3>
        <OpenIcon className={styles.openCloseIcon} />
      </div>
    );
  }

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.headline}>
        <div className={styles.title}>
          {title.icon}
          <h3 className={styles.titleText}>{title.text}</h3>
        </div>
        {collapsible && (
          <CloseIcon
            className={styles.openCloseIcon}
            onClick={() => setOpen(false)}
          />
        )}
      </div>
      <div
        className={clsx(styles.card, showBackground && styles.cardBackground)}
      >
        {children}
      </div>
    </div>
  );
};

export default CollapsibleCard;
