import api from './api';
import { ApiFetchParams, ApiResponse, TimeRange } from '../types';
import { Customer } from './customers.api';

const CUSTOMERS_TO_SHOW = 5;

export interface TrendingCustomersMetric {
  customerId: string;
  name: string;
}

export interface TrendingCustomersData {
  customers_in_focus: TrendingCustomersMetric[];
}

type TrendingCustomersResponse = ApiResponse<TrendingCustomersData>;

export const fetchTrendingCustomers = async ({
  baseUrl,
  signal,
  mock,
}: ApiFetchParams): Promise<Customer[]> => {
  const { data } = await api.get<TrendingCustomersResponse>({
    baseUrl,
    mock,
    path: '/analytics/api/v1/metrics',
    queryParams: {
      timeRange: TimeRange.ONE_YEAR,
      type: 'CustomersInFocus',
    },
    config: { signal },
  });

  return data.customers_in_focus
    .slice(0, CUSTOMERS_TO_SHOW)
    .map((customer) => ({
      id: customer.customerId,
      name: customer.name,
    }));
};
