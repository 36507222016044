import { useMediaQuery, useTheme } from '@mui/material';

export enum Resolution {
  Mobile = 'sm',
  Tablet = 'md',
  Desktop = 'lg',
  LargeDesktop = 'xl',
}

export const useMinResolution = (resolution: Resolution) => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.up(resolution));
};

export const useMaxResolution = (resolution: Resolution) => {
  const theme = useTheme();

  return useMediaQuery(theme.breakpoints.down(resolution));
};

export const useOnDesktop = (): boolean => {
  return useMinResolution(Resolution.Desktop);
};

export const useOnMobile = (): boolean => {
  return useMaxResolution(Resolution.Desktop);
};
