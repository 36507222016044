import { isDefined } from './isDefined';

export const getDay = (offset?: number): string => {
  if (!isDefined(offset)) {
    return new Date().toISOString();
  } else {
    const dayInMs = offset * 24 * 60 * 60 * 1000;

    return new Date(new Date().getTime() - dayInMs).toISOString();
  }
};

export const getDayMs = (offset?: number): number => {
  if (!isDefined(offset)) {
    return new Date().getTime();
  } else {
    const dayInMs = offset * 24 * 60 * 60 * 1000;

    return new Date().getTime() - dayInMs;
  }
};
