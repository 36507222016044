import React from 'react';

import { CustomerSessionProps, FetchingStatus } from '../../../../types';
import { ReactComponent as KpiIcon } from './assets/kpi.svg';
import { useTranslations } from '../../../../utils';
import SmartLoopCard from '../../../Card/SmartLoopCard';
import FirstTimeFixRate from './FirstTimeFixRate/FirstTimeFixRate';
import MeanTimeToResolution from './MeanTimeToResolution/MeanTimeToResolution';
import MeanTimeBetweenVisits from './MeanTimeBetweenVisits/MeanTimeBetweenVisits';
import styles from './CustomerKpi.module.scss';

const CustomerKpi: React.FC<CustomerSessionProps> = (props) => {
  const {
    assistant: {
      customerScreen: { kpi: translations },
    },
  } = useTranslations();

  return (
    <SmartLoopCard
      collapsible
      showBackground={false}
      collapsedCardClassname={styles.collapsedCard}
      status={FetchingStatus.SUCCESS}
      data={{}}
      title={{
        text: translations.title,
        icon: <KpiIcon className={styles.kpiIcon} />,
      }}
    >
      {() => (
        <div className={styles.content}>
          <FirstTimeFixRate {...props} />
          <MeanTimeToResolution {...props} />
          <MeanTimeBetweenVisits {...props} />
        </div>
      )}
    </SmartLoopCard>
  );
};

export default CustomerKpi;
