export const getExtractedSymptomSeed = (question: string): string | null => {
  if (question.match(/blade/i)) {
    return 'Irregular blade movement';
  }
  if (question.match(/generator/i)) {
    return 'Generator failure';
  }
  if (question.match(/pitch bearing/i)) {
    return 'Pitch bearing failure';
  }

  return null;
};
