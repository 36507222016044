import api from './api';
import {
  ApiFetchParams,
  ApiResponse,
  DiagnosisResponseData,
  ProcessedDiagnosisResponse,
  Symptom,
} from '../types';
import { delay, isDefined } from '../utils';
import { getDiagnosesSeed } from '../mocks/seed/diagnoses.seed';

type DiagnosisResponse = ApiResponse<DiagnosisResponseData>;

interface FetchDiagnosesParams extends ApiFetchParams {
  confirmedSymptoms: Symptom[];
  equipmentType: string;
}

export const fetchDiagnoses = async ({
  confirmedSymptoms,
  equipmentType,
  baseUrl,
  signal,
  mock,
}: FetchDiagnosesParams): Promise<ProcessedDiagnosisResponse> => {
  const symptoms = confirmedSymptoms.map((symptom) => symptom.name);
  if (mock) {
    await delay(1500);
    return getDiagnosesSeed(symptoms);
  }

  const { data } = await api.get<DiagnosisResponse>({
    baseUrl,
    mock,
    path: '/troubleshooting/api/v2/root-causes',
    queryParams: {
      equipmentType,
      confirmedSymptoms: symptoms,
    },
    config: { signal },
  });

  return {
    allNodes: isDefined(data.tree) ? data.tree.tree : [],
    rootCauses: data.root_causes,
  };
};
