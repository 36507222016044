import React from 'react';

import { useIsDarkMode } from '../../../../utils/darkMode';

const RocketSvg: React.FC = () => {
  const darkMode = useIsDarkMode();
  const fillColor = darkMode ? '#ffffff50' : '#00BDC7';

  return (
    <svg
      width="298"
      height="293"
      viewBox="0 0 298 293"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.3"
        d="M72.3006 234.985C72.2983 232.502 74.8175 230.074 77.6336 230.071C79.4346 230.07 80.9744 230.877 81.8924 232.118C81.8924 232.031 81.8923 231.973 81.8922 231.887C81.892 231.656 81.8591 231.454 81.8589 231.223C81.8511 222.762 89.6055 215.911 99.1999 215.903C102.736 215.899 106.012 216.82 108.731 218.406C109.548 216.875 111.283 215.805 113.346 215.803C113.641 215.803 113.903 215.831 114.197 215.86C114.194 212.019 117.76 208.898 122.115 208.894C123.098 208.893 124.015 209.065 124.866 209.324C124.866 209.266 124.899 209.209 124.899 209.18C124.866 208.949 124.833 208.718 124.833 208.487C124.83 205.888 127.219 203.807 130.133 203.804C130.559 203.804 130.952 203.861 131.312 203.919L134.286 196.986L139.198 196.981L142.217 204.024C144.444 204.628 146.148 206.33 146.51 208.438C147.198 208.206 147.951 208.061 148.737 208.061C152.175 208.057 154.928 210.509 154.931 213.512C154.931 213.743 154.898 213.974 154.866 214.205C154.899 214.379 154.899 214.523 154.899 214.696C159.713 214.692 163.743 217.604 164.86 221.559C167.021 220.72 169.41 220.227 171.932 220.225C181.297 220.216 188.933 226.735 189.301 234.935L72.3006 234.985Z"
        fill="#00BDC7"
      />
      <path
        d="M161.565 163.868C161.565 163.868 163.416 165.694 168.124 170.352C172.833 175.009 170.995 182.655 170.995 182.655L165.1 204.491L155.304 191.322"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        d="M111.037 165.021C111.037 165.021 107.109 169 103.802 172.309C100.516 175.642 101.21 182.136 101.21 182.136L106.95 205.267L116.817 192.428"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M111.053 167.511C111.053 167.511 105.041 173.152 104.643 178.591C104.246 184.03 108.716 203.101 108.716 203.101L106.885 205.241C106.885 205.241 101.965 188.082 101.074 182.172C100.721 179.772 100.755 175.678 103.354 172.701C105.952 169.725 110.945 165.06 110.945 165.06L111.053 167.511Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M161.068 167.341C161.068 167.341 166.392 172.321 166.785 176.903C167.154 181.506 162.598 201.306 162.598 201.306L155.273 191.43C155.27 191.474 160.597 173.79 161.068 167.341Z"
        fill="#00BDC7"
      />
      <path
        d="M116.77 192.47C116.77 192.47 130.179 202.061 154.946 192.292"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        d="M135.799 163.182C135.799 163.182 140.864 171.098 141.168 182.327C141.492 193.579 136.361 205.262 136.361 205.262C136.361 205.262 131.688 194.087 131.726 183.913C131.741 173.76 135.799 163.182 135.799 163.182Z"
        fill="white"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M135.799 163.182C135.799 163.182 137.735 170.475 137.641 182.628C137.546 194.781 136.341 205.239 136.341 205.239C136.341 205.239 141.702 191.014 141.148 182.304C140.403 169.573 135.799 163.182 135.799 163.182Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M135.799 163.182C135.799 163.182 131.463 175.154 131.726 183.913C131.967 192.694 136.361 205.262 136.361 205.262C133.859 192.143 133.44 178.264 135.799 163.182Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.4"
        d="M116.83 192.252C116.83 192.252 106.965 155.578 111.155 130.228C116.942 95.3903 136.018 82.6472 136.018 82.6472C106.124 132.997 119.613 175.895 123.311 195.273L119.907 194.194L116.83 192.252Z"
        fill="#00BDC7"
      />
      <path
        d="M128.255 136.092C123.828 140.548 123.789 147.726 128.212 152.059C132.612 156.412 139.764 156.3 144.214 151.823C148.64 147.368 148.68 140.189 144.256 135.857C139.856 131.504 132.705 131.616 128.255 136.092Z"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        d="M130.8 138.794C127.672 141.933 127.583 146.928 130.634 149.905C133.668 152.896 138.662 152.757 141.806 149.604C144.934 146.466 145.023 141.471 141.972 138.494C138.938 135.503 133.944 135.642 130.8 138.794Z"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        d="M136.019 82.6471C136.019 82.6471 94.3977 113.063 116.831 192.252"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        d="M136.018 82.6476C136.018 82.6476 179.021 112.068 155.269 191.805"
        stroke="#00BDC7"
        strokeMiterlimit="10"
      />
      <path
        opacity="0.3"
        d="M184.617 205.44C184.522 204.079 183.391 203 182.024 203C180.893 203 179.95 203.704 179.573 204.689C179.101 204.173 178.394 203.845 177.64 203.845C176.178 203.845 175 205.017 175 206.472C175 207.457 175.566 208.349 176.367 208.771C175.99 209.24 175.707 209.803 175.707 210.46C175.707 211.868 176.886 213.041 178.3 213.041C178.818 213.041 179.243 212.9 179.667 212.665C179.62 212.806 179.62 212.9 179.62 213.041C179.62 214.073 180.468 214.917 181.505 214.917C182.542 214.917 183.391 214.073 183.391 213.041C183.391 212.9 183.391 212.759 183.344 212.618C183.674 212.712 184.004 212.759 184.381 212.759C186.408 212.759 188.058 211.117 188.058 209.099C188.105 207.176 186.549 205.581 184.617 205.44Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M88.1011 206.843C88.0217 205.815 87.0686 205 85.917 205C84.9639 205 84.1697 205.531 83.852 206.276C83.4549 205.886 82.8592 205.638 82.2238 205.638C80.9928 205.638 80 206.524 80 207.622C80 208.366 80.4765 209.039 81.1516 209.358C80.8339 209.713 80.5957 210.138 80.5957 210.634C80.5957 211.697 81.5884 212.583 82.7798 212.583C83.2166 212.583 83.574 212.476 83.9314 212.299C83.8917 212.406 83.8917 212.476 83.8917 212.583C83.8917 213.362 84.6065 214 85.4801 214C86.3538 214 87.0686 213.362 87.0686 212.583C87.0686 212.476 87.0686 212.37 87.0289 212.264C87.3069 212.335 87.5848 212.37 87.9025 212.37C89.6101 212.37 91 211.13 91 209.606C91 208.154 89.7292 206.949 88.1011 206.843Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M196.637 212.196C196.59 211.586 196.119 211.117 195.459 211.117C194.94 211.117 194.516 211.445 194.375 211.867C194.139 211.633 193.856 211.492 193.526 211.492C192.866 211.492 192.348 212.008 192.348 212.665C192.348 213.087 192.583 213.51 192.96 213.697C192.772 213.885 192.678 214.166 192.678 214.448C192.678 215.105 193.196 215.621 193.856 215.621C194.092 215.621 194.28 215.574 194.469 215.433C194.469 215.48 194.469 215.527 194.469 215.621C194.469 216.09 194.846 216.465 195.317 216.465C195.789 216.465 196.166 216.09 196.166 215.621C196.166 215.574 196.166 215.48 196.119 215.433C196.26 215.48 196.402 215.48 196.59 215.48C197.486 215.48 198.24 214.729 198.24 213.838C198.193 212.947 197.486 212.243 196.637 212.196Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M100.4 202.033C100.364 201.467 99.8545 201 99.2 201C98.6909 201 98.2546 201.3 98.0727 201.7C97.8546 201.467 97.5273 201.333 97.2 201.333C96.5455 201.333 96 201.833 96 202.433C96 202.867 96.2545 203.2 96.6182 203.4C96.4364 203.6 96.3273 203.833 96.3273 204.1C96.3273 204.7 96.8727 205.2 97.5273 205.2C97.7455 205.2 97.9636 205.133 98.1455 205.033C98.1455 205.1 98.1455 205.133 98.1455 205.2C98.1455 205.633 98.5455 206 99.0182 206C99.4909 206 99.8909 205.633 99.8909 205.2C99.8909 205.133 99.8909 205.067 99.8545 205C100 205.033 100.145 205.067 100.327 205.067C101.236 205.067 102 204.367 102 203.533C101.964 202.767 101.273 202.1 100.4 202.033Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M78.912 216.807C78.88 216.351 78.56 216 78.112 216C77.76 216 77.472 216.246 77.376 216.561C77.216 216.386 77.024 216.281 76.8 216.281C76.352 216.281 76 216.667 76 217.158C76 217.474 76.16 217.789 76.416 217.93C76.288 218.07 76.224 218.281 76.224 218.491C76.224 218.982 76.576 219.368 77.024 219.368C77.184 219.368 77.312 219.333 77.44 219.228C77.44 219.263 77.44 219.298 77.44 219.368C77.44 219.719 77.696 220 78.016 220C78.336 220 78.592 219.719 78.592 219.368C78.592 219.333 78.592 219.263 78.56 219.228C78.656 219.263 78.752 219.263 78.88 219.263C79.488 219.263 80 218.702 80 218.035C79.968 217.368 79.488 216.842 78.912 216.807Z"
        fill="#00BDC7"
      />
      <path
        opacity="0.3"
        d="M66.4087 107.009C67.25 107.009 67.9904 107.248 68.6635 107.624C68.8317 105.299 70.75 103.453 73.0721 103.453C73.6442 103.453 74.1827 103.556 74.6875 103.761C75.3269 101.607 77.3125 100 79.6346 100C82.4952 100 84.8173 102.359 84.8173 105.265C84.8173 105.333 84.8173 105.402 84.8173 105.47C87.6779 105.47 90 107.829 90 110.735C90 113.641 87.6779 116 84.8173 116C84.649 116 84.4471 116 84.2788 115.966H66.9135C66.7452 116 66.6106 116 66.4423 116C63.9856 116 62 113.983 62 111.487C62 109.026 63.9856 107.009 66.4087 107.009Z"
        fill={fillColor}
      />
      <path
        opacity="0.3"
        d="M224.211 78.1049C222.75 78.1049 221.383 78.4803 220.204 79.1841C219.922 75.1491 216.527 71.9586 212.426 71.9586C211.531 71.9586 210.682 72.0994 209.881 72.3809C208.702 68.6743 205.261 66 201.16 66C200.83 66 200.547 66 200.264 66.0469C196.446 66.2815 193.24 68.862 192.156 72.3809C191.355 72.0994 190.506 71.9586 189.61 71.9586C185.462 71.9586 182.115 75.1491 181.832 79.1841C180.654 78.4803 179.287 78.1049 177.825 78.1049C173.536 78.1049 170 81.5769 170 85.8934C170 90.163 173.488 93.6818 177.825 93.6818C178.108 93.6818 178.391 93.6818 178.674 93.6349H190.647C190.977 93.6818 191.355 93.6818 191.732 93.6818C192.062 93.6818 192.344 93.6818 192.674 93.6349H209.786C209.975 93.6349 210.116 93.6818 210.305 93.6818C210.493 93.6818 210.635 93.6818 210.823 93.6349H223.363C223.646 93.6818 223.928 93.6818 224.211 93.6818C228.501 93.6818 232.037 90.2099 232.037 85.8934C231.989 81.5769 228.501 78.1049 224.211 78.1049Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default RocketSvg;
