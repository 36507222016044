import React from 'react';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';

import { useIsDarkMode, useTranslations } from '../../../utils';
import { ReactComponent as DesktopLogo } from '../../../assets/mobile-logo.svg';
import { ReactComponent as DesktopLogoLight } from '../../../assets/mobile-logo-light.svg';
import { ReactComponent as PlusIcon } from '../../../assets/plus.svg';
import { ReactComponent as SearchHistoryIcon } from '../../../assets/search-history.svg';
import { ReactComponent as MinimizeIcon } from './assets/minimize.svg';
import { ReactComponent as ExtendIcon } from './assets/extend.svg';
import { ReactComponent as MinimizedLogo } from './assets/logo.svg';
import { ReactComponent as TipsIcon } from './assets/arrow-corner.svg';
import { AppRoute, AssistantProps } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import { setDrawerMinimized } from '../../../store/slices/ui.slice';
import DrawerContent from '../DrawerContent/DrawerContent';
import styles from './DesktopDrawer.module.scss';

interface Props extends AssistantProps {
  onClose: () => void;
}

const DesktopDrawer: React.FC<Props> = ({ onClose, ...props }) => {
  const darkMode = useIsDarkMode();
  const {
    assistant: { drawer: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const { drawerMinimized } = useAppSelector((state) => state.ui);

  const handleMinimize = () => {
    dispatch(setDrawerMinimized(true));
  };
  const handleExtend = () => {
    dispatch(setDrawerMinimized(false));
  };

  if (drawerMinimized) {
    return (
      <div className={clsx(styles.container, styles.minimized)}>
        <div className={styles.topSection}>
          <ExtendIcon onClick={handleExtend} className={styles.extendIcon} />
          <MinimizedLogo className={styles.minimizedLogo} />
          <Tooltip title={translations.newSearch}>
            <Link
              to={AppRoute.Home}
              onClick={onClose}
              className={styles.newSearchBtn}
              replace
            >
              <PlusIcon className={styles.newSearchIcon} />
            </Link>
          </Tooltip>
          <Tooltip title={translations.searchHistory}>
            <SearchHistoryIcon
              className={styles.searchHistoryIcon}
              onClick={handleExtend}
            />
          </Tooltip>
        </div>
        <Tooltip title={translations.tipsContent}>
          <div className={styles.tips}>
            <TipsIcon className={styles.tipsIcon} />
          </div>
        </Tooltip>
      </div>
    );
  } else {
    return (
      <div className={clsx(styles.container, styles.extended)}>
        <MinimizeIcon
          onClick={handleMinimize}
          className={styles.minimizeIcon}
        />
        {darkMode ? (
          <DesktopLogo className={styles.desktopLogo} />
        ) : (
          <DesktopLogoLight className={styles.desktopLogo} />
        )}
        <DrawerContent {...props} onClose={onClose} />
      </div>
    );
  }
};

export default DesktopDrawer;
