import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AtLeast,
  FetchingStatus,
  CustomerSession,
  CustomerHistorySession,
  OPEN_JOBS_DEFAULT_FILTER_STATE,
  OpenJobsRequestParams,
} from '../../types';
import { useAppSelector } from '../utils/hooks';
import { isDefined } from '../../utils';
import { Customer } from '../../integration/customers.api';
import { deleteSessions } from '../../integration/conversations.api';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchOpenJobs } from '../../integration/openJobs.api';

export const CUSTOMER_DETAILS_KEY = 'customerDetails';

export interface CustomerDetailsState {
  sessions: CustomerSession[];
}

const initialState: CustomerDetailsState = {
  sessions: [],
};

export interface CreateCustomerSessionParams {
  customer: Customer;
}

const generateCustomerSession = ({
  customer,
}: CreateCustomerSessionParams): CustomerSession => ({
  ...OPEN_JOBS_DEFAULT_FILTER_STATE,
  id: customer.id,
  savedToHistory: false,
  customerName: customer.name,
  createdAt: Date.now(),
  summary: null,
  summaryStatus: FetchingStatus.PENDING,
  openJobs: null,
  openJobsStatus: FetchingStatus.PENDING,
  pastJobs: null,
  pastJobsStatus: FetchingStatus.PENDING,
  ftfr: null,
  ftfrStatus: FetchingStatus.PENDING,
  mttr: null,
  mttrStatus: FetchingStatus.PENDING,
  mtbv: null,
  mtbvStatus: FetchingStatus.PENDING,
});

interface DeleteCustomerSessionParams extends ApiThunkParams {
  id: string;
}

export const deleteCustomerSession = createAsyncThunk<
  void,
  DeleteCustomerSessionParams,
  AppThunkConfig
>(
  'deleteCustomerSession',
  async ({ id, baseUrl, mock, logError }, { signal }) => {
    await deleteSessions({
      ids: [id],
      baseUrl,
      mock,
      signal,
      logError,
    });
  },
);

type GetCustomerOpenJobsParams = ApiThunkParams &
  OpenJobsRequestParams & {
    customerId: string;
  };

export const getCustomerOpenJobs = createAsyncThunk<
  void,
  GetCustomerOpenJobsParams,
  AppThunkConfig
>(
  'getCustomerOpenJobs',
  async (
    {
      customerId,
      baseUrl,
      mock,
      logError,
      sorting,
      filter,
      currentPage,
      pageSize,
    },
    { dispatch, signal },
  ) => {
    try {
      dispatch(
        updateCustomerSession({
          id: customerId,
          openJobsStatus: FetchingStatus.PENDING,
        }),
      );
      const { jobs, totalElements } = await fetchOpenJobs({
        signal,
        logError,
        baseUrl,
        mock,
        customerId,
        sorting,
        filter,
        currentPage,
        pageSize,
      });
      dispatch(
        updateCustomerSession({
          id: customerId,
          openJobs: jobs,
          openJobsStatus: FetchingStatus.SUCCESS,
          totalElements: totalElements,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch open jobs for customer ${customerId}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customerId,
          openJobsStatus: FetchingStatus.ERROR,
          totalElements: 0,
        }),
      );
    }
  },
);

export interface AddCustomerSummaryPartialParams {
  customerId: string;
  partial: string;
}

const customerDetailsSlice = createSlice({
  name: CUSTOMER_DETAILS_KEY,
  initialState,
  reducers: {
    createCustomerSession: (
      state,
      action: PayloadAction<CreateCustomerSessionParams>,
    ) => {
      return {
        ...state,
        sessions: [...state.sessions, generateCustomerSession(action.payload)],
      };
    },
    updateCustomerSession: (
      state,
      action: PayloadAction<AtLeast<CustomerSession, 'id'>>,
    ) => {
      const updatedSession = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id === updatedSession.id) {
            return {
              ...session,
              ...updatedSession,
            };
          } else {
            return session;
          }
        }),
      };
    },
    addCustomerSummaryPartial: (
      state,
      action: PayloadAction<AddCustomerSummaryPartialParams>,
    ) => {
      const { customerId, partial } = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          // Ignore stray parts arriving after the final answer
          if (
            session.id === customerId &&
            session.summaryStatus === FetchingStatus.PENDING
          ) {
            return {
              ...session,
              summary: partial,
            };
          } else {
            return session;
          }
        }),
      };
    },
    addCustomerHistory: (
      state,
      action: PayloadAction<CustomerHistorySession[]>,
    ) => {
      return {
        ...state,
        sessions: [
          ...state.sessions,
          ...action.payload.map(({ id, content }) => ({
            ...OPEN_JOBS_DEFAULT_FILTER_STATE,
            id,
            savedToHistory: true,
            customerName: content.customerName,
            createdAt: content.createdAt,
            summary: content.summary,
            summaryStatus: FetchingStatus.SUCCESS,
            openJobs: content.openJobs,
            openJobsStatus: FetchingStatus.SUCCESS,
            openJobsTotalElements: -1,
            pastJobs: content.pastJobs,
            pastJobsStatus: FetchingStatus.SUCCESS,
            ftfr: content.ftfr,
            ftfrStatus: FetchingStatus.SUCCESS,
            mttr: content.mttr,
            mttrStatus: FetchingStatus.SUCCESS,
            mtbv: content.mtbv,
            mtbvStatus: FetchingStatus.SUCCESS,
          })),
        ],
      };
    },
    reset: (state) => ({
      ...state,
      sessions: [],
    }),
  },
  extraReducers(builder) {
    builder.addCase(deleteCustomerSession.pending, (state, action) => {
      return {
        ...state,
        sessions: state.sessions.filter(
          (session) => session.id !== action.meta.arg.id,
        ),
      };
    });
  },
});

const { actions, reducer } = customerDetailsSlice;

export const {
  createCustomerSession,
  updateCustomerSession,
  addCustomerSummaryPartial,
  addCustomerHistory,
  reset: resetConversations,
} = actions;

export const useCustomerDetails = (id?: string): CustomerSession | null => {
  const { sessions } = useAppSelector((state) => state.customerDetails);
  const session = sessions.find((session) => session.id === id);

  return isDefined(session) ? session : null;
};

export const customerDetailsReducer = reducer;
