import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {
  AssistantProps,
  DocumentStatus,
  NewDocument,
} from '../../../../../../../types';
import { ReactComponent as ReloadIcon } from '../assets/reload.svg';
import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import { uploadDocuments } from '../../../../../../../store/slices/documents.slice';
import { isDefined } from '../../../../../../../utils';
import styles from './DocumentStatusIndicator.module.scss';

interface Props extends AssistantProps {
  document: NewDocument;
  file?: File;
}

const DocumentStatusIndicator: React.FC<Props> = ({
  file,
  document,
  httpUrl,
  isMocking,
  logError,
}) => {
  const dispatch = useAppDispatch();
  const handleReUpload = () => {
    if (!isDefined(file)) {
      logError(`Could not re-upload file: ${document.name}`);
      return;
    }
    dispatch(
      uploadDocuments({
        logError,
        files: [file],
        equipmentTypes: document.tags,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );
  };

  switch (document.status) {
    case DocumentStatus.Uploaded:
      return <div>{(document.size / 1024).toFixed(0)} KB</div>;
    case DocumentStatus.Pending:
      return (
        <div className={styles.loading}>
          <CircularProgress color="inherit" size={22} />
        </div>
      );
    case DocumentStatus.Error:
      return (
        <div>
          <ReloadIcon className={styles.reloadIcon} onClick={handleReUpload} />
        </div>
      );
    default:
      return null;
  }
};

export default DocumentStatusIndicator;
