import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { ReactComponent as LogoDark } from '../../../../assets/mobile-logo.svg';
import { ReactComponent as LogoLight } from '../../../../assets/mobile-logo-light.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/close.svg';
import { useIsDarkMode, useTranslations } from '../../../../utils';
import { AppRoute } from '../../../../types';
import styles from './ConfigurationHeader.module.scss';

const ConfigurationHeader: React.FC = () => {
  const darkMode = useIsDarkMode();
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logoAndCloseBtn}>
          {darkMode ? (
            <LogoDark className={styles.logo} />
          ) : (
            <LogoLight className={styles.logo} />
          )}
          <Link to={AppRoute.Home}>
            <CloseIcon className={styles.closeIcon} />
          </Link>
        </div>
        <nav className={clsx(styles.navigation, darkMode && styles.darkMode)}>
          <NavLink
            to={AppRoute.Documents}
            replace
            className={({ isActive }) =>
              clsx(styles.link, isActive && styles.active)
            }
          >
            {translations.documentOverview}
          </NavLink>
          <NavLink
            to={AppRoute.Tags}
            replace
            className={({ isActive }) =>
              clsx(styles.link, isActive && styles.active)
            }
          >
            {translations.tagManagement}
          </NavLink>
          <NavLink
            to={AppRoute.CustomerInformation}
            replace
            className={({ isActive }) =>
              clsx(styles.link, isActive && styles.active)
            }
          >
            {translations.customerInformation}
          </NavLink>
        </nav>
      </div>
    </div>
  );
};

export default ConfigurationHeader;
