import React, { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import clsx from 'clsx';

import styles from './SurveysAppContainer.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const SurveysAppContainer: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { children, className },
  ref,
) => {
  return (
    <div>
      <div ref={ref} className={clsx(styles.container, className)}>
        {children}
      </div>
    </div>
  );
};

export default forwardRef(SurveysAppContainer);
