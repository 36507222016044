import React, { ReactElement } from 'react';

import { useOnDesktop } from '../../utils/responsive';

interface Props {
  mobileComponent: ReactElement;
  desktopComponent: ReactElement;
}

const Responsive: React.FC<Props> = ({ mobileComponent, desktopComponent }) => {
  const isDesktop = useOnDesktop();

  if (isDesktop) {
    return desktopComponent;
  }
  return mobileComponent;
};

export default Responsive;
