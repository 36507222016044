export enum SurveyStatus {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Closed = 'CLOSED',
}

export interface Survey {
  id: string;
  equipment_type: string;
  status: SurveyStatus;
  first_question: string;
  question_ids: string[];
  answered_question_count: number;
  total_question_count: number;
  created_at: string;
}

export interface SurveyRootCause {
  root_cause: string;
  confirmed: boolean;
}

export interface Question {
  id: string;
  text: string;
  answered: boolean;
  root_causes: SurveyRootCause[];
  additional_information: string | null;
  question_job_code?: string;
  source_job_codes?: string[];
}

export interface SurveyDetails {
  id: string;
  equipmentType: string;
  status: SurveyStatus;
  questions: Question[];
}
