import React, { useCallback } from 'react';
import {
  FetchingStatus,
  MixpanelEventType,
  SessionProps,
} from '../../../../../../types';
import { useAppDispatch } from '../../../../../../store/utils/hooks';
import {
  trackWithMixpanel,
  isRootCauseEmptyOrError,
} from '../../../../../../utils';
import { setCopiedRootCauseContent } from '../../../../../../store/slices/analytics.slice';
import TextLoader from '../../../../../TextLoader/TextLoader';
import styles from './RootCauses.module.scss';

const RootCauses: React.FC<SessionProps> = (props) => {
  const { session, environment } = props;
  const { rootCause, rootCauseStatus, parts } = session;
  const dispatch = useAppDispatch();

  const handleRootCauseCopied = useCallback(() => {
    const selection = document.getSelection();
    if (selection === null) {
      return;
    }
    dispatch(setCopiedRootCauseContent(selection.toString()));
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.RootCauseCopied,
        properties: { text: selection.toString() },
      },
    });
  }, [dispatch, environment]);

  if (rootCauseStatus === FetchingStatus.PENDING && rootCause === null) {
    return <TextLoader />;
  }

  // The parent component handles empty/error state
  if (rootCause === null || isRootCauseEmptyOrError(session)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <p
        onCopy={handleRootCauseCopied}
        dangerouslySetInnerHTML={{ __html: rootCause }}
        className={styles.text}
      />
      {parts && parts.length > 0 && (
        <div>
          <b>Critical Parts:</b>
          <ol className={styles.list}>
            {parts.map((part) => (
              <li key={part} className={styles.listItem}>
                {part}
              </li>
            ))}
          </ol>
        </div>
      )}
    </div>
  );
};

export default RootCauses;
