import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoute, AssistantProps, FetchingStatus } from '../../../types';
import { useAppSelector } from '../../../store/utils/hooks';
import { useTranslations } from '../../../utils';
import SurveyForm from './Form/SurveyForm';
import QuestionEnumerator from './QuestionEnumerator/QuestionEnumerator';
import LoadingOverlay from '../../LoadingOverlay';
import SurveyCompleted from './SurveyCompleted/SurveyCompleted';
import SurveysAppContainer from '../../SurveysAppContainer/SurveysAppContainer';
import styles from './SurveyQuestions.module.scss';

const SurveyQuestions: React.FC<AssistantProps> = (props) => {
  const {
    assistant: {
      curationHub: { surveyDetails: translations },
    },
  } = useTranslations();
  const navigate = useNavigate();
  const { details, selectedEquipment, status } = useAppSelector(
    (state) => state.surveyDetails,
  );
  const [index, setIndex] = useState(0);
  const [startingIndexFound, setStartingIndexFound] = useState(false);
  const [completed, setCompleted] = useState(() => {
    if (details === null) {
      return false;
    }
    return details.questions.every((question) => question.answered);
  });

  useEffect(() => {
    if (status === FetchingStatus.IDLE) {
      navigate(AppRoute.Surveys, { replace: true });
    }
  }, [status]);

  useEffect(() => {
    if (details === null || startingIndexFound) {
      return;
    }
    const startingIndex = details.questions.findIndex(
      (question) => !question.answered,
    );
    setIndex(startingIndex === -1 ? 0 : startingIndex);
    setStartingIndexFound(true);
  }, [details, startingIndexFound]);

  return (
    <SurveysAppContainer>
      {completed ? (
        <SurveyCompleted {...props} />
      ) : (
        <div>
          {selectedEquipment && (
            <h1 className={styles.title}>
              {translations.title(selectedEquipment)}
            </h1>
          )}
          <div className={styles.questions}>
            {status === FetchingStatus.PENDING && <LoadingOverlay />}
            {details !== null && (
              <div>
                {details.questions.map((question, questionIndex) => (
                  <SurveyForm
                    {...props}
                    key={question.id}
                    question={question}
                    visible={index === questionIndex}
                  />
                ))}
                <QuestionEnumerator
                  {...props}
                  currentIndex={index}
                  questions={details.questions}
                  setIndex={setIndex}
                  onCompleted={() => setCompleted(true)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </SurveysAppContainer>
  );
};

export default SurveyQuestions;
