export enum SymptomType {
  Dynamic = 'DYNAMIC',
  Static = 'STATIC',
  UserInput = 'UserInput',
}

export interface Symptom {
  id: string;
  name: string;
  type: SymptomType;
}

export interface SymptomSearchResults {
  symptoms: Symptom[];
}
