import { createAsyncThunk } from '@reduxjs/toolkit';

import { FetchingStatus } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { Customer } from '../../integration/customers.api';
import { fetchPastJobs } from '../../integration/pastJobs.api';
import { updateCustomerSession } from './customerDetails.slice';

interface GetCustomerPastJobsParams extends ApiThunkParams {
  customer: Customer;
}

export const getCustomerPastJobs = createAsyncThunk<
  void,
  GetCustomerPastJobsParams,
  AppThunkConfig
>(
  'getCustomerPastJobs',
  async ({ customer, baseUrl, mock, logError }, { dispatch, signal }) => {
    try {
      const jobs = await fetchPastJobs({
        signal,
        baseUrl,
        mock,
        customerId: customer.id,
      });
      dispatch(
        updateCustomerSession({
          id: customer.id,
          pastJobs: jobs,
          pastJobsStatus: FetchingStatus.SUCCESS,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch past jobs for customer ${customer.id}. Reason: ${error?.message}`,
      );
      dispatch(
        updateCustomerSession({
          id: customer.id,
          pastJobsStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);
