import React, { useRef } from 'react';
import clsx from 'clsx';

import { AssistantProps } from '../../../../../../../types';
import { ReactComponent as UploadIcon } from './assets/cloud-upload.svg';
import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import { uploadDocuments } from '../../../../../../../store/slices/documents.slice';
import { useIsDarkMode, useTranslations } from '../../../../../../../utils';
import styles from './DocumentDragAndDrop.module.scss';

interface Props extends AssistantProps {
  tags: string[];
  setFiles: (files: File[]) => void;
}

const DocumentDragAndDrop: React.FC<Props> = ({ tags, setFiles, ...props }) => {
  const { httpUrl, isMocking, logError } = props;
  const {
    assistant: { documentManagement: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const isDragging = false;
  const darkMode = useIsDarkMode();
  const disabled = tags.length === 0;
  const ref = useRef<HTMLInputElement>(null);

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    if (disabled) {
      return;
    }
    // setIsDragging(false);
    const files = Array.from(event.dataTransfer.files);
    setFiles(files);
    dispatch(
      uploadDocuments({
        files,
        logError,
        equipmentTypes: tags,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );
  };
  const handleClick = () => {
    if (disabled) {
      return;
    }
    ref.current?.click();
  };
  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!event.target.files) {
      return;
    }
    const files = Array.from(event.target.files);
    setFiles(files);
    dispatch(
      uploadDocuments({
        files,
        logError,
        equipmentTypes: tags,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );
  };

  return (
    <div
      onDragEnter={(ev) => ev.preventDefault()} // TODO
      // onDragLeave={() => {}} TODO
      onDragOver={(ev) => ev.preventDefault()} // TODO
      onDrop={handleDrop}
      onClick={handleClick}
      className={clsx(
        styles.dropZone,
        isDragging && styles.isDragging,
        disabled && styles.disabled,
        darkMode && styles.darkMode,
      )}
    >
      <UploadIcon
        className={clsx(styles.uploadIcon, darkMode && styles.darkMode)}
      />
      <div className={styles.uploadText}>
        {translations.fileDropZoneLabel}
        <input
          type="file"
          multiple
          onChange={handleFileInputChange}
          hidden
          accept=".pdf"
          ref={ref}
        />
      </div>
    </div>
  );
};

export default DocumentDragAndDrop;
